import React, { Component } from "react";
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Input, Modal, ModalBody, Label, Button, Form as Form1 } from "reactstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { StyledButtondiv3, StyledButtondiv4 } from "../../helpers/StyledButtondiv";
import InfiniteScroll from "react-infinite-scroller";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { Translation } from "react-i18next";
import queryString from "query-string";
import i18next from "i18next";
import ModalLayoutAlert from "../../helpers/ModalLayoutAlert";
import nonTransportation from '../../assets/img/non_transportation.png';
import { StyledTab } from '../../helpers/StyledCartLink';
const OrderStatusOpts = [
  { value: "", label: "commons.Select_Order_Status" },
  { value: "created", label: "commons.Created" },
  { value: "cancelled", label: "commons.Cancelled" },
  { value: "ready_to_pickup", label: "commons.Ready_To_Pickup" },
  { value: "picked_up", label: "commons.Picked_Up" },
  { value: "received", label: "commons.Received" },
  { value: "in-process ", label: "commons.In_Process" },
  { value: "finished", label: "commons.Finished" },
  { value: "ready_to_deliver", label: "commons.Ready_To_Deliver" },
  { value: "out_for_delivery", label: "commons.Out_For_Delivery" },
  { value: "delivered", label: "commons.Delivered" },
];

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}
const ServiceTypeFormSchema = Yup.object().shape({
  start_date: Yup.string(),
  end_date: Yup.string(),
  order_status: Yup.string(),
});

export default class Dashboard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "",
      limit: 10,
      hasMoreItems: true,
      orderList: [],
      modal: false,
      start_date: "",
      end_date: "",
      order_status: "",
      keyword: "",
      isLoading: false,
      showModal: false,
      vendorImage: "",
      vendorName: "",
      ratings: 0,
      lang: localStorage.getItem("lang") || "en",
      show: false,
      reorder_item_id: "",
      is_reorder_loader: false
    };
    this.timer = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.keyword !== this.state.keyword) {
      this.handleCheck();
    }
  }

  handleSearch = (e) => {
    this.setState({ keyword: e.target.value });
  };

  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.scroll.pageLoaded = 0;
      this.getOrders();
    }, 500);
  };

  componentDidMount = async () => {
    let query = queryString.parse(this.props.location.search);
    if (query && query.status) {
      this.showPaymentAlert();
    } else if (query && query.easy_pickup == '1') {
      this.setState({ activeTab: 'easy_pickup' });
    } else {
      this.setState({ activeTab: 'current' })
    }
    await this.getOrders();
  }
  showPaymentAlert = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  getOrderStatus(status) {
    switch (status) {
      case "created":
        return "commons.Created";
      case "cancelled":
        return "commons.Cancelled";
      case "ready_to_pickup":
        return "commons.Ready_To_Pickup";
      case "picked_up":
        return "commons.Picked_Up";
      case "received":
        return "commons.Received";
      case "in_process":
        return "commons.In_Process";
      case "finished":
        return "commons.Finished";
      case "ready_to_deliver":
        return "commons.Ready_To_Deliver";
      case "out_for_delivery":
        return "commons.Out_For_Delivery";
      case "delivered":
        return "commons.Delivered";
    }
  }

  getPaymentStatus(status) {
    switch (status) {
      case "pending":
        return "commons.Pending";
      case "paid":
        return "commons.Paid";
      case "refunded":
        return "commons.Refunded";
      case "failed":
        return "commons.Failed";
      case "partially paid":
        return "commons.partially paid";
    }
  }

  // get order details
  getOrders = async (currentPage = 1) => {
    var query = queryString.parse(this.props.location.search);
    var easypickup = query.easy_pickup;
    let path =
      ApiRoutes.GET_ORDERS +
      "?page_no=" +
      `${currentPage}` +
      "&limit=" +
      `${this.state.limit}` +
      `${this.state.start_date ? "&start_date=" + `${moment(this.state.start_date).format("YYYY-MM-DD")}` : ""}` +
      `${this.state.end_date ? "&end_date=" + `${moment(this.state.end_date).format("YYYY-MM-DD")}` : ""}` +
      `${this.state.order_status ? "&order_status=" + `${this.state.order_status}` : ""}` +
      `${this.state.keyword ? "&keyword=" + `${this.state.keyword}` : ""}` +
      `${this.state.activeTab ? "&tab_type=" + `${this.state.activeTab}` : easypickup == 1 ? "&tab_type=" + "easy_pickup" : "&tab_type=" + "current"}`;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        if (currentPage == 1) {
          this.setState({ orderList: [] }, () =>
            this.setState({
              orderList: [...this.state.orderList, ...resJson.data.docs],
              hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
            })
          );
        } else {
          this.setState({
            orderList: [...this.state.orderList, ...resJson.data.docs],
            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          });
        }
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  onChangeTab = (tabId) => {
    this.scroll.pageLoaded = 0;
    this.setState(
      {
        activeTab: tabId,
        orderList: [],
      },
      () => this.getOrders()
    );
  };

  toggle = () => {
    this.setState({
      modal: true,
    });
  };


  customerReorder = async (item_id) => {
    this.setState({
      ...this.state,
      is_reorder_loader: true,
      reorder_item_id: item_id
    });
    let path = ApiRoutes.RE_ORDER + "/" + item_id;

    const res = await Http("PUT", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        setTimeout(() => {
          this.props.history.push("/cart");
        }, 2000);
      }
      else if (res.status == 422) {
        NotificationManager.error(resJson.message, "Error!", 3000);
        this.setState({
          ...this.state,
          is_reorder_loader: false
        })
      }
      else {
        var cartaction = window.confirm(i18next.t("commons.Your_cart_contains_the_items_of_other_vendor._Do_you_want_to_clear_the_cart_before_browsing_other_vendors?"));
        if (cartaction == true) {
          this.deleteCart();
        } else if (cartaction == false) {
          this.setState({
            ...this.state,
            is_reorder_loader: false
          })
        }
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }

  };


  deleteCart = async () => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_CART;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.customerReorder(this.state.reorder_item_id)
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  changeRating(newRating, name) {
    this.setState({
      rating: newRating,
    });
  }

  hideModal = () => {
    this.scroll.pageLoaded = 0;
    this.setState(
      {
        modal: false,
        start_date: "",
        end_date: "",
        order_status: "",
        orderList: [],
      },
      () => this.getOrders()
    );
  };

  hideShowModal = () => {
    this.setState({
      showModal: false,
    });
  };

  loadPagination = async (currentPage) => {
    this.getOrders(currentPage);
  };
  OrderTimeFormat = (timestamp) => {
    var date = timestamp;
    date = date.split('T');
    var timestamp_date = date[0];
    var time = date[1].split(':');
    var hours = time[0];
    var minutes = time[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes == '00' ? minutes : minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  render() {
    const theme = this.context;
    var { orderList } = this.state;
    let query = queryString.parse(this.props.location.search);
    const loader = <div className="loading"></div>;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="py-3 px-md-5 px-4 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars p-2"></i>
                </div>
              </div>
              <div className="box_grey py-3 px-4 my-3">
                <Row>
                  <Col xs="12" lg="6">
                    <div className="order_tabs">
                      <div>
                        {/* <a
                          className={classnames({
                            active: this.state.activeTab === "current",
                          })}
                          onClick={() => {
                            this.onChangeTab("current");
                          }}
                        >
                          {t("commons.Current_Orders")}
                        </a> */}
                        <StyledTab
                          isActive={this.state.activeTab === "current"}
                          onClick={() => this.onChangeTab("current")}
                        >
                          {t("commons.Current_Orders")}
                        </StyledTab>
                      </div>
                      <div>
                        {/* <a
                          className={"easy_pickup " + classnames({
                            active: this.state.activeTab === "easy_pickup",
                          })}
                          onClick={() => {
                            this.onChangeTab("easy_pickup");
                          }}
                        >
                          {t("commons.easy_pickup_Orders")}
                        </a> */}
                        <StyledTab
                          isActive={this.state.activeTab === "easy_pickup"}
                          onClick={() => this.onChangeTab("easy_pickup")}
                        >
                          {t("commons.easy_pickup_Orders")}
                        </StyledTab>
                      </div>
                      <div>
                        {/* <a
                          className={classnames({
                            active: this.state.activeTab === "past",
                          })}
                          onClick={() => {
                            this.onChangeTab("past");
                          }}
                        >
                          {t("commons.Past_Orders")}
                        </a> */}
                        <StyledTab
                          isActive={this.state.activeTab === "past"}
                          onClick={() => this.onChangeTab("past")}
                        >
                          {t("commons.Past_Orders")}
                        </StyledTab>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12" lg="6">
                    <div className="d-md-flex justify-content-lg-end pt-3 pt-lg-0">
                      <Form1 className="filter_search" onSubmit={(e) => e.preventDefault()}>
                        <FormGroup className="mb-0">
                          <Input
                            type="text"
                            name="search"
                            placeholder={t("commons.Search_by_keyword")}
                            onChange={this.handleSearch}
                            onKeyDown={(e) => {
                              if (e.keyCode == 13) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </FormGroup>
                      </Form1>
                      <span className="filter_view" onClick={this.toggle}>
                        <img src="./assets/img/filter.svg" alt="Filter" />
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>


              <div activetab={this.state.activeTab}>
                <div>
                  <InfiniteScroll
                    ref={(scroll) => {
                      this.scroll = scroll;
                    }}
                    pageStart={0}
                    loadMore={this.loadPagination}
                    hasMore={this.state.hasMoreItems}
                    loader={<div key={0} className="loader"></div>}
                  >
                    <Row>
                      {orderList &&
                        orderList.map((item, index) => {
                          if (item.amountInfo.total_amount_paid_by_customer == 0 && item.wallet_used == "yes") {
                            var totalAmount = item.walletAmount;
                          } else if (item.amountInfo.total_amount_paid_by_customer != 0 && item.wallet_used == "yes") {
                            var totalAmount = item.amountInfo.total_amount_paid_by_customer + item.walletAmount;
                          } else {
                            var totalAmount = item.amountInfo.total_amount_paid_by_customer;
                          }
                          return (
                            <Col xs="12" lg="6" key={index}>
                              <div className="box_grey bg-white p-4 mb-3">
                                <span className="d-flex justify-content-between">
                                  <span>
                                    <Link
                                      to={{
                                        pathname: "/order-info/" + item._id,
                                      }}
                                    >
                                      <h5 className="m-0 pb-1 title" style={{ color: theme.colors.primary }}> {item.vendor.shop_name && this.state.lang && item.vendor.shop_name[this.state.lang]}</h5>

                                      {/* <h6 className="m-0 pb-1 title">{item.vendor.branch_name && this.state.lang && item.vendor.branch_name[this.state.lang]}</h6> */}
                                    </Link>
                                  </span>
                                  {/* {this.state.activeTab === "past" && */}
                                  <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <div>
                                      {
                                        !this.state.is_reorder_loader && item.reorder_method == 1 && (
                                          // <span
                                          //   onClick={this.customerReorder.bind(
                                          //     this,
                                          //     item._id
                                          //   )}
                                          //   className={item.order_items == 0 ? "btn-reorder disabled" : "btn-reorder"}
                                          //   style={item.is_easy_pickup == 0 ? { opacity: '1' } : { opacity: '0' }}
                                          // >
                                          //   {t("commons.Reorder")}
                                          // </span>
                                          <StyledButtondiv4 onClick={this.customerReorder.bind(this, item._id)}
                                            className={item.order_items == 0 ? "btn-reorder disabled" : "btn-reorder"}
                                            tyle={item.is_easy_pickup == 0 ? { opacity: '1' } : { opacity: '0' }}>
                                            {t("commons.Reorder")}
                                          </StyledButtondiv4>
                                        )
                                      }
                                      {
                                        this.state.is_reorder_loader && this.state.reorder_item_id == item._id && (
                                          <button class="btn-reorder" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                          </button>
                                        )
                                      }
                                    </div>

                                    {item.is_express_delivery == 1 ? (
                                      <div style={item.is_easy_pickup == 0 ? { marginTop: '20px' } : { marginTop: '0' }}>
                                        <img
                                          src="./assets/img/express_delivery.png"
                                          alt="Filter"
                                          className="mb-3"
                                          style={{
                                            width: "80px",
                                            height: "40px",
                                            position: 'absolute'
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                  </div>
                                  {/* } */}
                                </span>

                                <div style={{ paddingTop: '20px' }} className="d-flex align-items-center">
                                  <div className="w-100 order_info" >
                                    <div className="d-flex " style={{ justifyContent: "space-between", alignItems: 'flex-end' }}>
                                      <span className="pr-3 mb-2">
                                        {t("commons.Branch_Name")} :{" "}
                                        <span style={{ color: theme.colors.primary }}>
                                          {item.vendor.branch_name && this.state.lang && item.vendor.branch_name[this.state.lang]}
                                        </span>
                                      </span>
                                      {/* for non transportation image display */}
                                      {
                                        item && item.with_or_without_trans == 0 && (
                                          <div>
                                            <img
                                              src={nonTransportation}
                                              alt="Non Transportation"
                                              className="mb-3"
                                              style={{
                                                width: "80px",
                                                height: "76px",
                                              }}
                                            />
                                          </div>)
                                      }{" "}
                                    </div>
                                    <span className="pr-3 d-inlne">
                                      {t("commons.Order_Id")}:{" "}
                                      <Link
                                        to={{
                                          pathname: "/order-info/" + item._id,
                                        }}
                                      > <span style={{ color: theme.colors.primary }}>
                                          {item.order_number}
                                        </span>
                                      </Link>
                                    </span>
                                    <span className="pr-3 d-inlne">
                                      {t("commons.Total_Amount")}:{" "}
                                      <span style={{ color: theme.colors.primary }}
                                      >
                                        {t("commons.OMR") + " " + (totalAmount == undefined ? 0.000 : totalAmount.toFixed(3))}
                                      </span>
                                    </span>
                                    {item.with_or_without_trans == 1 && (
                                      <span className="pr-3 d-inlne">
                                        {t("commons.Pickup_Date")}:
                                        <span dir="ltr">
                                          {moment(item.pickup_date).format("DD MMM YYYY")}
                                          {", "}
                                          {moment().format('YYYY-MM-DD[T00:00:00.000Z]') > '2022-12-21T00:00:00.000Z' ?
                                            item && item.pickup_time && this.OrderTimeFormat(item.pickup_time)
                                            :
                                            moment(item.pickup_time).utc().format("hh:mm A")
                                          }
                                        </span>

                                      </span>
                                    )}
                                    {item.with_or_without_trans == 1 && (
                                      <span className="pr-3 d-inlne">
                                        {t("commons.Delivery_Date_Time")}:
                                        <span dir="ltr">
                                          {moment(item.delivery_date).format("DD MMM YYYY")}
                                          {", "}
                                          {moment().format('YYYY-MM-DD[T00:00:00.000Z]') > '2022-12-21T00:00:00.000Z' ?
                                            item && item.delivery_time && this.OrderTimeFormat(item.delivery_time)
                                            :
                                            moment(item.delivery_time).utc().format("hh:mm A")
                                          }
                                        </span>
                                      </span>
                                    )}
                                    <span
                                      className="pr-3 d-inlne"
                                      style={{
                                        textTransform: "capitalize",
                                        display: "block",
                                      }}
                                    >
                                      {t("commons.Order_Status")}:{" "}
                                      <span
                                        style={{
                                          color: theme.colors.primary,
                                        }}
                                      >
                                        {t(this.getOrderStatus(item.order_status))}
                                      </span>
                                    </span>
                                    <span className="pr-3 d-inlne">
                                      {t("commons.Payment_Status")}:{" "}
                                      <span
                                        style={{
                                          color: theme.colors.primary,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {t(this.getPaymentStatus(item.payment_status))}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                    </Row>
                  </InfiniteScroll>
                </div>
              </div>
              {/* )} */}
              {orderList && orderList.length == 0 && <div className="noorder" style={{ color: theme.colors.primary }}>{t("commons.No_orders_are_available")}</div>}
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>

            {/* Modal for filtering */}
            <Modal isOpen={this.state.modal} backdrop={"static"} keyboard={false}>
              <Formik
                enableReinitialize
                initialValues={{
                  start_date: this.state.start_date,
                  end_date: this.state.end_date,

                  order_status: this.state.order_status,
                }}
                validationSchema={ServiceTypeFormSchema}
                onSubmit={() => {
                  this.scroll.pageLoaded = 0;
                  this.setState({ orderList: [], modal: false }, () => this.getOrders());
                }}
              >
                {({ getOrders, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="filter_modal_header" style={{ backgroundColor: theme.colors.primary }}>
                      {/* <span onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                        {t("filters.Clear")}
                      </span> */}
                      <StyledButtondiv3 onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                        {t("filters.Clear")}
                      </StyledButtondiv3>
                      <div className="filter_modal_heading">{t("commons.Filter")}</div>
                      {/* <button type="submit" style={{ cursor: "pointer" }} className="btn1">
                        {t("filters.Apply")}
                      </button> */}
                      <StyledButtondiv3 type="submit" style={{ cursor: "pointer" }} className="btn1">
                        {t("filters.Apply")}
                      </StyledButtondiv3>
                    </div>

                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <DatePicker
                        className="form-control"
                        selected={this.state.start_date}
                        name="start_date"
                        autoComplete="off"
                        onChange={(date) => {
                          this.setState({ start_date: date });
                        }}
                        placeholderText={t("commons.Start_Date")}
                      ></DatePicker>

                      {errors.start_date && touched.start_date && <div className="invalid-feedback d-block">{errors.start_date}</div>}
                    </FormGroup>

                    <FormGroup className="pl-3 pr-3">
                      <DatePicker
                        className="form-control"
                        selected={this.state.end_date}
                        name="end_date"
                        autoComplete="off"
                        onChange={(date) => {
                          this.setState({ end_date: date });
                        }}
                        placeholderText={t("commons.End_Date")}
                      ></DatePicker>
                      {errors.end_date && touched.end_date ? <div className="invalid-feedback d-block">{errors.end_date}</div> : null}
                    </FormGroup>

                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <select
                        className="form-control"
                        name="order_status"
                        value={this.state.order_status}
                        onChange={(event) => {
                          this.setState({ order_status: event.target.value });
                        }}
                      >
                        {OrderStatusOpts.map((item, index) => {
                          return (
                            <>
                              <option value={item.value} key={index}>
                                {t(item.label)}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {errors.order_status && touched.order_status ? <div className="invalid-feedback d-block">{errors.order_status}</div> : null}
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Modal>
            {query && query.status == "success" && (
              <ModalLayoutAlert onClose={this.showPaymentAlert} show={this.state.show}>
                {t("commons.Your_payment_has_been_made_successfully_for_this_order")}
              </ModalLayoutAlert>
            )}

            {query && query.status == "cancel" && (
              <ModalLayoutAlert onClose={this.showPaymentAlert} show={this.state.show}>
                {t("commons.Your_payment_has_been_made_cancel_for_this_order")}
              </ModalLayoutAlert>
            )}
          </>
        )}
      </Translation>
    );
  }
}
