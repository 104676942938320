import React, { Component } from "react";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ThemeContext } from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import {StyledButtondiv1} from "../../helpers/StyledButtondiv";
import { Translation } from "react-i18next";
import i18next from "i18next";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const phoneRegExp = /^[1-9][0-9]{6,15}$/;

const FormEmailSchema = Yup.object().shape({
  email: Yup.string().required("commons.Please_enter_email_address").email("commons.Please_enter_valid_email_id").max(50, "commons.Please_enter_email_id_with_atmost_50_letters"),
});

const FormPhoneSchema = Yup.object().shape({
  phone: Yup.string()
    .required("commons.Please_enter_phone_number")

    .min(7, "commons.Phone_number_must_contain_atleast_7_letters.")
    .max(15, "commons.Phone_number must_contain_atmost_15_letters.")
    .matches(phoneRegExp, "commons.Please_enter_valid_phone_number"),
});

export default class ProfileEdit extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      phone: "",
      editEmail: "",
      editPhone: "",
      userData: [],
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = async () => {
    let path = ApiRoutes.GET_PROFILE;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.setAuthData(resJson.data);
        this.setState({
          userData: resJson.data,
          editEmail: resJson.data.editable_fields.find((val) => val == "email"),
          editPhone: resJson.data.editable_fields.find((val) => val == "phone"),
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  handlePhoneSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("phone", inputValues.phone);
    formData.append("email", this.props.authUser && this.props.authUser.email);
    formData.append("otp_for", "phone_update");

    let path = ApiRoutes.RESEND_OTP;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(i18next.t("commons.OTP_has_been_sent_to_provided_Phone"), "Success", 3000);
        this.props.history.push({
          pathname: "/verifyOTP",
          state: {
            phone: resJson.data.phone,
            email: resJson.data.email,
            otp_number: resJson.data.otp_number,
            otp_for: "phone_update",
          },
        });
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            NotificationManager.error(resJson.message, "Error!", 3000);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  handleEmailSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("phone", this.props.authUser && this.props.authUser.phone);
    formData.append("email", inputValues.email);
    formData.append("otp_for", "email_update");

    let path = ApiRoutes.RESEND_OTP;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(i18next.t("commons.OTP_has_been_sent_to_provided_email"), "Success", 3000);
        this.props.history.push({
          pathname: "/verifyOTP",
          state: {
            phone: resJson.data.phone,
            email: resJson.data.email,
            otp_number: resJson.data.otp_number,
            otp_for: "email_update",
          },
        });
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            NotificationManager.error(resJson.message, "Error!", 3000);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  render() {
    const { userData, editEmail, editPhone } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <h1 className="page_title mb-5 custom-page-tit-phem">{t("headings.Edit_Email_&_Phone_Number")}</h1>

              <Row className="justify-content-center">
                <Col xs={12}>
                  <div className="manage_account_user_sec p-5">
                    <Row>
                      <Col xs={12} md={6}>
                        <center>
                          <FormGroup className="mb-3 pl-3 pr-3">
                            <img
                              src={this.props.authUser && this.props.authUser.user_image_url}
                              style={{
                                width: "130px",
                                borderRadius: "100px",
                                border: "2px solid rgba(0, 0, 0, 0.05)",
                              }}
                            ></img>
                          </FormGroup>
                        </center>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <center>
                          <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                            <h5>{this.props.authUser && this.props.authUser.firstName + " " + this.props.authUser.lastName}</h5>
                          </FormGroup>
                        </center>
                      </Col>
                    </Row>

                    <Formik
                      enableReinitialize
                      initialValues={{
                        email: userData && userData.email ? userData.email : "",
                      }}
                      validationSchema={FormEmailSchema}
                      onSubmit={this.handleEmailSubmit}
                    >
                      {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                        <Form className="login_signup_form custom_email_phn_cls">
                          <Row>
                            <Col xs={12} md={6}>
                              <Label>{t("labels.email")}</Label>
                              <FormGroup>
                                <Field
                                  className="form-control"
                                  name="email"
                                  type="email"
                                  placeholder={t("placeholders.emailAddress")}
                                  disabled={editEmail && editEmail ? false : true}
                                  onChange={(event) => {
                                    setFieldValue("email", event.target.value.trimStart());
                                  }}
                                />
                                {errors.email && touched.email ? <div className="invalid-feedback d-block">{t(errors.email)}</div> : null}
                              </FormGroup>
                            </Col>
                            {editEmail && editEmail ? (
                              <Col xs={12} md={6} className="mt-4">
                                {/* <Button className="btn text-uppercase" type="submit">
                                  {t("buttons.update")}
                                </Button> */}
                                <StyledButtondiv1 type="submit" className="btn text-uppercase">{t("buttons.update")}</StyledButtondiv1>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Form>
                      )}
                    </Formik>

                    <Formik
                      enableReinitialize
                      initialValues={{
                        phone: userData && userData.phone ? userData.phone : "",
                      }}
                      validationSchema={FormPhoneSchema}
                      onSubmit={this.handlePhoneSubmit}
                    >
                      {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                        <Form className="login_signup_form custom_email_phn_cls">
                          <Row>
                            <Col xs={12} md={6}>
                              <Label>{t("labels.phone")}</Label>
                              <FormGroup>
                                <Field
                                  className="form-control"
                                  name="phone"
                                  type="text"
                                  placeholder={t("placeholders.phone")}
                                  disabled={editPhone && editPhone ? false : true}
                                  onChange={(event) => {
                                    setFieldValue("phone", event.target.value.trimStart());
                                  }}
                                />
                                {errors.phone && touched.phone ? <div className="invalid-feedback d-block">{t(errors.phone)}</div> : null}
                              </FormGroup>
                            </Col>

                            {editPhone && editPhone ? (
                              <Col xs={12} md={6} className="mt-4">
                                {/* <Button className="btn text-uppercase" type="submit">
                                  {t("buttons.update")}
                                </Button> */}
                                <StyledButtondiv1 type="submit" className="btn text-uppercase">{t("buttons.update")}</StyledButtondiv1>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
          </>
        )}
      </Translation>
    );
  }
}
