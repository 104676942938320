/** MainLayout Components **/
import PageNotFound from "./components/mainLayout/PageNotFound";
import AboutUs from "./components/mainLayout/AboutUs";
import ContactUs from "./components/mainLayout/ContactUs";
import Terms from "./components/mainLayout/Terms";
import FAQ from "./components/mainLayout/FAQ";
import PrivacyPolicy from "./components/mainLayout/PrivacyPolicy";
import Home from "./components/mainLayout/Home";
import VendorsList from "./components/mainLayout/VendorsList";
import VendorDetails from "./components/mainLayout/VendorDetails";
import ServiceList from "./components/mainLayout/ServiceList";
import Service from "./components/mainLayout/Service";

/** UnAuthLayout Components **/
import Login from "./components/unAuthLayout/Login";
import Register from "./components/unAuthLayout/Register";
import VerifyOtp from "./components/unAuthLayout/VerifyOtp";
import ForgotPassword from "./components/unAuthLayout/ForgotPassword";
import ResetPassword from "./components/unAuthLayout/ResetPassword";

/** AuthLayout Components **/
import ProfileView from "./components/authLayout/ProfileView";
import ProfileEdit from "./components/authLayout/ProfileEdit";
import ChangePassword from "./components/authLayout/ChangePassword";
import ManageAddress from "./components/authLayout/ManageAddress";
import ManageRepeatedOrders from "./components/authLayout/ManageRepeatedOrders";

import Favourites from "./components/authLayout/Favourites";
import OrdersList from "./components/authLayout/OrdersList";
import OrderDetails from "./components/authLayout/OrderDetails";
import Notifications from "./components/authLayout/Notifications";
import TrackOrder from "./components/authLayout/TrackOrder";
import Settings from "./components/authLayout/Settings";
import Promocodes from "./components/authLayout/Promocodes";
import PromocodeDetails from "./components/authLayout/PromocodeDetail";
import WalletHistory from "./components/authLayout/WalletHistory";
import Wallet from "./components/authLayout/Wallet";



import Cart from "./components/mainLayout/Cart";
import CheckOut from "./components/mainLayout/CheckOut";
import AdditionalInformation from "./components/mainLayout/AdditionalInformation";
import Payment from "./components/mainLayout/Payment";
import VerifyOTPEmail from "./components/authLayout/VerifyOTPEmail";
import EmailEdit from "./components/authLayout/EmailEdit";

import Package from "./components/authLayout/PromotionPackages";
import PackageDetail from "./components/authLayout/PackageDetails";
import PromotionList from "./components/authLayout/PromotionsList";
import PromotionInfo from "./components/authLayout/PromotionInfo";
import RegisterCorporateUser from "./components/unAuthLayout/RegisterCorporateUser";

import CorporatePackage from "./components/authLayout/CorporatePackage";
import CorporatePackageDetail from "./components/authLayout/CorporatePackageDetail";


/** Routes **/
const routesItems = [
  { path: "/page-not-found", component: PageNotFound, layout: "mainLayout" },
  { path: "/about-us", component: AboutUs, layout: "mainLayout" },
  { path: "/FAQ", component: FAQ, layout: "mainLayout" },
  { path: "/contact-us", component: ContactUs, layout: "mainLayout" },
  { path: "/privacy-policy", component: PrivacyPolicy, layout: "mainLayout" },
  { path: "/terms", component: Terms, layout: "mainLayout" },

  { path: "/home", component: Home, layout: "mainLayout" },
  { path: "/vendors", component: VendorsList, layout: "mainLayout" },
  {
    path: "/vendor-details/:itemId",
    component: VendorDetails,
    layout: "mainLayout",
  },

  {
    path: "/service-list/:itemId",
    component: ServiceList,
    layout: "mainLayout",
  },

  {
    path: "/service/:itemId/:vendor_id",
    component: Service,
    layout: "mainLayout",
  },

  { path: "/login", component: Login, layout: "unAuthLayout" },
  { path: "/register", component: Register, layout: "unAuthLayout" },
  { path: "/verify-otp", component: VerifyOtp, layout: "unAuthLayout" },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    layout: "unAuthLayout",
  },
  { path: "/reset-password", component: ResetPassword, layout: "unAuthLayout" },

  { path: "/manage-profile", component: ProfileView, layout: "authLayout" },
  { path: "/edit-profile", component: ProfileEdit, layout: "authLayout" },
  { path: "/change-password", component: ChangePassword, layout: "authLayout" },
  { path: "/manage-address", component: ManageAddress, layout: "authLayout" },
  { path: "/manage-repeated-orders", component: ManageRepeatedOrders, layout: "authLayout" },
  { path: "/notifications", component: Notifications, layout: "authLayout" },
  { path: "/edit", component: EmailEdit, layout: "authLayout" },
  { path: "/favourites", component: Favourites, layout: "authLayout" },
  { path: "/manage-orders", component: OrdersList, layout: "authLayout" },
  { path: "/promocodes", component: Promocodes, layout: "authLayout" },

  // { path: "/promocode/:itemId", component: PromocodeDetails, layout: "authLayout" },
  { path: "/promocode/:itemId/:promocode_type", component: PromocodeDetails, layout: "authLayout" },

  
  { path: "/promotions", component: PromotionList, layout: "authLayout" },
  { path: "/promotion/:itemId", component: PromotionInfo, layout: "authLayout" },
  { path: "/wallet-pay", component: Wallet, layout: "authLayout" },
  { path: "/wallet-history", component: WalletHistory, layout: "authLayout" },
  { path: "/track-order/:itemId", component: TrackOrder, layout: "authLayout" },
  { path: "/settings", component: Settings, layout: "authLayout" },

  { path: "/view-all-package/:itemId", component: Package, layout: "authLayout" },
  { path: "/package/:itemId", component: PackageDetail, layout: "authLayout" },


  {
    path: "/order-info/:itemId",
    component: OrderDetails,
    layout: "authLayout",
  },

  { path: "/verifyOTP", component: VerifyOTPEmail, layout: "authLayout" },

  { path: "/cart", component: Cart, layout: "mainLayout" },
  { path: "/checkout/:vendor_id", component: CheckOut, layout: "mainLayout" },
  {
    path: "/additional_info/:vendor_id",
    component: AdditionalInformation,
    layout: "mainLayout",
  },
  {
    path: "/payment/:vendor_id",
    component: Payment,
    layout: "mainLayout",
  },

  {
    path: "/register-corporateuser",
    component: RegisterCorporateUser,
    layout: "unAuthLayout",
  },

  //corporate package
  { path: "/corporate-package", component: CorporatePackage, layout: "authLayout" },
  { path: "/corporate-package-detail/:package_id/:vendor_id/:corporate_main_admin_id/:corporate_sub_admin_id/:branch_location_id", component: CorporatePackageDetail, layout: "authLayout" },

];

export default routesItems;
