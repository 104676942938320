import React, { Component } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';

import { Translation } from "react-i18next";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

export default class Notifications extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      item: "",
      itemData: "",
      couponData: "",
      subcategoryData: "",
      main_category: "",
      child_category: "",
      id: props.match.params.itemId,
      promocode_type: props.match.params.promocode_type,
      isloading: false,
      lang: localStorage.getItem("lang") || "en",
    };
  }

  componentDidMount() {
    if (this.state.promocode_type == 'free_delivery') {
      this.getFreedelivery();
    } else if (this.state.promocode_type == 'global_discount') {
      this.getPromoCodes();
    } else if (this.state.promocode_type == 'individual_discount') {
      this.getCouponCode();
    } else if (this.state.promocode_type == 'subcategory_discount') {
      this.getSubCategory();
    }
    this.setState({ lang: localStorage.getItem("lang") || "en" });

  }

  getPromoCodes = async () => {
    this.setState({ isloading: true });
    let path = ApiRoutes.GET_PROMOCODES + "/" + this.state.id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        if (this.state.lang == 'en') {
          moment.locale('en');
        } else {
          moment.locale('ar');
        }
        this.setState(
          {
            item: resJson.data,
          },
          () => this.setState({ isloading: false })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  getFreedelivery = async () => {
    this.setState({ isloading: true });
    let path = ApiRoutes.GET_FREEDELVIERY_OFFERS + "/" + this.state.id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        if (this.state.lang == 'en') {
          moment.locale('en');
        } else {
          moment.locale('ar');
        }
        this.setState(
          {
            itemData: resJson.data,
          },
          () => this.setState({ isloading: false })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  getCouponCode = async () => {
    this.setState({ isloading: true });
    let path = ApiRoutes.GET_COUPON_CODES + "/" + this.state.id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        if (this.state.lang == 'en') {
          moment.locale('en');
        } else {
          moment.locale('ar');
        }
        this.setState(
          {
            couponData: resJson.data,
          },
          () => this.setState({ isloading: false })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  getSubCategory = async () => {
    this.setState({ isloading: true });
    let path = ApiRoutes.GET_SUBCATEGORYDISCOUNT + "/" + this.state.id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        if (this.state.lang == 'en') {
          moment.locale('en');
        } else {
          moment.locale('ar');
        }
        var main_category = [];
        var child_category = [];
        var subcategory_values = [];
        Object.entries(resJson.data.main_category).forEach(([key, value]) => {
          main_category.push(value.name[this.state.lang]);
        });
        Object.entries(resJson.data.child_category).forEach(([key, value]) => {
          child_category.push(value.name[this.state.lang]);
        });
        var main_category1 = main_category.join(", ");
        var child_category1 = child_category.join(", ");
        this.setState(
          {
            subcategoryData: resJson.data,
            main_category: main_category1,
            child_category: child_category1,
          },
          () => this.setState({ isloading: false })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  render() {
    const { item, itemData, lang, couponData, subcategoryData, main_category, child_category } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <section className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
            <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
              <div onClick={SidebarAddClass} className="btn text-white py-2 px-3 toggle_btn d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                <i className="fas fa-bars"></i>
              </div>
            </div>
            <div className="d-flex clearCart align-items-center">
              <div className="w-100 d-flex justify-content-between my_cart_content_mb">
                <div>
                  <h1 className="page_title mb-5 custom-page-tit-phem" style={{ width: "100%" }}>{t("menu.promocode_detail")}</h1>
                </div>
              </div>
            </div>

            <Row className="border py-3" style={{ backgroundColor: "white" }}>
              {this.state.isloading ? (
                <div className="loading"></div>
              ) : (
                <>
                  {this.state.item && this.state.item != '' ? (
                    <>
                      <Col md="6">
                        <div className="leftpaneladdres">
                          <div className="d-flex w-100">
                            <div className="venderdetailsright custom-free-delivery-cls">
                              <p>
                                <span>{t("menu.promocode")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{item.title}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("menu.couponcode")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{item.promocode}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("commons.Type of Offer")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b style={{ textTransform: "capitalize" }}>{item.type_of_offer}</b>
                                </span>
                              </p>
                              {item.discount_value != null && (
                                <p>
                                  <span>{t("commons.Discount")} : </span>{" "}
                                  <span className="themeColor" style={{color: theme.colors.primary}}>
                                    <b>
                                      {" "}
                                      {item.discount_value && item.discount_type && item.discount_type == "flat"
                                        ? t("commons.OMR") + " " + item.discount_value.toFixed(3)
                                        : item.discount_value + " %"}
                                    </b>
                                  </span>
                                </p>
                              )}
                              <p>
                                <span>{t("labels.Coupon_usage")} :  </span>{" "}
                                <span className="themeColor">
                                  <span className="coupon_used_times">
                                    <b style={{color: theme.colors.primary}}>{item.no_of_times_used} </b>
                                    {item.coupon_used_count > 0 ?
                                      <p>{"  ( " + item.coupon_used_count + " " + t("package-used") + " ) "}</p>
                                      : <></>
                                    }
                                  </span>
                                </span>
                              </p>{" "}
                              <p>
                                <span>{t("Validity")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{moment(item.expiry_date_from).format("MMM DD, YYYY") + "-" + moment(item.expiry_date_to).format("MMM DD, YYYY")}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("labels.Minimum_Order_Value")} : </span>{" "}
                                <span className="text-break themeColor" style={{color: theme.colors.primary}}>
                                  <b> {item.min_amount_to_applicable ? t("commons.OMR") + " " + item.min_amount_to_applicable.toFixed(3) : t("commons.N/A")}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("labels.Maximum_distance_Value")} : </span>{" "}
                                <span className="text-break themeColor" style={{color: theme.colors.primary}}>
                                  <b> {item.max_distance_to_applicable ? item.max_distance_to_applicable + " " + t("commons.KM") : t("commons.N/A")}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("labels.description")} : </span>{" "}
                                <span className="text-break themeColor" style={{color: theme.colors.primary}}>
                                  <b>{item.description ? item.description : t("commons.N/A")}</b>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="leftpaneladdres">
                          <h5 className="mb-3 applicable_vendor">{t("labels.Applicable_Vendors")}</h5>
                          <div className="d-flex w-100">
                            <div className="venderdetailsright w-100 pl-0">
                              
                              {item && item.vendors && item.vendors.length > 0 ? (
                                item.vendors.map((val, index) => {
                                  return (
                                    <p className="themeColor mt-2" style={{ cursor: "pointer" }} key={index} onClick={() => this.props.history.push("/vendor-details/" + val._id)}>
                                      <b style={{color: theme.colors.primary}}> {val.shop_name[lang] + " (" + val.branch_name[lang] + ")"}</b>
                                    </p>
                                  );
                                })
                              ) : (
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{t("commons.Applicable_to_all_vendors")}</b>
                                </span>
                              )}
                             
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <h5 className="mb-3 applicable_timing">{t("Applicable_timings")}</h5>
                        {item && item.working_days && item.working_days != undefined && item.working_days.length > 0 ? (
                          item.working_days.map((item1, index1) => {
                            return (
                              <>
                                {
                                  item1.timings.length > 0 ? (
                                    <>
                                      <div className="Free-delivery-timings-section">
                                        <div className="free-delivery-offer-days">{t(item1.day)}</div>
                                        <div className="free-delivery-offer-timings">
                                          {item1.timings.map(function (item_1, i) {
                                            return (<p style={{ margin: "0px", color: theme.colors.primary }}>{item_1.start}-{item_1.end}</p>)
                                          })}
                                        </div>

                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                              </>

                            )
                          }
                          )
                        ) : (
                          <></>
                        )}
                      </Col>
                    </>
                  ) : this.state.itemData && this.state.itemData != '' ? (
                    <>
                      <Col md="6">
                        <div className="leftpaneladdres">
                          <div className="d-flex w-100">
                            <div className="venderdetailsright custom-free-delivery-cls">
                              <p>
                                <span>{t("commons.Shop_Name")} : </span>{" "}
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{itemData.shop_name[lang]}</b>{" "}

                                </span>
                              </p>
                              <p>
                                <span>{t("maximum_distance")} : </span>{" "}
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{itemData.maximum_distance}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("labels.Minimum_order")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{itemData.minimum_order}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("commons.Validity_till")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{moment(itemData.start_date).format("MMM DD, YYYY") + "-" + moment(itemData.end_date).format("MMM DD, YYYY")}</b>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="leftpaneladdres">
                          <h5 className="mb-3">{t("labels.free-delivery-timings")}</h5>
                          <div className="d-flex w-100">
                            <div className="venderdetailsright w-100 pl-0">
                              {itemData && itemData.free_delivery_timing && itemData.free_delivery_timing.length > 0 ? (
                                itemData.free_delivery_timing.map((item, index) => {
                                  return (
                                    <>
                                      {
                                        item.timings.length > 0 ? (
                                          <>
                                            <div className="Free-delivery-timings-section">
                                              <div className="free-delivery-offer-days">{t(item.day)}</div>
                                              <div className="free-delivery-offer-timings">
                                                {item.timings.map(function (item_1, i) {
                                                  return (<p style={{ margin: "0px", color: theme.colors.primary }}>{item_1.start} - {item_1.end}</p>)
                                                })}
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      }
                                    </>

                                  )
                                }
                                )
                              ) : (
                                <span className="themeColor">
                                  <b>{t("commons.Applicable_to_all_vendors")}</b>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : this.state.couponData && this.state.couponData != '' ? (
                    <>
                      <Col md="6">
                        <div className="leftpaneladdres">
                          <div className="d-flex w-100">
                            <div className="venderdetailsright custom-free-delivery-cls">
                              <p>
                                <span>{t("menu.promocode")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{couponData.title}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("menu.couponcode")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{couponData.couponcode}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("commons.Discount")} : </span>{" "}
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>
                                    {" "}
                                    {couponData.discount_value && couponData.discount_type && couponData.discount_type == "flat"
                                      ? t("commons.OMR") + " " + couponData.discount_value.toFixed(3)
                                      : couponData.discount_value + " %"}
                                  </b>
                                </span>
                              </p>
                            
                              <p>
                                <span>{t("labels.Minimum_Order_Value")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>
                                    {couponData.min_amount_to_applicable ? t("commons.OMR") + " " + couponData.min_amount_to_applicable.toFixed(3) : t("commons.N/A")}
                                  </b>
                                </span>
                              </p>
                              <p>
                                <span>{t("labels.Coupon_usage")} :</span>{" "}
                                <span className="themeColor">
                                  <span className="coupon_used_times">
                                    <b style={{color: theme.colors.primary}}> {couponData.no_of_times_used}</b>
                                    {couponData.coupon_used_count > 0 ?
                                      <p>{"  ( " + couponData.coupon_used_count + " " + t("package-used") + " ) "}</p>
                                      : <></>
                                    }
                                  </span>
                                </span>
                              </p>{" "}
                              <p>
                                <span>{t("Validity")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {couponData.from_expiry_date ?
                                    <b>{moment(couponData.from_expiry_date).format("MMM DD, YYYY") + " -" + moment(couponData.expiry_date).format("MMM DD, YYYY")}</b>
                                    :
                                    <b>{moment(new Date()).format("MMM DD, YYYY") + " -" + moment(couponData.expiry_date).format("MMM DD, YYYY")}</b>

                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="leftpaneladdres">
                          <h5 className="mb-3 applicable_vendor">{t("labels.Applicable_Vendors")}</h5>
                          <div className="d-flex w-100">
                            <div className="venderdetailsright w-100 pl-0">
                              {couponData && couponData.vendors && couponData.applicable_vendor != 'all' && couponData.vendors != null && couponData.vendors.length > 0 ? (
                                couponData.vendors.map((val, index) => {
                                  return (
                                    <p className="themeColor mt-2" style={{ cursor: "pointer" }} key={index} onClick={() => this.props.history.push("/vendor-details/" + val._id)}>
                                      <b style={{color: theme.colors.primary}}> {val.shop_name[lang] + " (" + val.branch_name[lang] + ")"}</b>
                                    </p>
                                  );
                                })
                              ) : (
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{t("commons.Applicable_to_all_vendors")}</b>
                                </span>
                              )}
                            </div>
                          </div>

                        </div>
                      </Col>
                      <Col md="3">
                        <h5 className="mb-3 applicable_timing">{t("Applicable_timings")}</h5>
                        {couponData && couponData.working_days && couponData.working_days != undefined && couponData.working_days.length > 0 ? (
                          couponData.working_days.map((item1, index1) => {
                            return (
                              <>
                                {
                                  item1.timings.length > 0 ? (
                                    <>
                                      <div className="Free-delivery-timings-section">
                                        <div className="free-delivery-offer-days">{t(item1.day)}</div>
                                        <div className="free-delivery-offer-timings">
                                          {item1.timings.map(function (item_1, i) {
                                            return (<p style={{ margin: "0px", color: theme.colors.primary }}>{item_1.start}-{item_1.end}</p>)
                                          })}
                                        </div>

                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                              </>

                            )
                          }
                          )
                        ) : (
                          <></>
                        )}
                      </Col>
                    </>
                  ) : this.state.subcategoryData && this.state.subcategoryData != '' ? (
                    <>
                      <Col md="6">
                        <div className="leftpaneladdres">
                          <div className="d-flex w-100">
                            <div className="venderdetailsright custom-free-delivery-cls">
                              <p>
                                <span>{t("menu.promocode")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{subcategoryData.title}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("menu.couponcode")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{subcategoryData.promocode}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("label_package_category")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{main_category}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("label_package_subcategory")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  {" "}
                                  <b>{child_category}</b>
                                </span>
                              </p>
                              <p>
                                <span>{t("commons.Discount")} : </span>{" "}
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>
                                    {" "}
                                    {t(subcategoryData.discount_type)}
                                    {/* {subcategoryData.discount_type} */}
                                  </b>
                                </span>
                              </p>
                              {subcategoryData && subcategoryData.discount_value && subcategoryData.discount_value.map((item, index) => {
                                return (
                                  <p>
                                    <span> {t("commons.Discount_value") + " " + item.name[lang]} : </span>
                                    <span className="themeColor" style={{color: theme.colors.primary}}>
                                      <b>
                                        {item.value}
                                      </b>
                                    </span>
                                  </p>
                                )
                              })}
                              {subcategoryData && subcategoryData.min_amount_to_applicable && subcategoryData.min_amount_to_applicable.map((item, index) => {
                                return (
                                  <p>
                                    <span> {t("minimum_amount_applicable") + " " + item.name[lang]} : </span>
                                    <span className="themeColor" style={{color: theme.colors.primary}}>
                                      <b>
                                        {item.value}
                                      </b>
                                    </span>
                                  </p>
                                )
                              })}
                              {subcategoryData && subcategoryData.service_types && subcategoryData.service_types.map((item, index) => {
                               var service_type = [];
                               return (
                                 
                                 <p>
                                   {item.ServiceName.map((name)=> {
                                     service_type.push(name[this.state.lang]);
                                       })}
                                   <span> {item.SubcategoryName[lang] + " " + t("discount_service_type")} : </span>
                                   <span className="themeColor" style={{color: theme.colors.primary}}>
                                       {/* {item.ServiceName[lang]} */}
                                       <b>{service_type.join(", ")}</b>
                                       
                                    </span>
                                  </p>
                                )
                              })}

                              <p>
                                <span>{t("labels.Coupon_usage")} :</span>{" "}
                                <span className="themeColor">
                                  <span className="coupon_used_times">
                                    <b style={{color: theme.colors.primary}}> {subcategoryData.no_of_times_used}</b>
                                    {subcategoryData.coupon_used_count > 0 ?
                                      <p>{"  ( " + subcategoryData.coupon_used_count + " " + t("package-used") + " ) "}</p>
                                      : <></>
                                    }
                                  </span>
                                </span>
                              </p>{" "}
                              <p>
                                <span>{t("Validity")} : </span>
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{moment(subcategoryData.expiry_date_from).format("MMM DD, YYYY") + " - " + moment(subcategoryData.expiry_date_to).format("MMM DD, YYYY")}</b>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="leftpaneladdres">
                          <h5 className="mb-3 applicable_vendor">{t("labels.Applicable_Vendors")}</h5>
                          <div className="d-flex w-100">
                            <div className="venderdetailsright w-100 pl-0">
                              {subcategoryData && subcategoryData.vendors && subcategoryData.applicable_vendor != 'all' && subcategoryData.vendors != null && subcategoryData.vendors.length > 0 ? (
                                subcategoryData.vendors.map((val, index) => {
                                  return (
                                    <p className="themeColor mt-2" style={{ cursor: "pointer" }} key={index} onClick={() => this.props.history.push("/vendor-details/" + val._id)}>
                                      <b style={{color: theme.colors.primary}}> {val.shop_name[lang] + " (" + val.branch_name[lang] + ")"}</b>
                                    </p>
                                  );
                                })
                              ) : (
                                <span className="themeColor" style={{color: theme.colors.primary}}>
                                  <b>{t("commons.Applicable_for_specific_laundry")}</b>
                                </span>
                              )}
                            </div>
                          </div>

                        </div>
                      </Col>
                      <Col md="3">
                        <h5 className="mb-3 applicable_timing">{t("Applicable_timings")}</h5>
                        {subcategoryData && subcategoryData.working_days && subcategoryData.working_days != undefined && subcategoryData.working_days.length > 0 ? (
                          subcategoryData.working_days.map((item1, index1) => {
                            return (
                              <>
                                {
                                  item1.timings.length > 0 ? (
                                    <>
                                      <div className="Free-delivery-timings-section">
                                        <div className="free-delivery-offer-days">{t(item1.day)}</div>
                                        <div className="free-delivery-offer-timings">
                                          {item1.timings.map(function (item_1, i) {
                                            return (<p style={{ margin: "0px", color: theme.colors.primary}}>{item_1.start}-{item_1.end}</p>)
                                          })}
                                        </div>

                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                              </>

                            )
                          }
                          )
                        ) : (
                          <></>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                </>
              )}
            </Row>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}>
              {" "}
            </div>
          </section>
        )}
      </Translation>
    );
  }
}
