export const theme = {
  colors: {
    primary: '#2f7dc9',  // Example primary color
    secondary: '#ffffff', // Example secondary color
    textgrey: '#6b6b6b',
  },
  font: {
    family: 'Arial, sans-serif', // Example font
    size: '16px',
    weight: 'normal',
  },
  button: {
    size: '15px',
    weight: 'normal',
  }
};
