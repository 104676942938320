class AuthService {
  getAuthUserData() {
    return JSON.parse(localStorage.getItem("customerData"));
  }

  getAuthToken() {
    return localStorage.getItem("customer_auth_token");
  }

  setAuthData(userData) {
    localStorage.setItem("customerData", JSON.stringify(userData));
    localStorage.setItem("customer_auth_token", userData.auth_token);
  }

  getAuthSettings() {
    return JSON.parse(localStorage.getItem("settings"));
  }

  setDeviceToken(token) {
    localStorage.setItem("device_token", token);
  }

  getDeviceToken() {
    return localStorage.getItem("device_token");
  }

  setSettings(data) {
    localStorage.setItem("settings", JSON.stringify(data));
  }

  setCurrentLocation(latitude, longitude) {
    var current_coords = { latitude: latitude, longitude: longitude };
    localStorage.setItem("current_coords", JSON.stringify(current_coords));
  }

  getCurrentLocation() {
    return JSON.parse(localStorage.getItem("current_coords"));
  }

  clearAuthData() {
    localStorage.removeItem("customerData");
    localStorage.removeItem("customer_auth_token");
  }

  clearDeviceData() {
    localStorage.removeItem("device_token");
  }
}

export default new AuthService();
