import React, { Component } from "react";
import { Col, Container, Row, Modal, Form, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import { Translation } from "react-i18next";
import { Formik, Field } from "formik";

export default class VendorDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemId: props.match.params.itemId,
            itemData: null,
            itemPacks: [],
            isloading: false,
            lang: "",
            show: false,
            showRatingModal: false,
            ratingData: [],
            packtitle: "",
            vendor_login_id: "",
            walletTotal: "",
            cardList: [],
            card_id: "",
            origin_amount: 0,
        };
    }


    async componentDidMount() {
        this.setState({ lang: localStorage.getItem("lang") || "en" });
        this.getPackage();
        this.getUserData();
        this.getWalletAmount();
        this.showCardList();
        this.getWalletData('');
    }

    handleSubmit = async (e) => {
        // e.preventDefault();
        // console.log(this.state.itemPacks);
        this.setState({ isloading: true });
        let formData = new FormData();
        var package_title = this.state.itemPacks.title;
        var free_delivery = this.state.itemPacks.promo_free_delivery;
        var balance_carry = this.state.itemPacks.balance_carry_forward;
        var service_type = this.state.itemPacks.servicetype;
        var category = this.state.itemPacks.main_category;
        var subcategory = this.state.itemPacks.subcategories;
        var individual_subcategory = this.state.individual_subcategory;
        var package_validity = this.state.itemPacks.promotion_package_validity;
        var vendor_id = this.state.vendor_id;
        var package_id = this.state.itemPacks.package_id;
        var add_promotion_type = this.state.itemPacks.add_promotion_type;
        var excluded_pieces = this.state.itemPacks.excluded_pieces;
        var customer_minimum_order_limit = this.state.itemPacks.customer_minimum_order_limit;
        var balance_carry_forward_validity = this.state.carry_forward_validity;
        var excluded_subcategory = this.state.itemPacks.excluded_subcategory;
        var vendor_login_id = this.state.vendor_login_id;
        var package_price = this.state.itemPacks.package_total_price;
        var walletUsed = "no";
        var WalletAmount = this.state.walletAmount;
        var vendor_package_id = this.state.itemPacks._id;
        var WalletUsedAmount = 0;
        var payment_method = "card";
        if (this.state.balanceAmount > 0 || this.state.balanceAmount == 0) {
            var walletUsed = "yes";
        }
        if (walletUsed == 'yes') {
            if (package_price >= WalletAmount) {
                WalletUsedAmount = WalletAmount;
                var walletAmount = 0;
            } else if (package_price < WalletAmount) {
                WalletUsedAmount = this.state.package_price;
                var originPrice = WalletAmount - this.state.package_price;
                var walletAmount = originPrice;
                payment_method = "wallet";
            } else {
                WalletUsedAmount = WalletAmount;
                var walletAmount = WalletAmount;

            }
        } else {
            var walletAmount = WalletAmount;
        }
        if (walletAmount == null) {
            walletAmount = 0;
        }
        if (this.state.balanceAmount > 0 || this.state.balanceAmount == 0) {
            var package_price = this.state.balanceAmount;
        }
        var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
        var date_buyed = new Date(current_date);
        var futureMonth = moment(current_date).add(package_validity, 'M');
        var PackageValidity = futureMonth.format('YYYY-MM-DD[T00:00:00.000Z]');
        var dated_buyed_with_addition = date_buyed.setMonth(date_buyed.getMonth() + package_validity);
        var package_date_expired = new Date(dated_buyed_with_addition);
        var date_format = moment(new Date(package_date_expired)).format('YYYY-MM-DD[T00:00:00.000Z]');
        if (balance_carry_forward_validity > 0) {
            var balance_carry12 = new Date(date_format);
            var balance_carry_addition = balance_carry12.setMonth(balance_carry12.getMonth() + balance_carry_forward_validity);
            var balanceCarry_validity = new Date(balance_carry_addition);
            var balance_carry13 = moment(new Date(date_format)).format('YYYY-MM-DD[T00:00:00.000Z]');
            var futurecarryMonth = moment(balance_carry13).add(balance_carry_forward_validity, 'M');
            var BalanceCarryForward = futurecarryMonth.format('YYYY-MM-DD[T00:00:00.000Z]');
        }
        formData.append("package_title", JSON.stringify(package_title) || "");
        formData.append("package_price", package_price);
        formData.append("service_type", service_type);
        formData.append("category", JSON.stringify(category) || '');
        formData.append("subcategory", JSON.stringify(subcategory) || '');
        formData.append("free_delivery", free_delivery);
        formData.append("balance_carry", balance_carry);
        formData.append("individual_subcategory", JSON.stringify(individual_subcategory) || '');
        formData.append("package_validity", package_validity);
        formData.append("payment_method", payment_method);
        formData.append("vendor_id", vendor_id);
        formData.append("package_id", package_id);
        formData.append("add_promotion_type", add_promotion_type);
        formData.append("excluded_pieces", excluded_pieces);
        formData.append("customer_minimum_order_limit", customer_minimum_order_limit);
        formData.append("package_validity_date", PackageValidity);
        formData.append("balanceCarry_validity", BalanceCarryForward);
        formData.append("card_id", this.state.card_id);
        formData.append("excluded_subcategory", JSON.stringify(excluded_subcategory) || "");
        formData.append("vendor_login_id", vendor_login_id);
        formData.append("walletUsed", walletUsed);
        formData.append("walletAmount", walletAmount);
        formData.append("vendor_package_id", vendor_package_id);
        formData.append("WalletUsedAmount", WalletUsedAmount);
        // formData.append("card_id", this.state.payment_method == "card" ? this.state.card_id : "");

        let path = ApiRoutes.CREATE_PACKAGE_ORDER;
        const res = await Http("POST", path, formData);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                if (resJson.data.wallet == 'yes') {
                    this.props.history.push("/promotions?status=success");
                } else {
                    window.location.replace(resJson.data.payment_url);
                }
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    getPackage = async () => {
        let path = ApiRoutes.GET_VENDOR_PACKAGE + "/" + this.state.itemId;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                var excluded_subcategory = resJson.data.excluded_subcategory;
                var category = resJson.data;
                var main_category = [];
                var sub_category = [];
                var service_type = [];
                var exclude_category = [];
                var included_category = [];
                category.new_main_categories.map((item, index) => {
                    main_category.push(item.name[this.state.lang]);
                })
                category.new_sub_categories.map((item, index) => {
                    sub_category.push(item.name[this.state.lang]);
                })
                category.services_new.map((item, index) => {
                    service_type.push(item.name[this.state.lang]);
                })
                excluded_subcategory.map((item3, index3) => {
                    // exclude_category.push(item3.key);
                    // if (item3.label != undefined) {
                    //     exclude_category.push(item3.label);
                    // } else {
                    //     exclude_category.push(item3.key);
                    // }
                    exclude_category.push(item3[this.state.lang])
                });
                category.new_included_subcategories.map((item2, index2) => {
                    included_category.push(item2.name[this.state.lang]);
                });

                exclude_category = exclude_category.join(", ");
                main_category = main_category.join(" , ");
                sub_category = sub_category.join(" ,  ");
                included_category = included_category.join(", ");
                if (resJson.data.carry_forward_validity) {
                    var carry_forward_validity = resJson.data.carry_forward_validity;
                }
                else {
                    var carry_forward_validity = 0;
                }
                this.setState({
                    itemPacks: resJson.data,
                    vendor_id: resJson.data.vendor_id,
                    category: main_category,
                    sub_category: sub_category,
                    service_type: service_type,
                    package_price: resJson.data.package_total_price,
                    free_delivery: resJson.data.promo_free_delivery,
                    balance_carry: resJson.data.balance_carry_forward,
                    individual_subcategory: resJson.data.individual_subCategory,
                    carry_forward_validity: carry_forward_validity,
                    exclude_category: exclude_category,
                    included_category: included_category,
                    vendor_login_id: resJson.data.login_user_id,
                    lang: localStorage.getItem("lang") || "en",
                    packtitle: resJson.data.title,
                });
                this.getVendorData();
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);

        }
    };

    getVendorData = async () => {
        let path = ApiRoutes.GET_VENDOR_NAME + "/" + this.state.vendor_id;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                var vendor_name = resJson.data.shop_name["en"];
                this.setState(
                    {
                        vendorData: vendor_name,
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    getUserData = async () => {
        let path = ApiRoutes.GET_PROFILE;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();

            if (res.status == 200) {
                AuthService.setAuthData(resJson.data);

                this.setState(
                    {
                        userData: resJson.data,
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };
    getWalletAmount = async () => {
        let path = ApiRoutes.GET_WALLET_AMOUNT;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                this.setState({
                    walletAmount: resJson.data.wallet_amount,
                });
            }
        }
    };
    deleteCard = async (cards) => {
        let path = ApiRoutes.DELETE_CARD;
        let formData = new FormData();
        formData.append("cardData", JSON.stringify(cards));
         const res = await Http("PUT", path, formData);
        if (res) {
            const resJson = await res.json();

            if (res.status == 200) {
                NotificationManager.success(resJson.message, "Success!", 3000);
                this.showCardList();
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    showCardList = async () => {
        let path = ApiRoutes.GET_CARDSLIST;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();

            if (res.status == 200) {
                this.setState({
                    cardList: resJson.data,
                });
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };
    addCardById = (event) => {
        var elems = document.getElementsByClassName("shampoo_opts");
        var currentState = event.target.checked;
        var elemsLength = elems.length;

        for (var i = 0; i < elemsLength; i++) {
            if (elems[i].type === "checkbox") {
                elems[i].checked = false;
            }
        }

        event.target.checked = currentState;
        if (event.target.checked) {
            this.setState({
                card_id: event.target.value,
            });
        } else {
            this.setState({
                card_id: "",
            });
        }
    };
    handleWallet = async (walletAmount) => {
        var PackPrice = this.state.package_price;
        if (walletAmount >= PackPrice) {
            var balanceAmount = 0;
            var reduced_amount = PackPrice;
            var origin_amount = walletAmount - PackPrice;
            this.setState({ balanceAmount: balanceAmount, reduced_amount: reduced_amount, origin_amount: origin_amount });
        } else if (PackPrice > walletAmount) {
            var balanceAmount = PackPrice - walletAmount;
            var reduced_amount = PackPrice - balanceAmount;
            var origin_amount = walletAmount - PackPrice;
            this.setState({ balanceAmount: balanceAmount, reduced_amount: reduced_amount, origin_amount: origin_amount });
        }

    };

    getWalletData = async (val) => {
        if (val != '') {
            this.setState({
                walletUsed: "no",
                reduced_amount: "",
                wallet_total: "",
            },
                () => this.getPackage(),
            );
        }
    }
    render() {
        var { authUser } = this.props;
        var { lang, itemData, itemPacks, category, sub_category, service_type, free_delivery, balance_carry, vendorData, packtitle, walletAmount, balanceAmount, reduced_amount, cardList } = this.state;
        var main_category = [];
        return (
            <>

                <Translation>
                    {(t) => (
                        <section className="vendor_list_detail_sec py-11 py-md-11" style={{ backgroundColor: "#F5FDFE" }}>
                            <h1 className="page_title mb-5 custom-page-tit-phem">{t("label_promotion_package")}</h1>
                            <div className="package-laundry-name">
                                <h4 className="promotional-package-shop-name">{t("promotion_laundry_name")}</h4>
                                <h5 className="promotional-package-shop-name">{vendorData}</h5>
                            </div>
                            <Container className="py-3 py-md-4">
                                {this.state.isloading ? (
                                    <div className="loading"></div>
                                ) : (
                                    <div className="promotions-package-view-package">
                                        <div className="promo-pack-details">
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_name")}</div>
                                                <div className="package-information">{packtitle[lang]}</div>
                                            </div>
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_type")}</div>
                                                {itemPacks && itemPacks.add_promotion_type == 1 ? (
                                                    <div className="package-information">{t("package_pieces_count")}</div>
                                                ) : (
                                                    <div className="package-information">{t("package_sub_category")}</div>
                                                )}
                                            </div>
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_price")}</div>
                                                <div className="package-information">{itemPacks.package_total_price + " " + t('commons.OMR')}</div>
                                            </div>
                                            {itemPacks && itemPacks.add_promotion_type == 1 && (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_total_count")}</div>
                                                    <div className="package-information">{itemPacks.SubcatTotPcsCount} {t("promotion_pieces_count")}</div>
                                                </div>
                                            )}
                                            {itemPacks && itemPacks.add_promotion_type == 0 && (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_total_count")}</div>
                                                    <div className="package-information">{itemPacks.SubcatTotPcsCount} {t("promotion_pieces_count")}</div>
                                                </div>
                                            )}
                                            {itemPacks && itemPacks.add_promotion_type == 0 && (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_order_count")}</div>
                                                    <div className="package-information">{itemPacks.customer_minimum_order_limit} {t("promotion_pieces_count")}</div>
                                                </div>
                                            )}
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_category")}</div>
                                                <div className="package-information">{category}</div>
                                            </div>
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_subcategory")}</div>

                                                <div className="package-information">{sub_category}</div>
                                            </div>

                                            {itemPacks && itemPacks.new_sub_categories != null && (
                                                itemPacks.new_sub_categories.map(function (value, key) {
                                                    return (
                                                        <div className="promo-pack-flex">
                                                            <div className="package-name">{value.name[lang]}{(" :")}</div>

                                                            <div className="package-information">{value.value} {t("promotion_pieces_count")}</div>

                                                        </div>
                                                    );
                                                })
                                            )}
                                            {itemPacks && itemPacks.add_promotion_type == 1 && itemPacks.excluded_pieces > 0 && (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_excluded_count")}</div>
                                                    <div className="package-information">{itemPacks.excluded_pieces} {t("promotion_pieces_count")}</div>
                                                </div>
                                            )}
                                            {itemPacks && itemPacks.add_promotion_type == 1 && itemPacks.new_included_subcategories.length > 0 && (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_included_subcategory") + ": "}</div>
                                                    <div className="package-information">{this.state.included_category} </div>
                                                </div>
                                            )}
                                            {itemPacks && itemPacks.add_promotion_type == 1 && itemPacks.excluded_subcategory.length > 0 && (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_excluded_subcategory")}</div>
                                                    <div className="package-information">{this.state.exclude_category} </div>
                                                </div>
                                            )}
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_service_type")}</div>
                                                <div className="package-information">{service_type}</div>
                                            </div>
                                            {free_delivery == 1 ? (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_package_free_delivery")}</div>
                                                    <div className="package-information">{t("no_free_delivery")}</div>
                                                </div>
                                            ) : (
                                                <>

                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_package_free_delivery")}</div>
                                                    <div className="package-information">{t("free_delivery")}</div>
                                                </div>
                                                {itemPacks && itemPacks.customer_minimum_order_amount && itemPacks.customer_minimum_order_amount != "" && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("filters.Minimum_Order_Amount") + ": "}</div>
                                                <div className="package-information">{itemPacks.customer_minimum_order_amount} {t("commons.balanceomr")}</div>
                                            </div>
                                        )}
                                                </>


                                            )}
                                            {balance_carry == 1 ? (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_package_balance_carry")}</div>
                                                    <div className="package-information">{t("no_balance_carry")}</div>
                                                </div>
                                            ) : (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("promotion_package_balance_carry")}</div>
                                                    <div className="package-information">{t("balance_carry")}</div>
                                                </div>
                                            )}
                                            {balance_carry == 0 && this.state.carry_forward_validity > 0 ? (
                                                <div className="promo-pack-flex">
                                                    <div className="package-name">{t("balance_carry_validity")}</div>
                                                    <div className="package-information">{this.state.carry_forward_validity} {this.state.carry_forward_validity == 1 ? t("pack_month") : t("pack_months")}</div>
                                                </div>

                                            ) : (
                                                <></>
                                            )}
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_validity")}</div>
                                                <div className="package-information">{itemPacks.promotion_package_validity} {itemPacks.promotion_package_validity == 1 ? t("pack_month") : t("pack_months")}</div>
                                            </div>
                                        </div>
                                        {walletAmount > 0 && (
                                            <div className="payment_section">
                                                <div className="copuncode d-flex justify-content-between align-items-center">
                                                    <h5>{t("commons.use_wallet")}</h5>
                                                    <Formik
                                                        enableReinitialize
                                                        initialValues={{
                                                        }}
                                                    >
                                                        {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                                                            <Form className="applycoupon">
                                                                <Row className="coupon_row">
                                                                    <Col xs={12} md={6}>

                                                                        <p>{this.state.walletAmount.toFixed(3) + " " + t("commons.balanceomr")}</p>
                                                                    </Col>
                                                                    <Button className="btn text-uppercase" type="button" onClick={() => this.handleWallet(this.state.walletAmount)}>
                                                                        {t("commons.apply")}
                                                                    </Button>
                                                                
                                                                </Row>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>

                                            </div>
                                        )}
                                        {cardList &&
                                            cardList.length > 0 &&
                                            <div className="copuncode" style={{ width: "50%" }}>

                                                {
                                                    cardList.map((item, index) => {
                                                        return (

                                                            <span key={index} className="addonouter d-flex justify-content-between align-items-center credit_card_arabic_amount">
{
                                                                    this.state.lang == 'en' ? (
                                                                        <>
                                                                <span className="custom_radio">
                                                                    <p className="m-0">{item.brand + " - " + item.masked_card}</p>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="shampoo_opts "
                                                                        name="shampoo"
                                                                        value={item.id}
                                                                        onClick={(event) => {
                                                                            this.addCardById(event);
                                                                        }}
                                                                    />
                                                                    <span className="radio_indicator">&nbsp;</span>
                                                                </span>
                                                                <p className="m-0">{t("commons.Expire_on") + " -- " + item.expiry_month + "/" + item.expiry_year}</p>
                                                                <span className="btn_cancel btn_cancel_close" onClick={(e) => this.deleteCard(item)}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </span>
                                                                </>
                                                                    ) : (
                                                                        <>
                                                                        <span className="btn_cancel btn_cancel_close" onClick={(e) => this.deleteCard(item)}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </span>
                                                                        <p className="m-0">{t("commons.Expire_on") + " -- " + item.expiry_month + "/" + item.expiry_year}</p>

                                                                <span className="custom_radio">
                                                                    <p className="m-0">{item.brand + " - " + item.masked_card}</p>
                                                                    <input
                                                                        type="checkbox"
                                                                        className="shampoo_opts "
                                                                        name="shampoo"
                                                                        value={item.id}
                                                                        onClick={(event) => {
                                                                            this.addCardById(event);
                                                                        }}
                                                                    />
                                                                    <span className="radio_indicator">&nbsp;</span>
                                                                </span>
                                                                        </>
                                                                    )
                                                                }


                                                            </span>

                                                        );
                                                    })}
                                            </div>
                                        }
                                        {reduced_amount > 0 && (
                                            <div className="balance_amount_section">
                                                <div className="balance_amount_flex">
                                                    <div className="balance_amount_title" style={{ color: "red" }}>{t("Wallet_amount_deducted")}</div>
                                                    <div className="wallet_amount_deduction">
                                                        <div className="balance_wallet_amount" style={{ color: "red" }}>{(this.state.reduced_amount).toFixed(3) + " " + (t("commons.balanceomr"))}</div>
                                                        <span className="close" onClick={() => {
                                                            this.getWalletData('remove_wallet');
                                                        }}>x</span>
                                                    </div>
                                                </div>

                                                <div className="balance_amount_flex">
                                                    <div className="balance_amount_title">{t("wallet_balance_amount")}</div>
                                                    <div className="balance_wallet_amount">{(this.state.balanceAmount).toFixed(3) + " " + (t("commons.balanceomr"))}</div>
                                                </div>
                                            </div>
                                        )}

                                        <div className="promo-buy-now">
                                            <input type="submit" className="package-btn1" onClick={(e) => this.handleSubmit(e)} value={t("buy_now_package")}></input>
                                        </div>
                                    </div>
                                )}
                            </Container>
                        </section>

                    )}
                </Translation>

            </>
        );
    }
}