import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Modal, ModalHeader, ModalBody, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ThemeContext } from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import {StyledButtondiv1} from "../../helpers/StyledButtondiv";
import { google_map } from "../../config/config";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Translation } from "react-i18next";
import ModalLayout from "../../helpers/ModalLayout";

var geocoder = require("geocoder");
var apiKey = { key: google_map };

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const FormSchema = Yup.object().shape({
  title: Yup.object().required("commons.Please_select_address_type"),
  apartment: Yup.string().required("commons.Please_enter_apartment_number"),
  street: Yup.string().required("commons.Please_enter_street"),
  landmark: Yup.string().required("commons.Please_enter_landmark"),
  city: Yup.string().required("commons.Please_enter_city"),

  postal_code: Yup.string(),
  state: Yup.string().required("commons.Please_enter_state"),
  country: Yup.string().required("commons.Please_enter_country"),
});

const mapStyles = {
  width: "100%",
  height: "200px",
};

class ManageAddress extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      addressList: [],

      showModal: false,
      itemId: "",
      title: "",

      latitude: "",
      longitude: "",
      apartment: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      apartmentInitial: "",
      streetInitial: "",
      landmarkInitial: "",
      cityInitial: "",
      stateInitial: "",
      countryInitial: "",
      postal_codeInitial: "",
      showEditModal: false,
      show: false,
      addressId: "",
      lang: "",
      addressTitle: [],
    };
  }

  componentDidMount() {
    this.getAddressTitle();
    this.getUserAddressList();
    this.setState({ lang: localStorage.getItem("lang") || "en" });
    navigator.geolocation.getCurrentPosition((e) => this.getAddressesbyMap(e, 1), this.error);
  }

  error = (err) => {
    if (err.message == "User denied Geolocation") {
      NotificationManager.error("Please enable location to get the current position", "Error!", 3000);
    }
  };

  toggleModal = (apartmentInitial = "", streetInitial = "", landmarkInitial = "", cityInitial = "", stateInitial = "", countryInitial = "", postal_codeInitial = "") => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      apartment: apartmentInitial,
      street: streetInitial,
      landmark: landmarkInitial,
      city: cityInitial,
      state: stateInitial,
      country: countryInitial,
      postal_code: postal_codeInitial,
    }));
  };

  showModalDelete = (e, id) => {
    this.setState({
      show: !this.state.show,
      addressId: id,
    });
  };

  toggleEditModal = (apartmentInitial = "", streetInitial = "", landmarkInitial = "", cityInitial = "", stateInitial = "", countryInitial = "", postal_codeInitial = "") => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
      apartment: apartmentInitial,
      street: streetInitial,
      landmark: landmarkInitial,
      city: cityInitial,
      state: stateInitial,
      country: countryInitial,
      postal_code: postal_codeInitial,
    }));
  };

  showModal = (itemId = "", title = "", appartment = "", landmark = "", street = "", city = "", state = "", country = "", postal_code = "") => {
    this.setState(
      {
        showModal: true,
        itemId: itemId,
        title: title,
        apartment: appartment,
        landmark: landmark,
        street: street,
        city: city,
        state: state,
        country: country,
        postal_code: postal_code,
      },

      () => navigator.geolocation.getCurrentPosition((e) => this.getAddressesbyMap(e, 1), this.error)
    );
  };

  showEditModal = (itemId = "", title = "", appartment = "", landmark = "", street = "", city = "", state = "", country = "", postal_code = "") => {
    this.setState({
      showEditModal: true,
      itemId: itemId,
      title: title,
      apartment: appartment,
      landmark: landmark,
      street: street,
      city: city,
      state: state,
      country: country,
      postal_code: postal_code,
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  getUserAddressList = async () => {
    let path = ApiRoutes.GET_ADDRESSES;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          addressList: resJson.data.docs,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getAddressTitle = async () => {
    let path = ApiRoutes.GET_ADDRESSESTITLE;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          addressTitle: resJson.data.docs,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleSubmit = async (inputValues, formOptions) => {
    if (this.state.itemId == "") {
      this.saveNewAddess(
        inputValues,

        this.state.latitude.toString(),
        this.state.longitude.toString(),
        formOptions
      );
    } else {
      this.updateAddess(inputValues, this.state.latitude.toString(), this.state.longitude.toString(), formOptions);
    }
  };

  saveNewAddess = async (
    inputValues,

    latitude,
    longitude,
    formOptions
  ) => {
    let formData = new FormData();
    formData.append("title", JSON.stringify(inputValues.title));
    formData.append("appartment", inputValues.apartment);
    formData.append("street", inputValues.street);
    formData.append("landmark", inputValues.landmark);
    formData.append("city", inputValues.city);
    formData.append("state", inputValues.state);
    formData.append("country", inputValues.country);
    formData.append("pincode", inputValues.postal_code ? inputValues.postal_code : "");
    formData.append("latitude", latitude ? latitude : 23.588);

    formData.append("longitude", longitude ? longitude : 58.3829);

    let path = ApiRoutes.CREATE_ADDRESS;

    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.hideModal();
        NotificationManager.success(resJson.message, "Success!", 3000);

        this.getUserAddressList();
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            formOptions.setFieldError(error.errField, error.errText);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  updateAddess = async (
    inputValues,

    latitude,
    longitude,
    formOptions
  ) => {
    let formData = new FormData();
    formData.append("title", inputValues.title ? JSON.stringify(inputValues.title) : JSON.stringify(this.state.title));
    formData.append("appartment", inputValues.apartment);
    formData.append("street", inputValues.street);
    formData.append("landmark", inputValues.landmark);
    formData.append("city", inputValues.city);
    formData.append("state", inputValues.state);
    formData.append("country", inputValues.country);
    formData.append("pincode", inputValues.postal_code ? inputValues.postal_code : "");
    formData.append("latitude", latitude ? latitude : 23.588);

    formData.append("longitude", longitude ? longitude : 58.3829);

    let path = ApiRoutes.UPDATE_ADDRESS + "/" + this.state.itemId;
    const res = await Http("PUT", path, formData);
    const resJson = await res.json();

    if (res.status == 200) {
      this.setState({ showEditModal: false });
      NotificationManager.success(resJson.message, "Success!", 3000);

      this.getUserAddressList();
    } else {
      if (resJson.data.errors && resJson.data.errors.length > 0) {
        resJson.data.errors.forEach((error) => {
          formOptions.setFieldError(error.errField, error.errText);
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  deleteAddess = async (itemId) => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_ADDRESS + "/" + itemId;
    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({ show: false });
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.getUserAddressList();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  //---------fetch address from  map and setto state of  react -----//
  getAddressesbyMap = (coord, latlngfrom = 1) => {
    var lat = "";
    var lng = "";

    if (latlngfrom == 1) {
      var crd = coord.coords;
      lat = crd.latitude;
      lng = crd.longitude;
    } else {
      const { latLng } = coord;
      lat = latLng.lat();
      lng = latLng.lng();
    }

    this.setState(
      {
        latitude: lat,
        longitude: lng,
        state: "",
        country: "",
        city: "",
        street: "",
        postal_code: "",
        apartment: "",

        landmark: "",
      },
      () =>
        geocoder.reverseGeocode(
          this.state.latitude,
          this.state.longitude,
          (error, results) => {
            if (error) {
              NotificationManager.error("Cannot get location", "Error!", 3000);
              return false;
            }

            if (results.results[0]) {
              var total = results.results[0].address_components.length;

              for (var i = 0; i < total; i++) {
                if (results.results[0].address_components[i].types[0] === "political") {
                  var street = results.results[0].address_components[1].long_name;
                  var area = results.results[0].address_components[2].long_name;
                  this.setState({
                    street: street + ", " + area,
                  });
                }
                if (results.results[0].address_components[i].types[0] === "administrative_area_level_2") {
                  var city = results.results[0].address_components[i].long_name;

                  this.setState({
                    city: city,
                  });
                }
                if (results.results[0].address_components[i].types[0] === "administrative_area_level_1") {
                  var state = results.results[0].address_components[i].long_name;

                  this.setState({
                    state: state,
                  });
                }
                if (results.results[0].address_components[i].types[0] === "country") {
                  var country = results.results[0].address_components[i].long_name;

                  this.setState({
                    country: country,
                  });
                }
                if (results.results[0].address_components[i].types[0] === "postal_code") {
                  var postal_code = results.results[0].address_components[i].long_name;

                  this.setState({
                    postal_code: postal_code,
                  });
                }
              }
            }
          },
          apiKey
        )
    );
  };

  render() {
    var { addressList, latitude, longitude, lang } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="btn text-white py-2 px-3 toggle_btn d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>

              <h1 className="page_title mb-5 d-flex justify-content-between align-items-center custom-address-btn">
              {t("labels.Address")}{" "}
                {/* <Button
                  className="py-2 px-3"
                  size="sm"
                  type="submit"
                  onClick={() => {
                    this.showModal();
                  }}
                >
                  {t("commons.Add_New")}
                </Button> */}
                 <StyledButtondiv1 className="py-2 px-3"
                  size="sm"
                  type="submit"
                  onClick={() => {
                    this.showModal();
                  }}>{t("commons.Add_New")}</StyledButtondiv1>
              </h1>
              <Row>
                {this.state.addressList.map((item, index) => {
                  var completeAddress = item.appartment + ", " + item.street + ", " + item.landmark + ", " + item.city + ", " + item.state + ", " + item.country + ", " + item.pincode;
                  return (
                    <Col xs="12" sm="6" className="mb-3" key={index}>
                      <div className="box_grey bg-white p-sm-4 p-3 mb-3 h-100">
                        <h5 className="mb-3 title">{lang && item && item.title && item.title[lang]}</h5>
                        <div className="d-flex align-items-center">
                          <div className="w-100 order_info">
                            <p className="m-0 p-0">{completeAddress}</p>
                          </div>
                          <div className="btn_orders d-flex">
                            <a
                              className="btn btn_packed px-3 mx-1"
                              onClick={() => {
                                this.showEditModal(item._id, item.title, item.appartment, item.landmark, item.street, item.city, item.state, item.country, item.pincode);
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </a>

                            <a
                              className="btn btn_cancel px-3 mx-1"
                              onClick={(e) => {
                                this.showModalDelete(e, item._id);
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              {this.state.addressList.length == 0 && <div className="noorder">{t("commons.No_address_found")}</div>}

              {/* Modal to add address */}
              <Modal
                isOpen={this.state.showModal}
                backdrop={"static"}
                className="AddressModal modal-lg"
                keyboard={false}
                toggle={() => {
                  this.toggleModal();
                }}
              >
                <ModalHeader
                  toggle={() => {
                    this.toggleModal();
                  }}
                >
                  {" "}
                  {t("commons.Add_Delivery_Address")}
                </ModalHeader>
                <ModalBody>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      title: this.state.title,
                      apartment: this.state.apartment,
                      street: this.state.street,
                      landmark: this.state.landmark,
                      city: this.state.city,
                      state: this.state.state,
                      country: this.state.country,
                      postal_code: this.state.postal_code,
                    }}
                    validationSchema={FormSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                      <Form className="login_signup_form">
                        <Row>
                          <Col xs={12} md={12}>
                            <FormGroup className="position-relative map_grp">
                              <span
                                className="map_marker"
                                title={t("commons.Current_Location")}
                                onClick={() => {
                                  navigator.geolocation.getCurrentPosition((e) => this.getAddressesbyMap(e, 1), this.error);
                                }}
                              >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                              </span>
                              <Map
                                google={this.props.google}
                                zoom={14}
                                fullscreenControl={true}
                                disableDefaultUI={true}
                                zoomControl={true}
                                mapTypeControl={true}
                                containerStyle={mapStyles}
                                center={{
                                  lat: latitude ? latitude : 23.588,
                                  lng: longitude ? longitude : 58.3829,
                                }}
                              >
                                <Marker
                                  draggable={true}
                                  position={{ lat: latitude && latitude, lng: longitude && longitude }}
                                  onDragend={(t, map, coord) =>
                                    this.setState(
                                      {
                                        street: "",
                                        city: "",
                                        country: "",
                                        postal_code: "",
                                      },
                                      () => this.getAddressesbyMap(coord, 2)
                                    )
                                  }
                                />
                              </Map>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <Label>{t("commons.Address_Type")}</Label>
                            <FormGroup className="mb-3">
                              <ul className="daycheck_type_list_sec">
                                {this.state.addressTitle &&
                                  this.state.addressTitle.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <input
                                          type="radio"
                                          name="title"
                                          value={item._id}
                                          defaultChecked={item._id == item.name[lang]}
                                          onChange={(event) =>
                                            this.setState({
                                              title: item.name,
                                            })
                                          }
                                        />
                                        <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                                      </li>
                                    );
                                  })}
                              </ul>
                              {errors.title && touched.title ? <div className="invalid-feedback d-block">{t(errors.title)}</div> : null}
                            </FormGroup>
                          </Col>
                          <Col xs={12} lg={6}>
                            <Label>{t("commons.Apartment_No./House_No.")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="apartment"
                                type="text"
                                placeholder={t("commons.Apartment_No./House_No.")}
                                onChange={(e) => this.setState({ apartment: e.target.value })}
                              />
                              {errors.apartment && touched.apartment ? <div className="invalid-feedback d-block">{t(errors.apartment)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={6}>
                            <Label>{t("commons.Street")}</Label>
                            <FormGroup>
                              <Field className="form-control" name="street" type="text" placeholder={t("commons.Street")} onChange={(e) => this.setState({ street: e.target.value })} />
                              {errors.street && touched.street ? <div className="invalid-feedback d-block">{t(errors.street)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={6}>
                            <Label>{t("commons.Landmark")}</Label>
                            <FormGroup>
                              <Field className="form-control" name="landmark" type="text" placeholder={t("commons.Landmark")} onChange={(e) => this.setState({ landmark: e.target.value })} />
                              {errors.landmark && touched.landmark ? <div className="invalid-feedback d-block">{t(errors.landmark)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={6}>
                            <Label>{t("commons.City")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="city"
                                type="text"
                                value={this.state.city}
                                placeholder={t("commons.City")}
                                onChange={(e) => this.setState({ city: e.target.value })}
                              />
                              {errors.city && touched.city ? <div className="invalid-feedback d-block">{t(errors.city)}</div> : null}
                            </FormGroup>{" "}
                          </Col>
                          <Col xs={12} lg={4}>
                            <Label>{t("commons.State")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="state"
                                type="text"
                                value={this.state.state}
                                placeholder={t("commons.State")}
                                onChange={(e) => this.setState({ state: e.target.value })}
                              />
                              {errors.state && touched.state ? <div className="invalid-feedback d-block">{t(errors.state)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={4}>
                            <Label>{t("commons.Country")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="country"
                                type="text"
                                value={this.state.country}
                                placeholder={t("commons.Country")}
                                onChange={(e) => this.setState({ country: e.target.value })}
                              />
                              {errors.country && touched.country ? <div className="invalid-feedback d-block">{t(errors.country)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={4}>
                            <Label>{t("commons.Postal_Code")}</Label>
                            <FormGroup>
                              <Field className="form-control" name="postal_code" type="text" placeholder={t("commons.Postal_Code")} onChange={(e) => this.setState({ postal_code: e.target.value })} />
                              {errors.postal_code && touched.postal_code ? <div className="invalid-feedback d-block">{t(errors.postal_code)}</div> : null}
                            </FormGroup>{" "}
                          </Col>
                        </Row>

                        <Button className="btn text-uppercase" type="submit">
                          {t("commons.Save")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </Modal>

              {/* Modal for editing address */}

              <Modal
                isOpen={this.state.showEditModal}
                backdrop={"static"}
                className="AddressModal modal-lg"
                keyboard={false}
                toggle={() => {
                  this.toggleEditModal();
                }}
              >
                <ModalHeader
                  toggle={() => {
                    this.toggleEditModal();
                  }}
                >
                  {" "}
                  {t("commons.Edit_Delivery_Address")}
                </ModalHeader>
                <ModalBody>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      title: this.state.title,
                      apartment: this.state.apartment,
                      street: this.state.street,
                      landmark: this.state.landmark,
                      city: this.state.city,
                      state: this.state.state,
                      country: this.state.country,
                      postal_code: this.state.postal_code,
                    }}
                    validationSchema={FormSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                      <Form className="login_signup_form">
                        <Row>
                          <Col xs={12} md={12}>
                            <FormGroup className="position-relative map_grp">
                              <span
                                className="map_marker"
                                title={t("commons.Current_Location")}
                                onClick={() => {
                                  navigator.geolocation.getCurrentPosition((e) => this.getAddressesbyMap(e, 1), this.error);
                                }}
                              >
                                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                              </span>
                              <Map
                                google={this.props.google}
                                fullscreenControl={true}
                                disableDefaultUI={true}
                                zoomControl={true}
                                mapTypeControl={true}
                                containerStyle={mapStyles}
                                center={{
                                  lat: latitude ? latitude : 23.588,
                                  lng: longitude ? longitude : 58.3829,
                                }}
                              >
                                <Marker
                                  draggable={true}
                                  position={{ lat: latitude && latitude, lng: longitude && longitude }}
                                  onDragend={(t, map, coord) =>
                                    this.setState(
                                      {
                                        street: "",
                                        city: "",
                                        country: "",
                                        postal_code: "",
                                      },
                                      () => this.getAddressesbyMap(coord, 2)
                                    )
                                  }
                                />
                              </Map>
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <Label>{t("commons.Address_Type")}</Label>
                            <FormGroup className="mb-3">
                              <ul className="daycheck_type_list_sec">
                                {this.state.addressTitle &&
                                  this.state.addressTitle.map((item, index) => {
                                    return (
                                      <li key={index}>
                                        <input
                                          type="radio"
                                          name="title"
                                          value={item._id}
                                          defaultChecked={lang && item.name[lang] == this.state.title[lang] ? true : false}
                                          onChange={(event) =>
                                            this.setState({
                                              title: item.name,
                                            })
                                          }
                                        />
                                        <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                                      </li>
                                    );
                                  })}
                              </ul>
                              {errors.title && touched.title ? <div className="invalid-feedback d-block">{t(errors.title)}</div> : null}
                            </FormGroup>
                          </Col>
                          <Col xs={12} lg={6}>
                            <Label>{t("commons.Apartment_No./House_No.")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="apartment"
                                type="text"
                                placeholder={t("commons.Apartment_No./House_No.")}
                                onChange={(e) => this.setState({ apartment: e.target.value })}
                              />
                              {errors.apartment && touched.apartment ? <div className="invalid-feedback d-block">{t(errors.apartment)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={6}>
                            <Label>{t("commons.Street")}</Label>
                            <FormGroup>
                              <Field className="form-control" name="street" type="text" placeholder={t("commons.Street")} onChange={(e) => this.setState({ street: e.target.value })} />
                              {errors.street && touched.street ? <div className="invalid-feedback d-block">{t(errors.street)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={6}>
                            <Label>{t("commons.Landmark")}</Label>
                            <FormGroup>
                              <Field className="form-control" name="landmark" type="text" placeholder={t("commons.Landmark")} onChange={(e) => this.setState({ landmark: e.target.value })} />
                              {errors.landmark && touched.landmark ? <div className="invalid-feedback d-block">{t(errors.landmark)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={6}>
                            <Label>{t("commons.City")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="city"
                                type="text"
                                value={this.state.city}
                                placeholder={t("commons.City")}
                                onChange={(e) => this.setState({ city: e.target.value })}
                              />
                              {errors.city && touched.city ? <div className="invalid-feedback d-block">{t(errors.city)}</div> : null}
                            </FormGroup>{" "}
                          </Col>
                          <Col xs={12} lg={4}>
                            <Label>{t("commons.State")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="state"
                                type="text"
                                value={this.state.state}
                                placeholder={t("commons.State")}
                                onChange={(e) => this.setState({ state: e.target.value })}
                              />
                              {errors.state && touched.state ? <div className="invalid-feedback d-block">{t(errors.state)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={4}>
                            <Label>{t("commons.Country")}</Label>
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="country"
                                type="text"
                                value={this.state.country}
                                placeholder={t("commons.Country")}
                                onChange={(e) => this.setState({ country: e.target.value })}
                              />
                              {errors.country && touched.country ? <div className="invalid-feedback d-block">{t(errors.country)}</div> : null}
                            </FormGroup>{" "}
                          </Col>

                          <Col xs={12} lg={4}>
                            <Label>{t("commons.Postal_Code")}</Label>
                            <FormGroup>
                              <Field className="form-control" name="postal_code" type="text" placeholder={t("commons.Postal_Code")} onChange={(e) => this.setState({ postal_code: e.target.value })} />
                              {errors.postal_code && touched.postal_code ? <div className="invalid-feedback d-block">{t(errors.postal_code)}</div> : null}
                            </FormGroup>{" "}
                          </Col>
                        </Row>

                        <Button className="btn text-uppercase" type="submit">
                          {t("buttons.update")}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </Modal>

              <ModalLayout onClose={this.showModalDelete} show={this.state.show} onConfirmation={() => this.deleteAddess(this.state.addressId)}>
                {t("commons.Do_you_really_want_to_remove_the_selected_address?")}
              </ModalLayout>
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}>
              {" "}
            </div>
          </>
        )}
      </Translation>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: google_map,
})(ManageAddress);
