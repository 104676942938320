import React, { Component } from "react";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";

function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const priceRegExp = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

// const FormSchema = Yup.object().shape({
//     walletAmount: Yup.string().required("Please enter the Wallet Amount").matches(priceRegExp, "Please enter amount greater than or equal to 0"),
// });

export default class ProfileEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            walletAmount: "",
            password: "",
            editGender: "",
            userData: null,
            walletError: "",
            isloading: false,
            lang: localStorage.getItem("lang") || "en",

        };
    }

    componentDidMount() {
        this.getBalanceWallet();
        this.getAdminWallet();
        this.getWalletSettings();
        this.setState({ lang: localStorage.getItem("lang") || "en" });

    }

    getBalanceWallet = async (refreshPage = false) => {
        let path = ApiRoutes.GET_BALANCE_WALLET;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                // console.log(resJson.data);
                function addZeroes(num) {
                    // Cast as number
                    var num = Number(num);
                    // If not a number, return 0
                    if (isNaN(num)) {
                        return 0;
                    }
                    // If there is no decimal, or the decimal is less than 2 digits, toFixed
                    if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2) {
                        num = num.toFixed(2);
                    }
                    return num;
                }
                var walletsAmount = resJson.data.wallet.wallet_amount;
                var balanceAmount = addZeroes(walletsAmount);
                var originAmount = parseFloat(balanceAmount);
                this.setState(
                    {
                        balanceAmount: originAmount.toFixed(3),
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    getAdminWallet = async (refreshPage = false) => {
        let path = ApiRoutes.GET_ADMIN_WALLET;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                var WalletAmount = resJson.data.docs;
                var WalletAmountData = [];
                WalletAmount && WalletAmount.map((item, index) => {
                    WalletAmountData.push(item.wallet_amount);
                });
                WalletAmountData.sort(function (a, b) { return a - b; });
                this.setState(
                    {
                        wallet_admin_amount: WalletAmountData,
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    getWalletSettings = async (refreshPage = false) => {
        let path = ApiRoutes.GET_WALLET_SETTINGS;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                this.setState(
                    {
                        wallet_minimum_amount: resJson.data.settingsData.minimum_wallet_amount,
                        wallet_maximum_amount: resJson.data.settingsData.maximum_wallet_amount
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };


    handleSubmit = async (inputValues, formOptions) => {
        let formData = new FormData();
        var payment_method = "card";
        if (inputValues.walletAmount == '') {
            if (this.state.lang == 'en') {
                var walletError = 'Please enter the Wallet Amount';
            } else {
                var walletError = 'الرجاء إدخال مبلغ المحفظة';
            }
            this.setState({ walletError: walletError });
            return false;
        }
        else if (typeof (inputValues.walletAmount) === 'string') {
            if (this.state.lang == 'en') {
                var walletError = 'Please enter amount greater than 0';
            } else {
                var walletError = 'الرجاء إدخال مبلغ أكبر من 0';
            }
            this.setState({ walletError: walletError });
            return false;
        }
        else if (inputValues.walletAmount < this.state.wallet_minimum_amount) {
            if (this.state.lang == 'en') {
                var walletError = "Entered amount must be greater than or equal to " + this.state.wallet_minimum_amount + " OMR";
            } else {
                var walletError = " يجب أن يكون المبلغ الذي تم إدخاله أكبر من أو يساوي" + this.state.wallet_minimum_amount + "ريال عماني ";
            }
            this.setState({ walletError: walletError });
            return false;
        } else if (inputValues.walletAmount > this.state.wallet_maximum_amount) {
            if(this.state.lang == 'en'){
                var walletError = "Entered amount must be lesser than or equal to " + this.state.wallet_maximum_amount + " OMR";
            }else{
                var walletError = "يجب أن يكون المبلغ الذي تم إدخاله أقل من أو يساوي" + this.state.wallet_maximum_amount + "ريال عماني ";
            }
            this.setState({ walletError: walletError });
            return false;
        } else {
            this.setState({ isloading: true });
            formData.append("walletAmount", inputValues.walletAmount);
            formData.append("payment_method", payment_method);
            let path = ApiRoutes.CREATE_WALLET;
            const res = await Http("PUT", path, formData);
            if (res) {
                const resJson = await res.json();
                if (res.status == 200) {
                    window.location.replace(resJson.data.payment_url);
                    console.log(resJson.data.payment_url, 'resJson.data.payment_url');
                } else {
                    NotificationManager.error(resJson.message, "Error!", 3000);
                }
            } else {
                NotificationManager.error("Server Error", "Error!", 3000);
            }
        }

    };
    getvalue(event) {
        var wallet_amount = parseFloat(event.target.value);
        this.setState({ wallet_amount: wallet_amount });
    }

    render() {
        var { userData, editGender, wallet_amount, balanceAmount, wallet_admin_amount } = this.state;

        return (
            <Translation>
                {(t) => (
                    <>
                        <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
                            <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none">
                                    <i className="fas fa-bars"></i>
                                </div>
                            </div>
                            <div className="wallet-display-bag-price-sec">
                                <h1 className="page_title mb-5">{t("commons.Wallet_Title")}</h1>
                                <div className="bag-sec-block-sec">
                                    <i className="fas fa-shopping-bag"></i>
                                    <p className="balance-wallet">{balanceAmount} {t("commons.balanceomr")}</p>
                                </div>
                            </div>
                            {this.state.isloading ? (
                                <div className="loading"></div>
                            ) : (
                                <div className="wallet-page-display-section">
                                    <Row className="justify-content-center">
                                        <Col xs={12}>
                                            <div className="manage_account_user_sec p-5">
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={{
                                                        walletAmount: wallet_amount ? wallet_amount : "",
                                                    }}
                                                    // validationSchema={FormSchema}
                                                    onSubmit={this.handleSubmit}
                                                >
                                                    {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                                                        <Form className="login_signup_form">
                                                            <Row>
                                                                <Col xs={12} md={6}>
                                                                    <Label>{t("labels.wallet_amount_label")}</Label>
                                                                    <FormGroup className="wallet-field">
                                                                        <Field
                                                                            className="form-control"
                                                                            name="walletAmount"
                                                                            type="number"
                                                                            min="1"
                                                                            placeholder={t("labels.walletAddAmount")}
                                                                        // onChange={(event) => {
                                                                        //     setFieldValue("walletAmount", event.target.value.trimStart());
                                                                        // }}
                                                                        />
                                                                        {/* {errors.walletAmount && touched.walletAmount ? <div className="invalid-feedback d-block">{t(errors.walletAmount)}</div> : null} */}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            {this.state.walletError && (

                                                                <div className="wallet-error-message">
                                                                    {this.state.walletError}
                                                                </div>
                                                            )}

                                                            <div className="amount-button-section">
                                                                {wallet_admin_amount && wallet_admin_amount != null ? (
                                                                    wallet_admin_amount.map((key, value) => {
                                                                        return (<Field className="button-section" onClick={(event) => { this.getvalue(event) }} type="text" value={key} />)
                                                                    })
                                                                    // Object.entries(wallet_admin_amount).map(([key, value]) => {
                                                                    //     console.log(value.wallet_amount);
                                                                    //     <Field className="button-section" onClick={(event) => { this.getvalue(event) }} type="text" value={value.wallet_amount}/>
                                                                    // })
                                                                ) : (
                                                                    <></>
                                                                )
                                                                }

                                                            </div>

                                                            <Button className="btn text-uppercase" type="submit">
                                                                {t("commons.AddAmount")}
                                                            </Button>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                        <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
                    </>
                )}
            </Translation>
        );
    }
}
