import styled from 'styled-components';

const StyledButtonSpan = styled.span`
 background-color: ${({ theme }) => theme.colors.primary};
 color: white;
 font-family: ${({ theme }) => theme.font.family};
 font-size: ${({ theme }) => theme.font.size};
 padding: 10px 20px;
 border-radius: 5px;
 display: inline-block;
 text-align: center;
 cursor: pointer;
 user-select: none;
 border: 1px solid transparent;
 transition: background-color 0.3s;

 &:hover {
   background-color: ${({ theme }) => theme.colors.secondary};
 }
`;

export default StyledButtonSpan;