import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, FormGroup, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field } from "formik";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";
import i18next from "i18next";
import { ThemeButtonLink1, ThemeButtonLink } from "../../helpers/Button";
import { StyledButtondiv } from "../../helpers/StyledButtondiv";

import * as Yup from "yup";

import Slider from "react-slick";
import authService from "../../services/auth.service";
var sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  centerPadding: "30",
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

var settingInfo = authService.getAuthSettings();
var priceRegExp = /^[1-9]\d*$/;

// validating schema
const ServiceTypeFormSchema = Yup.object().shape({
  measure_type: Yup.string().required("commons.Please_select_service_add_in"),
  quantity_by_measure: Yup.string()
    .required("commons.Please_add_numeric_value")
    .min(1, "commons.It_must_contain_atleast_1_number")
    .max(6, "commons.It_must_contain_atmost_6_numbers")
    .matches(priceRegExp, "commons.Please_add_numeric_value"),
});

export default class Cart extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {
      cartItems: [],
      serviceModal: false,
      isNonTransportation: false,
      isNonTransportationPlaceOrder: false,
      measure_type: "",
      quantity_by_measure: "",
      price_by_measure: "",
      service_item_id: "",
      service_id: "",
      serviceItems: [],
      vendor_id: "",
      item_id: "",
      adon_typesList: [],
      isloading: false,
      lang: "",
      freeDeliveryModal: false,
      isDelete: false,
      isDeleteAll: false,
      objId: "",
      vendorData_new: null,
      mainTotalPrice: 0,
      actualDeliveryMethod: '',
      actualDeliveryTime: '',
    };
  }

  async componentDidMount() {

    if (this.props.authUser) {
      this.setState({ lang: localStorage.getItem("lang") || "en" });

      const context = this.context;
      const { globalPickUpdate, globalPickUptime, globaldeliverydate, globaldeliverytime, globalExpress, globalPickUpaddress, globaldeliveryaddress, globalDeliveryMethod, specialRequest, specialRequestText } = context;

      this.setState({
        adon_text: specialRequestText && specialRequestText,
        adon_type: specialRequest && specialRequest,
        pickup_address: globalPickUpaddress && globalPickUpaddress,
        delivery_address: globaldeliveryaddress && globaldeliveryaddress,
        is_express_delivery: globalExpress && globalExpress,
        delivery_method: globalDeliveryMethod && globalDeliveryMethod,
        // pickup_date: globalPickUpdate && globalPickUpdate,
        // pickup_specific_date: globalPickUpdate && globalPickUpdate,
        // delivery_specific_date: globaldeliverydate && globaldeliverydate,
        // pickup_time: globalPickUptime && globalPickUptime,
        // delivery_date: globaldeliverydate && globaldeliverydate,
        // delivery_time: globaldeliverytime && globaldeliverytime,
        is_express_delivery: false,
        lang: localStorage.getItem("lang") || "en",
      });
      specialRequest && specialRequest.map((items) => {
        console.log(items.value, 'sdkl')
        if (items.value == "") {
          this.setState({
            show_adon_text_filed: true
          })
        }
      })


      var items = this.getCartData();

      items.then(async (response) => {
        await this.getVendroNavigateData();
        if (response.length > 0) {
          var customer_id = this.props.authUser._id;
          let formData = new FormData();
          formData.append("customer_id", customer_id);
          formData.append("vendor_id", this.state.vendor_id);

        }
      });
    }
  }

  getVendroNavigateData = async () => {
    let path = ApiRoutes.GET_VENDOR + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        await this.setState({
          vendorData_new: resJson.data,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  }

  showFreeDelivery = () => {
    this.setState((prevState) => ({
      freeDeliveryModal: !prevState.freeDeliveryModal,
    }));
  };

  showDeleteModal = (id) => {
    this.setState((prevState) => ({
      isDelete: !prevState.isDelete,
      objId: id,
    }));
  };

  showDeleteAll = () => {
    this.setState((prevState) => ({
      isDeleteAll: !prevState.isDeleteAll,
    }));
  };

  // getting all the cart data
  getCartData = async (currentPage = 1) => {
    const context = this.context;

    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {

        this.setState({
          transportationCart: resJson.data.transportationCart
        });

        //for getting the priority delivery method
        let longerDeliveryMethod = 0;
        let mediumDeliveryMethod = 0;
        let normalDeliveryMethod = 0;
        let longer_deliveryTime = '';
        let medium_deliveryTime = '';
        let normal_deliveryTime = '';
        let actualDeliveryMethod = '';
        let actualDeliveryTime = '';
        resJson.data.cartItems.map((item) => {
          if (item && item.delivery_method != undefined && item.delivery_method == 'longer') {
            longerDeliveryMethod = 1;
            longer_deliveryTime = item.delivery_time;
          }
          if (item && item.delivery_method != undefined && item.delivery_method == 'medium') {
            mediumDeliveryMethod = 1;
            medium_deliveryTime = item.delivery_time;
          }
          if (item && item.delivery_method != undefined && item.delivery_method == 'normal') {
            normalDeliveryMethod = 1;
            normal_deliveryTime = item.delivery_time;
          }
        });

        if (longerDeliveryMethod == 1) {
          actualDeliveryMethod = 'longer';
          if (this.state.lang == 'en') {
            actualDeliveryTime = `Your order items will be delivery within ${longer_deliveryTime} days`;
          } else {
            actualDeliveryTime = `سيتم تسليم عناصر طلبك خلال ${longer_deliveryTime} يوم`;
          }

        } else if (mediumDeliveryMethod == 1) {
          actualDeliveryMethod = 'medium';
          if (this.state.lang == 'en') {
            actualDeliveryTime = `Your order items will be delivery within ${medium_deliveryTime} days`;
          } else {
            actualDeliveryTime = `سيتم تسليم عناصر طلبك خلال ${medium_deliveryTime} يوم`;
          }
        } else if (normalDeliveryMethod == 1) {
          actualDeliveryMethod = 'normal';
          if (this.state.lang == 'en') {
            actualDeliveryTime = `Your order items will be delivery within ${normal_deliveryTime} days`;
          } else {
            actualDeliveryTime = `سيتم تسليم عناصر طلبك خلال ${normal_deliveryTime} يوم`;
          }
        }

        this.setState({
          actualDeliveryMethod: actualDeliveryMethod,
          actualDeliveryTime: actualDeliveryTime,
        });
        //for getting the priority delivery method

        resJson.data.cartItems.map((item) => {
          this.setState({
            vendor_id: item.vendor._id,
            item_id: item._id,
          });
        });


        let mainTotalPrice = 0;
        //for gettting the total price of each item 
        resJson.data.cartItems.map((item) => {
          item.service_items.map((serviceItem) => {
            mainTotalPrice += serviceItem.TotalPrice
          })
        });

        console.log(mainTotalPrice, 'mainTotalPrice+mainTotalPrice+mainTotalPrice');
        this.setState({
          mainTotalPrice: mainTotalPrice
        })


        this.setState(
          {
            cartItems: resJson.data.cartItems,
          },
          () => this.setState({ isloading: true }, () => context.setCartItem(resJson.data.cartItems.length))
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
      return resJson.data.cartItems;
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }

  };

  // deleting individual cart item
  deleteCartItem = async (id) => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_CARTITEM + "/" + id;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.getCartData();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  // deleting all cart
  deleteCart = async () => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_CART;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.getCartData();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  // show/hide edit modal
  toggleEditModal = (item, serviceItemObj) => {
    this.setState((prevState) => ({
      serviceModal: !prevState.serviceModal,
      measure_type: serviceItemObj.measure_type,
      quantity_by_measure: serviceItemObj.quantity_by_measure,
      price_by_measure: serviceItemObj.price_by_measure,
      service_item_id: serviceItemObj._id,
      service_id: item.service._id,
      item_id: item._id,
    }));
  };

  hideEditModal = () => {
    this.setState((prevState) => ({
      serviceModal: !prevState.serviceModal,
    }));
  };

  handleEditCart = async (inputValues, formOptions) => {
    if (inputValues.measure_type === "weight" && inputValues.quantity_by_measure < 200) {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_weight_more_than_200_grams");
    } else {
      if (inputValues.measure_type != "meter") {
        if (!/^\+?(0|[1-9]\d*)$/.test(parseFloat(inputValues.quantity_by_measure))) {
          formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_positive_quantity");
          return false;
        }

      }
      let formData = new FormData();
      formData.append("measure_type", inputValues.measure_type);
      formData.append("quantity_by_measure", inputValues.quantity_by_measure);
      formData.append("service_item_id", inputValues.service_item_id);
      formData.append("service_id", this.state.service_id);

      let path = ApiRoutes.UPDATE_CART + "/" + this.state.item_id;

      const res = await Http("PUT", path, formData);
      if (res) {
        const resJson = await res.json();

        if (res.status == 200) {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.setState({ serviceModal: false }, () => this.getCartData());
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    }
  };

  showPopup = () => {
    //  calcuation for delivery charges

    return (
      <p>
        {settingInfo &&
          settingInfo.min_order_amount &&
          settingInfo.max_distance_applicable &&
          i18next.t("commons.Free_delivery_is_available_if_your_order_value_above_OMR") +
          " " +
          settingInfo.min_order_amount.toFixed(3) +
          " " +
          i18next.t("commons.and_within_distance_of") +
          " " +
          settingInfo.max_distance_applicable +
          " " +
          i18next.t("commons.Km.")}
      </p>
    );
  };


  handlePlaceOrder = async (e) => {


    let state = {
      is_express_delivery: 0,
      delivery_method: "normal",
      pickup_address: {
        appartment: "",
        city: "",
        country: "",
        landmark: "",
        latitude: 0,
        longitude: 0,
        pincode: "",
        state: "",
        street: "",
        title: { en: 'Home', ar: 'منزل' }
      },
      delivery_address: {
        appartment: "",
        city: "",
        country: "",
        landmark: "",
        latitude: 0,
        longitude: 0,
        pincode: "",
        state: "",
        street: "",
        title: { en: 'Home', ar: 'منزل' }
      },
      pickup_date: "",
      pickup_time: "",
      delivery_date: "",
      delivery_time: "",

      shampoo: "",
      perfume: "",
      softener: "",
      bleach: "",
      sanitizer: "",
      is_seperate_wash: 0,
      laundry_bag_count: "",
      laundry_bag_charges: 0,

      addonCharges: 0,
    };

    console.log('hi_place_order');
    console.log('hi_place_order_e', e);
    console.log('hi_place_order_state', state);
    const context = this.context;
    e.preventDefault();
    // var WalletAmount = this.state.walletAmount;
    // if (this.state.walletUsed == 'yes') {
    //   if (this.state.wallet_total > this.state.totalprice) {
    //     var payment_method = "wallet";

    //   } else if (this.state.wallet_total < this.state.totalprice) {
    //     if (this.state.payment_method == "") {
    //       NotificationManager.error(i18next.t("commons.Please_select_payment_method"), "Error!", 3000);
    //       return false;
    //     }
    //     var payment_method = this.state.payment_method;
    //   }
    // } else {
    //   if (this.state.payment_method == "") {
    //     NotificationManager.error(i18next.t("commons.Please_select_payment_method"), "Error!", 3000);
    //     return false;
    //   }
    //   var payment_method = this.state.payment_method;
    // }
    // if (WalletAmount == null) {
    //   WalletAmount = 0;
    // }
    var total_amount = this.state.mainTotalPrice;
    this.setState({ isLoading: true });

    // Validate the valid pickup time
    // let isToday = moment(state.pickup_date).isSame(moment(), "day");
    // if (isToday) {
    //   var OrderPickupTime = state.pickup_time;
    //   var CurrentDate = moment(new Date());
    //   function round(date, duration, method) {
    //     return moment(Math[method]((+date) / (+duration)) * (+duration));
    //   }
    //   var roundedDate = round(CurrentDate, moment.duration(30, "minutes"), "ceil");
    //   var nowAddOneHour = moment(new Date(roundedDate)).toDate();
    //   nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    //   var ValidPickupTime = moment(nowAddOneHour).format("HH:mm");
    //   if (ValidPickupTime > OrderPickupTime) {
    //     if (this.state.lang == 'en') {
    //       var error_message = "Choosen Pickup Slot is not available. Please choose another pickup slot";
    //     } else {
    //       var error_message = "فتحة الالتقاط المختارة غير متاحة. الرجاء اختيار فتحة أخرى";
    //     }
    //     this.setState(
    //       {
    //         pickup_validation_error: error_message,
    //         enable_place_order: 1,
    //         isLoading: false,
    //       },
    //     );
    //     return false;
    //   } else {
    //     console.log('khj');
    //   }
    // }
    let payment_method = '';
    let WalletAmount = '';
    let formData = new FormData();
    formData.append("adon_others", this.state.adon_text);
    formData.append("adon_request", JSON.stringify(this.state.adon_type));
    formData.append("is_express_delivery", state.is_express_delivery ? 1 : 0);
    formData.append("pickup_address", JSON.stringify(state.pickup_address));
    formData.append("delivery_address", JSON.stringify(state.delivery_address));
    formData.append("pickup_date", state.pickup_date);
    formData.append("pickup_time", state.pickup_time);
    formData.append("is_easy_pickup", 0);
    formData.append("delivery_date", state.delivery_date);
    formData.append("delivery_time", state.delivery_time);
    formData.append("shampoo", state.shampoo ? JSON.stringify(state.shampoo) : "");
    formData.append("perfume", state.perfume ? JSON.stringify(state.perfume) : "");
    formData.append("softener", state.softener ? JSON.stringify(state.softener) : "");
    formData.append("bleach", state.bleach ? JSON.stringify(state.bleach) : "");
    formData.append("sanitizer", state.sanitizer ? JSON.stringify(state.sanitizer) : "");
    formData.append("is_seperate_wash", state.is_seperate_wash ? 1 : 0);
    formData.append("laundry_bag_count", state.laundry_bag_count != "" && state.laundry_bag_count.bags != "" ? state.laundry_bag_count.bags : "");
    formData.append("promocode", ((this.state.discountedPriceNew != 0 && this.state.discountedPriceNew != '') || this.state.OfferType == "freedelivery") ? this.state.promocode ? this.state.promocode : "" : "");
    formData.append("payment_method", payment_method || "card");
    formData.append("card_id", this.state.payment_method == "card" ? this.state.card_id : "");
    formData.append("freedelivery", this.state.totalDeliveryCharge == 0 ? 'freedelivery' : 'nofreedelivery');
    formData.append("Freedelivery_logic", this.state.Freedelivery);
    formData.append("coupon_applied_logic", this.state.coupon_applied_logic);
    formData.append("OfferType", this.state.coupon_applied_logic == "custom" ? this.state.OfferType : "");
    formData.append("walletUsed", this.state.walletUsed);
    formData.append("walletAmount", WalletAmount);
    formData.append("total_amount", total_amount);
    formData.append("product_discount", this.state.product_discount);
    formData.append("addonCharges", state.addonCharges.toFixed(3));
    formData.append("delivery_method", state.delivery_method);
    formData.append("subcategory_discount_name", this.state.subcategory_discount_name != undefined ? this.state.subcategory_discount_name : "");
    formData.append("subcategory_discount_id", this.state.subcategory_discount_id != undefined ? this.state.subcategory_discount_id : "");

    let path = ApiRoutes.CREATE_ORDER;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        const addonDetails = {
          wash: "",
          laundrybag: "",
          addShampoos: "",
          addBleaches: "",
          addPerfumes: "",
          addSofteners: "",
          addSanitizers: "",
        };

        const checkoutDetails = {
          is_express_delivery: "",
          delivery_method: "",
          pickup_address: "",
          delivery_address: "",
          pickup_date: "",
          pickup_time: "",
          delivery_date: "",
          delivery_time: "",
          shampoo: "",
          perfume: "",
          softener: "",
          bleach: "",
          sanitizer: "",
          is_seperate_wash: "",
          laundry_bag_count: "",
        };

        context.setCheckOut(checkoutDetails);
        context.setAdditionalInfo(addonDetails);

        if (this.state.payment_method == "cash") {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.props.history.push("/manage-orders");
        }
        else if (resJson.data.wallet == 'yes') {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.props.history.push("/manage-orders");
        }
        else {
          window.location.replace(resJson.data.payment_url);
        }
      } else {
        this.setState({ isLoading: false });
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      this.setState({ isLoading: false });
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { authUser } = this.props;
    var { cartItems, vendor_id, lang, transportationCart } = this.state;
    var vendor_details = this.state.vendorData_new;
    return (
      <Translation>
        {(t) => (
          <ThemeContext.Consumer>
            {theme => (
              <div>
                <section className="vendor_list_detail_sec py-5" style={{ backgroundColor: "#F5FDFE" }}>
                  <Container className="pt-md-4">
                    <Row>
                      <Col md="12">
                        <div className="d-flex clearCart align-items-center">
                          <div className="w-100 d-flex justify-content-between my_cart_content_mb">
                            {authUser && cartItems && cartItems.length > 0 ? (
                              <div className="w-100 d-flex justify-content-between custom_cart_cls">
                                <div>
                                  <h2 className="section_title mb-sm-4" style={{ marginBottom: '0px' }}>{t("headings.My_Cart")}</h2>
                                  <p className="vendor_cart_name">{cartItems && cartItems[0].vendor.shop_name[lang]}</p>
                                </div>
                                <div className="custom_clear_cart">
                                  {/* <Button className="" onClick={() => this.showDeleteAll()}>
                                {t("commons.CLEAR_CART")}
                              </Button> */}
                                  <ThemeButtonLink1 className="" onClick={() => this.showDeleteAll()} link={"/dashboard"} buttonText={t("commons.CLEAR_CART")}></ThemeButtonLink1>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex clearCart justify-content-between  ml-md-5 mt-5">
                                <div>
                                  <h2 className="section_title mb-sm-4">{t("headings.My_Cart")}</h2>
                                </div>
                                <span className="m-5">
                                  <img src="../assets/img/empty_cart.png"></img>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      {!this.state.isloading ? (
                        <div className="loading"></div>
                      ) : (
                        authUser &&
                        cartItems &&
                        cartItems.map((item, index) => {
                          return (
                            <Col md={6} key={index}>
                              <div className="catgoryproductouter">
                                <div>
                                  <div className="d-flex">
                                    <div className="productimgleft">
                                      <img src={item.service.subcategory.image} alt={lang && item.service.subcategory.name[lang]}></img>
                                    </div>
                                    <div className="productdetails">
                                      <div className="d-flex justify-content-between p-2">
                                        <div>
                                          <h4 style={{ textTransform: "capitalize" }}>{lang && item.service.subcategory.name[lang]}</h4>
                                          <p>
                                            {t("labels.Category")}: <span className="themeColor" style ={{color: theme.colors.primary}}>{lang && item.service.category.name[lang]}</span>{" "}
                                          </p>
                                          <p>
                                            {item.delivery_method != 'normal' && item.delivery_time && (
                                              <p>
                                                {t("filters.Delivery_Time")}:{" "}
                                                {lang &&
                                                  <span className="themeColor">
                                                    {t(item.delivery_time) + " " + t('commons.Days')}
                                                  </span>
                                                }
                                              </p>
                                            )}

                                            {item.delivery_method != 'normal' && item.express_delivery_time && (
                                              <p>
                                                {t("labels.Express_Delivery_time")}:{" "}
                                                {lang &&

                                                  item.delivery_method == 'medium' ? (<span className="themeColor">

                                                    {t(item.express_delivery_time) + " " + t('commons.Hours')}
                                                  </span>) : (<span className="themeColor">

                                                    {t(item.express_delivery_time) + " " + t('commons.Days')}
                                                  </span>)
                                                }
                                              </p>
                                            )}
                                          </p>{" "}
                                        </div>
                                        <div>
                                          <span>
                                            <i className="fas fa-trash-alt" onClick={() => this.showDeleteModal(item._id)}></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-3">
                                  <div className="col-md-12">
                                    <ul>
                                      <Slider {...sliderSettings}>
                                        {item.service_items.map((service_type, i) => {
                                          var totalprice = "";
                                          if (service_type.measure_type == 'piece') {
                                            totalprice = service_type.unit_quantity * service_type.price_by_measure + (service_type.unit_quantity * service_type.clothe_return_type.hanging_charge);
                                          } else {
                                            totalprice = service_type.unit_quantity * service_type.price_by_measure + service_type.clothe_return_type.hanging_charge;
                                          }
                                          return (
                                            <div className="dryclean" key={i}>
                                              <div className="d-flex justify-content-between">
                                                <h5>{lang && service_type.service_type.name[lang]}</h5>
                                                <i
                                                  className="fas fa-pencil-alt"
                                                  title="Edit"
                                                  style={{ cursor: "pointer", color: theme.colors.primary}}
                                                  onClick={() => this.toggleEditModal(item, service_type)}
                                                ></i>
                                              </div>
                                              <p>
                                                {t("contents.Qty")}:{" "}
                                                <span className="themeColor" style ={{color: theme.colors.primary}}>
                                                  {service_type.measure_type === "weight"
                                                    ? parseInt(service_type.quantity_by_measure) / 1000 + " " + t("commons.KG.")
                                                    : service_type.measure_type === "meter"
                                                      ? service_type.quantity_by_measure + " " + t("commons.Sq_Mts.")
                                                      : service_type.quantity_by_measure + " " + t("commons.Pcs.")}
                                                </span>
                                              </p>
                                              <p>
                                                {t("labels.Item_Return_Type")}: <span className="themeColor" style ={{color: theme.colors.primary}}>{lang && service_type.clothe_return_type.name[lang]}</span>
                                              </p>{" "}
                                              {service_type.PricePerUnit == 0 && service_type.PricePerUnit != undefined ? (
                                                <p>
                                                  {" "}
                                                  {service_type.measure_type == "piece"
                                                    ? t("commons.Price_per_piece")
                                                    : service_type.measure_type == "weight"
                                                      ? t("commons.Price_per_weight")
                                                      : t("commons.Price_per_square_meter")}
                                                  : <span className="themeColor" style ={{color: theme.colors.primary}}>{t("commons.OMR") + " " + service_type.PricePerUnit.toFixed(3)}</span>
                                                </p>
                                              ) : (
                                                <p>
                                                  {" "}
                                                  {service_type.measure_type == "piece"
                                                    ? t("commons.Price_per_piece")
                                                    : service_type.measure_type == "weight"
                                                      ? t("commons.Price_per_weight")
                                                      : t("commons.Price_per_square_meter")}
                                                  : <span className="themeColor" style ={{color: theme.colors.primary}}>{t("commons.OMR") + " " + service_type.price_by_measure.toFixed(3)}</span>
                                                </p>
                                              )

                                              }

                                              {service_type.clothe_return_type &&
                                                service_type.clothe_return_type.hanging_charge &&
                                                service_type.clothe_return_type.hanging_charge >= 0 ? (
                                                <p>
                                                  {" "}
                                                  {t("commons.Hanging_Charges")}:{" "}
                                                  <span className="themeColor" style ={{color: theme.colors.primary}}>{t("commons.OMR") + " " + service_type.clothe_return_type.hanging_charge.toFixed(3)}</span>
                                                </p>
                                              ) : (
                                                <p>
                                                  {t("commons.Hanging_Charges")}: <span className="themeColor" style ={{color: theme.colors.primary}}>{t("commons.OMR") + " 0.000"}</span>
                                                </p>
                                              )}
                                              {service_type && service_type.is_discount_subcategory == 'available' && service_type.DiscountPrice != 0 ?
                                                <>
                                                  <p className="subcategory_discount">
                                                    <span className="themeColor discount_latest"> {" "}
                                                      {t("commons.Discount")} ({service_type.subcategory_discount_name}):</span> <span className="themeColor discount">{t("commons.OMR") + " " + service_type.DiscountPrice.toFixed(3)}</span>
                                                  </p><p>
                                                    {" "}
                                                    {t("commons.Total_Price")}: <span className="themeColor" style ={{color: theme.colors.primary}}>{t("commons.OMR") + " " + (service_type.TotalPrice.toFixed(3) - service_type.DiscountPrice.toFixed(3)).toFixed(3)}</span>
                                                  </p></> : <p>
                                                  {" "}
                                                  {t("commons.Total_Price")}: <span className="themeColor" style ={{color: theme.colors.primary}}>{t("commons.OMR") + " " + service_type.TotalPrice.toFixed(3)}</span>
                                                </p>}

                                            </div>
                                          );
                                        })}
                                      </Slider>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      )}
                    </Row>

                    {!authUser && this.props.history.push("/login")}
                    {cartItems.length > 0 ? (
                      <div className="add_more_section">
                        {/* <center>
                      <Link to={"additional_info/" + this.state.vendor_id}>
                        <Button>{t("commons.Service_Ad-Ons")}</Button>
                      </Link>
                    </center> */}
                        {/* {
                      transportationCart == 0 ? (<>
                        <center>
                          <Button onClick={() => {this.setState({
                            isNonTransportation : true
                          })}}>{t("commons.Payment")}</Button>
                        </center> 
                      </>) : (<>
                    <center>
                      <Link to={"checkout/" + this.state.vendor_id}>
                        <Button>{t("commons.Checkout")}</Button>
                      </Link>
                    </center>
                           </>)
                          } */}
                        {
                          transportationCart == 0 && this.state.mainTotalPrice > 0 && (<>
                            <center>
                              {/* <Link to={"checkout/" + this.state.vendor_id}> */}
                              <Button onClick={() => {
                                this.setState({
                                  isNonTransportation: true
                                })
                              }}>{t("commons.Payment")}</Button>
                              {/* </Link> */}
                            </center>
                          </>)
                        }
                        {
                          transportationCart == 0 && this.state.mainTotalPrice == 0 && (<>
                            <center>
                              <Button
                                onClick={() => {
                                  this.setState({
                                    isNonTransportationPlaceOrder: true
                                  })
                                }}
                              >{t("commons.Place_Order")}</Button>
                            </center>
                          </>)
                        }
                        {
                          transportationCart == 1 && this.state.mainTotalPrice > 0 && (<>
                            <center>
                              {/* <Link to={"checkout/" + this.state.vendor_id}>
                        <Button>{t("commons.Checkout")}</Button>
                      </Link> */}
                              <ThemeButtonLink
                                link={"checkout/" + this.state.vendor_id} // Dynamic link
                                buttonText={t("commons.Checkout")}
                              />
                            </center>
                          </>)
                        }
                        {
                          transportationCart == 1 && this.state.mainTotalPrice == 0 && (<>
                            <center>
                              {/* <Link to={"checkout/" + this.state.vendor_id}>
                            <Button>{t("commons.Checkout")}</Button>
                          </Link> */}
                              <ThemeButtonLink
                                link={"checkout/" + this.state.vendor_id} // Dynamic link
                                buttonText={t("commons.Checkout")}
                              />
                            </center>
                          </>)
                        }
                        <center>
                          <Link
                            to={{
                              pathname: `/service-list/${this.state.vendor_id}`,
                              state: { shop: vendor_details && vendor_details.shop_name && vendor_details.shop_name[lang], branch: vendor_details && vendor_details.branch_name && vendor_details.branch_name[lang] },
                            }}
                          >
                            <StyledButtondiv>{t("commons.Add_More_Items")}</StyledButtondiv>
                          </Link>
                        </center>
                      </div>
                    ) : (
                      ""
                    )}
                  </Container>
                </section>
                <Modal
                  isOpen={this.state.serviceModal}
                  backdrop={"static"}
                  keyboard={false}
                  toggle={() => {
                    this.toggleEditModal();
                  }}
                >
                  <Formik
                    enableReinitialize
                    initialValues={{
                      measure_type: this.state.measure_type,
                      quantity_by_measure: this.state.quantity_by_measure,
                      service_item_id: this.state.service_item_id,
                      item_id: this.state.item_id,
                    }}
                    validationSchema={ServiceTypeFormSchema}
                    onSubmit={this.handleEditCart}
                  >
                    {({ handleEditCart, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                      <Form>
                        <div className="filter_modal_header">
                          <span onClick={() => this.hideEditModal()} style={{ cursor: "pointer" }} className="btn1">
                            {t("commons.Cancel")}
                          </span>
                          <div className="filter_modal_heading">{t("filters.Service_Item")}</div>
                          <button type="submit" style={{ cursor: "pointer" }} className="btn1">
                            {t("commons.Edit")}
                          </button>
                        </div>

                        <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                          <Field name="measure_type" className="form-control" style={{ textTransform: "capitalize" }} type="text" placeholder="Enter_Quantity" disabled />
                          {errors.measure_type && touched.measure_type && <div className="invalid-feedback d-block">{t(errors.measure_type)}</div>}
                        </FormGroup>

                        <FormGroup className="pl-3 pr-3">
                          <Field className="form-control" name="quantity_by_measure" type="text" placeholder={t("commons.Enter_Quantity")} />
                          {errors.quantity_by_measure && touched.quantity_by_measure ? <div className="invalid-feedback d-block">{t(errors.quantity_by_measure)}</div> : null}
                        </FormGroup>
                      </Form>
                    )}
                  </Formik>
                </Modal>

                {/* Modal for free delivery pop up */}
                <Modal isOpen={this.state.freeDeliveryModal} backdrop={"static"} keyboard={false}>
                  <ModalBody>{this.showPopup()}</ModalBody>
                  <ModalFooter>
                    <div>
                      <button
                        type="submit"
                        style={{ cursor: "pointer" }}
                        className="btn"
                        onClick={() => {
                          this.setState({ freeDeliveryModal: false });
                        }}
                      >
                        {t("commons.OK")}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>

                {/* Modal for clear cart item */}
                <Modal isOpen={this.state.isDelete} backdrop={"static"} keyboard={false}>
                  <ModalBody>
                    <h6>{t("commons.Are_you_sure_you_want_to_delete_the_selected_item_from_cart?")}</h6>
                  </ModalBody>
                  <ModalFooter>
                    <div>
                      <button
                        type="submit"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        className="btn"
                        onClick={() => {
                          this.deleteCartItem(this.state.objId);
                          this.setState({ isDelete: false });
                        }}
                      >
                        {t("commons.OK")}
                      </button>
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isDelete: false })}>
                        {t("commons.Cancel")}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>

                {/* Modal for clear all cart items */}
                <Modal isOpen={this.state.isDeleteAll} backdrop={"static"} keyboard={false}>
                  <ModalBody>
                    <h6>{t("commons.Are_you_sure_you_want_to_remove_all_the_items_from_cart?")}</h6>
                  </ModalBody>
                  <ModalFooter>
                    <div>
                      <button
                        type="submit"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        className="btn"
                        onClick={() => {
                          this.deleteCart();
                          this.setState({ isDeleteAll: false });
                        }}
                      >
                        {t("commons.OK")}
                      </button>
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isDeleteAll: false })}>
                        {t("commons.Cancel")}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>


                {/* Modal for non transportation place order */}
                <Modal isOpen={this.state.isNonTransportation} backdrop={"static"} keyboard={false}>
                  <ModalHeader>
                    <h2>{t("commons.Without_Transportation")}</h2>
                  </ModalHeader>
                  <ModalBody>
                    <h6 style={{
                      lineHeight: '2.2',
                      wordSpacing: '5px'
                    }}>{t("commons.Non_transportation_comments_to_customer?")}</h6>
                  </ModalBody>
                  <ModalFooter>
                    <div>
                      <button
                        type="submit"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        className="btn"
                        onClick={() => {
                          // this.deleteCartItem(this.state.objId);
                          // this.setState({ isDelete: false });
                          this.props.history.push({
                            pathname: "/payment/" + this.state.vendor_id,
                            state: {
                              is_express_delivery: 0,
                              delivery_method: "normal",
                              pickup_address: {
                                appartment: "",
                                city: "",
                                country: "",
                                landmark: "",
                                latitude: 0,
                                longitude: 0,
                                pincode: "",
                                state: "",
                                street: "",
                                title: { en: 'Home', ar: 'منزل' }
                              },
                              delivery_address: {
                                appartment: "",
                                city: "",
                                country: "",
                                landmark: "",
                                latitude: 0,
                                longitude: 0,
                                pincode: "",
                                state: "",
                                street: "",
                                title: { en: 'Home', ar: 'منزل' }
                              },
                              pickup_date: "",
                              pickup_time: "",
                              delivery_date: "",
                              delivery_time: "",
                              shampoo: "",
                              perfume: "",
                              softener: "",
                              bleach: "",
                              sanitizer: "",
                              is_seperate_wash: 0,
                              laundry_bag_count: "",
                              laundry_bag_charges: 0,
                              addonCharges: 0,
                            },
                          });
                        }}
                      >
                        {t("commons.OK")}
                      </button>
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isNonTransportation: false })}>
                        {t("commons.Cancel")}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
                {/* Modal for non transportation place order button */}
                <Modal isOpen={this.state.isNonTransportationPlaceOrder} backdrop={"static"} keyboard={false}>
                  <ModalHeader>
                    <h2>{t("commons.Without_Transportation")}</h2>
                  </ModalHeader>
                  <ModalBody>
                    <h6 style={{
                      lineHeight: '2.2',
                      wordSpacing: '5px'
                    }}>{t("commons.Non_transportation_comments_to_customer?")}</h6>
                  </ModalBody>
                  <ModalFooter>
                    <div>
                      <button
                        type="submit"
                        style={{ cursor: "pointer", marginLeft: "8px" }}
                        className="btn"
                        onClick={(e) => {
                          // this.deleteCartItem(this.state.objId);
                          // this.setState({ isDelete: false });
                          this.handlePlaceOrder(e)
                        }}
                      >
                        {t("commons.OK")}
                      </button>
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isNonTransportationPlaceOrder: false })}>
                        {t("commons.Cancel")}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      </Translation>
    );
  }
}
