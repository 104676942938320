import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

export default class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <section
          className="py-4 py-md-5 vendor_list_detail_sec"
          style={{
            backgroundImage: "url(" + "assets/img/download_app_bg.jpg" + ")",
          }}
        >
          <Container>
            <Row className="align-items-center py-4 py-md-5">
              <Col xs={12} md={6} className="pt-5 pt-md-0">
                <div className="download_image">
                  <img src="./assets/img/404.jpg" alt="download-app" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
