import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import Slider from "react-slick";
import { Shimmerimage, Shimmertext, Shimmerdescription, Shimmerbutton, Shimmerdescription1 } from "../../helpers/ShimmerCustom";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";
import authService from "../../services/auth.service";
import { ThemeButtonLink } from "../../helpers/Button";

var sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: false,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [{ breakpoint: 767, settings: { dots: false } }],
};

export default class Home extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {
      bannersList: [],
      vendorsList: [],
      lang: "",
      settingInfo: authService.getAuthSettings(),
    };
  }

  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
    this.getBanners();
    this.getVendors();
  }

  getBanners = async () => {
    let path = ApiRoutes.GET_BANNERS;
    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({
          bannersList: resJson.data.docs,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getVendors = async () => {
    let path = ApiRoutes.GET_VENDORS + "?page=1&limit=3";
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          vendorsList: resJson.data.docs,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  setFavourite = async (vendor_id, is_favourite, index) => {
    var action = is_favourite ? "unfavourite" : "favourite";
    var new_favourite = is_favourite ? 0 : 1;

    let formData = new FormData();
    formData.append("vendor_id", vendor_id);
    formData.append("action", action);

    let path = ApiRoutes.SET_FAVOURITES;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        let vendorsList = Object.assign([], this.state.vendorsList);

        vendorsList[index].is_favourite = new_favourite;

        this.setState({ vendorsList: vendorsList });

        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { authUser } = this.props;
    var { bannerList, lang, settingInfo } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
           {this.state.bannersList.length > 0 ? (
              <section className="home_banner home_banner_mobile">
                <Slider {...sliderSettings} className="text-center">
                  {this.state.bannersList.map((item, index) => {
                    return (
                      <img
                        className="home_banner_img"
                        key={index}
                        src={item.image_mobile}
                        loading="lazy"
                        onClick={() => {
                          if (item.banner_for == "vendor") {
                            this.props.history.push("/vendor-details/" + item.vendor_id);
                          } else {
                            item.website_url && window.open(item.website_url, "_blank");
                          }
                        }}
                      />
                    );
                  })}
                </Slider>
              </section>
            ):
             <div style={{height: "300px"}}></div>
           }
            {this.state.bannersList.length > 0 ? (
              <section className="home_banner home_banner_desktop">
                <Slider {...sliderSettings} className="text-center">
                  {this.state.bannersList.map((item, index) => {
                    return (
                      <img
                        className="home_banner_img"
                        key={index}
                        src={item.image_url}
                        loading="lazy"
                        onClick={() => {
                          if (item.banner_for == "vendor") {
                            this.props.history.push("/vendor-details/" + item.vendor_id);
                          } else {
                            item.website_url && window.open(item.website_url, "_blank");
                          }
                        }}
                      />
                    );
                  })}
                </Slider>
              </section>
            ):
             <div className="home_banner_desktop"  style={{height: "300px"}}></div>
           }


            <section className="find_laundries_sec py-4 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
              <Container className="py-3 py-md-4">
                <h2 className="section_title text-center pb-3 mb-4 mb-md-5">{t("headings.Find_Laundries")}</h2>
                <ul className="laundries_list home_laundries_list">
                  {
                    this.state.vendorsList.length == 0 ? (
                      // Render shimmer effect when data is loading
                      [1, 2, 3].map(index => (
                        <li key={index} className="custom-shimmer-section">
                          <div className="w-100 laundries_desc">
                            <div className="laundries_desc_img">
                              <Shimmerimage />
                            </div>
                            <div className="laundries_desc_data">
                              <Shimmertext />
                              <Shimmertext />
                              <ul className="laundries_desc_data_list d-flex flex-wrap">
                                <li>
                                  <Shimmerdescription />
                                </li>
                                <li>
                                  <Shimmerdescription />
                                </li>
                                <li>
                                  <Shimmerdescription />
                                </li>
                                <li>
                                  <Shimmerdescription />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="laundries_rates">
                            <Shimmerdescription1 />
                            <Shimmerbutton />
                            <Shimmerdescription1 />
                          </div>
                        </li>
                      ))
                    ) : (
                      this.state.vendorsList.length > 0 &&
                      this.state.vendorsList.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="w-100 laundries_desc">
                              <div className="laundries_desc_img">
                                <img src={item.user_image_thumb_url} alt={item.shop_name && item.shop_name[lang]} loading="lazy"/>
                              </div>
                              <div className="laundries_desc_data">
                                <h3 className="laundries_desc_data_title">{item.shop_name && item.shop_name[lang]}</h3>
                                <p className="branch_title mb-2">
                                  <b> {item.branch_name && item.branch_name[lang]}</b>
                                </p>
                                {item.customerCorporatePackage == 1 &&
                                  <div className="overlay-corporate-ribbon"><span>
                                    <Link to={"/corporate-package"} >{t("purchased_corporate_package")}</Link>
                                  </span></div>
                                }

                                {
                                  item.customerPackage == 1 &&
                                  <div className="overlay-ribbon"><span>
                                    <Link to={"/promotions"} >{t("purchased_package")}</Link>
                                  </span></div>
                                }
                                {/* }  */}

                                <ul className="laundries_desc_data_list d-flex flex-wrap">
                                  <li>
                                    {t("labels.Service_Provider")} :{" "}
                                    {item.service_providers.length > 0
                                      ? item.service_providers.map((item, index) => {
                                        return <span key={index}>{(index ? ", " : "") + item.name[lang]}</span>;
                                      })
                                      : t("commons.N/A")}
                                  </li>
                                  {/* <li>
                                    {t("labels.Express_Delivery")}: {item.is_normal_express_delivery == 1 ? t("commons.Available") : t("commons.Not Available")}
                                  </li> */}
                                  <li>
                                    {t("labels.Express_Delivery_Time")}:{" "}
                                    {item.normal_express_delivery_time
                                      ? item.normal_express_delivery_time > 1
                                        ? item.normal_express_delivery_time + " " + t("commons.Hours")
                                        : item.normal_express_delivery_time + " " + t("commons.Hour")
                                      : t("commons.N/A")}
                                  </li>
                                  <li>
                                    {t("labels.Normal_Delivery_Time")}:{" "}
                                    {item.normal_delivery_time
                                      ? item.normal_delivery_time > 1
                                        ? item.normal_delivery_time + " " + t("commons.Days")
                                        : item.normal_delivery_time + " " + t("commons.Day")
                                      : t("commons.N/A")}
                                  </li>
                                </ul>
                                <div className="lable_tag_vendor new">
                                  <ul>
                                    {item && item.is_freedelivery == 'available' && (
                                      <li className="freedelivery">{t("contents.Free-delivery")}</li>
                                    )}
                                    {item && item.is_discount == 'available' && (
                                      lang == 'en' ?
                                        <li className="discount">{item && item.discount_type == 'percent' ? item.discount_amount + "% " + t("contents.Discount") : t("contents.OMR") + item.discount_amount + " " + t("contents.Discount")}</li> :
                                        <li className="discount">{item && item.discount_type == 'percent' ? t("contents.Discount") + item.discount_amount + " % " : t("contents.Discount") + item.discount_amount + " " + t("contents.OMR")}</li>
                                    )}
                                    {item && item.is_discount_subcategory == 'available' && (
                                      <li className="discount_subcategory">{t("contents.Sub_Discount")}</li>
                                    )}
                                    {item && item.vendorPackage == 1 && (
                                      <li className="package">{t("package-available-section")}</li>
                                    )}
                                    {/* display corporate package tag */}
                                    {item && item.vendorCorporatePackage == 1 && (
                                      <li className="corporate-package">{t("corporate-package-available-section")}</li>
                                    )}
                                  </ul>
                                </div>
                              </div>

                            </div>
                            <div className="laundries_rates">
                              <div className="like_rate_view d-inline-flex align-items-center justify-content-end w-100 mb-3 mb-md-4">
                                {authUser && (
                                  <div
                                    className={"ml-4 " + (item.is_favourite ? "like_view" : "unlike_view")}
                                    onClick={() => {
                                      this.setFavourite(item._id, item.is_favourite, index);
                                    }}
                                  >
                                    <i className="fas fa-heart"></i>
                                  </div>
                                )}
                              </div>
                              <div>
                                {/* {item.average_rating && ( */}
                                <div className="ratting_view mb-3">
                                  <i className="fas fa-star mr-2"></i>
                                  {item.average_rating ? item.average_rating.toFixed(1) : "0.0"}
                                </div>
                                {/* )} */}
                                {/* <Link to={"vendor-details/" + item._id} className="btn">
                                  {t("buttons.View_Detail")}
                                </Link> */}
                                <ThemeButtonLink
                                  link={"vendor-details/" + item._id} // Dynamic link
                                  buttonText={t("buttons.View_Detail")}
                                />
                                {item && item.is_available ? (
                                  <span
                                    className="distance"
                                    style={{
                                      color: "green",
                                      fontWeight: "400",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {" "}
                                    {t("contents.Online")}
                                  </span>
                                ) : (
                                  <span
                                    className="distance"
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {" "}
                                    {t("contents.Offline")}
                                  </span>
                                )}

                              </div>
                            </div>
                          </li>
                        );
                      }))
                  }
                </ul>
                <div className="view_all_laundries_btn text-center py-5 block">
                  {/* <Link to="/vendors" className="btn">
                      {t("buttons.View_All_Laundries")}
                    </Link> */}
                  <ThemeButtonLink
                    link={"/vendors"} className="btn"
                    buttonText={t("buttons.View_All_Laundries")} />
                </div>
              </Container>
            </section>
            {this.state.vendorsList.length > 0 && (
              <section
                className="download_app_sec py-4 py-md-5"
                style={{
                  backgroundImage: "url(" + "assets/img/download_app_bg.jpg" + ")",
                }}
              >
                <Container>
                  <h2 className="section_title text-center pb-3 mb-4 mb-md-5">{t("contents.Download App")}</h2>
                  <Row className="align-items-center py-4 py-md-5">
                    <Col xs={12} md={6}>
                      <div className="download_content text-center text-md-left">
                        <h2 className="download_title mb-3">{t("contents.content1")}</h2>
                        <p className="mb-5">{t("contents.content2")}</p>
                        <p className="mb-5">{t("contents.content3")}</p>
                        <span className="download_text d-block mt-5">{t("contents.Download The App")} </span>
                        <div className="btn_app text-md-left mt-3">
                          <a href={settingInfo && settingInfo.live_app_links && settingInfo.live_app_links.customer_ios} target="blank">
                            <img src="./assets/img/ios.png" alt="ios" className="mr-3" loading="lazy" />
                          </a>
                          <a href={settingInfo && settingInfo.live_app_links && settingInfo.live_app_links.customer_android} target="blank">
                            <img src="./assets/img/andorid.png" alt="andorid" loading="lazy" />
                          </a>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="pt-5 pt-md-0">
                      <div className="download_image">
                        <img src="./assets/img/download-app.png" alt="download-app" loading="lazy"/>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
          </>
        )}
      </Translation>
    );
  }
}
