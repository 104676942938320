import React, { Component } from "react";
import { Col, Container, Row, Modal, Form, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ThemeContext } from 'styled-components';
import moment from "moment";
import StarRatings from "react-star-ratings";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import InfiniteScroll from "react-infinite-scroller";
import { Translation } from "react-i18next";
import MainContext from "../../helpers/MainContext";
import StyledInputButton from "../../helpers/StyledInputButton";
import ModalLayoutAlert from "../../helpers/ModalLayoutAlert";

const WorkingDaysOpts = ["commons.Sunday", "commons.Monday", "commons.Tuesday", "commons.Wednesday", "commons.Thursday", "commons.Friday", "commons.Saturday"];

export default class VendorDetails extends Component {
  static contextType = MainContext;
  static contextType = ThemeContext; 
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.match.params.itemId,
      itemData: null,
      itemPacks: [],
      itemCorporatePacks: [],
      first_shift_start_time: "",
      first_shift_end_time: "",
      second_shift_start_time: "",
      second_shift_end_time: "",
      workingDays: [],
      checkday: [],
      book_service_enable: 0,
      latitude: "",
      longitude: "",
      isloading: false,
      lang: "",
      show: false,
      showRatingModal: false,
      ratingData: [],
      vendor_id: props.match.params.itemId,
      isOpened: false,
      showPopUp: false,
      ispopup: 0,
      is_easy_pickup: 0,
      minimum_wallet_amount_for_easy_pickup: 0,
      wallet_amount: 0,
      show_topup_wallet_button: 0,
      promotion_pack_count: 3,
    };
  }

  async componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });

    var coords = AuthService.getCurrentLocation();
    this.setState(
      {
        latitude: coords.latitude,
        longitude: coords.longitude,
      },
      () => {
        this.getVendorData(false);
        this.getRatings();
      }
    );
    this.getPackage();
    this.getCorporatePackage();
  }

  getPackage = async () => {
    let path = ApiRoutes.GET_PROMOTION_PACKAGE + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
      if (res.status == 200) {
        // var buyed_pack = 0;
        // resJson.data.docs.map((item, index) => {
        //   if (index < 3 && current_date < item.vendor_package_date && item.customer_buyed_pack == 0) {
        //   } else {
        //     buyed_pack += 1;
        //   }
        // });
        // if (buyed_pack != resJson.data.docs.length && buyed_pack <= resJson.data.docs.length) {

        this.setState({
          itemPacks: resJson.data.docs,
          lang: localStorage.getItem("lang") || "en",
          current_date: current_date,
        });
        // }

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);

    }
  };


  getCorporatePackage = async () => {
    let path = ApiRoutes.GET_CORPORATE_PACKAGE + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
      if (res.status == 200) {
        // var buyed_pack = 0;
        // resJson.data.docs.map((item, index) => {
        //   if (index < 3 && current_date < item.vendor_package_date && item.customer_buyed_pack == 0) {
        //   } else {
        //     buyed_pack += 1;
        //   }
        // });
        // if (buyed_pack != resJson.data.docs.length && buyed_pack <= resJson.data.docs.length) {
        if ((resJson.data.result).length < 3) {
          var promotion_count = 3 - (resJson.data.result).length;
        }
        this.setState({
          itemCorporatePacks: resJson.data.result,
          lang: localStorage.getItem("lang") || "en",
          current_date: current_date,
          promotion_pack_count: promotion_count,
        });
        // }

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);

    }
  };


  getVendorData = async (refreshPage = false) => {
    let path = ApiRoutes.GET_VENDOR + "/" + this.state.itemId + "?latitude=" + `${this.state.latitude}` + "&longitude=" + `${this.state.longitude}`;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      var vendor_id = resJson.data.user_code;
      var checkday = [];
      if (res.status == 200) {
        var settingsData = JSON.parse(localStorage.getItem("settings"));
        this.setState({
          minimum_wallet_amount_for_easy_pickup: settingsData.minimum_wallet_amount_for_easy_pickup,
          wallet_amount: resJson.data && resJson.data.customer_setting && resJson.data.customer_setting.wallet_amount
        })
        // if (settingsData.minimum_wallet_amount_for_easy_pickup <= resJson.data.customer_setting.wallet_amount) {
        //   this.setState({
        //     is_easy_pickup: 1
        //   })
        // };
        this.setState({ ispopup: resJson.data && resJson.data.customer_setting && resJson.data.customer_setting.is_popup })
        var book_service_enable = 0;
        if (resJson.data.sunday_timings != undefined) {
          Object.entries(resJson.data.sunday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        if (resJson.data.monday_timings != undefined) {
          Object.entries(resJson.data.monday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        if (resJson.data.tuesday_timings != undefined) {
          Object.entries(resJson.data.tuesday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        if (resJson.data.wednesday_timings != undefined) {
          Object.entries(resJson.data.wednesday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        if (resJson.data.thursday_timings != undefined) {
          Object.entries(resJson.data.thursday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        if (resJson.data.friday_timings != undefined) {
          Object.entries(resJson.data.friday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        if (resJson.data.saturday_timings != undefined) {
          Object.entries(resJson.data.saturday_timings).forEach(([key, value]) => {
            if (value != "" && value != null && value != '1970-01-01T00:00:00.000Z') {
              this.setState({ [key]: moment(value).utc().format("hh:mm A") });
              book_service_enable = 1;
            } else {
              this.setState({ [key]: "Closed" });
            }
          });
        }
        await this.setState(
          {
            itemData: resJson.data,
            workingDays: resJson.data && resJson.data.working_days && resJson.data.working_days.sort(),
            vendor_id: resJson.data.user_code,
            book_service_enable: book_service_enable,
          },
          () => {
            this.setState({ isloading: true });
            if (refreshPage == false) {
              this.showPopUpBox(resJson.data.is_available);
            }
          }
        );
        this.state.workingDays && this.state.workingDays.map((item, index) => {
          checkday.push(item);
        });
        this.setState({ checkday: checkday });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }

    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  loadPagination = async (currentPage) => {
    this.getRatings(currentPage + 1);
  };

  getRatings = async (currentPage = 1) => {
    let path = ApiRoutes.RATINGS + "/" + this.state.itemId + "/reviews" + "?page_no=" + `${currentPage}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            ratingData: [...this.state.ratingData, ...resJson.data.docs],

            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  showModal = (e) => {
    this.setState({
      show: !this.state.show,
    });
  };

  showPopUpBox = (is_availablepop) => {
    if (is_availablepop == 0) {
      this.showModal();
    }
  };

  toggleRatingModal = () => {
    this.setState((prevState) => ({
      showRatingModal: !prevState.showRatingModal,
    }));
  };

  hideRatingModal = () => {
    this.setState((prevState) => ({
      showRatingModal: !prevState.showRatingModal,
    }));
  };

  openPopup = async () => {
    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (resJson.data.cartItems.length == 0) {
        if (this.state.ispopup == 1 && (this.state.wallet_amount >= this.state.minimum_wallet_amount_for_easy_pickup)) {
          this.props.history.push(`/checkout/${this.state.itemId}`);
        } else if (this.state.wallet_amount >= this.state.minimum_wallet_amount_for_easy_pickup) {
          this.setState((prevState) => ({
            isOpened: !prevState.isOpened,
          }));
        } else {
          this.setState((prevState) => ({
            isOpened: !prevState.isOpened,
            show_topup_wallet_button: 1,
          }));
        }
      } else {
        var cartaction = this.state.lang == 'en' ? window.confirm("Your laundry basket have items. Do you want to clear the laundry basket before proceeding to easy pick.") : window.confirm("تحتوي سلة الغسيل الخاصة بك على أشياء. هل تريد مسح سلة الغسيل قبل الشروع في الاختيار السهل؟")
        if (cartaction == true) {
          this.deleteCart();
          if (this.state.minimum_wallet_amount_for_easy_pickup >= this.state.wallet_amount) {
            this.setState((prevState) => ({
              isOpened: !prevState.isOpened,
              show_topup_wallet_button: 1,
            }));
          } else if (this.state.ispopup == 0) {
            this.setState((prevState) => ({
              isOpened: !prevState.isOpened,
            }));
          } else {
            this.props.history.push(`/checkout/${this.state.itemId}`);
          }
        }
      }
    };
  }

  deleteCart = async () => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_CART;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        const context = this.context;
        context.setCartItem(0);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  proceedToCheckOut = async () => {
    if (this.state.minimum_wallet_amount_for_easy_pickup <= this.state.wallet_amount) {
      let formData = new FormData();
      formData.append("easy_pickup_popup", this.state.showPopUp == true ? 1 : 0);
      let path = ApiRoutes.UPDATE_EASY_PICKUP_POPUP;
      const res = await Http("PUT", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.props.history.push(`/checkout/${this.state.itemId}`);
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    } else {
      let formData = new FormData();
      formData.append("easy_pickup_popup", this.state.showPopUp == true ? 1 : 0);
      let path = ApiRoutes.UPDATE_EASY_PICKUP_POPUP;
      const res = await Http("PUT", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.props.history.push(`/wallet-pay/`);
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    };
  }

  setFavourite = async (vendor_id, is_favourite) => {
    var action = is_favourite ? "unfavourite" : "favourite";
    var new_favourite = is_favourite ? 0 : 1;

    let formData = new FormData();
    formData.append("vendor_id", vendor_id);
    formData.append("action", action);

    let path = ApiRoutes.SET_FAVOURITES;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        let itemData = Object.assign([], this.state.itemData);
        itemData.is_favourite = new_favourite;
        this.setState({ itemData: itemData });

        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { authUser } = this.props;
    var { checkday, lang, itemData, itemPacks, first_shift_start_time, first_shift_end_time, second_shift_start_time, second_shift_end_time, ispopup } = this.state;
    const theme = this.context; 
    return (
      <>
        <Translation>
          {(t) => (
            <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
              <Container className="py-3 py-md-4">
                {!this.state.isloading ? (
                  <div className="loading"></div>
                ) : (
                  <Row className="mt-md-4">
                    <Col xs={12} md={5} sm={4}>
                      <div className="vendor_list_pic_sec mb-4">
                        <img src={itemData ? itemData.user_image_url : ""} alt="LaundryHUB" />
                        {authUser && (
                          <span
                            className={itemData && itemData.is_favourite ? "like_view1" : "unlike_view1"}
                            onClick={() => {
                              this.setFavourite(itemData._id, itemData.is_favourite);
                            }}
                          >
                            <i className="fas fa-heart"></i>
                          </span>
                        )}
                      </div>

                    </Col>
                    <Col xs={12} md={7} sm={8}>
                      <div className="vendor_list_detail_view">
                        <h2 className="mb-2">{itemData && itemData.shop_name && itemData.shop_name[lang]}</h2>
                        <h5 className="mb-2 mt-1">{t('commons.Branch_Name')} {itemData && itemData.branch_name && itemData.branch_name[lang]}</h5>
                        <div className="d-flex w-100">
                          <div className="address">
                            <p className="ml-1">
                              {t("labels.Address")}:{" "}
                              <span className="ml-1 themeColor text-break" style={{ color: theme.colors.primary }}>
                                {itemData &&
                                  itemData.default_address &&
                                  itemData.default_address.appartment +
                                  "," +
                                  itemData.default_address.street +
                                  ", " +
                                  itemData.default_address.landmark +
                                  ", " +
                                  itemData.default_address.city +
                                  ", " +
                                  itemData.default_address.state +
                                  ", " +
                                  itemData.default_address.country +
                                  ", " +
                                  itemData.default_address.pincode}
                              </span>
                            </p>

                            {/* <p>
                              {t("labels.Minimum_Order_Value")}:{" "}
                              <span className="themeColor ml-1">
                                {" "}
                                {itemData && itemData.minimum_order_amount ? t("commons.OMR") + " " + itemData.minimum_order_amount.toFixed(3) : "N/A"}
                              </span>
                            </p> */}
                          </div>

                          <div className="vendor_list_detail_heading">
                            {itemData && itemData.average_rating != null ? (
                              <span className="rating" onClick={this.toggleRatingModal} style={{ cursor: "pointer" }}>
                                <i className="fas fa-star"></i> {itemData.average_rating.toFixed(1)}
                              </span>
                            ) : (
                              <span className="rating">
                                <i className="fas fa-star"></i> 0.0
                              </span>
                            )}
                            <span className="distance">
                              {itemData && itemData.distance
                                ? t("commons.Distance") + ": " + itemData.distance.toFixed(2) + " " + t("commons.KMs.")
                                : t("commons.Distance") + ": 0.0 " + t("commons.KMs.")}
                            </span>

                            {itemData && itemData.is_available ? (
                              <span
                                className="distance"
                                style={{
                                  color: "green",
                                  fontWeight: "400",
                                  fontSize: "20px",
                                }}
                              >
                                {" "}
                                {t("contents.Online")}
                              </span>
                            ) : (
                              <span
                                className="distance"
                                style={{
                                  color: "red",
                                  fontWeight: "400",
                                  fontSize: "20px",
                                }}
                              >
                                {" "}
                                {t("contents.Offline")}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="lable_tag_vendor newdiv">
                          <ul>
                            {authUser && itemData && itemData.is_freedelivery == 'available' && (<div>
                              <li className="freedelivery">{t("contents.Free-delivery")}</li>
                              <span>
                                {itemData && itemData.is_freedelivery_text[lang]}
                                {/* {t("commons.Free_delivery_is_available_if_your_order_value_above_OMR")} {itemData && itemData.normalpickupminorder}{" "}
                                {t("commons.and_within_distance_of")} {itemData && itemData.normalpickupmaxdistance}{" "}
                                {t("commons.Km.")} */}
                              </span>
                            </div>
                            )}
                            {authUser && itemData && itemData.is_discount == 'available' && (
                              lang == 'en' ?
                                <div><li className="discount">{itemData && itemData.discount_type == 'percent' ? itemData.discount_amount + "% " + t("contents.Discount") : t("contents.OMR") + itemData.discount_amount + " " + t("contents.Discount")}</li><span>
                                  {itemData && itemData.is_discount_text[lang]}
                                </span></div> :
                                <div><li className="discount">{itemData && itemData.discount_type == 'percent' ? t("contents.Discount") + itemData.discount_amount + " % " : t("contents.Discount") + itemData.discount_amount + " " + t("contents.OMR")}</li><span>
                                  {itemData && itemData.is_discount_text[lang]}
                                </span></div>
                            )}
                            {authUser && itemData && itemData.is_discount_subcategory == 'available' && (
                              <div><li className="discount_subcategory">{t("contents.Sub_Discount")}</li><span>{itemData.is_discount_text_subcategory[lang]}</span></div>
                            )}
                            {itemData && itemData.vendorPackage == 1 && (<div>
                              <li className="pacakge">{t("package-available-section")}</li>
                            </div>
                            )}
                            {/* display corporate package tag */}
                            {itemData && itemData.vendorCorporatePackage == 1 && (<div>
                              <li className="corporate-package">{t("corporate-package-available-section")}</li>
                            </div>
                            )}
                          </ul>
                        </div>
                        <hr />
                        <div className="paymentlist">
                          <p>
                            {t("labels.Service_Provider")}{" "}
                            <span>
                              {" "}
                              {itemData && lang && itemData.service_providers.length > 0
                                ? itemData.service_providers.map((item, index) => {
                                  return <span key={index} style={{ color: theme.colors.primary }}>{(index ? ", " : "") + item.name[lang]}</span>;
                                })
                                : <span style={{ color: theme.colors.primary }}>{t("commons.N/A")}</span>
                              }
                            </span>
                          </p>

                          <p>
                            {t("labels.Cleaning_Type")}{" "}
                            <span>
                              {itemData && lang && itemData.cleaning_types.length > 0 ? (
                                itemData.cleaning_types.map((item, index) => {
                                  return <span key={index} style={{ color: theme.colors.primary }}>{(index ? ", " : "") + item.name[lang]}</span>;
                                })
                              ) : (
                                <span style={{ color: theme.colors.primary }}>{t("commons.N/A")}</span>
                              )}
                            </span>
                          </p>
                          <p>
                            {t("commons.Measure_Type")}{" "}
                            <span style={{ color: theme.colors.primary }}>
                              {itemData && lang && itemData.measure_types.length > 0 ? (
                                itemData.measure_types.map((item, index) => {
                                  return <span key={index} style={{ color: theme.colors.primary }}>{(index ? ", " : "") + item.name[lang]}</span>;
                                })
                              ) : (
                                <span style={{ color: theme.colors.primary }}>{t("commons.N/A")}</span>
                              )}
                            </span>
                          </p>
                          <p style={{ float: "right" }}>{t("commons.Express_Delivery")}</p>
                          <p>
                            {t("labels.Normal_Delivery_Time")}{" "}
                            <span style={{ color: theme.colors.primary }}>
                              {" "}
                              {itemData && itemData.normal_delivery_time
                                ? itemData.normal_delivery_time > 1
                                  ? itemData.normal_delivery_time + " " + t("commons.Days")
                                  : itemData.normal_delivery_time + " " + t("commons.Day")
                                : t("commons.N/A")}
                            </span>
                            <span style={{ width: "65px", textAlign: "end", color: theme.colors.primary }}>
                              {" "}
                              {itemData && itemData.normal_express_delivery_time && itemData.is_normal_express_delivery
                                ? itemData.normal_express_delivery_time > 1
                                  ? itemData.normal_express_delivery_time + " " + t("commons.Hours")
                                  : itemData.normal_express_delivery_time + " " + t("commons.Hour")
                                : t("commons.N/A")}
                            </span>
                          </p>
                          {/* {itemData && itemData.admin_medium_delivery == 1 && itemData.medium_delivery_time && (
                            <p>
                              {t("labels.Medium_Delivery_Time")}{" "}
                              <span>
                                {" "}
                                {itemData && itemData.medium_delivery_time
                                  ? itemData.medium_delivery_time > 1
                                    ? itemData.medium_delivery_time + " " + t("commons.Days")
                                    : itemData.medium_delivery_time + " " + t("commons.Day")
                                  : t("commons.N/A")}
                              </span>
                              <span style={{ width: "55px", textAlign: "end" }}>
                                {" "}
                                {itemData && itemData.medium_delivery_time && itemData.is_medium_express_delivery
                                  ? itemData.medium_express_delivery_time > 1
                                    ? itemData.medium_express_delivery_time + " " + t("commons.Hours")
                                    : itemData.medium_express_delivery_time + " " + t("commons.Hour")
                                  : t("commons.N/A")}
                              </span>
                            </p>
                          )}

                          {itemData && itemData.admin_longer_delivery == 1 && itemData.longer_delivery_time && (
                            <p>
                              {t("labels.Longer_Delivery_Time")}{" "}
                              <span>
                                {" "}
                                {itemData && itemData.longer_delivery_time
                                  ? itemData.longer_delivery_time > 1
                                    ? itemData.longer_delivery_time + " " + t("commons.Days")
                                    : itemData.longer_delivery_time + " " + t("commons.Day")
                                  : t("commons.N/A")}
                              </span>
                              <span style={{ width: "55px", textAlign: "end" }}>
                                {" "}
                                {itemData && itemData.longer_express_delivery_time && itemData.is_longer_express_delivery
                                  ? itemData.longer_express_delivery_time > 1
                                    ? itemData.longer_express_delivery_time + " " + t("commons.Days")
                                    : itemData.longer_express_delivery_time + " " + t("commons.Day")
                                  : t("commons.N/A")}
                              </span>
                            </p>
                          )} */}
                        </div>
                        <hr />
                        <div className="lable_tag_vendor">
                          <p>{t("labels.Tags")}</p>
                          <ul>
                            {itemData &&
                              itemData.tags &&
                              itemData.tags.map((item, index) => {
                                return <li key={index}>{lang && item.name[lang]}</li>;
                              })}
                          </ul>
                        </div>
                        <hr />
                        {/* {itemPacks && itemPacks.length == 0 && ( */}
                        <>
                          <p className="lable_working_vendor">{t("labels.Working_Time")}</p>
                          <ul className="workingtime">
                            {checkday.includes(0) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Sunday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_sun != "Closed" && this.state.first_shift_end_time_sun != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_sun && this.state.first_shift_end_time_sun ? this.state.first_shift_start_time_sun + " - " + this.state.first_shift_end_time_sun : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_sun != "Closed" && this.state.second_shift_end_time_sun != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_sun && this.state.second_shift_end_time_sun ? this.state.second_shift_start_time_sun + " - " + this.state.second_shift_end_time_sun : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Sunday"))}</span>
                                {t("commons.Closed")}
                              </li>}
                            {/* monday */}
                            {checkday.includes(1) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Monday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_mon != "Closed" && this.state.first_shift_end_time_mon != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_mon && this.state.first_shift_end_time_mon ? this.state.first_shift_start_time_mon + " - " + this.state.first_shift_end_time_mon : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_mon != "Closed" && this.state.second_shift_end_time_mon != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_mon && this.state.second_shift_end_time_mon ? this.state.second_shift_start_time_mon + " - " + this.state.second_shift_end_time_mon : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Monday"))}</span>
                                {t("commons.Closed")}
                              </li>}
                            {/* tuesday */}
                            {checkday.includes(2) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Tuesday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_tue != "Closed" && this.state.first_shift_end_time_tue != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_tue && this.state.first_shift_end_time_tue ? this.state.first_shift_start_time_tue + " - " + this.state.first_shift_end_time_tue : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_tue != "Closed" && this.state.second_shift_end_time_tue != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_tue && this.state.second_shift_end_time_tue ? this.state.second_shift_start_time_tue + " - " + this.state.second_shift_end_time_tue : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Tuesday"))}</span>
                                {t("commons.Closed")}
                              </li>}
                            {/* wedenesday */}
                            {checkday.includes(3) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Wednesday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_wed != "Closed" && this.state.first_shift_end_time_wed != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_wed && this.state.first_shift_end_time_wed ? this.state.first_shift_start_time_wed + " - " + this.state.first_shift_end_time_wed : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_wed != "Closed" && this.state.second_shift_end_time_wed != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_wed && this.state.second_shift_end_time_wed ? this.state.second_shift_start_time_wed + " - " + this.state.second_shift_end_time_wed : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Wednesday"))}</span>
                                {t("commons.Closed")}
                              </li>}
                            {/* thursday */}
                            {checkday.includes(4) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Thursday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_thur != "Closed" && this.state.first_shift_end_time_thur != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_thur && this.state.first_shift_end_time_thur ? this.state.first_shift_start_time_thur + " - " + this.state.first_shift_end_time_thur : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_thur != "Closed" && this.state.second_shift_end_time_thur != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_thur && this.state.second_shift_end_time_thur ? this.state.second_shift_start_time_thur + " - " + this.state.second_shift_end_time_thur : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Thursday"))}</span>
                                {t("commons.Closed")}
                              </li>}
                            {/* friday */}
                            {checkday.includes(5) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Friday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_fri != "Closed" && this.state.first_shift_end_time_fri != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_fri && this.state.first_shift_end_time_fri ? this.state.first_shift_start_time_fri + " - " + this.state.first_shift_end_time_fri : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_fri != "Closed" && this.state.second_shift_end_time_fri != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_fri && this.state.second_shift_end_time_fri ? this.state.second_shift_start_time_fri + " - " + this.state.second_shift_end_time_fri : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Friday"))}</span>
                                {t("commons.Closed")}
                              </li>}
                            {/* saturday */}
                            {checkday.includes(6) == true ?
                              <li>
                                <span className="dayname">{t(("commons.Saturday"))}</span>
                                <div className="d-flex">
                                  <div>
                                    {this.state.first_shift_start_time_sat != "Closed" && this.state.first_shift_end_time_sat != "Closed" ?
                                      <span dir="ltr">{this.state.first_shift_start_time_sat && this.state.first_shift_end_time_sat ? this.state.first_shift_start_time_sat + " - " + this.state.first_shift_end_time_sat : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                  <span className="px-2"> | </span>
                                  <div>
                                    {this.state.second_shift_start_time_sat != "Closed" && this.state.second_shift_end_time_sat != "Closed" ?
                                      <span dir="ltr">{this.state.second_shift_start_time_sat && this.state.second_shift_end_time_sat ? this.state.second_shift_start_time_sat + " - " + this.state.second_shift_end_time_sat : "---"}</span> : <span> {t("commons.Closed")}</span>}
                                  </div>
                                </div>
                              </li> :
                              <li>
                                <span className="dayname">{t(("commons.Saturday"))}</span>
                                {t("commons.Closed")}
                              </li>}

                          </ul>
                        </>
                        <hr />
                        {/* // )} */}


                      </div>
                    </Col>
                  </Row>
                )}
                {(this.state.itemCorporatePacks && this.state.itemCorporatePacks.length > 0 || itemPacks && itemPacks.length > 0) && (
                  <div className="Promotional_pack_sec">
                    <div className="package-sec-title">{t("corporate_pack_content")}</div>
                    <p>{ }</p>
                    {((this.state.itemCorporatePacks && this.state.itemCorporatePacks.length) + (itemPacks && itemPacks.length)) > 3 ? (
                      <div className="all-package-button">
                        <Link
                          to={{
                            pathname: `/view-all-package/${this.state.itemId}`,
                          }}
                        >
                          <input type="button" className="all-package-btn" value={t("button.view_all_package")} disabled={itemData && itemData.is_available ? false : true}></input>
                        </Link>

                      </div>
                    ) : (
                      <>

                      </>

                    )}
                  </div>
                )}

                {(this.state.itemCorporatePacks && this.state.itemCorporatePacks.length > 0 || itemPacks && itemPacks.length > 0) && (
                  <div className="package-display-section">
                    <div className="package-flex-sec">
                      {this.state.itemCorporatePacks && this.state.itemCorporatePacks.length > 0 && this.state.itemCorporatePacks.map((item, index) => {
                        if (index < 3) {
                          var sub_category = [];
                          var main_category = [];
                          if (item && item.new_sub_categories) {
                            item.new_sub_categories.map((item1, index) => {
                              sub_category.push(item1.name[lang]);
                            })
                          }

                          item.new_main_categories.map((item1, index) => {
                            main_category.push(item1.name[lang]);
                          })
                          var total_pieces = 0;
                          if (item && item.individual_subCategories && item.individual_subCategories.length > 0) {
                            item.individual_subCategories.map((item2, index2) => {
                              total_pieces += parseInt(item2.value);
                            });
                          }

                          var free_delivery = item.free_delivery;
                          return (
                            <div key={item._id} className="col-md-4 corporate-individual-section" style={{ borderColor: item.packageColor }}>
                              {item.most_selling_package == 1 ?
                                <div className="package-tag-section">
                                  {this.state.lang == 'en' ?
                                    <img src="./assets/img/tag-eng.png" alt="LaundryHUB" style={{ position: 'relative', top: '-13px', left: '-14px' }} />
                                    :
                                    <img src="./assets/img/tag-arb.png" alt="LaundryHUB" style={{ position: 'relative', top: '-12px', left: '-182px' }} />
                                  }
                                </div>
                                :
                                <>
                                  <div style={{
                                    padding: '13px 4px 0px 4px',
                                    textAlign: 'center',
                                    fontSize: '22px',
                                    border: '1px solid darkgray',
                                    borderRadius: '17px',
                                    position: 'relative',
                                    top: '10px',
                                    boxShadow: '2px 2px 2px lightgray',
                                    backgroundColor: '#2f7dc9',
                                  }}>
                                    <p style={{
                                      // color: item.packTitleColor,
                                      color: 'white',
                                      fontWeight: 'bold'
                                    }}>{t('corporate-package')}</p>
                                  </div>
                                </>
                              }

                              <div style={{
                                border: '1px solid lightgray', borderRadius: '12px',
                                borderTop: 'hidden',
                                borderTopLeftRadius: 'unset',
                                borderTopRightRadius: 'unset',
                                height: '83%',
                              }}>
                                <div className="package-but-sec"
                                // style={{ backgroundColor: item.packageColor }}
                                >
                                  <div className="price-section">
                                    <p style={{ color: item.packTitleColor, paddingRight: "5px" }}>{t('package_omr')}</p>{" "}
                                    <p style={{ color: item.packTitleColor }}>{item.corporate_package_total_price}</p>

                                  </div>
                                  <div style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'bold' }}>
                                    {
                                      item.is_company_or_staff_pay == 'staff_pay' ? (<><p style={{ color: item.packTitleColor }}>{t('staff-pay-package')}</p></>) : (<><p style={{ color: item.packTitleColor }}>{t('company-pay-package')}</p></>)
                                    }

                                  </div>
                                  <h3 className="package-title" style={{ color: item.packTitleColor, fontWeight: 'bold' }}>{item.corporate_package_title[lang]}</h3>
                                  <div className="pack-sec1">
                                    {free_delivery == 'no' ? (
                                      <></>
                                    ) : (
                                      <li style={{ color: 'red' }}>{t("free_delivery_package")}</li>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="corporate-details-sec"
                                  style={{
                                    backgroundColor: item.packageColor,
                                  }}>
                                  <div className="corporate-package-design-button-sec">
                                    <div style={{ color: item.packageTextColor, textAlign: 'center', fontWeight: 'bold', paddingTop: '10px', fontSize: '22px' }} >
                                      <p style={{ marginBottom: '0px' }}>{item.corporate_admin_name[lang]}</p>
                                    </div>
                                    {item.corporate_package_type && item.corporate_package_type == 1 ? (
                                      <>
                                        <div className="package-pieces-count" style={{ color: item.packageTextColor, paddingBottom: '10px' }} >{t("package_pieces_count")}</div>
                                        {item.customer_minimum_order_limit && item.customer_minimum_order_limit == total_pieces ? (
                                          <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                        ) : (
                                          <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_undefined_piece")}</div>
                                        )
                                        }
                                      </>
                                    ) : (
                                      <>
                                        <div className="package-sub-cat" style={{ color: item.packTitleColor }} >{t("subcategory_title")}</div>
                                        <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                      </>
                                    )
                                    }
                                  </div>
                                  <div style={{ color: 'white', height: '248px' }}>

                                    <div className="package-detail-sec">
                                      <div className="package-detail-include" style={{ color: item.packageTextColor }}>{t("promotion_including")}</div>
                                      <div className="package-info">
                                        {item && item.individual_subCategories && item.individual_subCategories.map((category_new, index) => {
                                          if (index < 4) {
                                            return (
                                              <div className="pack-sec">
                                                <i class="fa fa-check" aria-hidden="true" style={{ color: item.packageColor, marginRight: '10px', marginLeft: '15px' }}></i>
                                                <div className="individual_sec">
                                                  <li style={{ color: item.packageTextColor }}># {category_new.value}</li>
                                                  <li style={{ color: item.packageTextColor }}>{category_new.name[lang]}</li>
                                                </div>
                                              </div>
                                            )
                                          }
                                        })}

                                      </div>
                                    </div>
                                    <div className="corporate-package-button">
                                      {/* <Link
                                  to={{
                                    pathname: `/package/${category._id}`,
                                  }}
                                > */}
                                      <button className="package-btn"
                                        //  value={t("button.view_package")} 
                                        onClick={() => this.props.history.push(`/corporate-package-detail/${item.package_id}/${item.vendor_id}/${item.corporate_main_admin_id}/${item.corporate_sub_admin_id}/${item.branch_location_id}`)}
                                        //  disabled={categoryData && categoryData.is_available ? false : true} 

                                        style={{
                                          background: 'white',
                                          border: 'none',
                                          // color: '#0978eb',
                                          color: item.packageTextColor,
                                          fontWeight: 'bold',
                                          fontSize: '18px',
                                          padding: '14px 66px',
                                          borderRadius: '9px',
                                          // position: 'absolute',
                                          // top : '-25px',
                                          // left :'0px',
                                          // right : '0px'
                                        }}>
                                        {t("button.view_package")}
                                        {"  "}
                                        {/* <i class="fa fa-arrow-right"></i> */}
                                      </button>
                                      {/* </Link> */}

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      })}
                      {this.state.itemPacks && this.state.itemPacks.length > 0 && this.state.itemCorporatePacks.length < 3 && this.state.itemPacks.map((item, index) => {
                        if (index < this.state.promotion_pack_count) {
                          if (item.vendor_package_date >= this.state.current_date) {
                            var sub_category = [];
                            var main_category = [];
                            item.new_sub_categories.map((item1, index) => {
                              sub_category.push(item1[lang]);
                            })
                            item.new_main_categories.map((item1, index) => {
                              main_category.push(item1.name[lang]);
                            })
                            var total_pieces = 0;
                            item.individual_piece_count.map((item2, index2) => {
                              total_pieces += parseInt(item2);
                            })
                            var free_delivery = item.promo_free_delivery;
                            return (
                              <div className="individual-section" style={{ borderColor: item.packageColor }}>
                                {item.most_selling_package == 1 ?
                                  <div className="package-tag-section">
                                    {/* <p>{t("package_most_selling")}</p> */}
                                    {this.state.lang == 'en' ?
                                      <img src="./assets/img/tag-eng.png" alt="LaundryHUB" style={{ position: 'relative', top: '-13px', left: '-14px' }} />
                                      :
                                      <img src="./assets/img/tag-arb.png" alt="LaundryHUB" style={{ position: 'relative', top: '-12px', left: '-182px' }} />
                                    }
                                  </div>
                                  :
                                  <></>
                                }
                                <div className="package-but-sec" style={{ backgroundColor: item.packageColor }}>
                                  <div className="price-section">
                                    <p style={{ color: item.packTitleColor }}>{t('package_omr')}</p><p style={{ color: item.packTitleColor }}>{item.package_total_price}</p>
                                  </div>
                                  <h3 className="package-title" style={{ color: item.packTitleColor }}>{item.title[lang]}</h3>
                                  <div className="pack-sec1">
                                    {free_delivery == 1 ? (
                                      <></>
                                    ) : (
                                      <li style={{ color: item.packageTextColor }}>{t("free_delivery_package")}</li>
                                    )}
                                  </div>
                                </div>
                                <div className="package-design-button-sec">
                                  {item.add_promotion_type && item.add_promotion_type == 1 ? (
                                    <>
                                      <div className="package-pieces-count" style={{ color: item.packTitleColor }} >{t("package_pieces_count")}</div>
                                      {item.customer_minimum_order_limit && item.customer_minimum_order_limit == total_pieces ? (
                                        <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                      ) : (
                                        <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_undefined_piece")}</div>
                                      )
                                      }
                                    </>
                                  ) : (
                                    <>
                                      <div className="package-sub-cat" style={{ color: item.packTitleColor }} >{t("subcategory_title")}</div>
                                      <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                    </>
                                  )
                                  }
                                </div>
                                <div className="package-detail-sec">
                                  <div className="package-detail-include" style={{ color: item.packageTextColor }}>{t("promotion_including")}</div>
                                  <div className="package-info">
                                    {item && item.individual_piece_count && item.individual_piece_count.map((item_new, index) => {
                                      if (index < 4) {
                                        // return item_new + " " + item.new_sub_categories[index][lang];
                                        return (
                                          <div className="pack-sec">
                                            <i class="fa fa-check" aria-hidden="true" style={{ color: item.packageColor, marginRight: '10px', marginLeft: '15px' }}></i>
                                            <div className="individual_sec">
                                              <li style={{ color: item.packageTextColor }}>{item_new}</li>
                                              <li style={{ color: item.packageTextColor }}>{item.new_sub_categories[index][lang]}</li>
                                            </div>
                                          </div>
                                        )
                                      }
                                    })}

                                  </div>
                                </div>
                                <div className="package-button">
                                  <Link
                                    to={{
                                      pathname: `/package/${item._id}`,
                                    }}
                                  >
                                    <input type="button" className="package-btn" value={t("button.view_package")} disabled={itemData && itemData.is_available ? false : true} style={{ backgroundColor: item.packageColor, borderColor: item.packageColor }}></input>
                                  </Link>

                                </div>
                              </div>
                            )
                          }
                        }
                      })}


                    </div>

                  </div>

                )}

                <div className="package-book-service-sec">
                  {itemPacks && itemPacks.length == 0 && (
                    <div className="display_flex">
                      <div className="view_all_laundries_btn text-center py-5 margin-right">
                        {itemData && itemData._id ? (
                          <Link
                            to={{
                              pathname: `/service-list/${this.state.itemId}`,
                              state: { shop: itemData && itemData.shop_name && itemData.shop_name[lang], branch: itemData && itemData.branch_name && itemData.branch_name[lang] },
                            }}
                          >
                            <StyledInputButton type="button" className="btn" value={t("buttons.Book_Services")}
                              disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true} />
                            {/* <input type="button" className="btn" value={t("buttons.Book_Services")} disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true}></input> */}
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="view_all_laundries_btn text-center py-5 margin-right">
                        {itemData && itemData._id ? (
                          // <input type="button" className="btn" value={t("buttons.easy_Pickup")} disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true} onClick={() => this.openPopup()}></input>
                          <StyledInputButton type="button" className="btn" value={t("buttons.easy_Pickup")}
                          disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true} onClick={() => this.openPopup()} />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="package-book-service-sec">
                  {itemPacks && itemPacks.length > 0 && (
                    <div className="display_flex">
                      <div className="view_all_laundries_btn text-center py-5 margin-right">
                        {itemData && itemData._id ? (
                          <Link
                            to={{
                              pathname: `/service-list/${this.state.itemId}`,
                              state: { shop: itemData && itemData.shop_name && itemData.shop_name[lang], branch: itemData && itemData.branch_name && itemData.branch_name[lang] },
                            }}
                          >
                            {/* <input type="button" className="btn" value={t("buttons.Book_Services")} disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true}></input> */}
                            <StyledInputButton type="button" className="btn" value={t("buttons.Book_Services")}
                              disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true} />
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* {this.state.is_easy_pickup == 1 ? : (null)} */}
                      <div className="view_all_laundries_btn text-center py-5 margin-right">
                        {itemData && itemData._id ? (
                          // <input type="button" className="btn" value={t("buttons.easy_Pickup")} disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true} onClick={() => this.openPopup()}></input>
                          <StyledInputButton type="button" className="btn" value={t("buttons.easy_Pickup")}
                          disabled={itemData && itemData.is_available ? this.state.book_service_enable == 0 ? true : false : true} onClick={() => this.openPopup()} />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <ModalLayoutAlert onClose={this.showModal} show={this.state.show}>
                  {t("commons.We_are_sorry_Laundry_Busy_at_this_moment._But_no_worries,_you_can_schedule_your_order_at_another_time.")}
                </ModalLayoutAlert>

                <Modal
                  isOpen={this.state.showRatingModal}
                  backdrop={"static"}
                  keyboard={false}
                  toggle={() => {
                    this.toggleRatingModal();
                  }}
                >
                  <Form>
                    <div className="filter_modal_header">
                      <span onClick={() => this.hideRatingModal()} style={{ cursor: "pointer" }} className="btn1">
                        {t("commons.Cancel")}
                      </span>
                      <div className="filter_modal_heading">{t("commons.Reviews")}</div>
                    </div>
                    {!this.state.isloading ? (
                      <div className="loading"></div>
                    ) : (
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                        <div className="coupon_sec">
                          {this.state.ratingData &&
                            this.state.ratingData.length > 0 &&
                            this.state.ratingData.map((item, index) => {
                              return (
                                <div className="d-flex mb-3" key={index}>
                                  <div className="coupon_code">
                                    <h3 style={{ color: "black" }}>{item.feedback_by && item.feedback_by.firstName + " " + item.feedback_by.lastName}</h3>
                                    <div className="popup_rating">
                                      <span style={{ color: "black" }}> {moment(item.createdAt).format("MMM DD, YYYY")}</span>
                                      <span style={{ float: "right" }}>
                                        <StarRatings
                                          rating={item.feedback_value}
                                          name="rating"
                                          starRatedColor="blue"
                                          numberOfStars={5}
                                          starHoverColor="#007bff"
                                          starDimension="20px"
                                          // starRatedColor="#007bff"
                                          starSpacing="2px"
                                        />
                                      </span>
                                    </div>
                                    <p style={{ color: "black" }} className="mt-2">
                                      {item.feedback_text}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          {this.state.ratingData.length == 0 && <center>{t("commons.No_comments_available")}</center>}
                        </div>
                      </InfiniteScroll>
                    )}
                  </Form>
                </Modal>
                {/* for Eassy Pick pop up */}
                <Modal className="easy_pickup" isOpen={this.state.isOpened} backdrop={"static"} keyboard={false}>
                  <ModalHeader style={{ textTransform: 'capitalize' }}>{t("commons.easy_Pickup")}</ModalHeader>
                  <ModalBody>
                    <div className="with-100 margin"><h6>{t("Easpy_pickup_popup_content")}</h6>{this.state.lang == 'en' ? <h6>Minimum ePocket balance to utilize easy pickup is {this.state.minimum_wallet_amount_for_easy_pickup} OMR.</h6> : <p>الحد الأدنى لرصيد الجيب الإلكتروني للاستفادة من ميزة الاستلام السهل هو {this.state.minimum_wallet_amount_for_easy_pickup} ريال عماني.</p>}</div>
                    <div className="with-100 margin">
                      <input
                        checked={this.state.ispopup == 1}
                        type="checkbox"
                        id="input_check"
                        className="mr-2"
                        onChange={(event) => {
                          if (event.target.checked) {
                            this.setState({ showPopUp: true, ispopup: 1 });
                          } else {
                            this.setState({ showPopUp: false, ispopup: 0 });
                          }
                        }}
                      />{" "}
                      <label htmlFor="input_check" className="m-0"><h6>{this.state.lang == 'en' ? `Don't show the popup again` : 'لا تظهر النافذة المنبثقة مرة أخرى'}</h6></label>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div className="with-100 margin display_flex justify-content-space-between">
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isOpened: false })}>
                        {t("commons.back")}
                      </button>
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.proceedToCheckOut()}>
                        {this.state.show_topup_wallet_button == 0 ? <>{t("commons.proceed")}</> : <>{t("buttons.Topup ePocket")}</>}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
              </Container>
            </section>
          )}
        </Translation>
      </>
    );
  }
}
