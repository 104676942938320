import React from 'react';

export const Shimmerimage = () => (
    <div className="shimmer-image-wrapper">
      <div className="shimmer"></div>
    </div>
);
  
export const Shimmertext = () => (
    <div className="shimmer-text-wrapper">
      <div className="shimmer"></div>
    </div>
);

export const Shimmerdescription = () => (
    <div className="shimmer-description-wrapper">
      <div className="shimmer"></div>
    </div>
);

export const Shimmerdescription1 = () => (
    <div className="shimmer-description1-wrapper">
      <div className="shimmer"></div>
    </div>
);

export const Shimmerbutton = () => (
    <div className="shimmer-button-wrapper">
      <div className="shimmer"></div>
    </div>
);