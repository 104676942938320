import React, { Component } from "react";
import { Row, Col, Button, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ThemeContext } from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { StyledButtondiv1 } from "../../helpers/StyledButtondiv";
import { Translation } from "react-i18next";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}
const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;

const FormSchema = Yup.object().shape({
  old_password: Yup.string()
    .required("commons.Please_enter_your_old_password")
    .min(6, "commons.Password_must_contain_atleast_6_letters")
    .max(15, "commons.Password_must_contain_atmost_15_letters"),
  new_password: Yup.string()
    .required("commons.Please_enter_your_new_password")
    .min(6, "commons.Password_must_contain_atleast_6_letters")
    .max(15, "commons.Password_must_contain_atmost_15_letters")
    .matches(passwordRegExp, "commons.Password_must_contain_at_least_one_lower_case,_upper_case,_numeric_value_and_special_character"),
  confirm_password: Yup.string()
    .required("commons.Please_confirm_your_new_password")
    .min(6, "commons.Password_must_contain_atleast_6_letters")
    .max(15, "commons.Password_must_contain_atmost_15_letters")
    .oneOf([Yup.ref("new_password"), null], "commons.Passwords_must_match"),
});

export default class ProfileEdit extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      old_password: "",
      new_password: "",
      confirm_password: "",
    };
  }

  componentDidMount() { }

  handleSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("old_password", inputValues.old_password);
    formData.append("new_password", inputValues.new_password);

    let path = ApiRoutes.CHANGE_PASSWORD;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        formOptions.resetForm();

        NotificationManager.success(resJson.message, "Success!", 3000);
        this.props.history.push("/manage-profile");
      } else {
        formOptions.setFieldError("old_password", resJson.message);
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { userData } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <h1 className="page_title mb-5 custom-page-tit-phem">{t("labels.Change_Password")}</h1>

              <Row className="justify-content-center">
                <Col xs={12}>
                  <div className="manage_account_user_sec p-5">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        old_password: this.state.old_password,
                        new_password: this.state.new_password,
                        confirm_password: this.state.confirm_password,
                      }}
                      validationSchema={FormSchema}
                      onSubmit={this.handleSubmit}
                    >
                      {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                        <Form className="w-100 login_signup_form">
                          <Row>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Field className="form-control" name="old_password" type="password" placeholder={t("placeholders.Old_Password")} />
                                {errors.old_password && touched.old_password ? <div className="invalid-feedback d-block">{t(errors.old_password)}</div> : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Field className="form-control" name="new_password" type="password" placeholder={t("placeholders.New_Password")} />
                                {errors.new_password && touched.new_password ? <div className="invalid-feedback d-block">{t(errors.new_password)}</div> : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} md={6}>
                              <FormGroup>
                                <Field className="form-control" name="confirm_password" type="password" placeholder={t("placeholders.Confirm_Password")} />
                                {errors.confirm_password && touched.confirm_password ? <div className="invalid-feedback d-block">{t(errors.confirm_password)}</div> : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <Button className="btn text-uppercase" type="submit">
                            {t("buttons.submit")}
                          </Button> */}
                          <StyledButtondiv1 type="submit" className="btn text-uppercase">{t("buttons.submit")}</StyledButtondiv1>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
          </>
        )}
      </Translation>
    );
  }
}
