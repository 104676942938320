import styled from 'styled-components';

const StyledInputButton = styled.input`
  background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.primary : theme.colors.primary} !important;
  color: ${({ theme, disabled }) =>
        disabled ? theme.colors.secondary : theme.colors.secondary};
  border: 1px solid ${({ theme, disabled }) =>
        disabled ? theme.colors.primary : theme.colors.primary} !important;

  &:hover {
    background-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.disabledBackground : theme.colors.secondary} !important;
    color: ${({ theme, disabled }) =>
        disabled ? theme.colors.disabledText : theme.colors.primary} !important;
    border-color: ${({ theme, disabled }) =>
        disabled ? theme.colors.disabledBorder : theme.colors.primary} !important;
  }
`;

export default StyledInputButton;
