import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components"; // Import ThemeProvider
import { theme } from "./theme"; // Import your theme

import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import i18next from "i18next";
import axios from "axios";

const lang = localStorage.getItem("lang") || "en";
axios.defaults.headers.common["Accept-Language"] = lang;
i18next.changeLanguage(lang);
document.documentElement.lang = lang;
if (lang == "ar") {
  document.body.classList.add("Arabic");
}

document.documentElement.style.setProperty('--primary-color', theme.colors.primary);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>

  </BrowserRouter>,

  document.getElementById("root")
);

serviceWorker.unregister();
