import React, { Component } from "react";
import { Col, Container, Row, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import Switch from "react-switch";
import { ThemeContext } from "styled-components";
import { StyledSwitch } from '../../helpers/StyledCartLink'; // Adjust the path as needed
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import { Translation } from "react-i18next";
import i18next from "i18next";
function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}
export default class Dashboard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      allow_notifications: "",
      allow_repeated_orders: "",
      lang: "",
    };
  }

  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") });
    this.getUserData();
  }

  handleLanguage = async (e) => {
    localStorage.setItem("lang", e.target.value);
    let formData = new FormData();
    formData.append("user_lang", e.target.value);
    let path = ApiRoutes.UPDATE_LANGUAGE;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        window.location.reload();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("validations.Server_Error"), "Error!", 3000);
    }
  };

  getUserData = async () => {
    let path = ApiRoutes.GET_PROFILE;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.setAuthData(resJson.data);

        this.setState({
          allow_notifications: resJson.data.allow_notifications,
          allow_repeated_order: resJson.data.allow_repeated_order,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  changeNotification = async (notifications) => {
    let notifications1 = notifications ? 1 : 0;
    let formData = new FormData();
    formData.append("allow_notifications", notifications1);

    let path = ApiRoutes.UPDATE_PROFILE_PARTIALLY;
    const res = await Http("PATCH", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.getUserData();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  changeRepeatedOrder = async (repeatedOrder) => {
    let repeatedOrder1 = repeatedOrder ? 1 : 0;
    let formData = new FormData();
    formData.append("allow_repeated_order", repeatedOrder1);

    let path = ApiRoutes.UPDATE_REPEATED_ORDER_STAUTS;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.getUserData();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }

  }
  render() {
    var { allow_notifications, lang, allow_repeated_order } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <h1 className="page_title mb-md-5">{t("menu.settings")}</h1>
              <Row>
                <Col md={6}>
                  <div className="Innerheading d-flex justify-content-between align-items-center settings">
                    <Label className="mb-3 mt-3" style={{color: theme.colors.primary}}>{t("commons.Allow_Notifications")}</Label>{" "}
                    <Switch
                      checked={allow_notifications && allow_notifications ? true : false}
                      onChange={(allow_notifications) => {
                        this.setState({ allow_notifications: allow_notifications }, () => {
                          this.changeNotification(allow_notifications);
                        });
                      }}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className={allow_notifications ? "toggleMe" : "react-switch"}
                      id="material-switch"
                    />
                    {/* <StyledSwitch
                      checked={allow_notifications && allow_notifications ? true : false}
                      onChange={(allow_notifications) => {
                        this.setState({ allow_notifications: allow_notifications }, () => {
                          this.changeNotification(allow_notifications);
                        });
                      }}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className={allow_notifications ? "toggleMe" : "react-switch"}
                      id="material-switch"
                    /> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="Innerheading d-flex justify-content-between align-items-center settings">
                    <Label className="mb-3 mt-3" style={{color: theme.colors.primary}}>{t("commons.Change_Language")}</Label>{" "}
                    <select onChange={this.handleLanguage} value={lang} className="select_lang">
                      <option value="en">English</option>
                      <option value="ar">عربي</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="Innerheading d-flex justify-content-between align-items-center settings">
                    <Label className="mb-3 mt-3" style={{color: theme.colors.primary}}>{t("commons.Repeated_Order")}</Label>{" "}
                    <Switch
                      checked={allow_repeated_order && allow_repeated_order ? true : false}
                      onChange={(allow_repeated_order) => {
                        this.setState({ allow_repeated_order: allow_repeated_order }, () => {
                          this.changeRepeatedOrder(allow_repeated_order);
                        });
                      }}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className={allow_repeated_order ? "toggleMe" : "react-switch"}
                      id="material-switch"
                    />
                    {/* <StyledSwitch
                      checked={allow_notifications && allow_notifications ? true : false}
                      onChange={(allow_notifications) => {
                        this.setState({ allow_notifications: allow_notifications }, () => {
                          this.changeNotification(allow_notifications);
                        });
                      }}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className={allow_notifications ? "toggleMe" : "react-switch"}
                      id="material-switch"
                    /> */}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
          </>
        )}
      </Translation>
    );
  }
}
