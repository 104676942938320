import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";
import i18next from "i18next";

const numRegExp = /^[0-9]+$/i;
const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;

const FormSchema = Yup.object().shape({
  otp_number: Yup.string()
    .required("commons.Please_enter_your_OTP")

    .min(4, "commons.It_should_be_a_4_digit_number")
    .max(4, "commons.It_should_be_a_4_digit_number")
    .matches(numRegExp, "commons.It_should_be_a_numeric_value"),
  new_password: Yup.string()
    .required("commons.Please_enter_your_new_password")
    .min(6, "commons.Password_must_contain_atleast_6_letters")
    .max(15, "commons.Password_must_contain_atmost_15_letters")
    .matches(passwordRegExp, "commons.Password_must_contain_at_least_one_lower_case,_upper_case,_numeric_value_and_special_character"),
  confirm_password: Yup.string()
    .required("commons.Please_confirm_your_new_password")
    .min(6, "commons.Password_must_contain_atleast_6_letters")
    .max(15, "commons.Password_must_contain_atmost_15_letters")
    .oneOf([Yup.ref("new_password"), null], "commons.Passwords_must_match"),
});

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp_number: "",
      new_password: "",
      confirm_password: "",
      minutes: 2,
      seconds: 0,
      min: 2,
      sec: 0,
      isButtonDisabled: true,
      isDisabled: false,

      phone: this.props.location.state ? this.props.location.state.phone : null,
      email: this.props.location.state ? this.props.location.state.email : null,
      view_otp_number: this.props.location.state ? this.props.location.state.otp_number : null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);

    if (!this.props.location.state) {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    this.props.location.state = null;
    this.myInterval = setInterval((id) => this.Timeout((id = 1)), 1000);
  }

  // OTP timer
  Timeout = (id) => {
    const { seconds, minutes, sec, min } = this.state;

    if (id == 1) {
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
          this.setState({ isButtonDisabled: false, isDisabled: true });
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    } else {
      if (sec > 0) {
        this.setState(({ sec }) => ({
          sec: sec - 1,
        }));
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(this.myInterval);
          this.setState({ isButtonDisabled: false, isDisabled: true, sec: 0, min: 2 });
        } else {
          this.setState(({ min }) => ({
            min: min - 1,
            sec: 59,
          }));
        }
      }
    }
  };

  handleSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("phone", this.state.phone);
    formData.append("email", this.state.email);
    formData.append("new_password", inputValues.new_password);
    formData.append("otp_number", inputValues.otp_number);
    formData.append("otp_for", "forgot_password");

    let path = ApiRoutes.UPDATE_FORGET_PASSWORD;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success", 3000);
        this.props.history.push("/login");
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            formOptions.setFieldError(error.errField, error.errText);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  onResendOtp = async () => {
    let formData = new FormData();
    formData.append("phone", this.state.phone);
    formData.append("email", this.state.email);
    formData.append("otp_for", "forgot_password");

    let path = ApiRoutes.RESEND_OTP;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            view_otp_number: resJson.data.otp_number,
            isButtonDisabled: true,
          },
          () => (this.myInterval = setInterval((id) => this.Timeout((id = 2)), 1000))
        );

        NotificationManager.success(i18next.t("commons.OTP_has_been_sent_to_provided_email"), "Success", 3000);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  render() {
    const { minutes, seconds, min, sec } = this.state;

    return (
      <Translation>
        {(t) => (
          <>
            <section
              className="login_signup_sec py-4 py-md-5"
              style={{
                backgroundImage: "url(" + "assets/img/login_signup.jpg" + ")",
              }}
            >
              <Container className="py-5">
                <Row className="justify-content-center">
                  <Col xs={12} md={7} lg={5} xl={6}>
                    <div className="login_signup_block p-4">
                      <div className="form_logo text-center mb-5">
                        <img src="./assets/img/logo.png" alt="LaundryHUB" />
                      </div>

                      <Formik
                        enableReinitialize
                        initialValues={{
                          otp_number: this.state.otp_number,
                          new_password: this.state.new_password,
                          confirm_password: this.state.confirm_password,
                        }}
                        validationSchema={FormSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({ handleSubmit, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                          <Form className="login_signup_form">
                            <FormGroup>
                              <Field
                                className="form-control"
                                name="otp_number"
                                type="text"
                                placeholder={t("commons.OTP_Number")}
                                onChange={(event) => {
                                  setFieldValue("otp_number", event.target.value.trimStart());
                                }}
                              />
                              {errors.otp_number && touched.otp_number && <div className="invalid-feedback d-block">{t(errors.otp_number)}</div>}
                            </FormGroup>
                            <FormGroup className="form-group has-float-label">
                              <Field className="form-control" name="new_password" type="password" placeholder={t("commons.New_Password")} />
                              {errors.new_password && touched.new_password && <div className="invalid-feedback d-block">{t(errors.new_password)}</div>}
                            </FormGroup>
                            <FormGroup className="form-group has-float-label">
                              <Field className="form-control" name="confirm_password" type="password" placeholder={t("commons.Confirm_Password")} />
                              {errors.confirm_password && touched.confirm_password && <div className="invalid-feedback d-block">{t(errors.confirm_password)}</div>}
                            </FormGroup>

                            <Button className="w-100 btn text-uppercase" type="submit">
                              {t("commons.Submit")}
                            </Button>

                            <div className="dont_signup_text text-center">
                              {" "}
                              {minutes === 0 && seconds === 0 ? null : (
                                <div className="mt-2">
                                  {t("commons.Time_Remaining")}: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                </div>
                              )}
                            </div>

                            <div className="dont_signup_text text-center py-3">
                              {t("commons.Didn't_received?")}{" "}
                              {!this.state.isButtonDisabled && this.state.isDisabled ? (
                                <span onClick={this.onResendOtp} style={{ color: "#2f7dc9", cursor: "pointer" }}>
                                  {t("commons.Resend_OTP")}
                                </span>
                              ) : (
                                <>
                                  <span>{t("commons.Resend_OTP")} </span>

                                  {(min === 0 && sec === 0) || !this.state.isDisabled ? null : (
                                    <div className="mt-2">
                                      {t("commons.Time_Remaining")}: {min}:{sec < 10 ? `0${sec}` : sec}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        )}
      </Translation>
    );
  }
}
