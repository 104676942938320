import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { facebook_app_id, google_client_id } from "../../config/config";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { StyledButtondiv2 } from "../../helpers/StyledButtondiv";
import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import firebase from "../../helpers/firebase";
import authService from "../../services/auth.service";
import { Translation } from "react-i18next";
import i18next from "i18next";

const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;

const FormSchema = Yup.object().shape({
  username: Yup.string().required("commons.Please_enter_your_email/phone_number").max(50, "commons.Please_enter_email_id_with_atmost_50_letters"),
  password: Yup.string().required("commons.Please_enter_your_password").max(15, "commons.Password_must_contain_atmost_15_letters"),
});

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("username", inputValues.username);
    formData.append("password", inputValues.password);

    let path = ApiRoutes.LOGIN;
    const res = await Http("POST", path, formData);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        if (resJson.data.is_user_verified == 0) {
          this.props.history.push({
            pathname: "/verify-otp",
            state: {
              phone: resJson.data.phone,
              email: resJson.data.email,
              otp_number: resJson.data.otp_number,
              otp_for: "register",
            },
          });
        } else {
          AuthService.setAuthData(resJson.data);
          window.location.reload();
        }
      } 
      else if(res.status == 201){
        this.props.history.push({
          pathname: "/register-corporateuser",
          state: {
            userName: resJson.data.userName,
            firstName: resJson.data.firstName,
            lastName: resJson.data.lastName,
            bcryptpassword: resJson.data.bcryptpassword,
            originalpassword: resJson.data.originalpassword,
            email: resJson.data.email,
            phone: resJson.data.phone,
            gender: resJson.data.gender,
            corporate_group_id: resJson.data.corporate_group_id,
            corporate_staff_id: resJson.data.corporate_staff_id,
            corporate_sub_admin_id: resJson.data.corporate_sub_admin_id,
            corporate_main_admin_id: resJson.data.corporate_main_admin_id,
            branch_location_id: resJson.data.branch_location_id,
          },
        });
      }
      else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            formOptions.setFieldError(error.errField, error.errText);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  responseFacebook = async (response) => {
    if (response && response.userID) {
      var userID = response.userID || "";
      var name = response.name || "";
      var email = response.email || "";
      var phone = response.phone || "";

      let formData = new FormData();
      formData.append("social_login_id", userID);
      formData.append("social_login_type", "facebook");
      formData.append("firstName", name);
      formData.append("lastName", "");
      formData.append("email", email);
      formData.append("phone", phone);

      let path = ApiRoutes.SOCIAL_LOGIN;
      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();

        if (res.status == 200) {
          AuthService.setAuthData(resJson.data);
          window.location.reload();
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
      }
    }
  };

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  render() {
    return (
      <Translation>
        {(t) => (
          <>
            <section
              className="login_signup_sec py-4 py-md-5"
              style={{
                backgroundImage: "url(" + "assets/img/login_signup.jpg" + ")",
              }}
            >
              <Container className="py-5">
                <Row className="justify-content-center">
                  <Col xs={12} md={7} lg={5} xl={6}>
                    <div className="login_signup_block p-4">
                      <div className="form_logo text-center mb-5">
                        <img src="./assets/img/logo.png" alt="LaundryHUB" />
                      </div>

                      <Formik
                        enableReinitialize
                        initialValues={{
                          username: this.state.username,
                          password: this.state.password,
                        }}
                        validationSchema={FormSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({ handleSubmit, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                          <Form className="login_signup_form">
                            <Row>
                              <Col xs={12} md={12}>
                                <Label>{t("labels.username")}</Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="username"
                                    type="text"
                                    placeholder={t("placeholders.email")}
                                    onChange={(event) => {
                                      setFieldValue("username", event.target.value.trimStart());
                                    }}
                                  />
                                  {errors.username && touched.username && <div className="invalid-feedback d-block">{t(errors.username)}</div>}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={12}>
                                <Label>{t("labels.password")}</Label>
                                <FormGroup>
                                  <Field className="form-control" name="password" type="password" placeholder={t("placeholders.password")} />
                                  {errors.password && touched.password && <div className="invalid-feedback d-block">{t(errors.password)}</div>}
                                </FormGroup>
                              </Col>
                            </Row>

                            {/* <Button className="w-100 btn text-uppercase" type="submit">
                              {t("menu.login")}
                            </Button> */}
                            <StyledButtondiv2 className="w-100 btn text-uppercase" type="submit">{t("menu.login")} </StyledButtondiv2>

                            <div className="or_devider text-center py-4">{t("labels.or")}</div>
                            <FacebookLogin
                              appId={facebook_app_id}
                              fields="name,email,picture"
                              callback={this.responseFacebook}
                              cssClass="w-100 btn facebook d-flex align-items-center justify-content-center"
                              icon="fab fa-facebook-f mr-3 ml-3"
                            />

                            <div className="dont_signup_text text-center py-5">
                              <p>
                                {t("commons.Don’t_have_an_account?")} <Link to="/register">{t("menu.signup")}</Link>
                              </p>
                              <p>
                                {t("labels.forgot")} <Link to="/forgot-password">{t("labels.reset")}</Link>
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        )}
      </Translation>
    );
  }
}
