import React, { Component } from "react";
import { Row, Col, Form, FormText, FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ThemeContext } from "styled-components";
import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export default class ProfileView extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      settingsData: null,
      is_corporate_customer: 0
    };
  }

  componentDidMount() {
    this.getUserData();

    let getCustomerInfo = localStorage.getItem('customerData');

    let parsedgetCustomerInfo = JSON.parse(getCustomerInfo);
    if (parsedgetCustomerInfo && parsedgetCustomerInfo.is_corporate_customer && parsedgetCustomerInfo.is_corporate_customer != undefined) {
      this.setState({
        is_corporate_customer: parsedgetCustomerInfo.is_corporate_customer
      })
    }
  }

  getUserData = async (refreshPage = false) => {
    let path = ApiRoutes.GET_PROFILE;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.setAuthData(resJson.data);

        this.setState(
          {
            userData: resJson.data,
          },
          () => {
            if (refreshPage) {
              setTimeout(window.location.reload(), 7000);
            }
          }
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  uploadProfileImage = async (e) => {
    var fileType = e.currentTarget.files[0].type;

    if (SUPPORTED_IMAGE_FORMATS.includes(fileType)) {
      let formData = new FormData();
      formData.append("user_image", e.currentTarget.files[0]);
      let path = ApiRoutes.UPDATE_PROFILE_PARTIALLY;
      const res = await Http("PATCH", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.getUserData(true);
          NotificationManager.success(resJson.message, "Success!", 3000);
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    } else {
      let message = "The uploaded file should be an image.";
      NotificationManager.error(message, "Error!", 3000);
    }
  };

  render() {
    var { userData } = this.state;

    return (
      <Translation>
        {(t) => (
          <ThemeContext.Consumer>
            {theme => (
              <>
                <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
                  <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                    <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                      <i className="fas fa-bars"></i>
                    </div>
                  </div>
                  <h1 className="page_title mb-5">{t("menu.Account")}</h1>

                  <Row className="justify-content-center">
                    <Col xs={12}>
                      <div className="manage_account_user_sec p-md-5 p-3">
                        <div className="manage_account_user_info_sec">
                          <div className="manage_account_pic mb-4">
                            <img src={userData ? userData.user_image_thumb_url : ""} alt="LaundryHUB" />
                            <div className="edit_profile_sec">
                              <i className="fas fa-plus"></i>
                              <Input type="file" name="user_image" onChange={this.uploadProfileImage} />
                            </div>
                          </div>
                          <div className="manage_account_user_info">
                            <h2 className="manage_account_name mb-3">{userData ? userData.firstName + " " + userData.lastName : ""}</h2>
                            {
                              this.state.is_corporate_customer == 1 && (<div style={{
                                marginBottom: '20px',
                                color: '#c2185b',
                                fontWeight: 'bold',
                                fontSize: '18px'
                              }}>
                                <span>{t("menu.Corporate_Customer")}</span>
                              </div>)
                            }
                            <div className="manage_account_user_info_list mb-2">
                              <i className="fas fa-id-card-alt ml-2"></i> {userData ? userData.user_code : ""}
                            </div>
                            <div className="manage_account_user_info_list mb-2">
                              <i className="fa fa-envelope ml-2"></i> {userData ? userData.email : ""}
                            </div>
                            <div className="manage_account_user_info_list mb-2">
                              <i className="fas fa-phone-alt ml-2"></i> {userData ? userData.phone : ""}
                            </div>
                            <div className="manage_account_user_info_list mb-2" style={{ textTransform: "capitalize" }}>
                              <i className="fas fa-venus-mars ml-2"></i> {userData ? userData.gender : ""}
                            </div>
                          </div>
                        </div>
                        <ul className="manage_Account_links mt-2">
                          <li>
                            <Link to="/edit-profile">
                              {t("commons.Edit_Profile")} <i className="fas fa-angle-right ml-auto"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="/edit">
                              {t("commons.Update_Email_&_Phone_Number")} <i className="fas fa-angle-right ml-auto"></i>
                            </Link>
                          </li>

                          <li>
                            <Link to="/change-password">
                              {t("labels.Change_Password")} <i className="fas fa-angle-right ml-auto"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="/manage-address">
                              {t("labels.Address")} <i className="fas fa-angle-right ml-auto"></i>
                            </Link>
                          </li>
                          <li>
                            <Link to="/manage-repeated-orders">
                              {t("commons.Repeated_Order")} <i className="fas fa-angle-right ml-auto"></i>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
              </>
            )}
          </ThemeContext.Consumer>
        )}
      </Translation>
    );
  }
}
