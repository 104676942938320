import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { NotificationManager } from "react-notifications";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";

export default class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      content_key: "customer_terms_conditions",
      content_text: "",
    };
  }

  componentDidMount() {
    this.dataRender();
  }

  dataRender = async () => {
    let path = ApiRoutes.GET_CONTENT + "/" + this.state.content_key;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          content_text: resJson.data.content_data,
          isloading: true,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { authUser } = this.props;
    const { content_text, isloading } = this.state;

    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <Container className="py-3 py-md-4">
              <h2 className=" section_title mb-4">{t("menu.terms")}</h2>
              <div className="laundries_list">
                {!isloading ? (
                  <div className="loading"></div>
                ) : (
                  <h6
                    className="ql-editor"
                    style={{ color: "#979797", letterSpacing: ".8px" }}
                    dangerouslySetInnerHTML={{
                      __html: content_text,
                    }}
                  ></h6>
                )}
              </div>
            </Container>
          </section>
        )}
      </Translation>
    );
  }
}
