import React, { Component } from "react";
import { Row, Col, Table, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { Link } from "react-router-dom";
import i18next from "i18next";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { StyledButtondiv4 } from "../../helpers/StyledButtondiv";
import { ThemeContext } from "styled-components";
import { NotificationManager } from "react-notifications";
import { Translation } from "react-i18next";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import DatePicker from "react-datepicker";
import StarRatings from "react-star-ratings";

import moment from "moment";

import Slider from "react-slick";
var sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  centerPadding: "30",
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const DeliveryOpts = [
  { value: "yes", label: "commons.Yes" },
  { value: "no", label: "commons.No" },
];

const feedbackOpts = [
  { value: "rating", label: "commons.Review" },
  { value: "report", label: "commons.Report" },
];

var orderdeliverydate,
  currentDate = "",
  currentDatewithTime = "",
  totalPickTimeLessOne = "",
  totalpickupdate = "";

const ServiceTypeFormSchema = Yup.object().shape({
  delivery_date: Yup.string().required("commons.Please_select_Delivery_Date").nullable(),
  delivery_time: Yup.string().required("commons.Please_select_Delivery_Time").nullable(),
});

const RatingFormSchema = Yup.object().shape({
  ratings: Yup.number().min("1", "commons.Please_enter_Rating"),
  comments: Yup.string(),
  feedback_user_type: Yup.string(),
});

const RatingReportFormSchema = Yup.object().shape({
  comments: Yup.string().required("commons.Please_enter_Comments"),
});

const WorkingDaysOpts = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var workingDays = "";
var selectedDate = "";
var deliverytimestatus = '';
var deliverytimetemp = 0;
var temp_1 = 0;
var temp_2 = 0;
var next_day = 0;
var vendornotavailable = [];
var available_dates = [];
var availabletime = [];
var availablefiltertime = [];

export default class Dashboard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      is_reorder_loader: false,
      orderDetails: [],
      VendorDetails: [],
      orderId: props.match.params.itemId,
      amount_info: "",
      status: "",
      showModal: false,
      delivery_date: "",
      delivery_time: "",
      first_shift_start_time: "",
      first_shift_end_time: "",
      second_shift_start_time: "",
      second_shift_end_time: "",
      vendorId: "",
      showModalRating: false,
      vendorName: "",
      vendorImage: [],
      ratings: -1,
      feedback_type: "",
      feedback_user_type: "",
      showReportRating: false,
      name: "",
      image: "",
      lastname: "",
      lang: "",
      isCancelled: false,
      adon_type: "",
      adon_others: "",
      isLoading: false,
      reorder_item_id: "",
      vendor_comments: "",
      vendor_image: [],
      is_easy_pickup: 0,
      cancel_button: false,
      showtimepicker: false,
      delivery_specific_date: "",
      delivery_specific_new_date: "",
      minTime_delivery: "",
      new_pickup_date: "",
      static_minTime_delivery: "",
      delivery_time_new: "",
      is_payment_popup: false,
      first_shift_start_time_sun: "",
      first_shift_end_time_sun: "",
      second_shift_start_time_sun: "",
      second_shift_end_time_sun: "",
      first_shift_start_time_mon: "",
      first_shift_end_time_mon: "",
      second_shift_start_time_mon: "",
      second_shift_end_time_mon: "",
      first_shift_start_time_tue: "",
      first_shift_end_time_tue: "",
      second_shift_start_time_tue: "",
      second_shift_end_time_tue: "",
      first_shift_start_time_wed: "",
      first_shift_end_time_wed: "",
      second_shift_start_time_wed: "",
      second_shift_end_time_wed: "",
      first_shift_start_time_thur: "",
      first_shift_end_time_thur: "",
      second_shift_start_time_thur: "",
      second_shift_end_time_thur: "",
      first_shift_start_time_fri: "",
      first_shift_end_time_fri: "",
      second_shift_start_time_fri: "",
      second_shift_end_time_fri: "",
      first_shift_start_time_sat: "",
      first_shift_end_time_sat: "",
      second_shift_start_time_sat: "",
      second_shift_end_time_sat: "",
      minTime_delivery_update: this.calculateMinTime_Update_delivery(new Date()),
      currentday: "",
      currendate1: "",
      ajhcurrentday: "",
      vendornotavailabledate: [],
      vendorTimings: "",
      vendoravailabledate: "",
      max_delivery_date_for_popup: "",
      show_update_delivery_date_time: 0,
      nextdate: "",
      validatedate: moment(new Date).format('DD-MM-YYYY'),
      validate_currentdate: moment(new Date).format('DD-MM-YYYY'),
      temp_2: 0,
      temp_3: 0,
      isEasyPickupCancelled: false,
      easy_cancel_payment_url: "",
      show_wallet: false,
      customer_availability: 0,
      with_or_without_trans: '',
      vendor_update_date: "",
      showCancelExpressDeliveryModal: false,
    };
  }

  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
    this.getOrderDetails();
  }

  // Convert timestamp to date format
  toDate = (dStr, format) => {
    var now = new Date();
    if (format == "h:m") {
      now.setHours(dStr.substr(0, dStr.indexOf(":")));
      now.setMinutes(dStr.substr(dStr.indexOf(":") + 1));
      now.setSeconds(0);
      return now;
    } else return "Invalid Format";
  };

  // Show Filtered Time
  filterPassedTime = (time) => {
    // let first_shift_start = this.toDate(this.state.first_shift_start_time && this.state.first_shift_start_time, "h:m");
    // let first_shift_end = this.toDate(this.state.first_shift_end_time && this.state.first_shift_end_time, "h:m");
    // let second_shift_start = this.toDate(this.state.second_shift_start_time && this.state.second_shift_start_time, "h:m");
    // let second_shift_end = this.toDate(this.state.second_shift_end_time && this.state.second_shift_end_time, "h:m");
    // selectedDate = new Date(time);
    // first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    // first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    // second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    // second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    // selectedDate = parseInt(selectedDate / 1000);
    // var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    // return test;
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;

    if (this.state.delivery_specific_new_date == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.delivery_specific_new_date == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
    }
    if (this.state.delivery_specific_new_date == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.delivery_specific_new_date == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.delivery_specific_new_date == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.delivery_specific_new_date == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.delivery_specific_new_date == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }
    let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
    nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    first_shift_start = new Date(nowAddOneHour)
    let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
    nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
    second_shift_start = new Date(nowAddOneHour1);

    selectedDate = new Date(time);
    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    return test;
  };

  filterPassedTime__delivery = (time) => {
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;
    if (this.state.currentday == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.currentday == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
    }
    if (this.state.currentday == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.currentday == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.currentday == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.currentday == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.currentday == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }

    let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
    nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    first_shift_start = new Date(nowAddOneHour);
    let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
    nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
    second_shift_start = new Date(nowAddOneHour1);

    selectedDate = new Date(time);
    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    // deliverytimestatus = test;
    if (test == true) {
      availablefiltertime.push('true');
    } else {
      availablefiltertime.push('false');
    }
    if (availablefiltertime.includes('true')) {
      if (this.state.temp_2 != 1) {
        var availdate = this.state.vendoravailabledate;
        var vendornotavaildate = vendornotavailable.filter(function (e) { return e !== String(availdate) });
        this.setState({
          currentday: this.state.currentday,
          currendate1: this.state.currendate1,
          vendoravailabledate: this.state.vendoravailabledate,
          vendornotavailabledate: vendornotavaildate,
          validatedate: this.state.validatedate,
          temp_2: 1,
        });
      }
    } else {
      next_day = 1;
      deliverytimestatus = false;
      deliverytimetemp = 1;
      var vendordate = moment(new Date(this.state.currendate1)).format('DD MMM YYYY');
      var date = moment(new Date(this.state.currendate1)).format('DD');
      if (!available_dates.includes(date)) {
        available_dates.push(date);
        vendornotavailable.push(vendordate);
      }

      var newdate = new Date(this.state.currendate1.getTime() + 24 * 60 * 60 * 1000);
      var availabledate = moment(new Date(newdate)).format('DD MMM YYYY');
      var validatedate = moment(new Date(newdate)).format('DD-MM-YYYY');
      var getday = newdate.getDay();
      this.setState({
        currentday: getday,
        currendate1: newdate,
        vendoravailabledate: availabledate,
        vendornotavailabledate: vendornotavailable,
        validatedate: validatedate,
      });
    }
    return test;
    // if (test == true) {
    //   temp_1 = 1;
    //   return test;
    // }
    // if (test != true && temp_1 != 1) {
    //   next_day = 1;
    //   var vendordate = moment(new Date(this.state.currendate1)).format('DD MMM YYYY');
    //   var date = moment(new Date(this.state.currendate1)).format('DD');
    //   if (!available_dates.includes(date)) {
    //     available_dates.push(date);
    //     vendornotavailable.push(vendordate);
    //   }

    //   deliverytimestatus = false;
    //   var newdate = new Date(this.state.currendate1.getTime() + 24 * 60 * 60 * 1000);
    //   // var currendate = new Date();
    //   var availabledate = moment(new Date(newdate)).format('DD MMM YYYY');
    //   var validatedate = moment(new Date(newdate)).format('DD-MM-YYYY');
    //   var getday = newdate.getDay();
    //   this.setState({
    //     currentday: getday,
    //     currendate1: newdate,
    //     vendoravailabledate: availabledate,
    //     vendornotavailabledate: vendornotavailable,
    //     validatedate: validatedate,
    //   });
    //   // this.filterPassedTime__delivery();
    // }
    //  else {
    //   console.log('xcvbnmnbvcx');
    //   return test;
    // }

  }

  filterPassedTimeOld__delivery = (time) => {
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;
    if (this.state.currentday == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.currentday == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
    }
    if (this.state.currentday == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.currentday == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.currentday == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.currentday == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.currentday == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }
    let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
    nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    first_shift_start = new Date(nowAddOneHour);
    let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
    nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
    second_shift_start = new Date(nowAddOneHour1);
    selectedDate = new Date(time);
    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    if (test == true) {
      availabletime.push('true');
    } else {
      availabletime.push('false');
    }
    if (availabletime.includes('true')) {
      if (this.state.temp_2 != 1) {
        this.setState({
          currentday: this.state.ajhcurrentday,
          // currendate1: new Date(),
          vendoravailabledate: "",
          // vendornotavailabledate: vendornotavailable,
          validatedate: "",
          temp_2: 1,
        });
      }
    } else {
      next_day = 1;
      deliverytimestatus = false;
      deliverytimetemp = 1;
      var vendordate = moment(new Date(this.state.currendate1)).format('DD MMM YYYY');
      var date = moment(new Date(this.state.currendate1)).format('DD');
      if (!available_dates.includes(date)) {
        available_dates.push(date);
        vendornotavailable.push(vendordate);
      }
      var newdate = new Date(this.state.currendate1.getTime() + 24 * 60 * 60 * 1000);
      var availabledate = moment(new Date(newdate)).format('DD MMM YYYY');
      var validatedate = moment(new Date(newdate)).format('DD-MM-YYYY');
      var getday = newdate.getDay();
      this.setState({
        currentday: getday,
        currendate1: newdate,
        vendoravailabledate: availabledate,
        // vendornotavailabledate: vendornotavailable,
        validatedate: validatedate,
      });
    }
    return test;

    // console.log(second_shift_start <= selectedDate && second_shift_end >= selectedDate,'second_shift_start <= selectedDate && second_shift_end >= selectedDate');
    // console.log(test,'test');
    // deliverytimestatus = test;
    // if (test == true) {
    //   //   deliverytimetemp
    //   // deliverytimestatus = true;
    //   temp_2 = 1;
    //   return test;
    // } else {
    //   if (test != true && temp_2 != 1) {
    //     console.log(this.state.currendate1,'this.state.currendate1');
    //     next_day = 1;
    //     deliverytimestatus = false;
    //     deliverytimetemp = 1;
    //     var vendordate = moment(new Date(this.state.currendate1)).format('DD MMM YYYY');
    //     var date = moment(new Date(this.state.currendate1)).format('DD');
    //     if (!available_dates.includes(date)) {
    //       available_dates.push(date);
    //       vendornotavailable.push(vendordate);
    //     }

    //     var newdate = new Date(this.state.currendate1.getTime() + 24 * 60 * 60 * 1000);
    //     var availabledate = moment(new Date(newdate)).format('DD MMM YYYY');
    //     var validatedate = moment(new Date(newdate)).format('DD-MM-YYYY');
    //     var getday = newdate.getDay();
    //     this.setState({
    //       currentday: getday,
    //       currendate1: newdate,
    //       vendoravailabledate: availabledate,
    //       // vendornotavailabledate: vendornotavailable,
    //       validatedate: validatedate,
    //     });
    //     return test;

    //   }

    // }

  }

  calculateMinTime_Update_delivery = (date) => {
    // let isToday = moment(date).isSame(moment(), "day");
    // if (isToday) {
    //   let nowAddOneHour = moment(new Date()).toDate();
    //   return nowAddOneHour;
    // }
    // return moment().startOf("day").toDate();
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
      nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
      return nowAddOneHour1;
    }
    return moment().startOf("day").toDate();
  };

  handleMinTime_delivery = (date) => {
    this.setState({
      minTime_delivery_update: this.calculateMinTime_Update_delivery(date),
    });
  };

  showCancelOrder = async (e) => {
    if (e == 0) {
      this.setState((prevState) => ({
        isCancelled: !prevState.isCancelled,
      }));
    } else {
      var new_date = moment(new Date())
      var vendor_update_date = moment(this.state.vendor_update_date).subtract(6, 'hours');
      var duration = moment.duration(new_date.diff(vendor_update_date));
      if (duration.minutes() <= 5) {
        if (this.state.orderDetails.amountInfo.total_transport_charges < this.state.orderDetails.customer_setting.wallet_amount) {
          this.setState((prevState) => ({
            show_wallet: true,
            isEasyPickupCancelled: !prevState.isEasyPickupCancelled,
          }));
        } else {
          this.setState((prevState) => ({
            show_wallet: false,
            isEasyPickupCancelled: !prevState.isEasyPickupCancelled,
          }));
        }
      } else {
        if (this.state.lang == 'en') {
          NotificationManager.error("Cancellation is not allowed after 5 minutes from the time the order item is updated", "Error!", 3000);
        } else {
          NotificationManager.error("لا يُسمح بالإلغاء بعد 5 دقائق من وقت تحديث عنصر الطلب", "Error!", 3000);
        }
      };
    }
  };

  customerReorder = async (item_id) => {
    this.setState({
      ...this.state,
      is_reorder_loader: true,
      reorder_item_id: item_id
    });
    let path = ApiRoutes.RE_ORDER + "/" + item_id;

    const res = await Http("PUT", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        setTimeout(() => {
          this.props.history.push("/cart");
        }, 2000);
      } else {
        var cartaction = window.confirm(i18next.t("commons.Your_cart_contains_the_items_of_other_vendor._Do_you_want_to_clear_the_cart_before_browsing_other_vendors?"));
        if (cartaction == true) {
          this.deleteCart();
        } else if (cartaction == false) {
          this.setState({
            ...this.state,
            is_reorder_loader: false
          })
        }
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  deleteCart = async () => {
    let formData = new FormData();
    let path = ApiRoutes.DELETE_CART;
    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.customerReorder(this.state.reorder_item_id)
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  toggleModal = () => {
    this.getVendorData();
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  toggleDeliveryModal = () => {
    // this.getVendorData();
    this.setState((prevState) => ({
      showdeliverytimepicker: !prevState.showdeliverytimepicker,
    }));
  };

  hideModal = () => {
    this.setState((prevState) => ({
      showModal: false,
    }));
  };

  hideDeliveryModal = () => {
    this.setState((prevState) => ({
      showdeliverytimepicker: false,
    }));
  };

  showVendorRatingModal = (feedback_for, image, name, lastname = "") => {
    this.setState((prevState) => ({
      showModalRating: !prevState.showModalRating,
      feedback_user_type: feedback_for,
      name: name,
      image: image,
      lastname: lastname,
    }));
  };

  hideVendorRatingModal = () => {
    this.setState((prevState) => ({
      showModalRating: false,
      ratings: -1,
    }));
  };

  showVendorReportModal = (feedback_for, image, name, lastname = "") => {
    this.setState((prevState) => ({
      showReportRating: !prevState.showModalRating,
      feedback_user_type: feedback_for,
      image: image,
      name: name,
    }));
  };

  hideVendorReportModal = () => {
    this.setState((prevState) => ({
      showReportRating: false,
    }));
  };
  handleSubmitUpdateTimings = async () => {
    let formData = new FormData();
    var strEndTime = this.state.delivery_time_new ? moment(this.state.delivery_time_new).format('HH:mm') : this.EditTimeFormat(this.state.orderDetails.delivery_time);
    var delivery_date = this.state.new_pickup_date != '' ? moment(this.state.new_pickup_date).format("YYYY-MM-DD") : moment(this.state.orderDetails.delivery_date).format("YYYY-MM-DD");
    formData.append("delivery_time", strEndTime);
    formData.append("delivery_date", delivery_date);
    formData.append("is_easy_pickup", 1);
    let path = ApiRoutes.UPDATE_ORDER_TIMINGS + "/" + this.state.orderId;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({
          showtimepicker: false,
          admin_delivery_date: "",
          admin_pickup_date: "",
          delivery_time_new: new Date(),
          pickup_date_new: new Date(),
          timepicker_id: "",
          err_endtime: "",
          new_pickup_date: ""
        });
        this.getOrderDetails();
        NotificationManager.success(resJson.message, "Success!", 3000);
      }
      else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  EditTimeFormat = (date) => {
    var date = date.split('T');
    var timestamp_date = date[0];
    var time = date[1].split(':');
    var hours = time[0];
    var minutes = time[1];
    minutes = minutes == '00' ? minutes : minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes;
    return strTime;
  };
  getOrderDetails = async () => {
    let path = ApiRoutes.GET_ORDERS + "/" + this.state.orderId;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        var data = []
        resJson.data.adon_request && resJson.data.adon_request.map((res) => {
          if (this.state.lang == "en") {
            data.push(res.en);
          } else {
            data.push(res.ar);
          }
        })
        resJson.data.customer_availability && this.setState({ customer_availability: resJson.data.customer_availability })
        this.setState({
          adon_type: data,
          adon_others: resJson.data.adon_others,
        });
        var delivery_datetime = new Date(resJson.data.delivery_datetime)
        var curren_date = new Date();
        if (Date.parse(delivery_datetime) < Date.parse(curren_date)) {
          this.setState({ show_update_delivery_date_time: 1 })
        }
        this.getDateforOrder(resJson.data.delivery_date, resJson.data.pickup_date, resJson.data.pickup_time);
        var packagename = [];
        var categoryname = [];
        if (resJson.data.PackageName) {
          resJson.data.PackageName.map((pack, index) => {
            packagename.push(pack[this.state.lang]);
          });
        }
        if (resJson.data.CategoryName) {
          resJson.data.CategoryName.map((cat, index) => {
            categoryname.push(cat[this.state.lang]);
          });
        }
        //for corporate package and main categories
        var corporatepackagename = [];
        var corporatecategoryname = [];
        if (resJson.data.CorporatePackageName) {
          resJson.data.CorporatePackageName.map((pack, index) => {
            corporatepackagename.push(pack[this.state.lang]);
          });
        }
        if (resJson.data.CorporateCategoryName) {
          resJson.data.CorporateCategoryName.map((cat, index) => {
            corporatecategoryname.push(cat[this.state.lang]);
          });
        }
        var DeliveryDate = moment(resJson.data.delivery_date).format('ddd');
        var DeliveryWeekDay = '';

        if (DeliveryDate == 'Sun') {
          DeliveryWeekDay = 0;

        } else if (DeliveryDate == 'Mon') {
          DeliveryWeekDay = 1;

        } else if (DeliveryDate == 'Tue') {
          DeliveryWeekDay = 2;

        } else if (DeliveryDate == 'Wed') {
          DeliveryWeekDay = 3

        } else if (DeliveryDate == 'Thu') {
          DeliveryWeekDay = 4;

        } else if (DeliveryDate == 'Fri') {
          DeliveryWeekDay = 5;

        } else if (DeliveryDate == 'Sat') {
          DeliveryWeekDay = 6;

        };

        var max_delivery_date = new Date(resJson.data.delivery_date);
        max_delivery_date.setDate(max_delivery_date.getDate() + 7);
        var delivery_time = new Date(resJson.data.delivery_time);
        this.setState({
          delivery_time_new: new Date(delivery_time).getTime(),
          max_delivery_date_for_popup: max_delivery_date,
          new_pickup_date: new Date(resJson.data.delivery_date),
          orderDetails: resJson.data,
          amount_info: resJson.data.amountInfo,
          PackageName: packagename.join(", "),
          CategoryName: categoryname.join(", "),
          CorporatePackageName: corporatepackagename.join(", "),
          CorporateCategoryName: corporatecategoryname.join(", "),
          vendorId: resJson.data.vendor._id,
          delivery_specific_date: DeliveryWeekDay,
          minTime_delivery: this.calculateMinTime_delivery(resJson.data.delivery_datetime),
          static_minTime_delivery: this.calculateMinTime_delivery(resJson.data.delivery_datetime),
          with_or_without_trans: resJson.data.with_or_without_trans
        });

        if (this.state.orderDetails.order_status == 'finished' && this.state.orderDetails.customer_ready_for_delivery == 0) {
          var temp = 0;
          var currentdate = new Date();
          if (deliverytimestatus == "") {
            console.log('hjkn');
            var currentday = currentdate.getDay();
            this.setState({
              currentday: currentday,
              ajhcurrentday: currentday,
              currendate1: currentdate,
            })
          }
        }
        this.getVendorData();

        if (resJson.data.is_easy_pickup == 1) {
          this.setState({
            is_easy_pickup: 1,
          })
          resJson.data.image && this.setState({
            vendor_image: resJson.data.image,
          });
          resJson.data.comments && this.setState({
            vendor_comments: resJson.data.comments
          });
          if (resJson.data.vendor_update_date != "") {
            this.setState({ vendor_update_date: resJson.data.vendor_update_date })
            var new_date = moment(new Date())
            var vendor_update_date = moment(resJson.data.vendor_update_date).subtract(6, 'hours');
            var duration = moment.duration(new_date.diff(vendor_update_date));
            if (duration.minutes() <= 5) {
              this.setState({
                cancel_button: true,
              })
            }
          }
        }
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };
  cancelOrder = async () => {
    let path = ApiRoutes.CANCEL_ORDER + "/" + this.state.orderId + "/cancel";

    const res = await Http("PUT", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.getOrderDetails();
        NotificationManager.success(resJson.message, "Success", 3000);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getOrderStatus(status) {
    switch (status) {
      case "created":
        return "commons.Created";
      case "cancelled":
        return "commons.Cancelled";
      case "ready_to_pickup":
        return "commons.Ready_To_Pickup";
      case "picked_up":
        return "commons.Picked_Up";
      case "received":
        return "commons.Received";
      case "in_process":
        return "commons.In_Process";
      case "finished":
        return "commons.Finished";
      case "ready_to_deliver":
        return "commons.Ready_To_Deliver";
      case "out_for_delivery":
        return "commons.Out_For_Delivery";
      case "delivered":
        return "commons.Delivered";
    }
  }

  getPaymentStatus(status) {
    switch (status) {
      case "pending":
        return "commons.Pending";
      case "paid":
        return "commons.Paid";
      case "refunded":
        return "commons.Refunded";
      case "failed":
        return "commons.Failed";
      case "partially paid":
        return "commons.partially paid";
    }
  }

  getDateforOrder(orderDeliveryDate, orderPickupDate, orderPickUpTime) {
    var orderdelivery = new Date(orderDeliveryDate);
    var pickupdelivery = new Date(orderPickupDate).getTime();
    var orderpickTime = new Date(orderPickUpTime).getTime();
    currentDatewithTime = new Date().getTime();
    totalpickupdate = pickupdelivery + orderpickTime - 19800000;
    totalPickTimeLessOne = totalpickupdate - 3600000;

    currentDate = moment(new Date()).format("MMM DD YYYY");
    orderdeliverydate = moment(orderdelivery).format("MMM DD YYYY");

    return orderdeliverydate, currentDate, totalpickupdate, currentDatewithTime;
  }

  getWorkingDaysString(sortArr) {
    var daysArr = sortArr.sort();

    var str = [];
    if (daysArr && daysArr.length > 0) {
      daysArr.forEach((item) => {
        str += WorkingDaysOpts[item] + ", ";
      });
    }

    return str;
  }

  handleDeliveryResponse = async (inputValues, formOptions) => {
    var utcString = "";
    let formData = new FormData();

    if (this.state.status == "no") {

      var dateObj = new Date(this.state.delivery_time);
      utcString = moment(dateObj).format("HH:mm");
      var deliveryValidation = workingDays.includes(moment(this.state.delivery_date).format("dd"));

      if (!deliveryValidation) {
        formOptions.setFieldError("delivery_date", "commons.Please_select_another_date_as_vendor_is_unavailable_on_selected_date.");
        return false;
      }
      formData.append("status", this.state.status);

      formData.append("delivery_date", this.state.delivery_date ? moment(this.state.delivery_date).format("YYYY-MM-DD") : "");
      formData.append("delivery_time", this.state.delivery_time ? utcString : "");

    } else if (this.state.status == "yes") {
      var deliverydate = moment(this.state.currendate1).format("YYYY-MM-DD")
      var dateObj = new Date(this.state.delivery_time);
      utcString = moment(dateObj).format("HH:mm");

      formData.append("status", this.state.status);
      formData.append("delivery_date", deliverydate);
      formData.append("delivery_time", utcString);
    }


    let path = ApiRoutes.UPDATE_DELIVERY_STATUS + "/" + this.state.orderId + "/delivery/availibilty";

    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        var x = document.getElementById("reschedule");
        x.style.display = "none";
        this.setState({ showModal: false });
        this.setState({ showdeliverytimepicker: false });
        NotificationManager.success(resJson.message, "Success!", 3000);
        window.location.reload();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getVendorData = async (currentPage = 1) => {
    let path = ApiRoutes.GET_VENDOR + "/" + this.state.vendorId;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        workingDays = this.getWorkingDaysString(resJson.data && resJson.data.working_days);
        if (resJson.data.sunday_timings) {
          Object.entries(resJson.data.sunday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.monday_timings) {
          Object.entries(resJson.data.monday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.tuesday_timings) {
          Object.entries(resJson.data.tuesday_timings).forEach(([key, value]) => {
            // for (var key in value) {
            //   this.setState({ [key]: value[key] ? moment(value[key]).utc().format("HH:mm") : "N/A" });
            // }
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.wednesday_timings) {
          Object.entries(resJson.data.wednesday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.thursday_timings) {
          Object.entries(resJson.data.thursday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.friday_timings) {
          Object.entries(resJson.data.friday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.saturday_timings) {
          Object.entries(resJson.data.saturday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        this.setState({
          VendorDetails: resJson.data,
          vendorTimings: resJson.data.saturday_timings,
        })
        // var currentdate = new Date();
        // var currentday = currentdate.getDay();

        // this.setState({
        //   currentday: currentday,
        //   currendate1: currentdate,
        // })

        // await this.setState({
        //   first_shift_start_time: resJson.data.first_shift_start_time ? moment(resJson.data.first_shift_start_time).utc().format("HH:mm") : "N/A",
        //   first_shift_end_time: resJson.data.first_shift_end_time ? moment(resJson.data.first_shift_end_time).utc().format("HH:mm") : "N/A",
        //   second_shift_start_time: resJson.data.second_shift_start_time ? moment(resJson.data.second_shift_start_time).utc().format("HH:mm") : "N/A",
        //   second_shift_end_time: resJson.data.second_shift_end_time ? moment(resJson.data.second_shift_end_time).utc().format("HH:mm") : "N/A",
        // });

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleRating = async (formOptions) => {
    let formData = new FormData();
    formData.append("feedback_user_type", this.state.feedback_user_type);

    formData.append("feedback_type", this.state.feedback_type);
    formData.append("feedback_value", this.state.ratings && this.state.ratings);
    formData.append("feedback_text", this.state.comments ? this.state.comments : "");
    let path = ApiRoutes.GET_ORDERS + "/" + this.state.orderId + "/feedback";

    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({ showModalRating: false, ratings: -1, comments: "" });
        this.getOrderDetails();
        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        this.setState({ showModalRating: false, ratings: -1, comments: "" });
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleReport = async (formOptions) => {
    let formData = new FormData();
    formData.append("feedback_user_type", this.state.feedback_user_type);

    formData.append("feedback_type", this.state.feedback_type);
    formData.append("feedback_value", "");
    formData.append("feedback_text", this.state.comments ? this.state.comments : "");
    let path = ApiRoutes.GET_ORDERS + "/" + this.state.orderId + "/feedback";

    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({ showReportRating: false, ratings: -1, comments: "" });
        this.getOrderDetails();
        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        this.setState({ showReportRating: false, ratings: -1, comments: "" });
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };
  OrderTimeFormat = (timestamp) => {
    var date = timestamp;
    date = date.split('T');
    var timestamp_date = date[0];
    var time = date[1].split(':');
    var hours = time[0];
    var minutes = time[1];
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes == '00' ? minutes : minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  togglePickUpTime = () => {
    this.setState((prevState) => ({
      showtimepicker: !prevState.showtimepicker,
    }));
  };

  calculateMinTime_delivery = (date) => {
    let nowAddOneHour = moment.parseZone(date).format("ddd MMM DD YYYY HH:mm:ss") + " GMT+0530 (India Standard Time)";
    return nowAddOneHour;
  };

  TopupWallet = () => {
    this.props.history.push({
      pathname: "/wallet-pay",
    });
  };

  image_open = (e) => {
    window.open(e);
  };

  easyPickupPayment = async (payment_method, wallet_used, is_cancel) => {
    let path = ApiRoutes.GET_MONEY_FROM_WALLET + "/" + this.state.orderId;
    let formData = new FormData();
    if (this.state.orderDetails.payment_status == "partially paid") {
      formData.append("amount", this.state.orderDetails.amountInfo.remaining_amount);
    };
    formData.append("order_number", this.state.orderDetails.order_number);
    formData.append('payment_method', payment_method);
    formData.append('wallet_used', wallet_used);
    formData.append('is_cancel', is_cancel);
    formData.append("wallet_amount", this.state.orderDetails.customer_setting.wallet_amount);
    if (payment_method == 'wallet' && wallet_used == 'yes' && is_cancel == 0) {
      if (this.state.orderDetails.payment_status == "partially paid") {
        if (this.state.orderDetails.amountInfo.remaining_amount < this.state.orderDetails.customer_setting.wallet_amount) {
          const res = await Http("POST", path, formData);
          if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
              this.getOrderDetails();
              NotificationManager.success(resJson.message, "Success!", 3000);
            } else {
              NotificationManager.error(resJson.message, "Error!", 3000);
            }
          } else {
            NotificationManager.error("Server Error", "Error!", 3000);
          }
        } else {
          this.setState({
            is_payment_popup: true
          })
        }
      } else {
        if (this.state.orderDetails.amountInfo.total_amount_paid_by_customer < this.state.orderDetails.customer_setting.wallet_amount) {
          const res = await Http("POST", path, formData);
          if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
              this.getOrderDetails();
              NotificationManager.success(resJson.message, "Success!", 3000);
            } else {
              NotificationManager.error(resJson.message, "Error!", 3000);
            }
          } else {
            NotificationManager.error("Server Error", "Error!", 3000);
          }
        } else {
          this.setState({
            is_payment_popup: true
          })
        }
      }

    } else if (payment_method == 'card' && wallet_used == 'no' && is_cancel == 0) {
      this.setState({ isLoading: true })
      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.setState({ isLoading: false })
          window.location.replace(resJson.data.payment_url);
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    } else if (payment_method == "wallet" && wallet_used == "yes" && is_cancel == 1) {
      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.getOrderDetails();
          this.setState({ isEasyPickupCancelled: false });
          NotificationManager.success(resJson.message, "Success!", 3000);
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    } else if (payment_method == "card" && wallet_used == "no" && is_cancel == 1) {
      this.setState({ isLoading: true })
      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.setState({ isLoading: false })
          window.location.replace(resJson.data.payment_url);
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    }
  };

  showCancelExpressDelivery = async () => {
    this.setState({ showCancelExpressDeliveryModal: true });
  };

  cancelExpressDelivery = async () => {
    let path = ApiRoutes.CANCEL_EXPRESS_DELIVERY + "/" + this.state.orderId
    const res = await Http("PUT", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({ showCancelExpressDeliveryModal: false });
        this.getOrderDetails();
        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        this.setState({ showReportRating: false, ratings: -1, comments: "" });
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    const theme = this.context;
    var { orderDetails, amount_info, pickup_driver_id, lang, PackageName, CategoryName, CorporatePackageName, CorporateCategoryName, with_or_without_trans } = this.state;
    const { state } = this.props.location;
    var total_transport_charges = orderDetails && orderDetails.amountInfo && parseFloat(orderDetails.amountInfo.total_transport_charges).toFixed(3);
    var price =
      orderDetails &&
      orderDetails.amountInfo &&
      parseFloat(orderDetails.amountInfo.total_adon_charges) +
      parseFloat(orderDetails.amountInfo.total_seperate_wash_charges) +
      parseFloat(orderDetails.amountInfo.total_express_delivery_charges);
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <Row className="d-flex justify-content-between">
                <div>
                  <h1 className="page_title mb-md-5">{t("commons.Order_Details")}</h1>
                </div>
                <div className="d-flex justify-content-end gap-2">
                  {orderDetails.is_easy_pickup == 0 ? (
                    <div>
                      {orderDetails && orderDetails.reorder_method == 1 && !this.state.is_reorder_loader && (
                        // <button className="btn-reorder ml-2" onClick={this.customerReorder.bind(this, orderDetails._id)}>
                        //   {t("commons.Reorder")}
                        // </button>
                        <StyledButtondiv4 onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                          {t("commons.Reorder")}
                        </StyledButtondiv4>
                      )}
                      {this.state.is_reorder_loader && <>
                        <button class="btn-reorder ml-2" type="button" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </button>
                      </>}
                    </div>
                  ) : ""}
                  {this.state.is_easy_pickup == 1 && orderDetails.is_easy_pickup_cancel == 0 && (orderDetails.payment_status == "pending" || orderDetails.payment_status == "partially paid") && orderDetails.order_items.length != 0 ? (
                    <div>
                      <button className="btn-track ml-2" onClick={(e) => this.easyPickupPayment("wallet", "yes", 0)}>
                        {t("commons.Pay_Now")}
                      </button>
                    </div>
                  ) : ("")}
                  {this.state.is_easy_pickup == 1 && this.state.cancel_button == true && orderDetails.is_easy_pickup_cancel == 0 && orderDetails.order_status != 'cancelled' ? (
                    <div>
                      <button className="btn-cancel mx-2" onClick={() => this.showCancelOrder(orderDetails.payment_status == "pending" ? 1 : 0)}>
                        {t("commons.Cancel_Order")}
                      </button>
                    </div>
                  ) : ("")}
                  {this.state.is_easy_pickup == 1 && orderDetails.is_express_delivery && orderDetails.payment_status == "pending" && orderDetails.order_items.length != 0 ? <div>
                    <button className="btn-cancel cancel_express_delivery mx-2" onClick={() => this.showCancelExpressDelivery()}>
                      {t("commons.Cancel_Express_delivery")}
                    </button>
                  </div> : ""}
                  {orderDetails && orderDetails.delivery_method != "normal" ? (
                    <>
                      {this.state.is_easy_pickup == 1 && this.state.customer_availability == 0 && (orderDetails && orderDetails.payment_status == "paid" && (orderDetails.order_status == "in_process" || orderDetails.order_status == "received")) ? (
                        <div style={{ marginLeft: "10px" }}>
                          <button className="btn-reorder ml-2" onClick={() => { this.togglePickUpTime() }}>
                            {t("commons.update_delivery_date_time")}
                          </button>
                        </div>
                      ) : ("")}
                    </>
                  ) : (null)}
                  <div>
                    {orderDetails && orderDetails.order_status == "created" && this.state.is_easy_pickup == 0 ? (
                      <button className="btn-cancel mx-2" onClick={() => this.showCancelOrder(0)}>
                        {t("commons.Cancel_Order")}
                      </button>
                    ) : (
                      ""
                    )}
                    {orderDetails &&
                      this.state.is_easy_pickup == 0 &&
                      orderDetails.payment_status == "pending" &&
                      orderDetails.paymentInfo.payment_method == "card" &&
                      orderDetails.order_status != "cancelled" &&
                      currentDatewithTime <= totalPickTimeLessOne ? (
                      <button className="btn-track ml-2" onClick={() => window.location.replace(orderDetails.paymentInfo.payment_url)}>
                        {t("commons.Pay_Now")}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Row>
              {/* <div id="reschedule">
                {orderDetails &&
                  orderdeliverydate &&
                  currentDate &&
                  orderDetails.order_status == "finished" &&
                  orderDetails.customer_ready_for_delivery == 0 &&
                  orderdeliverydate <= currentDate
                  ? (
                    <Row>
                      <Col>
                        <FormGroup className="mb-5">
                          <Label className="field_view_name pb-2">{t("commons.Are_you_available_to_accept_the_delivery?")}</Label>
                          <ul className="daycheck_type_list_sec rating">
                            {DeliveryOpts.map((item, index) => {
                              return (
                                <li key={index}>
                                  <Input
                                    type="radio"
                                    name="status"
                                    value={item.value}
                                    onClick={(event) => {
                                      if (event.target.value == "yes") {
                                        this.toggleDeliveryModal();
                                        // this.setState({ status: event.target.value }, () => this.handleDeliveryResponse());
                                        this.setState({ status: event.target.value });
                                      } else {
                                        this.toggleModal();
                                        this.setState({ status: event.target.value });
                                      }
                                    }}
                                  />
                                  <span className="daycheck_type_list" style={{ backgroundColor: item.value == "yes" ? "#339999" : "#ffa500", color: "white" }}>
                                    {t(item.label)}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </FormGroup>
                      </Col>{" "}
                    </Row>
                  ) : (
                    ""
                  )}
              </div> */}

              <Row className="border py-3" style={{ backgroundColor: "white" }}>
                <Col md="6">
                  <div className="leftpaneladdres">
                    <h5 className="mb-3">{t("commons.Vendor_Details")}</h5>
                    <div className="d-flex w-100">
                      <div className="vender-img">
                        <img
                          src={orderDetails && orderDetails.vendor && orderDetails.vendor.user_image_thumb_url}
                          alt={orderDetails && orderDetails.vendor && orderDetails.vendor.shop_name && orderDetails.vendor.shop_name[lang]}
                        ></img>
                      </div>
                      <div className="venderdetailsright">
                        <p>
                          <span>{t("commons.Shop_Name")} : </span>{" "}
                          {orderDetails && lang && orderDetails.vendor && orderDetails.vendor.shop_name && orderDetails.vendor.shop_name[lang]}
                        </p>

                        <p>
                          <span>{t("commons.Branch_Name")} : </span>{" "}
                          {orderDetails && lang && orderDetails.vendor && orderDetails.vendor.branch_name && orderDetails.vendor.branch_name[lang]}
                        </p>
                        <p>
                          <span>{t("commons.Phone_No.")} : </span> {orderDetails && orderDetails.vendor && orderDetails.vendor.phone}
                        </p>
                        <p>
                          <span>{t("labels.Address")} : </span>{" "}
                          <span className="text-break">
                            {orderDetails &&
                              orderDetails.vendor_address &&
                              orderDetails.vendor_address.appartment +
                              ", " +
                              orderDetails.vendor_address.street +
                              ", " +
                              orderDetails.vendor_address.landmark +
                              ", " +
                              orderDetails.vendor_address.city +
                              ", " +
                              orderDetails.vendor_address.state +
                              ", " +
                              orderDetails.vendor_address.country}
                          </span>
                        </p>

                        <Row>
                          <Col>
                            <FormGroup className="mb-5 mt-3">
                              <ul className="daycheck_type_list_sec rating">
                                {orderDetails && orderDetails.ratingInfo && orderDetails.order_status == "delivered" && orderDetails.ratingInfo.customer_to_vendor == null && (
                                  <li>
                                    <Input
                                      type="radio"
                                      name="feedback_type"
                                      value="rating"
                                      onClick={(event) => {
                                        var feedback_for = "vendor";

                                        this.setState(
                                          {
                                            feedback_type: event.target.value,
                                          },
                                          () =>
                                            this.showVendorRatingModal(
                                              feedback_for,
                                              orderDetails && orderDetails.vendor.user_image_thumb_url,
                                              orderDetails && orderDetails.vendor.shop_name && orderDetails.vendor.branch_name && orderDetails.vendor.shop_name[lang],
                                              orderDetails.vendor.branch_name[lang]
                                            )
                                        );
                                      }}
                                    />
                                    <span className="daycheck_type_list" style={{ backgroundColor: "#339999", color: "white", fontWeight: "500" }}>
                                      {t("commons.Review")}
                                    </span>
                                  </li>
                                )}

                                {orderDetails && orderDetails.reportInfo && orderDetails.order_status == "delivered" && orderDetails.reportInfo.customer_to_vendor == null && (
                                  <li>
                                    <Input
                                      type="checkbox"
                                      name="feedback_type"
                                      value="report"
                                      onClick={(event) => {
                                        var feedback_for = "vendor";

                                        this.setState(
                                          {
                                            feedback_type: event.target.value,
                                          },
                                          () =>
                                            this.showVendorReportModal(
                                              feedback_for,
                                              orderDetails && orderDetails.vendor.user_image_thumb_url,
                                              orderDetails && orderDetails.vendor.shop_name[lang]
                                            )
                                        );
                                      }}
                                    />
                                    <span className="daycheck_type_list" style={{ backgroundColor: "#ffa500", color: "white", fontWeight: "500" }}>
                                      {t("commons.Report")}
                                    </span>
                                  </li>
                                )}

                              </ul>
                            </FormGroup>
                          </Col>{" "}
                        </Row>
                        {/* ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <div className="leftpaneladdres">
                    <h5 className="mb-3">{t("commons.Order_Details")}</h5>
                    <div className="d-flex w-100">
                      <div className="venderdetailsright w-100 pl-0">
                        <p>
                          <span>
                            <strong>{t("commons.Order_No.")} : </strong>
                          </span>{" "}
                          <span className="themeColor" style={{ color: theme.colors.primary }}>{orderDetails && orderDetails.order_number}</span>
                        </p>
                        <p>
                          <span>
                            <strong> {t("commons.Order_Status")} : </strong>
                          </span>{" "}
                          <span className="themeColor" style={{ color: theme.colors.primary }}>{orderDetails && orderDetails.order_status && t(this.getOrderStatus(orderDetails.order_status))}</span>
                        </p>
                        <p>
                          <span>
                            <strong>{t("commons.Payment_Status")} : </strong>
                          </span>{" "}
                          <span style={{ textTransform: "capitalize", color: theme.colors.primary }}>
                            {orderDetails && orderDetails.payment_status && t(this.getPaymentStatus(orderDetails.payment_status))}
                          </span>
                        </p>
                        {orderDetails && orderDetails.is_easy_pickup == 1 && orderDetails.is_easy_pickup_cancel == 1 ? (
                          <p>
                            <span>
                              <strong>{t("commons.Transporation_Payment_status")} : </strong>
                            </span>{" "}
                            <span style={{ textTransform: "capitalize", color: theme.colors.primary }}>
                              {t("Paid")}
                            </span>
                          </p>
                        ) : ""}
                        <p>
                          <span>

                          </span>
                        </p>

                        <p>
                          <span>
                            <strong>{t("commons.Placed_on")} :</strong>
                          </span>{" "}
                          <span dir="ltr">{moment(orderDetails.createdAt).format("DD MMM YYYY hh:mm A")}</span>
                        </p>
                        {
                          orderDetails && orderDetails.with_or_without_trans == 1 ? (<>
                            <p>
                              <span>
                                <strong>{t("commons.Pickup_Date")}: </strong>
                              </span>
                              <span dir="ltr">
                                {moment(orderDetails.pickup_date).format("DD MMM YYYY")}
                                {", "}
                                {moment().format('YYYY-MM-DD[T00:00:00.000Z]') > '2022-12-21T00:00:00.000Z' ?
                                  orderDetails && orderDetails.pickup_time && this.OrderTimeFormat(orderDetails.pickup_time)
                                  :
                                  moment(orderDetails.pickup_time).utc().format("hh:mm A")
                                }
                              </span>
                            </p>
                            <p>
                              <span>
                                <strong>{t("commons.Delivery_Date_Time")}: </strong>
                              </span>
                              <span dir="ltr">
                                {" "}
                                {moment(orderDetails.delivery_date).format("DD MMM YYYY")}
                                {", "}
                                {moment().format('YYYY-MM-DD[T00:00:00.000Z]') > '2022-12-21T00:00:00.000Z' ?
                                  orderDetails && orderDetails.delivery_time && this.OrderTimeFormat(orderDetails.delivery_time)
                                  :
                                  moment(orderDetails.delivery_time).utc().format("hh:mm A")
                                }
                              </span>
                            </p>
                          </>) : (<>
                            <p>
                              <span style={{ color: 'red' }}>
                                <strong>{t("commons.Transportation")}: </strong>
                              </span>
                              <span style={{ color: 'red' }} dir="ltr">
                                {t("commons.No")}
                              </span>
                            </p>
                          </>)
                        }

                      </div>
                    </div>
                  </div>
                </Col>

                {
                  orderDetails && orderDetails.with_or_without_trans == 1 && (<>
                    <Col md="6" className="mt-3 pt-3 border-top">
                      <div className="leftpaneladdres pr-md-5">
                        <h5 className="mb-3">{t("commons.Pickup_Details")}</h5>
                        {orderDetails && orderDetails.is_accepted_pickup == 1 || (orderDetails.lyve_order_status1 == 0 && orderDetails.order_status != 'created') ? (
                          <div className="d-flex w-100">
                            <div className="vender-img">
                              {orderDetails.lyve_order_status1 == 1 ? <img src={orderDetails.pickup_person && orderDetails.pickup_person.user_image_thumb_url}></img> :
                                <img src="./assets/img/placeholder-user.jpg"></img>}
                            </div>
                            <div className="venderdetailsright">
                              <p>
                                <span>{t("commons.PickUp_Person")}: </span> {orderDetails.lyve_order_status1 == 1 ? orderDetails.pickup_person && orderDetails.pickup_person.firstName + " " + orderDetails.pickup_person.lastName : orderDetails.lyve_pickup_driver}
                              </p>
                              <p>
                                <span>{t("commons.Phone_No.")} : </span> {orderDetails.lyve_order_status1 == 1 ? orderDetails.pickup_person && orderDetails.pickup_person.phone : orderDetails.lyve_pickup_driver_phone}{" "}
                              </p>
                              <p>
                                <span>{t("labels.Address")} : </span>{" "}
                                {orderDetails.pickup_address.appartment +
                                  ", " +
                                  orderDetails.pickup_address.street +
                                  ", " +
                                  orderDetails.pickup_address.landmark +
                                  ", " +
                                  orderDetails.pickup_address.city +
                                  ", " +
                                  orderDetails.pickup_address.state +
                                  ", " +
                                  orderDetails.pickup_address.country}
                              </p>
                              {orderDetails && orderDetails.order_status == "picked_up" ? (
                                <Col md="4">
                                  <button
                                    className="btn-track"
                                    onClick={() =>
                                      this.props.history.push({
                                        pathname: "/track-order/" + this.state.orderId,
                                        state: {
                                          flag: 1,
                                        },
                                      })
                                    }
                                  >
                                    {t("commons.Track_Order")}
                                  </button>
                                </Col>
                              ) : (
                                ""
                              )}

                              <Row>
                                <Col>
                                  <FormGroup className="mb-5 mt-3">
                                    <ul className="daycheck_type_list_sec rating">
                                      {orderDetails &&
                                        orderDetails.ratingInfo &&
                                        orderDetails.order_status == "delivered" &&
                                        orderDetails.ratingInfo.customer_to_pickup_person == null && (
                                          <li>
                                            <Input
                                              type="radio"
                                              name="feedback_type"
                                              value="rating"
                                              onClick={(event) => {
                                                var feedback_for = "pickup_person";

                                                this.setState(
                                                  {
                                                    feedback_type: event.target.value,
                                                  },
                                                  () =>
                                                    this.showVendorRatingModal(
                                                      feedback_for,
                                                      orderDetails && orderDetails.pickup_person.user_image_thumb_url,
                                                      orderDetails.pickup_person.firstName,
                                                      orderDetails.pickup_person.lastName
                                                    )
                                                );
                                              }}
                                            />
                                            <span className="daycheck_type_list" style={{ backgroundColor: "#339999", color: "white", fontWeight: "500" }}>
                                              {t("commons.Review")}
                                            </span>
                                          </li>
                                        )}


                                    </ul>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ) : (
                          t("commons.Pick_Up_Driver_is_not_assigned_yet")
                        )}
                      </div>
                    </Col>
                    <Col md="6" className="mt-3 pt-3 border-top">
                      <div className="leftpaneladdres pr-md-5">
                        <h5 className="mb-3">{t("commons.Delivery_Details")}</h5>
                        {orderDetails && orderDetails.is_accepted_delivery == 1 || (orderDetails.lyve_order_status2 == 0 && (orderDetails.order_status == 'ready_to_deliver' || orderDetails.order_status == 'out_for_delivery' || orderDetails.order_status == 'delivered')) ? (
                          <div className="d-flex w-100">
                            <div className="vender-img">
                              {orderDetails.lyve_order_status2 == 1 ? <img src={orderDetails.delivery_person.user_image_thumb_url}></img>
                                : <img src="./assets/img/placeholder-user.jpg"></img>
                              }
                            </div>
                            <div className="venderdetailsright">
                              <p>
                                <span>{t("commons.Delivery_Person")}: </span> {orderDetails.lyve_order_status2 == 1 ? orderDetails.delivery_person.firstName + " " + orderDetails.delivery_person.lastName : orderDetails.lyve_delivery_driver}
                              </p>
                              <p>
                                <span>{t("commons.Phone_No.")} : </span> {orderDetails.lyve_order_status2 == 1 ? orderDetails.delivery_person.phone : orderDetails.lyve_delivery_driver_phone}{" "}
                              </p>
                              <p>
                                <span>{t("labels.Address")} : </span>{" "}
                                {orderDetails &&
                                  orderDetails.delivery_address &&
                                  orderDetails.delivery_address.appartment +
                                  ", " +
                                  orderDetails.delivery_address.street +
                                  ", " +
                                  orderDetails.delivery_address.landmark +
                                  ", " +
                                  orderDetails.delivery_address.city +
                                  ", " +
                                  orderDetails.delivery_address.state +
                                  ", " +
                                  orderDetails.delivery_address.country}
                              </p>
                              {orderDetails && orderDetails.order_status == "out_for_delivery" ? (
                                <Col md="4">
                                  <button
                                    className="btn-track"
                                    onClick={() =>
                                      this.props.history.push({
                                        pathname: "/track-order/" + this.state.orderId,
                                        state: {
                                          flag: 2,
                                        },
                                      })
                                    }
                                  >
                                    {t("commons.Track_Order")}
                                  </button>
                                </Col>
                              ) : (
                                ""
                              )}
                              <Row>
                                <Col>
                                  <FormGroup className="mb-5 mt-3">
                                    <ul className="daycheck_type_list_sec rating">
                                      {orderDetails &&
                                        orderDetails.ratingInfo &&
                                        orderDetails.order_status == "delivered" &&
                                        orderDetails.ratingInfo.customer_to_delivery_person == null && (
                                          <li>
                                            <Input
                                              type="radio"
                                              name="feedback_type"
                                              value="rating"
                                              onClick={(event) => {
                                                var feedback_for = "delivery_person";

                                                this.setState(
                                                  {
                                                    feedback_type: event.target.value,
                                                  },
                                                  () =>
                                                    this.showVendorRatingModal(
                                                      feedback_for,
                                                      orderDetails && orderDetails.delivery_person.user_image_thumb_url,
                                                      orderDetails.delivery_person.firstName,
                                                      orderDetails.delivery_person.lastName
                                                    )
                                                );
                                              }}
                                            />
                                            <span className="daycheck_type_list" style={{ backgroundColor: "#339999", color: "white", fontWeight: "500" }}>
                                              {t("commons.Review")}
                                            </span>
                                          </li>
                                        )}


                                    </ul>

                                  </FormGroup>
                                </Col>{" "}
                              </Row>
                              {/* ) : (
                            ""
                          )} */}
                            </div>
                          </div>
                        ) : (
                          t("commons.Delivery_driver_is_not_assigned_yet")
                        )}
                      </div>
                    </Col>
                  </>)
                }

                <Col md="12 mt-3 border-top pt-3">
                  <Row>
                    <Col md="12" className="mb-3">
                      <h5>{t("commons.Order_Items")}</h5>
                    </Col>
                    {orderDetails &&
                      orderDetails.order_items && orderDetails.order_items.length != 0 ?
                      orderDetails.order_items.map((item, index) => {
                        return (
                          <Col md={6} key={index}>
                            <div className={"catgoryproductouter " + (item.is_modify && item.is_modify == 1 ? "service_item_removed" : "service_item_newly_added")}>
                              <div>
                                <div className="d-flex">
                                  <div className="productimgleft">
                                    <img src={item.service.subcategory.image} alt={lang && item.service.subcategory.name[lang]}></img>
                                  </div>
                                  <div className="productdetails">
                                    <div className="d-flex justify-content-between p-2">
                                      <div>
                                        <h5 style={{ textTransform: "capitalize" }}>{lang && item.service.subcategory.name[lang]}</h5>
                                        <p>
                                          {t("labels.Category")} : {lang && item.service.category.name[lang]}{" "}
                                        </p>
                                      </div>
                                      {item.is_modify && item.is_modify == 1 ?
                                        <div>
                                          <p className="color-red">
                                            {t("Service Item Removed")}
                                          </p>
                                        </div>
                                        : ""
                                      }
                                      {item.is_modify && item.is_modify == 2 ?
                                        <div>
                                          <p className="color-green">
                                            {t("Service Item Newly Added")}
                                          </p>
                                        </div> : ""
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-md-12">
                                  <ul>
                                    <Slider {...sliderSettings}>
                                      {item.service_items.map((service_type, i) => {
                                        var totalprice = "";
                                        if (service_type.measure_type == 'piece') {
                                          totalprice = service_type.unit_quantity * service_type.price_by_measure + (service_type.unit_quantity * service_type.clothe_return_type.hanging_charge);
                                        } else {
                                          totalprice = service_type.unit_quantity * service_type.price_by_measure + service_type.clothe_return_type.hanging_charge;
                                        }
                                        return (
                                          <div className="dryclean" key={i}>
                                            <h5>{lang && service_type.service_type.name[lang]}</h5>
                                            <p>
                                              {t("commons.Qty")} :{" "}
                                              <span className="themeColor" style={{ color: theme.colors.primary }}>
                                                {service_type.measure_type === "weight"
                                                  ? parseInt(service_type.quantity_by_measure) / 1000 + " " + t("commons.KG.")
                                                  : service_type.measure_type === "meter"
                                                    ? service_type.quantity_by_measure + " " + t("commons.Sq_Mts.")
                                                    : service_type.quantity_by_measure + " " + t("commons.Pcs.")}
                                              </span>
                                            </p>
                                            {item.is_qty_modify && item.is_qty_modify != 0 ?
                                              <p className="color-red">{t("commons.qty_modify")} : {" "} <span className="color-red">
                                                {item.is_qty_modify + " " + t("commons.Pcs.")}
                                              </span></p>
                                              : ""}
                                            <p>
                                              {t("labels.Item_Return_Type")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{lang && service_type.clothe_return_type.name[lang]}</span>
                                            </p>{" "}
                                            <p>
                                              {" "}
                                              {service_type.measure_type == "piece"
                                                ? t("commons.Price_per_piece")
                                                : service_type.measure_type == "weight"
                                                  ? t("commons.Price_per_weight")
                                                  : t("commons.Price_per_square_meter")}
                                              : <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + service_type.price_by_measure.toFixed(3)}</span>
                                            </p>
                                            <p>
                                              {t("commons.Hanging_Charges")}:{" "}
                                              <span className="themeColor" style={{ color: theme.colors.primary }}>
                                                {orderDetails && orderDetails.amountInfo && orderDetails.amountInfo.total_hanging_charges != null && service_type.clothe_return_type.hanging_charge != null
                                                  ? t("commons.OMR") + " " + service_type.clothe_return_type.hanging_charge.toFixed(3)
                                                  : t("commons.OMR") + " " + "0.000"}
                                              </span>
                                            </p>{" "}
                                            {service_type.DiscountPrice != 0 && orderDetails.subcategory_coupon_is_applied == 1 ?
                                              <><p className="subcategory_discount">
                                                <span className="themeColor discount_latest">{" "}
                                                  {t("commons.Discount")} ({service_type.DiscountName ? service_type.DiscountName : null}):</span><span className="themeColor discount">{t("commons.OMR") + " " + service_type.DiscountPrice.toFixed(3)}</span>
                                              </p>
                                                <p>
                                                  {" "}
                                                  {t("commons.Total_Price")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + (totalprice.toFixed(3) - service_type.DiscountPrice.toFixed(3)).toFixed(3)}</span>
                                                </p></> : <p>
                                                {" "}
                                                {t("commons.Total_Price")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + totalprice.toFixed(3)}</span>
                                              </p>}

                                          </div>
                                        );
                                      })}
                                    </Slider>
                                  </ul>{" "}
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      }) : <Col md="12" className="mb-3">
                        {t("commons.Yet order items not updated by vendor")}
                      </Col>}
                  </Row>
                </Col>
                <hr />

                {/* for promotional package */}
                {orderDetails && ((orderDetails.package_used_details && Object.keys(orderDetails.package_used_details).length > 0) || orderDetails.other_used_pieces && Object.keys(orderDetails.other_used_pieces).length > 0) && (

                  <Col md="12 mt-3 border-top pt-3">
                    <Row>
                      <Col md="12" className="mb-3">
                        <h5>{t("commons.package_details")}</h5>
                      </Col>

                      <div className="package-detail">
                        <div className="package-order-deta-flex">
                          <div className="package-title-sec">{t("label_package_name") + ":"}</div>
                          <div className="package-content-sec">{PackageName}</div>
                        </div>
                        <div className="package-order-deta-flex">
                          <div className="package-title-sec">{t("label_package_category") + ":"}</div>
                          <div className="package-content-sec">{CategoryName}</div>
                        </div>

                        {orderDetails.package_used_details && Object.keys(orderDetails.package_used_details).length > 0 ? (
                          orderDetails.package_used_details.map(function (index, value) {
                            return (
                              <>
                                <div className="package-order-deta-flex">
                                  <div className="package-title-sec">{t("label_package_subcategory") + ":"}</div>
                                  <div className="package-content-sec">{index.name[lang]}</div>
                                  <div className="pack-count"> {"(" + t("package-used") + " " + index.usedcount + " " + t("promotion_pieces_count") + ")"}</div>
                                </div>
                                <div className="package-order-deta-flex">
                                  <div className="package-title-sec">{index.name[lang] + " " + t("label_package_remain_count") + ":"}</div>
                                  <div className="package-content-sec">{index.remainCount + " " + t("promotion_pieces_count")}</div>
                                </div>
                              </>
                            )
                          })
                        ) : (
                          <></>
                        )
                        }
                        {orderDetails.other_used_pieces && Object.keys(orderDetails.other_used_pieces).length > 0 && orderDetails.other_used_pieces.map(function (index, value) {
                          return (
                            <>
                              <div className="package-order-deta-flex">
                                <div className="package-title-sec">{index.package_title[lang] + " " + t('promotion_other_pieces') + " : "}</div>
                                <div className="package-content-sec">{index.other_pieces + " " + t("promotion_pieces_count")}</div>
                                <div className="pack-count"> {"(" + t("package-used") + " " + index.other_used_pieces + " " + t("promotion_pieces_count") + ")"}</div>
                              </div>
                              <div className="package-order-deta-flex">
                                <div className="package-title-sec">{index.package_title[lang] + " " + t('label_package_remain_count') + " " + t('menu.OtherPieces') + ":"}</div>
                                <div className="package-content-sec">{index.Remaining_pieces + " " + t("promotion_pieces_count")}</div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </Row>
                  </Col>

                )}


                {/* for corporate package */}
                {orderDetails && ((orderDetails.Corporate_other_used_pieces && Object.keys(orderDetails.Corporate_other_used_pieces).length > 0) || orderDetails.Corporate_package_used_details && Object.keys(orderDetails.Corporate_package_used_details).length > 0) && (

                  <Col md="12 mt-3 border-top pt-3">
                    <Row>
                      <Col md="12" className="mb-3">
                        <h5>{t("commons.corporate_package_details")}</h5>
                      </Col>

                      <div className="package-detail">
                        <div className="package-order-deta-flex">
                          <div className="package-title-sec">{t("label_package_name") + ":"}</div>
                          <div className="package-content-sec">{CorporatePackageName}</div>
                        </div>
                        <div className="package-order-deta-flex">
                          <div className="package-title-sec">{t("label_package_category") + ":"}</div>
                          <div className="package-content-sec">{CorporateCategoryName}</div>
                        </div>

                        {orderDetails.Corporate_package_used_details && Object.keys(orderDetails.Corporate_package_used_details).length > 0 ? (
                          orderDetails.Corporate_package_used_details.map(function (index, value) {
                            return (
                              <>
                                <div className="package-order-deta-flex">
                                  <div className="package-title-sec">{t("label_package_subcategory") + ":"}</div>
                                  <div className="package-content-sec">{index.name[lang]}</div>
                                  <div className="pack-count"> {"(" + t("package-used") + " " + index.usedcount + " " + t("promotion_pieces_count") + ")"}</div>
                                </div>
                                <div className="package-order-deta-flex">
                                  <div className="package-title-sec">{index.name[lang] + " " + t("label_package_remain_count") + ":"}</div>
                                  <div className="package-content-sec">{index.remainCount + " " + t("promotion_pieces_count")}</div>
                                </div>
                              </>
                            )
                          })
                        ) : (
                          <></>
                        )
                        }
                        {orderDetails.Corporate_other_used_pieces && Object.keys(orderDetails.Corporate_other_used_pieces).length > 0 && orderDetails.Corporate_other_used_pieces.map(function (index, value) {
                          return (
                            <>
                              <div className="package-order-deta-flex">
                                <div className="package-title-sec">{index.package_title[lang] + " " + t('promotion_other_pieces') + " : "}</div>
                                <div className="package-content-sec">{index.other_pieces + " " + t("promotion_pieces_count")}</div>
                                <div className="pack-count"> {"(" + t("package-used") + " " + index.other_used_pieces + " " + t("promotion_pieces_count") + ")"}</div>
                              </div>
                              <div className="package-order-deta-flex">
                                <div className="package-title-sec">{index.package_title[lang] + " " + t('menu.OtherPieces') + " " + t('label_package_remain_count') + ":"}</div>
                                <div className="package-content-sec">{index.Remaining_pieces + " " + t("promotion_pieces_count")}</div>
                              </div>
                            </>
                          )
                        })}
                      </div>


                    </Row>
                  </Col>

                )}


                <hr />

                {this.state.is_easy_pickup == 1 ? (
                  <>
                    {this.state.vendor_comments != "" ?
                      <Col md={6} className="mt-3 pt-3 border-top col-md-6">
                        <Col md="12" className="mb-3">
                          <h5>{t("commons.comments")}</h5>
                        </Col>
                        <div className="selectpoints border-top mt-2 pricebekup">
                          <h6>
                            {this.state.vendor_comments}
                          </h6>
                        </div>
                      </Col>
                      : (null)
                    }
                    {this.state.vendor_image && this.state.vendor_image.length != 0 ?
                      <Col md={6} className="mt-3 pt-3 border-top col-md-6">
                        <Col md="12" className="mb-3">
                          <h5>{t("commons.image")}</h5>
                        </Col>
                        <div className="mt-2 display_flex flex-wrap-wrap">
                          {this.state.vendor_image.map((vendor_image_temp) => {
                            return (
                              <div className="vendor_image" style={{ cursor: 'pointer' }}>
                                <img src={vendor_image_temp} onClick={(e) => { this.image_open(vendor_image_temp) }}></img>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                      : (null)}
                  </>
                ) : (null)}
                {(this.state.adon_type && this.state.adon_type.length != 0) || this.state.adon_others || (orderDetails && orderDetails.is_express_delivery) ?
                  <Col md={6} className="mt-3 pt-3 border-top col-md-6">
                    <Col md="12" className="mb-3">
                      <h5>{t("commons.Special-Request")}</h5>
                    </Col>
                    {(this.state.adon_type && this.state.adon_type.length != 0) || this.state.adon_others ?
                      <div className="selectpoints border-top mt-2 pricebekup">
                        {this.state.adon_type ? (
                          <h6>
                            {this.state.adon_type.join(",")}
                          </h6>
                        ) : (
                          ""
                        )}
                        {this.state.adon_others ? (
                          <h6>
                            {t("commons.Others")} {this.state.adon_others}
                          </h6>
                        ) : (
                          ""
                        )}
                      </div> : ""}
                    {orderDetails && orderDetails.is_express_delivery ? (
                      <div className="selectpoints border-top mt-2 pricebekup">
                        <h6>
                          <p> {t("labels.Express_Delivery")}:</p> <span className="order_inf_layout">{orderDetails.is_express_delivery ? t("commons.Yes") : t("commons.No")}</span>
                          <span>
                            {orderDetails.amountInfo && orderDetails.amountInfo ? t("commons.OMR") + " " + orderDetails.amountInfo.total_express_delivery_charges.toFixed(3) : ""}
                          </span>
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                  : (null)}
                {/* <Col md={6}>
                <Col md="12" className="mb-3">
                    <h5>{t("commons.Selected_AddOns")}</h5>
                  </Col>
                  <div className="selectpoints border-top mt-2 pricebekup">
                    {orderDetails && orderDetails.is_express_delivery ? (
                      <h6>
                        <p> {t("labels.Express_Delivery")}:</p> <span className="order_inf_layout">{orderDetails.is_express_delivery ? t("commons.Yes") : t("commons.No")}</span>
                        <span>
                          {orderDetails.amountInfo && orderDetails.amountInfo ? t("commons.OMR") + " " + orderDetails.amountInfo.total_express_delivery_charges.toFixed(3) : ""}
                        </span>
                      </h6>
                    ) : (
                      ""
                    )}

                    {orderDetails && orderDetails.is_seperate_wash ? (
                      <h6>
                        <p> {t("commons.Separate_Wash")}:</p> <span className="order_inf_layout">{orderDetails.is_seperate_wash ? t("commons.Yes") : t("commons.No")}</span>
                        <span>
                          {orderDetails.amountInfo && orderDetails.amountInfo ? t("commons.OMR") + " " + orderDetails.amountInfo.total_seperate_wash_charges.toFixed(3) : ""}
                        </span>
                      </h6>
                    ) : (
                      ""
                    )}

                    {orderDetails && orderDetails.bleach ? (
                      <h6>
                        <p> {t("commons.Bleach")}:</p> <span className="order_inf_layout">{lang && orderDetails.bleach.name[lang]}</span>
                        <span>{t("commons.OMR") + " " + orderDetails.bleach.price.toFixed(3)}</span>
                      </h6>
                    ) : (
                      ""
                    )}
                    {orderDetails && orderDetails.perfume ? (
                      <h6>
                        <p>{t("commons.Perfume")}: </p> <span className="order_inf_layout">{lang && orderDetails.perfume.name[lang]}</span>
                        <span>{t("commons.OMR") + " " + orderDetails.perfume.price.toFixed(3)}</span>
                      </h6>
                    ) : (
                      ""
                    )}
                    {orderDetails && orderDetails.softener ? (
                      <h6>
                        <p> {t("commons.Softener")}: </p>
                        <span className="order_inf_layout">{lang && orderDetails.softener.name[lang]}</span>
                        <span>{t("commons.OMR") + " " + orderDetails.softener.price.toFixed(3)}</span>
                      </h6>
                    ) : (
                      ""
                    )}
                    {orderDetails && orderDetails.shampoo ? (
                      <h6>
                        <p> {t("commons.Shampoo")}:</p> <span className="order_inf_layout">{lang && orderDetails.shampoo.name[lang]}</span>
                        <span>{t("commons.OMR") + " " + orderDetails.shampoo.price.toFixed(3)}</span>
                      </h6>
                    ) : (
                      ""
                    )}

                    {orderDetails && orderDetails.sanitizer ? (
                      <h6>
                        <p>{t("commons.Sanitizer")}:</p> <span className="order_inf_layout">{lang && orderDetails.sanitizer.name[lang]}</span>
                        <span>{t("commons.OMR") + " " + orderDetails.sanitizer.price.toFixed(3)}</span>
                      </h6>
                    ) : (
                      ""
                    )}

                    {orderDetails && orderDetails.amountInfo ? (
                      <h6>
                        <strong>{t("commons.Total_Additional_Charges")} : </strong>
                        <strong>
                          <span style={{ color: "black" }}>{price && price > 0 ? t("commons.OMR") + " " + price.toFixed(3) : t("commons.OMR") + " 0.000"}</span>
                        </strong>
                      </h6>
                    ) : (
                      ""
                    )}
                  </div>
                </Col> */}
                <Col md={6} className="mt-3 pt-3 border-top col-md-6">
                  <div className="pricebekup">
                    <h4>{t("commons.Price_Breakup")}</h4>
                    <div className="orderprice">
                      <p>
                        {t("commons.Sub_Total")}:{" "}
                        {orderDetails && orderDetails.subcategory_coupon_is_applied == 1 ?
                          <span className="themeColor" style={{ color: theme.colors.primary }}>{orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + (orderDetails.total_vendor_subcategory_discount_bearcost + orderDetails.total_admin_subcategory_discount_bearcost + orderDetails.amountInfo.sub_total).toFixed(3)}</span>
                          :
                          <span className="themeColor" style={{ color: theme.colors.primary }}>{orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + orderDetails.amountInfo.sub_total.toFixed(3)}</span>
                        }
                      </p>
                      {orderDetails && orderDetails.amountInfo && orderDetails && orderDetails.amountInfo.remaining_amount > 0 ?
                        <p style={{ color: "red" }}>{t("commons.remaining_amount")}:{""}<span>{t("commons.OMR") + " " + (orderDetails.amountInfo.remaining_amount).toFixed(3)}</span></p>
                        : ""}
                      <p>
                        {t("commons.Discount")}{orderDetails && orderDetails.promocodeInfo && "(" + orderDetails.promocodeInfo.promocode + ")"}:{" "}
                        <span className="themeColor" style={{ color: "red" }}>
                          {orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + orderDetails.amountInfo.discounted_amount.toFixed(3)}
                        </span>
                      </p>
                      {orderDetails && orderDetails.subcategory_coupon_is_applied == 1 &&
                        <p>
                          {t("commons.SubcategoryDiscount")}:{" "}
                          <span className="themeColor" style={{ color: "red" }}>
                            {orderDetails && orderDetails.total_vendor_subcategory_discount_bearcost != undefined && orderDetails.total_admin_subcategory_discount_bearcost != undefined ? t("commons.OMR") + " " + (orderDetails.total_vendor_subcategory_discount_bearcost + orderDetails.total_admin_subcategory_discount_bearcost).toFixed(3) : 0.000}
                          </span>
                        </p>}
                      <p>
                        {t("commons.Order_VAT")}:{" "}
                        <span className="themeColor" style={{ color: theme.colors.primary }}>
                          {orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + orderDetails.amountInfo.order_vat_amount.toFixed(3)}
                        </span>
                      </p>

                      {orderDetails && orderDetails.is_transport_free == 1 ? (
                        <p>
                          {t("commons.Total_Transport_Charges")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + "0.000"}</span>
                        </p>
                      ) : (
                        <p>
                          {t("commons.Total_Transport_Charges")}:{" "}
                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                            {orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + (orderDetails.amountInfo.total_transport_charges.toFixed(3) - orderDetails.total_customer_freedelivery_bearcost != undefined ? orderDetails.total_customer_freedelivery_bearcost.toFixed(3) : 0)}
                          </span>
                        </p>
                      )}

                      {orderDetails && orderDetails.is_transport_free == 1 ? (
                        <p>
                          {t("commons.Transport_VAT")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + "0.000"}</span>
                        </p>
                      ) : (
                        <p>
                          {t("commons.Transport_VAT")}:{" "}
                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                            {orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + orderDetails.amountInfo.transport_vat_amount.toFixed(3)}
                          </span>
                        </p>
                      )}

                      <p>
                        {t("commons.Laundry_Bag_Charges")}:{" "}
                        <span className="themeColor" style={{ color: theme.colors.primary }}>
                          {orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + orderDetails.amountInfo.total_laundry_bag_charges.toFixed(3)}
                        </span>
                      </p>
                      {orderDetails.wallet_used && orderDetails.wallet_used == "yes" ? (
                        <p>
                          {t("commons.epocket_used")}:{" "}
                          <span className="themeColor" style={{ color: "red" }}>
                            {orderDetails && orderDetails.walletAmount && t("commons.OMR") + " " + orderDetails.walletAmount.toFixed(3)}
                          </span>
                        </p>
                      ) : (
                        <></>
                      )
                      }
                      <p className="totalp">
                        <strong>{t("commons.Total")} :</strong>{" "}
                        <strong>
                          <span>{orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + orderDetails.amountInfo.total_amount_paid_by_customer.toFixed(3)}</span>
                        </strong>
                      </p>
                    </div>
                  </div>
                  {orderDetails && orderDetails.is_easy_pickup == 1 && orderDetails.is_easy_pickup_cancel == 1 ? (
                    <div>
                      <div className="orderprice">
                        <p className="totalp" style={{ border: 'none' }}>
                          <span>{t("commons.Total_Transport_Charges_paid")} :</span>{" "}
                          <span>
                            <span>{orderDetails && orderDetails.amountInfo && t("commons.OMR") + " " + (orderDetails && orderDetails.amountInfo && (orderDetails.amountInfo.total_transport_charges.toFixed(3) - orderDetails.total_customer_freedelivery_bearcost != undefined ? orderDetails.total_customer_freedelivery_bearcost == 0 ? orderDetails.amountInfo.total_transport_charges.toFixed(3) : orderDetails.total_customer_freedelivery_bearcost.toFixed(3) : 0))}</span>
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : ""}
                </Col>
              </Row>
            </div>

            {/* Modal for delivery status */}
            <Modal
              isOpen={this.state.showModal}
              backdrop={"static"}
              keyboard={false}
              toggle={() => {
                this.toggleAddServiceItem();
              }}
            >
              <Formik
                enableReinitialize
                initialValues={{
                  delivery_date: this.state.delivery_date,
                  delivery_time: this.state.delivery_time,
                }}
                validationSchema={ServiceTypeFormSchema}
                onSubmit={this.handleDeliveryResponse}
              >
                {({ handleAddServiceItem, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="filter_modal_header">
                      <span onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                        {t("commons.Cancel")}
                      </span>
                      <div className="filter_modal_heading">{t("commons.Reschedule_Delivery")}</div>
                      <button type="submit" style={{ cursor: "pointer" }} className="btn1">
                        {t("buttons.submit")}
                      </button>
                    </div>

                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <Label className="mb-3 mt-3">{t("commons.Reschedule_Delivery_Date")}</Label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.delivery_date}
                        autoComplete="off"
                        name="delivery_date"
                        onChange={(date) => {
                          this.setState({
                            delivery_date: date,
                            delivery_specific_new_date: date.getDay()
                          },
                            () => this.handleMinTime_delivery(this.state.delivery_date)
                          );
                        }}
                        minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                        placeholderText="MM/DD/YYYY"
                        isClearable={this.state.delivery_date ? true : false}
                      />
                      {errors.delivery_date && touched.delivery_date ? <div className="invalid-feedback d-block">{t(errors.delivery_date)}</div> : null}
                    </FormGroup>

                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <Label className="mb-3 mt-3">{t("commons.Reschedule_Delivery_Time")}</Label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.delivery_time}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        autoComplete="off"
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        minTime={this.state.minTime_delivery_update}
                        maxTime={moment().endOf("day").toDate()}
                        filterTime={this.filterPassedTime}
                        placeholderText={t("commons.Select_Time")}
                        onChange={(date) =>
                          this.setState({
                            delivery_time: new Date(date).getTime(),
                          })
                        }
                      />
                      {errors.delivery_time && touched.delivery_time ? <div className="invalid-feedback d-block">{t(errors.delivery_time)}</div> : null}
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Modal>

            {/* Modal for only Delivery time update */}
            <Modal
              isOpen={this.state.showdeliverytimepicker}
              backdrop={"static"}
              keyboard={false}
              toggle={() => {
                this.toggleAddServiceItem();
              }}
            >
              <Formik
                enableReinitialize
                initialValues={{
                  delivery_time: this.state.delivery_time,
                }}
                // validationSchema={ServiceTypeFormSchema}
                onSubmit={this.handleDeliveryResponse}
              >
                {({ handleAddServiceItem, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="filter_modal_header">
                      <span onClick={() => this.hideDeliveryModal()} style={{ cursor: "pointer" }} className="btn1">
                        {t("commons.Cancel")}
                      </span>
                      <div className="filter_modal_heading">{t("commons.Reschedule_Delivery")}</div>
                      <button type="submit" style={{ cursor: "pointer" }} className="btn1">
                        {t("buttons.submit")}
                      </button>
                    </div>
                    {this.state.vendornotavailabledate.length > 0 ? <div className="custom-text-display">
                      <p>{t("vendor is not available on these dates ") + this.state.vendornotavailabledate.join(", ") + ". Please choose timing for the date " + this.state.vendoravailabledate}</p>
                    </div>
                      : <></>}
                    {/* {deliverytimetemp == 1 && deliverytimestatus == false ? */}
                    {deliverytimetemp == 1 && deliverytimestatus == false ?
                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <Label className="mb-3 mt-3">{t("commons.Reschedule_Delivery_Time")}</Label>
                        <DatePicker
                          className="form-control"
                          selected={this.state.delivery_time}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          autoComplete="off"
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          // minTime={this.state.minTime_delivery_update}
                          // maxTime={moment().endOf("day").toDate()}
                          filterTime={this.filterPassedTime__delivery}
                          placeholderText={t("commons.Select_Time")}
                          onChange={(date) =>
                            this.setState({
                              delivery_time: new Date(date).getTime(),
                            })
                          }
                        />
                        {errors.delivery_time && touched.delivery_time ? <div className="invalid-feedback d-block">{t(errors.delivery_time)}</div> : null}
                      </FormGroup>
                      :
                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <Label className="mb-3 mt-3">{t("commons.Reschedule_Delivery_Time")}</Label>
                        <DatePicker
                          className="form-control"
                          selected={this.state.delivery_time}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          autoComplete="off"
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          minTime={this.state.minTime_delivery_update}
                          maxTime={moment().endOf("day").toDate()}
                          filterTime={this.filterPassedTimeOld__delivery}
                          placeholderText={t("commons.Select_Time")}
                          onChange={(date) =>
                            this.setState({
                              delivery_time: new Date(date).getTime(),
                            })
                          }
                        />
                        {errors.delivery_time && touched.delivery_time ? <div className="invalid-feedback d-block">{t(errors.delivery_time)}</div> : null}
                      </FormGroup>
                    }

                  </Form>
                )}
              </Formik>
            </Modal>

            {/* Modal for rating the vendor */}
            <Modal isOpen={this.state.showModalRating} backdrop={"static"} keyboard={false}>
              <Formik
                enableReinitialize
                initialValues={{
                  feedback_user_type: this.state.feedback_user_type,
                  comments: this.state.comments,
                  ratings: this.state.ratings,
                }}
                validationSchema={RatingFormSchema}
                onSubmit={this.handleRating}
              >
                {({ handleRating, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="filter_modal_header">
                      <span onClick={() => this.hideVendorRatingModal()} style={{ cursor: "pointer" }} className="btn2">
                        {t("commons.Cancel")}
                      </span>
                      <button type="submit" style={{ cursor: "pointer" }} className="btn2">
                        {t("commons.Submit")}
                      </button>
                    </div>

                    <center>
                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <img src={this.state.image} style={{ width: "130px" }}></img>
                      </FormGroup>
                    </center>

                    <center>
                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        {this.state.feedback_user_type && this.state.feedback_user_type == "vendor" ? (
                          <>
                            <h5>{this.state.name}</h5>
                            <h6>{this.state.lastname && this.state.lastname ? " " + this.state.lastname : ""}</h6>
                          </>
                        ) : (
                          <h5>
                            {this.state.name}

                            {this.state.lastname && this.state.lastname ? " " + this.state.lastname : ""}
                          </h5>
                        )}
                      </FormGroup>
                    </center>

                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <center>
                        <StarRatings
                          rating={this.state.ratings}
                          name="rating"
                          starRatedColor="blue"
                          changeRating={(e) => this.setState({ ratings: e })}
                          numberOfStars={5}
                          starHoverColor="#007bff"
                          starDimension="40px"
                          // starRatedColor="#007bff"
                          starSpacing="2px"
                        />
                      </center>
                      {errors.ratings && touched.ratings ? <div className="invalid-feedback d-block">{t(errors.ratings)}</div> : null}
                    </FormGroup>
                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <Field
                        className="form-control"
                        name="comments"
                        as="textarea"
                        rows="5"
                        placeholder={t("commons.Write_your_comments_here(optional)")}
                        value={this.state.comments}
                        onChange={(e) => this.setState({ comments: e.target.value })}
                      />

                      {errors.comments && touched.comments ? <div className="invalid-feedback d-block">{t(errors.comments)}</div> : null}
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Modal>

            <Modal isOpen={this.state.showReportRating} backdrop={"static"} keyboard={false}>
              <Formik
                enableReinitialize
                initialValues={{
                  feedback_user_type: this.state.feedback_user_type,
                  comments: this.state.comments,
                }}
                validationSchema={RatingReportFormSchema}
                onSubmit={this.handleReport}
              >
                {({ handleRating, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                  <Form>
                    <div className="filter_modal_header">
                      <span onClick={() => this.hideVendorReportModal()} style={{ cursor: "pointer" }} className="btn2">
                        {t("commons.Cancel")}
                      </span>
                      <button type="submit" style={{ cursor: "pointer" }} className="btn2">
                        {t("commons.Submit")}
                      </button>
                    </div>

                    <center>
                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <img src={this.state.image}></img>
                      </FormGroup>
                    </center>

                    <center>
                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <h5>{this.state.name}</h5>
                      </FormGroup>
                    </center>

                    <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                      <Field
                        className="form-control"
                        name="comments"
                        as="textarea"
                        rows="5"
                        placeholder={t("commons.Write_your_comments_here")}
                        value={this.state.comments}
                        onChange={(e) => this.setState({ comments: e.target.value })}
                      />

                      {errors.comments && touched.comments ? <div className="invalid-feedback d-block">{t(errors.comments)}</div> : null}
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </Modal>
            {/* for order cancel pop up */}
            <Modal isOpen={this.state.isCancelled} backdrop={"static"} keyboard={false}>
              <ModalBody>
                <h6>{t("commons.Are_you_sure_want_to_cancel_the_order?")}</h6>
              </ModalBody>
              <ModalFooter>
                <div>
                  <button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    className="btn"
                    onClick={() => {
                      this.cancelOrder();
                      this.setState({ isCancelled: false });
                    }}
                  >
                    {t("commons.OK")}
                  </button>
                  <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isCancelled: false })}>
                    {t("commons.Cancel")}
                  </button>
                </div>
              </ModalFooter>
            </Modal>
            {/* for easy pickup order cancel pop up */}
            <Modal isOpen={this.state.isEasyPickupCancelled} backdrop={"static"} keyboard={false}>
              {/* {console.log(, 'sdsd')} */}
              <ModalBody>
                <h5 style={{ lineHeight: "25px", fontSize: "17px", marginTop: '15px' }}>
                  {this.state.lang == 'en' ? "You need to pay the transportation amount " + (orderDetails && orderDetails.amountInfo && (orderDetails.amountInfo.total_transport_charges.toFixed(3) - orderDetails.total_customer_freedelivery_bearcost != undefined ? orderDetails.total_customer_freedelivery_bearcost == 0 ? orderDetails.amountInfo.total_transport_charges.toFixed(3) : orderDetails.total_customer_freedelivery_bearcost.toFixed(3) : 0)) + " OMR, if you want to cancel the order" : "عليك أن تدفع مبلغ النقل" + (orderDetails && orderDetails.amountInfo && (orderDetails.amountInfo.total_transport_charges.toFixed(3) - orderDetails.total_customer_freedelivery_bearcost != undefined ? orderDetails.total_customer_freedelivery_bearcost == 0 ? orderDetails.amountInfo.total_transport_charges.toFixed(3) : orderDetails.total_customer_freedelivery_bearcost.toFixed(3) : 0)) + "ريال عماني، إذا كنت ترغب في إلغاء الطلب"}
                </h5>
              </ModalBody>
              <button style={{ position: 'absolute', right: '20px', top: '20px', fontSize: '30px', color: "red" }} onClick={(e) => { this.setState({ isEasyPickupCancelled: false }) }} type="button" class="close" aria-label="Close"><span aria-hidden="true">×</span></button>
              <ModalFooter style={{ justifyContent: 'space-around', alignContent: 'center', border: 'none' }}>
                {this.state.show_wallet ? (
                  <button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    className="btn"
                    onClick={() => {
                      this.easyPickupPayment("wallet", "yes", 1);
                    }}
                  >
                    {t("Pay By Wallet")}
                  </button>) : (
                  <button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    className="btn"
                    onClick={() => {
                      this.TopupWallet();
                    }}
                  >
                    {t("buttons.Topup Wallet")}
                  </button>
                )
                }
                <p style={{ marginBottom: '0' }} className="esy-pckip-tspt">{t("or")}</p>
                {this.state.isLoading ? <div style={{ margin: '0 54px' }} className="loading" /> : (
                  <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.easyPickupPayment("card", "no", 1)}>
                    {t("commons.Pay By Card")}
                  </button>
                )}
              </ModalFooter>
            </Modal>
            {/* Modal for Delivery Time Update */}
            <Modal isOpen={this.state.showtimepicker} backdrop={"static"} keyboard={false} toggle={this.togglePickUpTime} className="custom-edit-pickup-delivery-time">
              <ModalHeader>
                <h4>{t("commons.update_delivery_date_time")}</h4>
              </ModalHeader>
              <ModalBody>
                <Formik
                  initialValues={{
                    delivery_specific_date: this.state.delivery_specific_date,
                  }}
                >
                  {({ handleSubmitUpdateTimings, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Row className="align-items-center justify-content-space-between">
                        <div className="order-pickup_date">
                          <FormGroup className="form-group has-float-label">
                            <Label className="d-block">
                              {t("labels.delivery_date")}{Date.parse(new Date()) > Date.parse(new Date(orderDetails.delivery_date)) ? moment(orderDetails.delivery_date).format("DD MMM YYYY") : ""}
                            </Label>
                            <DatePicker
                              className="form-control"
                              name="deliverydate"
                              selected={this.state.new_pickup_date}
                              minDate={this.state.new_pickup_date}
                              maxDate={new Date(this.state.max_delivery_date_for_popup)}
                              onChange={(date) => {
                                let delivery_date = new Date(date);
                                this.setState({
                                  new_pickup_date: new Date(delivery_date),
                                  delivery_specific_date: delivery_date.getDay(),
                                  err_admin_pickup_delivery_date: ""
                                });
                              }}
                            />
                          </FormGroup>
                        </div>
                        <div>
                          <FormGroup className="form-group has-float-label">
                            <Label className="d-block">
                              {t("labels.deliverytime")}
                            </Label>
                            <DatePicker
                              className="form-control"
                              name="endtime"
                              selected={new Date(this.state.delivery_time_new)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              timeFormat="hh:mm a"
                              dateFormat="hh:mm a"
                              minTime={new Date(this.state.static_minTime_delivery)}
                              maxTime={new Date(this.state.static_minTime_delivery).setHours(23, 59, 59, 999)}
                              onChange={(date) => {
                                let delivery_time = new Date(date);
                                this.setState({
                                  delivery_time_new: new Date(delivery_time).getTime()
                                });
                              }}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <div className="pickup_delivery_button">
                        <div className="freedeliverytimingdiv">
                          <button className="btn-pickup-time" onClick={() => { this.togglePickUpTime() }}>
                            {t("pages.cancel")}
                          </button>{" "}
                        </div>
                        <div className="freedeliverytimingdiv">
                          <button className="btn-pickup-time" type="submit" onClick={() => this.handleSubmitUpdateTimings()}>
                            {t("pages.submit")}
                          </button>{" "}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </ModalBody>
            </Modal>

            {/* Modal for payment popup */}
            <Modal isOpen={this.state.is_payment_popup} className="custom-edit-pickup-delivery-time">
              <ModalHeader>
                <h4>{t("commons.Topup Wallet")}</h4>
              </ModalHeader>
              <button onClick={(e) => { this.setState({ is_payment_popup: false }) }} type="button" class="close easy-pickup-close" aria-label="Close"><span aria-hidden="true">×</span></button>
              <ModalBody>
                <div className="display_flex align-items-center justify-content-space-between">
                  <button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    className="btn"
                    onClick={() => {
                      this.TopupWallet();
                    }}
                  >
                    {t("buttons.Topup Wallet")}
                  </button>
                  <p>Or</p>
                  {this.state.isLoading ? <div style={{ margin: '0 54px' }} className="loading" /> : (
                    <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.easyPickupPayment("card", "no", 0)}>
                      {t("commons.Pay By Card")}
                    </button>
                  )}
                </div>
              </ModalBody>
            </Modal>

            <Modal isOpen={this.state.showCancelExpressDeliveryModal} backdrop={"static"} keyboard={false}>
              <ModalBody>
                <h6>{t("validations.Are_you_sure_want_to_cancel_the_express_delivery?")}</h6>
              </ModalBody>
              <ModalFooter>
                <div>
                  <Button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    onClick={(e) => {
                      this.cancelExpressDelivery();
                      // this.setState({ showCancelExpressDeliveryModal: false });
                    }}
                  >
                    {t("OK")}
                  </Button>
                  <Button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} onClick={() => this.setState({ showCancelExpressDeliveryModal: false })}>
                    {t("pages.cancel")}
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
          </>
        )}
      </Translation>
    );
  }
}
