import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Input, Button, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";
import i18next from "i18next";

const Genders = [
  { value: "", label: "selects.select" },
  { value: "male", label: "commons.Male" },
  { value: "female", label: "commons.Female" },
];

const phoneRegExp = /^[1-9][0-9]{6,15}$/;

const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,15}$/;
const nameRegex = /^[A-Za-z]+$/;

const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("commons.Please_enter_first_name")
    .min(2, "commons.Fisrt_Name_must_contain_atleast_2_letters.")
    .max(30, "commons.First_name_must_contain_atmost_30_letters.")
    .matches(nameRegex, "commons.First_Name_accepts_only_alphabets"),
  lastName: Yup.string()
    .required("commons.Please_enter_last_name")
    .min(2, "commons.Last_name_must_contain_atleast_2_letters.")
    .max(30, "commons.Last_name_must_contain_atmost_30_letters.")
    .matches(nameRegex, "commons.Last_Name_accepts_only_alphabets"),
  gender: Yup.string().required("commons.Please_select_gender"),

  email: Yup.string().required("commons.Please_enter_email_address").email("commons.Please_enter_valid_email_id").max(50, "commons.Please_enter_email_id_with_atmost_50_letters"),
  phone: Yup.string()
    .required("commons.Please_enter_phone_number")

    .min(7, "commons.Phone_number_must_contain_atleast_7_letters.")
    .max(15, "commons.Phone_number must_contain_atmost_15_letters.")
    .matches(phoneRegExp, "commons.Please_enter_valid_phone_number"),
  password: Yup.string()
    .required("commons.Please_enter_a_password")
    .min(6, "commons.Password_must_contain_atleast_6_letters")
    .max(15, "commons.Password_must_contain_atmost_15_letters")
    .matches(passwordRegExp, "commons.Password_must_contain_at_least_one_lower_case,_upper_case,_numeric_value_and_special_character"),
});

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      phone: "",
      password: "",
      isButtonDisabled: SVGComponentTransferFunctionElement,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (inputValues, formOptions) => {
    if (/^000/.test(inputValues.phone)) {
      formOptions.setFieldError("phone", "commons.Please_enter_valid_phone_number");
    } else {
      let formData = new FormData();
      formData.append("firstName", inputValues.firstName);
      formData.append("lastName", inputValues.lastName);
      formData.append("gender", inputValues.gender);
      formData.append("email", inputValues.email);
      formData.append("phone", inputValues.phone);
      formData.append("password", inputValues.password);

      let path = ApiRoutes.REGISTER;
      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();

        if (res.status == 200) {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.props.history.push({
            pathname: "/verify-otp",
            state: {
              phone: inputValues.phone,
              email: inputValues.email,
              otp_number: resJson.data.otp_number,
              otp_for: "register",
            },
          });
        } else {
          if (resJson.data.errors && resJson.data.errors.length > 0) {
            resJson.data.errors.forEach((error) => {
              formOptions.setFieldError(error.errField, error.errText);
            });
          } else {
            NotificationManager.error(resJson.message, "Error!", 3000);
          }
        }
      } else {
        NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
      }
    }
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <>
            <section
              className="login_signup_sec py-4 py-md-5"
              style={{
                backgroundImage: "url(" + "assets/img/login_signup.jpg" + ")",
              }}
            >
              <Container className="py-5">
                <Row className="justify-content-center">
                  <Col xs={12} md={12} lg={12} xl={12}>
                    <div className="login_signup_block p-4">
                      <div className="form_logo text-center mb-5">
                        <img src="./assets/img/logo.png" alt="LaundryHUB" />
                      </div>

                      <Formik
                        initialValues={{
                          firstName: this.state.firstName.trim(),
                          lastName: this.state.lastName,
                          gender: this.state.gender,
                          email: this.state.email,
                          phone: this.state.phone,
                          password: this.state.password,
                        }}
                        validationSchema={FormSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                          <Form className="login_signup_form">
                            <Row>
                              <Col xs={12} md={6}>
                                <Label>
                                  {t("labels.firstName")}
                                  <span style={{ color: "red" }}> *</span>
                                </Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="firstName"
                                    type="text"
                                    placeholder={t("labels.firstName")}
                                    onChange={(event) => {
                                      setFieldValue("firstName", event.target.value.trimStart());
                                    }}
                                  />
                                  {errors.firstName && touched.firstName ? <div className="invalid-feedback d-block">{t(errors.firstName)}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6}>
                                <Label>
                                  {t("labels.lastName")} <span style={{ color: "red" }}> *</span>
                                </Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="lastName"
                                    type="text"
                                    placeholder={t("labels.lastName")}
                                    onChange={(event) => {
                                      setFieldValue("lastName", event.target.value.trimStart());
                                    }}
                                  />
                                  {errors.lastName && touched.lastName ? <div className="invalid-feedback d-block">{t(errors.lastName)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Label>
                                  {t("labels.email")} <span style={{ color: "red" }}> *</span>
                                </Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="email"
                                    type="email"
                                    placeholder={t("placeholders.emailAddress")}
                                    onChange={(event) => {
                                      setFieldValue("email", event.target.value.trimStart());
                                    }}
                                  />
                                  {errors.email && touched.email ? <div className="invalid-feedback d-block">{t(errors.email)}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6}>
                                <Label>
                                  {t("labels.phone")} <span style={{ color: "red" }}> *</span>
                                </Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="phone"
                                    type="text"
                                    placeholder={t("placeholders.phone")}
                                    onChange={(event) => {
                                      setFieldValue("phone", event.target.value.trimStart());
                                    }}
                                  />
                                  {errors.phone && touched.phone ? <div className="invalid-feedback d-block">{t(errors.phone)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <Label>
                                  {t("labels.gender")}
                                  <span style={{ color: "red" }}> *</span>
                                </Label>
                                <FormGroup>
                                  <Field className="form-control" name="gender" as="select">
                                    {Genders.map((item, index) => {
                                      return (
                                        <option key={index} value={item.value}>
                                          {t(item.label)}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                  {errors.gender && touched.gender ? <div className="invalid-feedback d-block">{t(errors.gender)}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6}>
                                <Label>
                                  {t("labels.password")} <span style={{ color: "red" }}> *</span>
                                </Label>
                                <FormGroup>
                                  <Field className="form-control" name="password" type="password" placeholder={t("labels.password")} />
                                  {errors.password && touched.password ? <div className="invalid-feedback d-block">{t(errors.password)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <div>
                              <div className="trems_use_text py-md-5" color="muted">
                                <div className="d-flex align-items-center justify-content-center">
                                  <input
                                    id="input_check"
                                    className="mr-2"
                                    type="checkbox"
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        this.setState({ isButtonDisabled: false });
                                      } else {
                                        this.setState({ isButtonDisabled: true });
                                      }
                                    }}
                                  />{" "}
                                  <label htmlFor="input_check" className="m-0">
                                    {" "}
                                    {t("contents.bySigning")}{" "}
                                    <Link to="/terms" target="_blank">
                                      {t("menu.terms")}
                                    </Link>{" "}
                                    {t("contents.and")}{" "}
                                    <Link to="/privacy-policy" target="_blank">
                                      {t("menu.privacy")}
                                    </Link>
                                  </label>
                                </div>

                                <br />
                                <Button className="w-50 btn text-uppercase m-3 m-auto d-block" type="submit" disabled={this.state.isButtonDisabled ? true : false}>
                                  {t("menu.signup")}
                                </Button>
                              </div>
                            </div>
                            <div className="dont_signup_text text-center py-2">
                              {t("contents.already")} <Link to="/login">{t("menu.login")}</Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </>
        )}
      </Translation>
    );
  }
}
