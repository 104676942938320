import React, { Component } from "react";
import { Col, Container, Row, Modal, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import StarRatings from "react-star-ratings";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import InfiniteScroll from "react-infinite-scroller";
import { Translation } from "react-i18next";

import ModalLayoutAlert from "../../helpers/ModalLayoutAlert";

export default class VendorDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemId: props.match.params.itemId,
      itemData: null,
      itemPacks: [],
      itemCorporatePacks: [],
      first_shift_start_time: "",
      first_shift_end_time: "",
      second_shift_start_time: "",
      second_shift_end_time: "",
      workingDays: [],
      latitude: "",
      longitude: "",
      isloading: false,
      lang: "",
      show: false,
      showRatingModal: false,
      ratingData: [],
    };
  }

  async componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
    var coords = AuthService.getCurrentLocation();
    this.setState(
      {
        latitude: coords.latitude,
        longitude: coords.longitude,
      },
      () => {
        this.getVendorData(false);
      }
    );
  }

  getPackage = async () => {
    let path = ApiRoutes.GET_PROMOTION_PACKAGE + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({
          itemPacks: resJson.data.docs,
          lang: localStorage.getItem("lang") || "en",
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);

    }
  };


  getCorporatePackage = async () => {
    let path = ApiRoutes.GET_CORPORATE_PACKAGE + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
      if (res.status == 200) {
        // var buyed_pack = 0;
        // resJson.data.docs.map((item, index) => {
        //   if (index < 3 && current_date < item.vendor_package_date && item.customer_buyed_pack == 0) {
        //   } else {
        //     buyed_pack += 1;
        //   }
        // });
        // if (buyed_pack != resJson.data.docs.length && buyed_pack <= resJson.data.docs.length) {

        this.setState({
          itemCorporatePacks: resJson.data.result,
          lang: localStorage.getItem("lang") || "en",
          current_date: current_date,
        });
        // }

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);

    }
  };

  getVendorData = async (refreshPage = false) => {
    let path = ApiRoutes.GET_VENDOR + "/" + this.state.itemId + "?latitude=" + `${this.state.latitude}` + "&longitude=" + `${this.state.longitude}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
      var vendor_id = resJson.data._id;
      if (res.status == 200) {
        await this.setState(
          {
            itemData: resJson.data,
            vendor_id: resJson.data._id,
            current_date: current_date,
          },
          () => {
            this.setState({ isloading: true });
            // if (refreshPage == false) {
            //   this.showPopUpBox(resJson.data.is_available);
            // }
          }
        );
        this.getPackage(vendor_id);
        this.getCorporatePackage(vendor_id);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }

    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };





  render() {
    var { authUser } = this.props;
    var { lang, itemData, itemPacks } = this.state;
    return (
      <>
        <Translation>
          {(t) => (
            <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
              <Container className="py-3 py-md-4">
                {/* <Row className="mt-md-4"> */}
                {/* <Col xs={12} md={7} sm={8}> */}
                {(this.state.itemCorporatePacks && this.state.itemCorporatePacks.length > 0 || itemPacks && itemPacks.length > 0) && (
                  <div className="package-display-section">
                    <div className="package-flex-sec">
                      {this.state.itemCorporatePacks && this.state.itemCorporatePacks.length > 0 && this.state.itemCorporatePacks.map((item, index) => {
                        console.log(item, 'item');
                        // if (index < 3 ) {
                        var sub_category = [];
                        var main_category = [];
                        if (item && item.new_sub_categories) {
                          item.new_sub_categories.map((item1, index) => {
                            sub_category.push(item1.name[lang]);
                          })
                        }

                        item.new_main_categories.map((item1, index) => {
                          main_category.push(item1.name[lang]);
                        })
                        var total_pieces = 0;
                        if (item && item.individual_subCategories && item.individual_subCategories.length > 0) {
                          item.individual_subCategories.map((item2, index2) => {
                            total_pieces += parseInt(item2.value);
                          });
                        }

                        var free_delivery = item.free_delivery;
                        return (
                          <div key={item._id} className="col-md-4 corporate-individual-section" style={{ borderColor: item.packageColor }}>
                            {item.most_selling_package == 1 ?
                              <div className="package-tag-section">
                                {this.state.lang == 'en' ?
                                  <img src="./assets/img/tag-eng.png" alt="LaundryHUB" style={{ position: 'relative', top: '-13px', left: '-14px' }} />
                                  :
                                  <img src="./assets/img/tag-arb.png" alt="LaundryHUB" style={{ position: 'relative', top: '-12px', left: '-182px' }} />
                                }
                              </div>
                              :
                              <>
                                <div style={{
                                  // position: 'relative',
                                  // top: '-22px',
                                  // left: '-19px',
                                  // background: 'gray',
                                  // color: 'white',
                                  // width: '68%',
                                  padding: '13px 4px 0px 4px',
                                  textAlign: 'center',
                                  fontSize: '22px',
                                  border: '1px solid darkgray',
                                  borderRadius: '17px',
                                  position: 'relative',
                                  top: '10px',
                                  boxShadow: '2px 2px 2px lightgray',
                                  backgroundColor: '#2f7dc9',
                                }}>
                                  <p style={{
                                    // color: item.packTitleColor,
                                    color: 'white',
                                    fontWeight: 'bold'
                                  }}>{t('corporate-package')}</p>
                                </div>
                              </>
                            }

                            <div style={{
                              border: '1px solid lightgray', borderRadius: '12px',
                              borderTop: 'hidden',
                              borderTopLeftRadius: 'unset',
                              borderTopRightRadius: 'unset',
                              height: '83%',
                            }}>
                              <div className="package-but-sec"
                              // style={{ backgroundColor: item.packageColor }}
                              >
                                <div className="price-section">
                                  <p style={{ color: item.packTitleColor }}>{t('package_omr')}</p><p style={{ color: item.packTitleColor }}>{item.corporate_package_total_price}</p>
                                  </div>
                                <div style={{textAlign: 'center', fontSize:'17px', fontWeight: 'bold'}}>
                                    {
                                      item.is_company_or_staff_pay == 'staff_pay' ? (<><p style={{ color: item.packTitleColor }}>{t('staff-pay-package')}</p></>) : (<><p style={{ color: item.packTitleColor }}>{t('company-pay-package')}</p></>)
                                    }
                                    
                                </div>
                                <h3 className="package-title" style={{ color: item.packTitleColor, fontWeight: 'bold' }}>{item.corporate_package_title[lang]}</h3>
                                <div className="pack-sec1">
                                  {item.with_or_without_trans == 'yes' ?
                                    free_delivery == 'no' ? (
                                      <></>
                                    ) : (
                                      <li style={{ color: 'red' }}>{t("free_delivery_package")}</li>
                                    ) :
                                    <li style={{ color: 'red' }}>{t("non_transportation")}</li>}
                                </div>
                              </div>
                              <div className="corporate-details-sec" style={{
                                backgroundColor: item.packageColor,
                              }}>
                                <div className="corporate-package-design-button-sec">
                                <div style={{ color: item.packageTextColor, textAlign:'center',fontWeight: 'bold', paddingTop: '10px', fontSize: '22px'}} >
                                     <p style={{marginBottom: '0px'}}>{item.corporate_admin_name[lang]}</p>
                                    </div>
                                  {item.corporate_package_type && item.corporate_package_type == 1 ? (
                                    <>
                                      <div className="package-pieces-count" style={{ color: item.packageTextColor, paddingBottom: '10px' }} >{t("package_pieces_count")}</div>
                                      {item.customer_minimum_order_limit && item.customer_minimum_order_limit == total_pieces ? (
                                        <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                      ) : (
                                        <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_undefined_piece")}</div>
                                      )
                                      }
                                    </>
                                  ) : (
                                    <>
                                      <div className="package-sub-cat" style={{ color: item.packTitleColor }} >{t("subcategory_title")}</div>
                                      <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                    </>
                                  )
                                  }
                                </div>
                                <div style={{ color: 'white', height: '248px' }}>

                                  <div className="package-detail-sec">
                                    <div className="package-detail-include" style={{ color: item.packageTextColor }}>{t("promotion_including")}</div>
                                    <div className="package-info">
                                      {item && item.individual_subCategories && item.individual_subCategories.map((category_new, index) => {
                                        if (index < 4) {
                                          return (
                                            <div className="pack-sec">
                                              <i class="fa fa-check" aria-hidden="true" style={{ color: item.packageColor, marginRight: '10px', marginLeft: '15px' }}></i>
                                              <div className="individual_sec">
                                                <li style={{ color: item.packageTextColor }}># {category_new.value}</li>
                                                <li style={{ color: item.packageTextColor }}>{category_new.name[lang]}</li>
                                              </div>
                                            </div>
                                          )
                                        }
                                      })}

                                    </div>
                                  </div>
                                  <div className="corporate-package-button">
                                    {/* <Link
                                  to={{
                                    pathname: `/package/${category._id}`,
                                  }}
                                > */}
                                    <button className="package-btn"
                                      //  value={t("button.view_package")} 
                                      onClick={() => this.props.history.push(`/corporate-package-detail/${item.package_id}/${item.vendor_id}/${item.corporate_main_admin_id
                                        }/${item.corporate_sub_admin_id
                                        }/${item.branch_location_id
                                        }`)}

                                      style={{
                                        background: 'white',
                                        border: 'none',
                                        color: '#0978eb',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        padding: '14px 66px',
                                        borderRadius: '9px',
                                        // position: 'absolute',
                                        // top : '-25px',
                                        // left :'0px',
                                        // right : '0px'
                                      }}>
                                      {t("button.view_package")}
                                      {"  "}
                                      {/* <i class="fa fa-arrow-right"></i> */}
                                    </button>
                                    {/* </Link> */}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        // }
                      })}
                      {this.state.itemPacks && this.state.itemPacks.length > 0 && this.state.itemPacks.map((item, index) => {
                        if (item.vendor_package_date >= this.state.current_date) {
                          var sub_category = [];
                          var main_category = [];
                          item.new_sub_categories.map((item1, index) => {
                            sub_category.push(item1[lang]);
                          })
                          item.new_main_categories.map((item1, index) => {
                            main_category.push(item1.name[lang]);
                          })
                          var total_pieces = 0;
                          item.individual_piece_count.map((item2, index2) => {
                            total_pieces += parseInt(item2);
                          })
                          var free_delivery = item.promo_free_delivery;
                          return (
                            <div className="individual-section" style={{ borderColor: item.packageColor }}>
                              {item.most_selling_package == 1 ?
                                <div className="package-tag-section">
                                  {/* <p>{t("package_most_selling")}</p> */}
                                  {this.state.lang == 'en' ?
                                    <img src="./assets/img/tag-eng.png" alt="LaundryHUB" style={{ position: 'relative', top: '-13px', left: '-14px' }} />
                                    :
                                    <img src="./assets/img/tag-arb.png" alt="LaundryHUB" style={{ position: 'relative', top: '-12px', left: '-182px' }} />
                                  }
                                </div>
                                :
                                <></>
                              }
                              <div className="package-but-sec" style={{ backgroundColor: item.packageColor }}>
                                <div className="price-section">
                                  <p style={{ color: item.packTitleColor }}>{t('package_omr')}</p><p style={{ color: item.packTitleColor }}>{item.package_total_price}</p>
                                </div>
                                <h3 className="package-title" style={{ color: item.packTitleColor }}>{item.title[lang]}</h3>
                                <div className="pack-sec1">
                                  {free_delivery == 1 ? (
                                    <></>
                                  ) : (
                                    <li style={{ color: item.packageTextColor }}>{t("free_delivery_package")}</li>
                                  )}
                                </div>
                              </div>
                              <div className="package-design-button-sec">
                                {item.add_promotion_type && item.add_promotion_type == 1 ? (
                                  <>
                                    <div className="package-pieces-count" style={{ color: item.packTitleColor }} >{t("package_pieces_count")}</div>
                                    {item.customer_minimum_order_limit && item.customer_minimum_order_limit == total_pieces ? (
                                      <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                    ) : (
                                      <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_undefined_piece")}</div>
                                    )
                                    }
                                  </>
                                ) : (
                                  <>
                                    <div className="package-sub-cat" style={{ color: item.packTitleColor }} >{t("subcategory_title")}</div>
                                    <div className="package-defined-pieces" style={{ color: item.packageTextColor }}>{t("pack_defined_piece")}</div>
                                  </>
                                )
                                }
                              </div>
                              <div className="package-detail-sec">
                                <div className="package-detail-include" style={{ color: item.packageTextColor }}>{t("promotion_including")}</div>
                                <div className="package-info">
                                  {item.individual_piece_count.map((item_new, index) => {
                                    if (index < 4) {
                                      // return item_new + " " + item.new_sub_categories[index][lang];
                                      return (
                                        <div className="pack-sec">
                                          <i class="fa fa-check" aria-hidden="true" style={{ color: item.packageColor, marginRight: '10px', marginLeft: '15px' }}></i>
                                          <div className="individual_sec">
                                            <li style={{ color: item.packageTextColor }}>{item_new}</li>
                                            <li style={{ color: item.packageTextColor }}>{item.new_sub_categories[index][lang]}</li>
                                          </div>
                                        </div>
                                      )
                                    }
                                  })}

                                </div>
                              </div>
                              <div className="package-button">
                                <Link
                                  to={{
                                    pathname: `/package/${item._id}`,
                                  }}
                                >
                                  <input type="button" className="package-btn" value={t("button.view_package")} disabled={itemData && itemData.is_available ? false : true} style={{ backgroundColor: item.packageColor, borderColor: item.packageColor }}></input>
                                </Link>

                              </div>
                            </div>
                          )
                        }
                      })}


                    </div>

                  </div>

                )}
                {/* </Col> */}
                {/* </Row> */}
              </Container>
            </section>
          )}
        </Translation>
      </>
    );
  }
}
