import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, FormGroup, Label, Input, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field } from "formik";
import ApiRoutes from "../../helpers/ApiRoutes";
import Http from "../../helpers/Http";
import * as Yup from "yup";
import Switch from "react-switch";
import moment from "moment";
import i18next from "i18next";
import Select from "react-select";
import ModalLayoutAlert from "../../helpers/ModalLayoutAlert";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";
import Slider from "react-slick";
import authService from "../../services/auth.service";
import ReactToolTip from '../../helpers/reactToolTip';
import { StyledButtondiv1 } from "../../helpers/StyledButtondiv";


var sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  centerPadding: "30",
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
};

const FormSchema = Yup.object().shape({
  pickup_date: Yup.string().required("commons.Please_select_Pick_up_date").nullable(),
  delivery_date: Yup.string().required("commons.Please_select_Delivery_Date").nullable(),
  pickup_address: Yup.object().required("commons.Please_select_pick_Up_address").nullable(),
  delivery_address: Yup.object().required("commons.Please_select_delivery_address").nullable(),
  pickup_time: Yup.string().required("commons.Please_select_pick_up_time").nullable(),
  delivery_time: Yup.string().required("commons.Please_select_Delivery_Time").nullable(),
});
const FormSchemaEasyPickup = Yup.object().shape({
  pickup_date: Yup.string().required("commons.Please_select_Pick_up_date").nullable(),
  delivery_date: Yup.string().required("commons.Please_select_Delivery_Date").nullable(),
  pickup_address: Yup.object().required("commons.Please_select_pick_Up_address").nullable(),
  delivery_address: Yup.object().required("commons.Please_select_delivery_address").nullable(),
  pickup_time: Yup.string().required("commons.Please_select_pick_up_time").nullable(),
  delivery_time: Yup.string().required("commons.Please_select_Delivery_Time").nullable(),
  service_type: Yup.string().required("commons.Please_select_Service_Type").nullable(),
  item_return_back: Yup.string().required("commons.Please_select_Items_Return_back").nullable(),
});

const WorkingDaysOpts = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var workingDays = "";
var selectedDate = "";
var settingInfo = authService.getAuthSettings();
var addonCharges = "";
var expressDelivery = "";
var response = "";
const title = '<p><b>Monthly</b> - Order will be picked up every month on the scheduled pickup date and time chosen on 3rd,<br/> the order will be automatically created 2 days before the scheduled date with the pickup date of 3rd.<br/> Note: Pickup date and time may differ based on the vendor availability.<br/><br/><b>Weekly</b> - Order will be picked up every week on the scheduled pickup day and time<br/> For Example: If the pickup day is chosen on Wednesday,<br/> the order will be automatically created 2 days before the scheduled day with the pickup date of Wednesday.<br/> Note: Pickup day and time may differ based on the vendor availability.<br/><br/><b>Bi-Weekly</b> - Order will be picked up once in a twice a week on the scheduled pickup day and time.<br/> For Example: If the pickup day is chosen on Wednesday,<br/> the order will be created on alternative Wednesday Note: Pickup day and time may differ based on the vendor availability.<br/><br/><b>Daily</b> - Order will be picked up daily on the scheduled pickup time.<br/> For Example: If the pickup time is chosen as 4PM means,<br/> daily the order will be created on the scheduled pick up time.<br/> Note: Pickup date and time may differ based on the vendor availability.</p>';

const title1 = '<p><b>شهريًا</b> - سيتم استلام الطلب كل شهر في تاريخ الاستلام المحدد والوقت المحدد في اليوم الثالث،<br/> سيتم إنشاء الطلب تلقائيًا قبل يومين من التاريخ المحدد للاستلام. تاريخ الثالث.<br/> ملاحظة: قد يختلف تاريخ ووقت الاستلام بناءً على توفر البائع.<br/><br/><b>أسبوعيًا</b> - سيتم استلام الطلب كل أسبوع عند الاستلام المقرر اليوم والوقت<br/> على سبيل المثال: إذا تم اختيار يوم الاستلام يوم الأربعاء،<br/> فسيتم إنشاء الطلب تلقائيًا قبل يومين من اليوم المحدد مع تاريخ الاستلام وهو الأربعاء.<br/> ملاحظة: يوم الاستلام وقد يختلف الوقت بناءً على توفر البائع.<br/><br/><b>كل أسبوعين</b> - سيتم استلام الطلب مرة واحدة في الأسبوع مرتين في الأسبوع في يوم ووقت الاستلام المحددين.<br /> على سبيل المثال: إذا تم اختيار يوم الاستلام يوم الأربعاء،<br/> فسيتم إنشاء الطلب يوم الأربعاء البديل. ملاحظة: قد يختلف يوم الاستلام ووقته بناءً على توفر البائع.<br/><br/><b >يوميًا</b> - سيتم استلام الطلب يوميًا في وقت الاستلام المحدد.<br/> على سبيل المثال: إذا تم اختيار وقت الاستلام على أنه الساعة 4 مساءً،<br/> فسيتم إنشاء الطلب يوميًا في وقت الاستلام المجدول وقت التشغيل.<br/> ملاحظة: قد يختلف تاريخ ووقت الاستلام بناءً على توفر البائع.</p>';

const weeklyOpts = [
  { value: '0', label: 'Sunday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' }
]

const weeklyOpts1 = [
  { value: '0', label: 'يوم الأحد' },
  { value: '1', label: 'الاثنين' },
  { value: '2', label: 'يوم الثلاثاء' },
  { value: '3', label: 'الأربعاء' },
  { value: '4', label: 'الخميس' },
  { value: '5', label: 'يوم الجمعة' },
  { value: '6', label: 'يوم السبت' }
];

export default class CheckOut extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      // subcategory_discount_distance: 0,
      checkoutDetails: null,
      is_express_delivery: false,
      pickup_date: "",
      pickup_specific_date: "",
      delivery_specific_date: "",
      delivery_date: "",
      pickup_address: {},
      delivery_address: {},
      pickup_time: "",
      delivery_time: "",
      vendorId: props.match.params.vendor_id,
      addressModal: false,
      deliveryAddressModal: false,
      addressList: [],
      itemData: [],
      cartItems: [],
      vendorData: [],
      first_shift_start_time: "",
      first_shift_end_time: "",
      second_shift_start_time: "",
      second_shift_end_time: "",
      carttotal: "",
      carttotalwithhanging: "",
      expressDelivery: "",
      minTime: this.calculateMinTime(new Date()),
      minTime_delivery: this.calculateMinTime_delivery(new Date()),
      lang: "",
      freeDeliveryModal: false,
      expressBackend: "",
      isAvailable: false,
      show: false,
      myArr: [],
      first_shift_start_time_sun: "",
      first_shift_end_time_sun: "",
      second_shift_start_time_sun: "",
      second_shift_end_time_sun: "",
      first_shift_start_time_mon: "",
      first_shift_end_time_mon: "",
      second_shift_start_time_mon: "",
      second_shift_end_time_mon: "",
      first_shift_start_time_tue: "",
      first_shift_end_time_tue: "",
      second_shift_start_time_tue: "",
      second_shift_end_time_tue: "",
      first_shift_start_time_wed: "",
      first_shift_end_time_wed: "",
      second_shift_start_time_wed: "",
      second_shift_end_time_wed: "",
      first_shift_start_time_thur: "",
      first_shift_end_time_thur: "",
      second_shift_start_time_thur: "",
      second_shift_end_time_thur: "",
      first_shift_start_time_fri: "",
      first_shift_end_time_fri: "",
      second_shift_start_time_fri: "",
      second_shift_end_time_fri: "",
      first_shift_start_time_sat: "",
      first_shift_end_time_sat: "",
      second_shift_start_time_sat: "",
      second_shift_end_time_sat: "",
      package_express_delivery: 0,
      pickup_validation_error: "",
      delivery_option_validation: 0,
      delivery_method: '',
      min_delivery_date: '',
      express_delivery_option: '',
      actual_express_delivery_time: "",
      is_express_delivery_available: "",
      mixedDeliveryErrorShowing: "",
      first_shift_start: "",
      first_shift_end: "",
      second_shift_start: "",
      second_shift_end: "",
      auto_delivery_time: 0,
      adon_text: "",
      adon_type: [],
      adon_data: [],
      show_adon_text_filed: false,
      service_type_array: [],
      clothe_return_types: [],
      service_type: "",
      item_return_back: "",
      customerPackage: "",
      openotherModal: false,
      repeated_order: "",
      schedule_type: "",
      weeklyDay: "",
      biweeklyDays: [],
      monthStartDate: "",
      monthEndDate: "",
      button_disable: true,
      repeated_order_pickup_time: "",
      repeated_order_delivery_time: "",
      repeated_order_popup: false,
      easy_pickup_no_free_delivery: "",
      repeated_order_vendor_id: "",
      repeated_order_delivery_date: "",
      repeated_order_pickup_specific_date: "",
      repeated_order_pickup_date: "",
      repeated_order_delivery_specific_date: "",
      repeated_order_pickup_minTime: moment().startOf("day").toDate(),
      repeated_order_delivery_minTime: this.repeated_order_delivery_calculateMinTime(new Date()),
      easy_pickup_popup: false,
      longer_delivery_method_array: [],
      easy_pickup_terms_and_conditions: false,
      repeated_order_schedule_popup: false,
      repeated_order_schedule_popup_text: "",
      isLoading: false,
      max_repeated_order_delivery_date: "",
      min_repeated_order_delivery_date: "",
      show_next_week_delviery_date: false,
      show_next_week_pickup_date: false,
      next_week_delviery_date: "",
      next_week_pickup_date: "",
      use_package: false,
      i: 0,
      repeated_order_auto_delivery_time: 0,
      repeated_order_delivery_option_validation: 0,
      orgin_delivery_time: "",
      show_repeated_order: true,
      customer_wallet_amount: 0,
      minimum_wallet_amount_for_easy_pickup: 0,
      show_topup_button: false,
    };
  }

  componentDidMount() {
    if (this.props.authUser) {
      this.getAddresses();
      this.getCartData();

      // getting values from context
      const context = this.context;
      const { globalPickUpdate, globalPickUptime, globaldeliverydate, globaldeliverytime, globalExpress, globalPickUpaddress, globaldeliveryaddress, globalDeliveryMethod, specialRequest, specialRequestText } = context;

      this.setState({
        adon_text: specialRequestText && specialRequestText,
        adon_type: specialRequest && specialRequest,
        pickup_address: globalPickUpaddress && globalPickUpaddress,
        delivery_address: globaldeliveryaddress && globaldeliveryaddress,
        is_express_delivery: globalExpress && globalExpress,
        delivery_method: globalDeliveryMethod && globalDeliveryMethod,
        // pickup_date: globalPickUpdate && globalPickUpdate,
        // pickup_specific_date: globalPickUpdate && globalPickUpdate,
        // delivery_specific_date: globaldeliverydate && globaldeliverydate,
        // pickup_time: globalPickUptime && globalPickUptime,
        // delivery_date: globaldeliverydate && globaldeliverydate,
        // delivery_time: globaldeliverytime && globaldeliverytime,
        is_express_delivery: false,
        lang: localStorage.getItem("lang") || "en",
      });
      specialRequest && specialRequest.map((items) => {
        if (items.value == "") {
          this.setState({
            show_adon_text_filed: true
          })
        }
      })
    };
    const firstdate = moment().add(1, 'month').startOf('month').format('MM/DD/YYYY');
    const lastdate = moment().add(1, 'month').endOf('month').format("MM/DD/YYYY");
    this.setState({
      monthStartDate: firstdate,
      monthEndDate: lastdate
    });
  }

  componentDidUpdate() {
    const context = this.context;
    // settingInfo = context.settingsInfo;

    const { state } = this.props.location;

    var shampooPrice = state && Object.keys(state.addShampoos).length != 0 ? state.addShampoos.price : 0;

    var bleachPrice = state && Object.keys(state.addBleaches).length != 0 ? state.addBleaches.price : 0;

    var perfumePrice = state && Object.keys(state.addPerfumes).length != 0 ? state.addPerfumes.price : 0;

    var softenerPrice = state && Object.keys(state.addSofteners).length != 0 ? state.addSofteners.price : 0;

    var sanitizerPrice = state && Object.keys(state.addSanitizers).length != 0 ? state.addSanitizers.price : 0;

    var laundry_bag_count = state && Object.keys(state.laundrybag).length != 0 ? state.laundrybag.bags : 0;

    addonCharges = parseFloat(shampooPrice + bleachPrice + softenerPrice + perfumePrice + sanitizerPrice);

    if (state && state.wash.name == "yes") {
      addonCharges = parseFloat(state.wash.price) + parseFloat(addonCharges);
    } else {
      addonCharges = addonCharges;
    }

    var subtotal =
      addonCharges >= 0 && this.state.carttotalwithhanging && this.state.carttotalwithhanging && parseFloat(addonCharges) + parseFloat(this.state.carttotalwithhanging);

    if (this.state.delivery_method == 'longer') {
      expressDelivery = this.state.vendorData && this.state.carttotal && parseFloat(this.state.vendorData.longer_express_delivery_charge
        / 100) * parseFloat(this.state.carttotal);
    } else if (this.state.delivery_method == 'medium') {
      expressDelivery = this.state.vendorData && this.state.carttotal && parseFloat(this.state.vendorData.medium_express_delivery_charge
        / 100) * parseFloat(this.state.carttotal);
    } else {
      expressDelivery = this.state.vendorData && this.state.carttotal && parseFloat(this.state.vendorData.normal_express_delivery_charge
        / 100) * parseFloat(this.state.carttotal);
    }
  }

  calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
      nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
      return nowAddOneHour1;
    }
    return moment().startOf("day").toDate();
  };


  repeated_order_delivery_calculateMinTime = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
      nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
      var date = new Date(nowAddOneHour1 * 1000);
      return nowAddOneHour1;
    } else if (this.state.repeated_order_delivery_option_validation == 1) {
      let nowAddOneHour = moment(new Date()).toDate();
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
      nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
      var date = new Date(nowAddOneHour1 * 1000);
      return nowAddOneHour1;
    } else {
      this.setState({
        repeated_order_auto_delivery_time: 1,
      });
    }
    return moment().startOf("day").toDate();
  };

  adon_type(event) {
    this.setState({ show_adon_text_filed: false });
    event.map((items) => {
      if (items.label == "Others" || items.label == "آحرون") {
        this.setState({ openotherModal: true })
      }
    });
    const context = this.context;
    this.setState({ adon_type: event, addressModal: false }, () => context.setSpecialRequest(this.state.adon_type));
    this.setState({ adon_type: event });
  }

  hideothersModal = () => {
    this.setState({
      openotherModal: false,
      show_adon_text_filed: true,
      adon_text: '',
    });
  };

  calculateMinTime_delivery = (date) => {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).toDate();
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
      nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
      var date = new Date(nowAddOneHour1 * 1000);
      return nowAddOneHour1;
    }
    else if (this.state.delivery_option_validation == 1) {
      let nowAddOneHour = moment(new Date()).toDate();
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
      nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
      var date = new Date(nowAddOneHour1 * 1000);
      return nowAddOneHour1;
    } else {
      this.setState({
        auto_delivery_time: 1,
      });
    }
    return moment().startOf("day").toDate();
  };

  handleMinTime = (date) => {
    this.setState({
      minTime: this.calculateMinTime(date),
    });
  };

  repeated_order_pickup_handleMinTime = (date) => {
    this.setState({
      repeated_order_pickup_minTime: moment(date).startOf("day").toDate(),
    });
  };

  repeated_order_delivery_handleMinTime = (date) => {
    this.setState({
      repeated_order_delivery_minTime: this.repeated_order_delivery_calculateMinTime(date),
    });
  };

  handleMinTime_delivery = (date) => {
    this.setState({
      minTime_delivery: this.calculateMinTime_delivery(date),
    });
  };

  showModal = (e) => {
    this.setState(
      {
        show: !this.state.show,
      },
      () => {
        if (this.state.show == false) {
          this.props.history.push("/cart");
        }
      }
    );
  };

  // Convert timestamp to date format
  toDate = (dStr, format) => {
    var now = new Date();
    if (format == "h:m") {
      now.setHours(dStr.substr(0, dStr.indexOf(":")));
      now.setMinutes(dStr.substr(dStr.indexOf(":") + 1));
      now.setSeconds(0);
      return now;
    } else return "Invalid Format";
  };

  // Show Filtered Time
  filterPassedTime = (time) => {
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;

    if (this.state.pickup_specific_date == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.pickup_specific_date == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");

    }
    if (this.state.pickup_specific_date == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.pickup_specific_date == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.pickup_specific_date == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.pickup_specific_date == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.pickup_specific_date == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }
    selectedDate = new Date(time);
    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var extracttime = this.state.pickup_time && this.state.pickup_time;
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    return test;
  };

  // Show Filtered Time
  filterPassedTime__delivery = (time) => {
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;

    if (this.state.delivery_specific_date == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.delivery_specific_date == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
    }
    if (this.state.delivery_specific_date == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.delivery_specific_date == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.delivery_specific_date == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.delivery_specific_date == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.delivery_specific_date == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }
    // this.setState({
    //   first_shift_start: first_shift_start,
    //   first_shift_end: first_shift_end,
    //   second_shift_start: second_shift_start,
    //   second_shift_end: second_shift_end,
    // });
    let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
    nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    first_shift_start = new Date(nowAddOneHour)
    let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
    nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
    second_shift_start = new Date(nowAddOneHour1)
    selectedDate = new Date(time);
    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    return test;
  };

  // Show Filtered Time For Repated Order pickup
  repeated_order_pickup_filterPassedTime = (time) => {
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;
    if (this.state.repeated_order_pickup_specific_date == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.repeated_order_pickup_specific_date == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
    }
    if (this.state.repeated_order_pickup_specific_date == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.repeated_order_pickup_specific_date == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.repeated_order_pickup_specific_date == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.repeated_order_pickup_specific_date == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.repeated_order_pickup_specific_date == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }

    let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
    nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    first_shift_start = new Date(nowAddOneHour)
    let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
    nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
    second_shift_start = new Date(nowAddOneHour1)
    selectedDate = new Date(time);
    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    return test;
  };

  // Show Filtered Time For Repated Order delivery
  repeated_order_delivery_filterPassedTime = (time) => {
    let first_shift_start;
    let first_shift_end;
    let second_shift_start;
    let second_shift_end;
    if (this.state.repeated_order_delivery_specific_date == 0) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
    }
    if (this.state.repeated_order_delivery_specific_date == 1) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
    }
    if (this.state.repeated_order_delivery_specific_date == 2) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
    }
    if (this.state.repeated_order_delivery_specific_date == 3) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
    }
    if (this.state.repeated_order_delivery_specific_date == 4) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
    }
    if (this.state.repeated_order_delivery_specific_date == 5) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
    }
    if (this.state.repeated_order_delivery_specific_date == 6) {
      first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
      first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
      second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
      second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
    }
    // this.setState({
    //   first_shift_start: first_shift_start,
    //   first_shift_end: first_shift_end,
    //   second_shift_start: second_shift_start,
    //   second_shift_end: second_shift_end,
    // });
    let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
    nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
    first_shift_start = new Date(nowAddOneHour)
    let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
    nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
    second_shift_start = new Date(nowAddOneHour1)
    selectedDate = new Date(time);

    if (selectedDate.getDate() > first_shift_start.getDate()) {
      selectedDate = parseInt(selectedDate.getTime() - 86400000);
    }
    first_shift_start = parseInt(first_shift_start.getTime() / 1000);
    first_shift_end = parseInt(first_shift_end.getTime() / 1000);
    second_shift_start = parseInt(second_shift_start.getTime() / 1000);
    second_shift_end = parseInt(second_shift_end.getTime() / 1000);
    selectedDate = parseInt(selectedDate / 1000);
    var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
    return test;
  };

  getCartData = async () => {
    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        var totalprice = 0;
        var delivery_method = '';
        // var subcategory_discount_distance = 0;
        resJson.data.cartItems.map((item) => {
          item.service_items.map((service_type) => {
            if (item.express_delivery == 1) {
              this.setState({ package_express_delivery: 1 });
            }
            if (delivery_method != 'medium') {
              delivery_method = item.delivery_method;
            }
            // subcategory_discount_distance = service_type.max_distance_coupon_subcategory;
            totalprice += service_type.unit_quantity * service_type.price_by_measure + service_type.clothe_return_type.hanging_charge;
          })
        }
        );

        //checking for mixed orders
        if (resJson.data.mixedCart == 1) {
          var message;
          if (this.state.lang == 'ar') {
            message = "تحتوي عناصر سلة التسوق الخاصة بك على فئة مختلطة لذا فإن التسليم السريع غير متوفر"
          } else {
            message = "Your cart items contains mixed category so express delivery not avaliable"
          }
          this.setState({
            mixedDeliveryErrorShowing: message
          })
        }
        console.log(resJson.data.amountInfo, 'resJson.data.amountInfo');

        this.setState(
          {
            // subcategory_discount_distance: subcategory_discount_distance,
            carttotalwithhanging: totalprice,
            vendor_id: resJson.data && resJson.data.cartItems.length > 0 ? resJson.data.cartItems[0].vendor._id : "",
            itemData: resJson.data.cartItems,
            pickup_address: this.state.pickup_address,
            delivery_method: delivery_method,
            carttotal: resJson.data.amountInfo.sub_total,
          },
          () => this.getVendorData()
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  getNewDate = (date, time) => {
    var time = moment(time).format("hh:mm A");
    var date = moment(date).format("MM/DD/YYYY");
    var newDate = date + " " + time;
    var newDate1 = new Date(newDate).getTime();
    return newDate1;
  };

  // get vendor data
  showPopUpBox = (expressdeliverypop) => {
    const { itemData } = this.state;

    for (const cartItem of itemData) {
      if (expressdeliverypop == 1 && cartItem.service.category.is_express_available == 1) {
        return <p>{i18next.t("commons.Try_Express_service_and_get your_laundry_faster_within_same_day.")}</p>;
      }
    }
  };

  showFreeDelivery = (expressdeliverypop) => {
    const { itemData } = this.state;
    for (const cartItem of itemData) {
      if (expressdeliverypop == 1 && cartItem.service.category.is_express_available == 1) {
        this.setState((prevState) => ({
          freeDeliveryModal: !prevState.freeDeliveryModal,
          expressBackend: expressdeliverypop,
        }));
        break;
      }
    }
  };

  showExpressAvailable = () => {
    this.checkExpressAvailbale();
  };

  getVendorData = async (currentPage = 1) => {
    let vendor_id_temp = this.state.vendor_id == "" ? this.state.vendorId : this.state.vendor_id;
    let path = ApiRoutes.GET_VENDOR + "/" + vendor_id_temp;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        var settingsData = JSON.parse(localStorage.getItem("settings"));
        this.setState({
          minimum_wallet_amount_for_easy_pickup: settingsData.minimum_wallet_amount_for_easy_pickup,
          wallet_amount: resJson.data.customer_setting.wallet_amount
        })
        workingDays = this.getWorkingDaysString(resJson.data && resJson.data.working_days);
        if (resJson.data.sunday_timings) {
          Object.entries(resJson.data.sunday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.monday_timings) {
          Object.entries(resJson.data.monday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.tuesday_timings) {
          Object.entries(resJson.data.tuesday_timings).forEach(([key, value]) => {
            // for (var key in value) {
            //   this.setState({ [key]: value[key] ? moment(value[key]).utc().format("HH:mm") : "N/A" });
            // }
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.wednesday_timings) {
          Object.entries(resJson.data.wednesday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.thursday_timings) {
          Object.entries(resJson.data.thursday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.friday_timings) {
          Object.entries(resJson.data.friday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        if (resJson.data.saturday_timings) {
          Object.entries(resJson.data.saturday_timings).forEach(([key, value]) => {
            this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
          });
        }
        var express_delivery = '';
        var express_delivery_option = 0;
        var actual_express_delivery_time = 0;
        if (this.state.delivery_method == 'longer') {
          express_delivery = resJson.data.is_longer_express_delivery;
          if (resJson.data.is_longer_express_delivery == 1) {
            express_delivery_option = 1;
            var longer_delivery_time = resJson.data.longer_express_delivery_time;
            actual_express_delivery_time = longer_delivery_time;
          }
        } else if (this.state.delivery_method == 'medium') {
          express_delivery = resJson.data.is_medium_express_delivery;
          if (resJson.data.is_medium_express_delivery == 1) {
            express_delivery_option = 1;
            actual_express_delivery_time = resJson.data.medium_express_delivery_time;
          }
        } else {
          express_delivery = resJson.data.is_normal_express_delivery;
          if (resJson.data.is_normal_express_delivery == 1) {
            express_delivery_option = 1;
            actual_express_delivery_time = resJson.data.normal_express_delivery_time;
          }
        }
        const adon_temp = [];
        resJson.data.adon && resJson.data.adon.map((value) => {
          if (this.state.lang == 'en') {
            adon_temp.push({ value: value._id, label: value.adon_type.en })
          } else {
            adon_temp.push({ value: value._id, label: value.adon_type.ar })

          }
        });
        resJson.data.longer_delivery_method_array && this.setState({
          longer_delivery_method_array: resJson.data.longer_delivery_method_array
        })
        if (this.state.lang == 'en') {
          adon_temp.push({ value: "", label: "Others" });

        } else {
          adon_temp.push({ value: "", label: "آحرون" });

        }

        const service_type_temp = []

        resJson.data.service_type && resJson.data.service_type.map((value) => {
          if (this.state.lang == 'en') {
            service_type_temp.push({ value: value.service_type._id, label: value.service_type.name.en })
          } else {
            service_type_temp.push({ value: value.service_type._id, label: value.service_type.name.ar })
          }

        })

        const clothe_return_types_temp = []

        resJson.data.clothe_return_types && resJson.data.clothe_return_types.map((value) => {
          if (this.state.lang == 'en') {
            clothe_return_types_temp.push({ value: value._id, label: value.name.en })
          } else {
            clothe_return_types_temp.push({ value: value._id, label: value.name.ar })
          }
        })
        resJson.data.customer_setting.repeated_order_vendor_id && this.setState({ repeated_order_vendor_id: resJson.data.customer_setting.repeated_order_vendor_id, });
        if ((resJson.data.customer_setting.repeated_order_vendor_id == this.state.vendorId && resJson.data.customer_setting.allow_repeated_order == 1) || (this.state.itemData.length > 0)) {
          console.log('hhhjhuiji');
          this.setState({ show_repeated_order: false })
        }
        this.setState({
          adon_data: adon_temp,
          service_type_array: service_type_temp,
          clothe_return_types: clothe_return_types_temp,
          customerPackage: resJson.data.customerPackage
        });
        await this.setState(
          {
            easy_pickup_no_free_delivery: resJson.data.easy_pickup_no_free_delivery,
            vendorData: resJson.data,
            express_delivery_option: express_delivery_option,
            actual_express_delivery_time: actual_express_delivery_time,
            // first_shift_start_time: resJson.data.first_shift_start_time ? moment(resJson.data.first_shift_start_time).utc().format("HH:mm") : "N/A",
            // first_shift_end_time: resJson.data.first_shift_end_time ? moment(resJson.data.first_shift_end_time).utc().format("HH:mm") : "N/A",
            // second_shift_start_time: resJson.data.second_shift_start_time ? moment(resJson.data.second_shift_start_time).utc().format("HH:mm") : "N/A",
            // second_shift_end_time: resJson.data.second_shift_end_time ? moment(resJson.data.second_shift_end_time).utc().format("HH:mm") : "N/A",
          },
          () => this.showFreeDelivery(express_delivery)
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  // Get working days array
  getWorkingDaysString(sortArr) {
    if (sortArr) {
      var daysArr = sortArr.sort();
      var str = [];
      if (daysArr && daysArr.length > 0) {
        daysArr.forEach((item) => {
          str += WorkingDaysOpts[item] + ", ";
        });
      }
      return str;
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  }

  toggleAddressModal = () => {
    this.setState((prevState) => ({
      addressModal: !prevState.addressModal,
    }));
  };

  hideaddressModal = () => {
    this.setState((prevState) => ({
      addressModal: !prevState.addressModal,
    }));
  };

  hidedeliveryaddressModal = () => {
    this.setState((prevState) => ({
      deliveryAddressModal: !prevState.deliveryAddressModal,
    }));
  };

  onChangePickUpAddress = (
    e,
    title,
    appartment,

    street,
    landmark,
    city,
    state,
    country,
    pincode,
    latitude,
    longitude
  ) => {
    var pickup_address = "";
    pickup_address = {
      title: title,
      appartment: appartment,

      street: street,
      landmark: landmark,
      city: city,
      state: state,
      country: country,
      pincode: pincode,
      latitude: latitude,
      longitude: longitude,
    };

    const context = this.context;

    this.setState({ pickup_address: pickup_address, addressModal: false }, () => context.setGlobalPickUpaddress(this.state.pickup_address));
  };

  onChangedeliveryAddress = (
    e,
    title,
    appartment,

    street,
    landmark,
    city,
    state,
    country,
    pincode,
    latitude,
    longitude
  ) => {
    var delivery_address = "";
    delivery_address = {
      title: title,
      appartment: appartment,

      street: street,
      landmark: landmark,
      city: city,
      state: state,
      country: country,
      pincode: pincode,
      latitude: latitude,
      longitude: longitude,
    };

    const context = this.context;

    this.setState(
      {
        delivery_address: delivery_address,
        deliveryAddressModal: false,
      },
      () => context.setGlobaldeliveryaddress(this.state.delivery_address)
    );
  };

  toggleDeliveryAddressModal = () => {
    this.setState((prevState) => ({
      deliveryAddressModal: !prevState.deliveryAddressModal,
    }));
  };

  getDateTimeStamp = (date) => {
    var date = moment(date).format("MM/DD/YYYY");
    var newDate1 = new Date(date).getTime();
    return newDate1;
  };

  getTimeStamp = (time) => {
    var newDate1 = new Date(time).getTime();
    return newDate1;
  };

  handleSubmit = async (inputValues, formOptions) => {
    const { vendorData } = this.state;
    var newpickup_time = this.getNewDate(inputValues.pickup_date, inputValues.pickup_time);
    var newdelivery_time = this.getNewDate(inputValues.delivery_date, inputValues.delivery_time);
    var newOrderPickUpDate = this.getDateTimeStamp(inputValues.pickup_date);
    var newOrderDeliveryDate = this.getDateTimeStamp(inputValues.delivery_date);
    var DateDifference = (newOrderDeliveryDate - newOrderPickUpDate) / 1000;
    if (inputValues.pickup_date > inputValues.delivery_date) {
      formOptions.setFieldError("delivery_date", "commons.Please_select_pickup_date_shorter_than_delivery_date.");
      return false;
    }
    if (newdelivery_time <= newpickup_time) {
      formOptions.setFieldError("delivery_time", "commons.Please_select_delivery_time_greater_than_pickup_time");
      return false;
    }
    var checkestimated = (newdelivery_time - newpickup_time) / 1000;
    if (inputValues.is_express_delivery) {
      var express_delivery_time = '';
      if (this.state.delivery_method == 'longer') {
        var longDeliveryTime = vendorData.longer_express_delivery_time * 24;
        express_delivery_time = vendorData && longDeliveryTime * 3600;
      } else if (this.state.delivery_method == 'medium') {
        express_delivery_time = vendorData && vendorData.medium_express_delivery_time * 3600;
      } else {
        express_delivery_time = vendorData && vendorData.normal_express_delivery_time * 3600;
      }
      if (checkestimated < 0 || checkestimated < express_delivery_time) {
        formOptions.setFieldError("delivery_time", "commons.Please_select_either_default_Express_Delivery_Time_or_less_than_that.");
        return false;
      }
    } else {
      if (this.state.delivery_method == 'medium') {
        var normal_delivery = vendorData && vendorData.medium_delivery_time * 24 * 3600;
      } else if (this.state.delivery_method == 'longer') {
        var normal_delivery = vendorData && vendorData.longer_delivery_time * 24 * 3600;
      } else {
        var normal_delivery = vendorData && vendorData.normal_delivery_time * 24 * 3600;
      }
      if (DateDifference < normal_delivery) {
        if (this.state.delivery_method == 'normal') {
          formOptions.setFieldError("delivery_date", "commons.Please_select_either_default_normal_delivery_date_or_more_than_that");
        } else if (this.state.delivery_method == 'medium') {
          formOptions.setFieldError("delivery_date", "commons.Please_select_either_default_medium_delivery_date_or_more_than_that");
        } else if (this.state.delivery_method == 'longer') {
          formOptions.setFieldError("delivery_date", "commons.Please_select_either_default_longer_delivery_date_or_more_than_that");
        } else {
          formOptions.setFieldError("delivery_date", "commons.Please_select_either_default_normal_delivery_date_or_more_than_that");
        }
        return false;
      } else if ((DateDifference > normal_delivery && checkestimated < 0) || checkestimated < normal_delivery) {
        if (this.state.delivery_method == 'normal') {
          formOptions.setFieldError("delivery_time", "commons.Please_select_either_default_normal_delivery_time_or_more_than_that");
        } else if (this.state.delivery_method == 'medium') {
          formOptions.setFieldError("delivery_time", "commons.Please_select_either_default_medium_delivery_time_or_more_than_that");
        } else if (this.state.delivery_method == 'longer') {
          formOptions.setFieldError("delivery_time", "commons.Please_select_either_default_longer_delivery_time_or_more_than_that");
        } else {
          formOptions.setFieldError("delivery_time", "commons.Please_select_either_default_normal_delivery_time_or_more_than_that");
        }
        return false;
      }
    }

    const { state } = this.props.location;
    const context = this.context;

    var pickup_date = moment(inputValues.pickup_date).format("ddd");
    var delivery_date = moment(inputValues.delivery_date).format("ddd");
    var pickupValidation = workingDays.includes(pickup_date);
    var deliveryValidation = workingDays.includes(delivery_date);
    // console.log(inputValues.delivery_time, 'inputValues.delivery_time');
    if (!this.filterPassedTime__delivery(inputValues.delivery_time)) {
      formOptions.setFieldError("delivery_time", "commons.Please_select_another_time_as_vendor_is_unavailable_on_selected_time.");
      return false;
    }
    // if (!this.filterPassedTime(inputValues.pickup_time)) {
    //   console.log('inside_this.filterPassedTime__delivery')
    //   formOptions.setFieldError("pickup_time", "commons.Please_select_another_time_as_vendor_is_unavailable_on_selected_time.");
    //   return false;
    // }
    if (!pickupValidation) {
      formOptions.setFieldError("pickup_date", "commons.Please_select_another_date_as_vendor_is_unavailable_on_selected_date.");
      return false;
    }
    if (!deliveryValidation) {
      formOptions.setFieldError("delivery_date", "commons.Please_select_another_date_as_vendor_is_unavailable_on_selected_date.");
      return false;
    }

    // Validate the valid pickup time
    let isToday = moment(pickup_date).isSame(moment(), "day");
    if (isToday) {
      var OrderPickupTime = moment(inputValues.pickup_time).format("hh:mm:ss");
      var CurrentDate = moment(new Date());
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(CurrentDate, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour = moment(new Date(roundedDate)).toDate();
      nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
      var ValidPickupTime = moment(nowAddOneHour).format("hh:mm:ss");
      if (ValidPickupTime > OrderPickupTime) {
        if (this.state.lang == 'en') {
          var error_message = "Please choose the Valid Pickup Time";
        } else {
          var error_message = "الرجاء اختيار وقت الاستلام الصحيح";
        }
        this.setState(
          {
            pickup_validation_error: error_message,
            delivery_option_validation: 1,
          },
          () => this.handleMinTime(this.state.pickup_date),
        );
        this.handleMinTime_delivery(this.state.delivery_date);
        return false;
      } else {
        // console.log('khj');
      }
    }
    var error = 0;
    if (this.state.show_repeated_order) {
      if (this.state.repeated_order == "") {
        formOptions.setFieldError("repeated_order", "validations.Please_choose_repeated_order");
        error = 1;
      };
    }
    if (this.state.repeated_order == 'yes') {
      if (this.state.schedule_type == '' || this.state.schedule_type == undefined || this.state.schedule_type == null) {
        formOptions.setFieldError("schedule_type", "validations.Please_choose_schedule_type");
        error = 1;
      }
      if (this.state.schedule_type == 'monthly' || this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly') {
        if (this.state.repeated_order_pickup_date == '' || this.state.repeated_order_pickup_date == undefined || this.state.repeated_order_pickup_date == null) {
          formOptions.setFieldError("repeated_order_pickup_date", "commons.Please_select_Pick_up_date");
          error = 1;
        };
        if (this.state.repeated_order_delivery_date == '' || this.state.repeated_order_delivery_date == undefined || this.state.repeated_order_delivery_date == null) {
          formOptions.setFieldError("repeated_order_delivery_date", "commons.Please_select_Delivery_Date");
          error = 1;
        };
      };
      if (this.state.repeated_order_pickup_time == '' || this.state.repeated_order_pickup_time == undefined || this.state.repeated_order_pickup_time == null) {
        formOptions.setFieldError("repeated_order_pickup_time", "commons.Please_select_pick_up_time");
        error = 1;
      };
      if (this.state.repeated_order_delivery_time == '' || this.state.repeated_order_delivery_time == undefined || this.state.repeated_order_delivery_time == null) {
        formOptions.setFieldError("repeated_order_delivery_time", "commons.Please_select_Delivery_Time");
        error = 1;
      } else if (!this.repeated_order_delivery_filterPassedTime(this.state.repeated_order_delivery_time)) {
        formOptions.setFieldError("repeated_order_delivery_time", "commons.Please_select_another_time_as_vendor_is_unavailable_on_selected_time.");
        error = 1;
      };
    };
    if (error == 1) {
      return false;
    }
    // set value in context

    const checkoutDetails = {
      is_express_delivery: inputValues.is_express_delivery ? 1 : 0,
      pickup_address: inputValues.pickup_address,
      delivery_address: inputValues.delivery_address,
      pickup_date: inputValues.pickup_date,
      pickup_time: inputValues.pickup_time,
      delivery_date: inputValues.delivery_date,
      delivery_time: inputValues.delivery_time,
      shampoo: state && state.addShampoos ? state.addShampoos : "",
      perfume: state && state.addPerfumes ? state.addPerfumes : "",
      softener: state && state.addSofteners ? state.addSofteners : "",
      bleach: state && state.addBleaches ? state.addBleaches : "",
      sanitizer: state && state.addSanitizers ? state.addSanitizers : "",
      is_seperate_wash: state && state.wash ? state.wash : "",
      laundry_bag_count: state && state.laundrybag ? state.laundrybag : "",
    };

    context.setCheckOut(checkoutDetails);

    // sending details to next page

    var dateObj = new Date(inputValues.delivery_time);

    var utcString = moment(dateObj).format("HH:mm");
    // if (this.state.subcategory_discount_distance != 0 && this.state.subcategory_discount_distance != undefined) {
    //   alert("Subcategory discount will be applied if your pickup & delivery distance withinin " + this.state.subcategory_discount_distance + "Kms.")
    // }
    if (this.state.show_adon_text_filed == true) {
      if (this.state.adon_text == "") {
        formOptions.setFieldError("adon_text", "commons.Please_enter_any_special_type");
        return false;
      }
    };

    var adon_type = [];

    this.state.adon_type && this.state.adon_type.map((items) => {
      if (items.value != "") {
        adon_type.push(items.value)
      }
    });

    if (this.state.itemData.length == 0) {
      if (this.state.wallet_amount >= this.state.minimum_wallet_amount_for_easy_pickup) {
        let formData = new FormData();
        formData.append("repeated_order_vendor_id", this.state.repeated_order_vendor_id);
        formData.append("adon_others", this.state.adon_text);
        formData.append("service_type", this.state.service_type);
        formData.append("items_return_back", this.state.item_return_back)
        formData.append("adon_request", JSON.stringify(adon_type));
        formData.append("is_express_delivery", this.state.is_express_delivery ? 1 : 0);
        formData.append("pickup_address", JSON.stringify(this.state.pickup_address));
        formData.append("delivery_address", JSON.stringify(this.state.delivery_address));
        formData.append("pickup_date", moment(inputValues.pickup_date).format("YYYY-MM-DD"));
        formData.append("pickup_time", moment(inputValues.pickup_time, ["h:mm A"]).format("HH:mm"),);
        formData.append("delivery_date", moment(inputValues.delivery_date).format("YYYY-MM-DD"));
        formData.append("delivery_time", utcString);
        formData.append("is_easy_pickup", 1);
        formData.append("vendor_id", this.state.vendorId);
        formData.append('vendor_address', JSON.stringify(this.state.vendorData.default_address))
        formData.append("freedelivery", this.state.totalDeliveryCharge == 0 ? 'freedelivery' : 'nofreedelivery');
        formData.append("repeated_order", this.state.repeated_order);
        formData.append("schedule_type", this.state.schedule_type);
        formData.append("use_package", this.state.use_package ? 1 : 0);
        if (this.state.repeated_order == "yes") {
          formData.append("repeated_order_delivery_time", moment(this.state.repeated_order_delivery_time, ["h:mm A"]).format("HH:mm"));
          formData.append("repeated_order_pickup_time", moment(this.state.repeated_order_pickup_time, ["h:mm A"]).format("HH:mm"));
          if (this.state.schedule_type == 'daily') {
            formData.append("repeated_order_pickup_date", moment(this.state.next_week_pickup_date).format("YYYY-MM-DD"));
            formData.append("repeated_order_delivery_date", moment(this.state.next_week_delviery_date).format("YYYY-MM-DD"));
          } else {
            if (this.state.schedule_type == 'monthly') {
              formData.append("repeated_order_pickup_date", moment(this.state.repeated_order_pickup_date).format("YYYY-MM-DD"));
              formData.append("repeated_order_delivery_date", moment(this.state.repeated_order_delivery_date).format("YYYY-MM-DD"));
            } else {
              formData.append("repeated_order_pickup_date", moment().startOf('week').add('days', this.state.repeated_order_pickup_date.value).format("YYYY-MM-DD"));
              formData.append("repeated_order_delivery_date", moment().startOf('week').add('days', this.state.repeated_order_pickup_date.value).format("YYYY-MM-DD"));
            }
          }
        };
        this.setState({ isLoading: true, button_disable: true });
        let path = ApiRoutes.CREATE_EASY_PICK_ORDER;
        const res = await Http("POST", path, formData);
        if (res) {
          const resJson = await res.json();
          if (res.status == 200) {
            NotificationManager.success(resJson.message, "Success!", 3000);
            this.props.history.push("/manage-orders?easy_pickup=1");
            context.setSpecialRequest("");
            context.setGlobalPickUpaddress("");
            context.setGlobaldeliveryaddress("");
          } else {
            this.setState({ isLoading: false, show_topup_button: true });
            NotificationManager.error(resJson.message, "Error!", 3000);
          }
        } else {
          // this.setState({ isLoading: false });
          NotificationManager.error("Server Error", "Error!", 3000);
        }
      } else {
        var message = "";
        if (this.state.lang == 'en') {
          message = `The minimum e-pocket amount required to place an easy pickup order is ${this.state.minimum_wallet_amount_for_easy_pickup} OMR`;
        } else {
          message = `الحد الأدنى لمبلغ الجيب الإلكتروني المطلوب لتقديم طلب استلام سهل هو ${this.state.minimum_wallet_amount_for_easy_pickup} ريال عماني`
        }
        NotificationManager.error(message, "Error!", 3000);
      };
    } else {
      this.props.history.push({
        pathname: "/payment/" + this.state.vendor_id,
        state: {
          is_express_delivery: inputValues.is_express_delivery ? 1 : 0,
          delivery_method: this.state.delivery_method,
          pickup_address: inputValues.pickup_address,
          delivery_address: inputValues.delivery_address,
          pickup_date: moment(inputValues.pickup_date).format("YYYY-MM-DD"),
          pickup_time: moment(inputValues.pickup_time, ["h:mm A"]).format("HH:mm"),
          delivery_date: moment(inputValues.delivery_date).format("YYYY-MM-DD"),
          delivery_time: utcString,
          shampoo: state && state.addShampoos && state.addShampoos.name != "No Shampoo" ? state.addShampoos : "",
          perfume: state && state.addPerfumes && state.addPerfumes.name != "No Perfume" ? state.addPerfumes : "",
          softener: state && state.addSofteners && state.addSofteners.name != "No Softener" ? state.addSofteners : "",
          bleach: state && state.addBleaches && state.addBleaches.name != "No Bleach" ? state.addBleaches : "",
          sanitizer: state && state.addSanitizers && state.addSanitizers.name != "No Sanitizer" ? state.addSanitizers : "",
          is_seperate_wash: state && state.wash.name == "yes" ? 1 : 0,
          laundry_bag_count: state && state.laundrybag ? state.laundrybag : "",
          laundry_bag_charges: state && state.laundrybag && state.laundrybag.value == "Yes" ? settingInfo.laundry_bag_charge * parseInt(state.laundrybag.bags) : 0,
          addonCharges: addonCharges && addonCharges,
          adon_type: adon_type && adon_type,
          adon_text: this.state.adon_text && this.state.adon_text
        },
      });
    }
  };

  handleRepeatedOrderRadioChange = (event) => {
    if (event.target.value == "yes") {
      if (this.state.repeated_order_vendor_id != "") {
        if (this.state.vendorId != this.state.repeated_order_vendor_id) {
          var repeated_order = this.state.lang == 'en' ? window.confirm("You have already selected a repeated order in another laundry. Do you want to cancel that ?") : window.confirm("لقد قمت بالفعل بتحديد طلب متكرر في مغسلة أخرى. هل تريد إلغاء ذلك؟")
          if (repeated_order == true) {
            if (this.state.i == 0) {
              this.setState({ repeated_order_popup: true });
              this.state.i++;
            }
            this.setState({
              repeated_order: event.target.value,
            });
          } else {
            this.setState({
              repeated_order: "no",
            });
          }
        } else {
          if (this.state.i == 0) {
            this.setState({ repeated_order_popup: true });
            this.state.i++;
          }
          this.setState({
            repeated_order: event.target.value,
          });
        };
      } else {
        if (this.state.i == 0) {
          this.setState({ repeated_order_popup: true });
          this.state.i++;
        }
        this.setState({
          repeated_order: event.target.value,
        });
      }

    } else {
      this.setState({
        repeated_order: event.target.value,
      })
    }
  };

  handleScheduleTypeRadioChange = event => {
    if (event.target.value == 'daily') {
      var repeated_order_pickup_specific_date = new Date().getDay() + 1;
      for (let i = 0; i <= 6; i++) {
        if (repeated_order_pickup_specific_date > 6) {
          repeated_order_pickup_specific_date = repeated_order_pickup_specific_date % 7;
        };
        this.repeated_order_pickup_handleMinTime(new Date().setDate(repeated_order_pickup_specific_date));
        let pickup_first_shift_start;
        let pickup_first_shift_end;
        let pickup_second_shift_start;
        let pickup_second_shift_end;
        if (repeated_order_pickup_specific_date == 0) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
        }
        if (repeated_order_pickup_specific_date == 1) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
        }
        if (repeated_order_pickup_specific_date == 2) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
        }
        if (repeated_order_pickup_specific_date == 3) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
        }
        if (repeated_order_pickup_specific_date == 4) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
        }
        if (repeated_order_pickup_specific_date == 5) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
        }
        if (repeated_order_pickup_specific_date == 6) {
          pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
          pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
          pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
          pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
        };
        if (pickup_first_shift_start == 'Invalid Date' && pickup_first_shift_end == 'Invalid Date' && pickup_second_shift_start == 'Invalid Date' && pickup_second_shift_end == 'Invalid Date') {
          repeated_order_pickup_specific_date++;
        };
      };
      var repeated_order_delivery_specific_date = new Date().setDate(new Date().getDate() + this.state.vendorData.normal_delivery_time + 1);
      repeated_order_delivery_specific_date = new Date(repeated_order_delivery_specific_date).getDay();
      for (let i = 0; i <= 6; i++) {
        this.repeated_order_delivery_handleMinTime(new Date(new Date().getDate() + repeated_order_delivery_specific_date));
        if (repeated_order_delivery_specific_date > 6) {
          repeated_order_delivery_specific_date = repeated_order_delivery_specific_date % 7;
        };
        let delivery_first_shift_start;
        let delivery_first_shift_end;
        let delivery_second_shift_start;
        let delivery_second_shift_end;
        if (repeated_order_delivery_specific_date == 0) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
        }
        if (repeated_order_delivery_specific_date == 1) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
        }
        if (repeated_order_delivery_specific_date == 2) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
        }
        if (repeated_order_delivery_specific_date == 3) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
        }
        if (repeated_order_delivery_specific_date == 4) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
        }
        if (repeated_order_delivery_specific_date == 5) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
        }
        if (repeated_order_delivery_specific_date == 6) {
          delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
          delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
          delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
          delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
        }
        if (delivery_first_shift_start == 'Invalid Date' && delivery_first_shift_end == 'Invalid Date' && delivery_second_shift_start == 'Invalid Date' && delivery_second_shift_end == 'Invalid Date') {
          repeated_order_delivery_specific_date++;
        };
      };
      var d = new Date();
      d.setDate(d.getDate() + (((repeated_order_pickup_specific_date + 7 - d.getDay()) % 7) || 7));
      var d2 = new Date();
      d2.setDate(d2.getDate() + (((repeated_order_delivery_specific_date + 7 - d2.getDay()) % 7) || 7));
      this.setState({
        repeated_order_pickup_specific_date: repeated_order_pickup_specific_date,
        next_week_delviery_date: new Date(d2),
        repeated_order_delivery_specific_date: repeated_order_delivery_specific_date,
        next_week_pickup_date: new Date(d),
        repeated_order_auto_delivery_time: 0,
      });
    };
    this.setState({
      show_next_week_delviery_date: false,
      show_next_week_pickup_date: false,
      schedule_type: event.target.value,
      repeated_order_delivery_date: "",
      repeated_order_pickup_date: "",
      repeated_order_delivery_time: "",
      repeated_order_pickup_time: "",
    })
  }

  getAddresses = async () => {
    let path = ApiRoutes.GET_ADDRESSES;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          addressList: resJson.data.docs,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  checkExpressAvailbale = async () => {
    const { itemData, lang } = this.state;

    var result = itemData.filter((val) => {
      return val.service.category.is_express_available == 0;
    });

    var myArr = [];
    for (const cartItem of result) {
      myArr.push(lang && cartItem.service.subcategory.name[lang]);
    }
    this.setState({ myArr: myArr }, () => {
      if (myArr.length > 0) {
        this.showModal();
      }
    });
  };

  easy_pickup_onClick = () => {
    this.setState({
      easy_pickup_popup: !this.state.easy_pickup_popup,
    });
  };

  easy_pickup_onChecked = (e) => {
    if (e.target.checked) {
      this.setState({
        easy_pickup_terms_and_conditions: true,
        button_disable: false,
      });
    } else {
      this.setState({
        easy_pickup_terms_and_conditions: false,
        button_disable: true,
      });
    }
  };

  proceedTopupEPocket = (e) => {
    this.props.history.push(`/wallet-pay/`);
  }
  render() {
    var { authUser } = this.props;
    var { lang, addressList, itemData, vendorData, pickup_address, delivery_address, delivery_method, actual_express_delivery_time } = this.state;
    const { state } = this.props.location;
    const context = this.context;
    var actual_delivery_time = this.state.itemData.length > 0 ? this.state.delivery_method == 'normal' ? vendorData.normal_delivery_time : this.state.delivery_method == 'medium' ? vendorData.medium_delivery_time : vendorData.longer_delivery_time : vendorData.normal_delivery_time;
    return (
      <Translation>
        {(t) => (
          <ThemeContext.Consumer>
            {theme => (
              <div className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
                {" "}
                {!authUser && this.props.history.push("/login")}
                {authUser && (
                  <Container>
                    <div className="pl-md-4 pt-3 pr-md-4">
                      <div className="headingouter">
                        <h2>{t("commons.Checkout")}</h2>
                        {itemData.length == 0 && this.state.easy_pickup_no_free_delivery ? <p>{this.state.easy_pickup_no_free_delivery}</p> : ""}
                      </div>
                      {
                        this.state.mixedDeliveryErrorShowing &&
                        <div style={{ color: 'green', fontSize: '17px' }}>
                          {this.state.mixedDeliveryErrorShowing}</div>
                      }
                      <Formik
                        enableReinitialize
                        initialValues={{
                          is_express_delivery: this.state.is_express_delivery,
                          pickup_date: this.state.pickup_date,
                          pickup_specific_date: this.state.pickup_specific_date,
                          delivery_specific_date: this.state.delivery_specific_date,
                          delivery_date: this.state.delivery_date,
                          pickup_address: this.state.pickup_address,
                          delivery_address: this.state.delivery_address,
                          pickup_time: this.state.pickup_time,
                          delivery_time: this.state.delivery_time,
                          item_return_back: this.state.item_return_back,
                          service_type: this.state.service_type,
                          service_type_array: this.state.service_type_array,
                          adon_text: this.state.adon_text,
                          repeated_order: this.state.repeated_order,
                          schedule_type: this.state.schedule_type,
                          repeated_order_pickup_date: this.state.repeated_order_pickup_date,
                          repeated_order_pickup_time: this.state.repeated_order_pickup_time,
                          repeated_order_delivery_date: this.state.repeated_order_delivery_date,
                          repeated_order_delivery_time: this.state.repeated_order_delivery_time,
                        }}
                        validationSchema={this.state.itemData.length == 0 ? FormSchemaEasyPickup : FormSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({ handleSubmit, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                          <Form>
                            {this.state.package_express_delivery == 0 ?
                              vendorData && this.state.express_delivery_option == 1 ? (
                                <>
                                  <Row className="align-items-center">
                                    <Col md={6} className="arabic_innerheading">
                                      <div className="Innerheading1 d-flex justify-content-between align-items-center">
                                        <Label className="mb-3 mt-3">
                                          {t("labels.Express_Delivery")}{" "}
                                          {this.state.is_express_delivery ? (
                                            <strong>
                                              <span className="themeColor" style={{ marginLeft: "100px", color: theme.colors.primary }}>
                                                {expressDelivery && t("commons.OMR") + " " + expressDelivery.toFixed(3)}
                                              </span>
                                            </strong>
                                          ) : (
                                            " "
                                          )}
                                        </Label>{" "}
                                        <Switch
                                          checked={this.state.is_express_delivery ? true : false}
                                          onChange={(is_express_delivery) => {
                                            this.checkExpressAvailbale();
                                            context.setGlobalExpress(is_express_delivery);
                                            context.setGlobalPickUpdate("");
                                            context.setGlobalPickUptime("");
                                            context.setGlobaldeliverydate("");
                                            context.setGlobaldeliverytime("");
                                            this.setState({
                                              is_express_delivery: is_express_delivery,
                                              is_express_delivery_available: this.state.is_express_delivery_available == 0 ? 1 : 0,
                                              pickup_date: "",
                                              pickup_time: "",
                                              delivery_date: "",
                                              delivery_time: "",
                                              repeated_order_delivery_date: "",
                                              repeated_order_pickup_date: "",
                                              repeated_order_delivery_time: "",
                                              repeated_order_pickup_time: "",
                                            });
                                          }}
                                          onColor="#86d3ff"
                                          onHandleColor="#2693e6"
                                          handleDiameter={30}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={20}
                                          width={48}
                                          className={this.state.is_express_delivery ? "toggleMe" : "react-switch"}
                                          id="material-switch"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  {itemData.length == 0 && this.state.is_express_delivery ? <p style={{ marginTop: "1rem" }}>{this.state.lang == 'en' ? 'Express delivery charge will be calculated once the laundry updates the service items. Express delivery will not be applicable for repeated orders' : 'سيتم احتساب رسوم التوصيل السريع بمجرد قيام المغسلة بتحديث عناصر الخدمة. لن يتم تطبيق التسليم السريع على الطلبات المتكررة'}</p> : ""}
                                </>
                              ) : (
                                <div className="express_delivery_line"><hr /></div>
                              ) :
                              <div className="express_delivery_line"><hr /></div>
                            }
                            <Row>
                              <Col xs={12} md={3}>
                                <Label className="mb-3 mt-3">{t("commons.Pick_Up_Date")}</Label>
                                <FormGroup>
                                  <DatePicker
                                    className="form-control"
                                    selected={this.state.pickup_date}
                                    name="pickup_date"
                                    autoComplete="off"
                                    minDate={new Date()}
                                    maxDate={new Date().setDate(new Date().getDate() + 7)}
                                    placeholderText="MM/DD/YYYY"
                                    onChange={(date) => {
                                      let pickup_date = new Date(date);
                                      let delivery_date =
                                        vendorData && this.state.is_express_delivery_available && this.state.express_delivery_option == 1
                                          ? this.state.delivery_method == 'longer' ? new Date(date).setDate(new Date(date).getDate() + actual_express_delivery_time) : new Date(date).setHours(new Date(date).getHours() + actual_express_delivery_time + new Date(date).getHours())
                                          : new Date(date).setDate(new Date(date).getDate() + actual_delivery_time
                                          );
                                      context.setGlobalPickUpdate(pickup_date);
                                      context.setGlobaldeliverydate(delivery_date);
                                      context.setGlobalDeliveryMethod(this.state.delivery_method);
                                      var date_d = new Date(delivery_date);
                                      this.setState(
                                        {
                                          pickup_date: pickup_date,
                                          delivery_date: delivery_date,
                                          pickup_specific_date: pickup_date.getDay(),
                                          // delivery_specific_date: date_d.getDay(),
                                        },
                                        () => this.handleMinTime(this.state.pickup_date)
                                      );
                                    }}
                                  />
                                  {errors.pickup_date && touched.pickup_date ? <div className="invalid-feedback d-block">{t(errors.pickup_date)}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={3}>
                                <Label className="mb-3 mt-3">{t("commons.Pick_Up_Time")}</Label>
                                <FormGroup>
                                  <DatePicker
                                    className="form-control"
                                    selected={this.state.pickup_time}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption="Time"
                                    placeholderText={t("commons.Select_Time")}
                                    dateFormat="h:mm aa"
                                    minTime={this.state.minTime}
                                    maxTime={moment().endOf("day").toDate()}
                                    filterTime={this.filterPassedTime}
                                    onChange={(date) => {
                                      let pickup_time = new Date(date);
                                      let delivery_date =
                                        this.state.pickup_date && vendorData && this.state.is_express_delivery_available && this.state.express_delivery_option
                                          ? this.state.delivery_method == 'longer' ? new Date(this.state.pickup_date).setDate(new Date(this.state.pickup_date).getDate() + actual_express_delivery_time) :
                                            new Date(this.state.pickup_date).setHours(
                                              new Date(this.state.pickup_date).getHours() + actual_express_delivery_time + new Date(date).getHours()
                                            )
                                          : new Date(this.state.pickup_date).setDate(new Date(this.state.pickup_date).getDate() + actual_delivery_time);
                                      let delivery_time =
                                        this.state.is_express_delivery_available && this.state.express_delivery_option && vendorData && actual_express_delivery_time && new Date(date)
                                          ? this.state.delivery_method == 'longer' ? new Date(date) : new Date(date).setHours(new Date(date).getHours() + actual_express_delivery_time)
                                          : new Date(date);
                                      if (this.state.is_express_delivery_available == 1) {
                                        var deliveryTime_time = moment(new Date(delivery_time)).format("HH:mm:ss");
                                        var current_deliveryDate = moment(new Date()).format("ddd MMM DD YYYY");
                                        var concat_delivery_time = current_deliveryDate + " " + deliveryTime_time + " GMT+0530 (India Standard Time)";
                                        var actual_delivery_datetime = new Date(concat_delivery_time).getTime();
                                      } else {
                                        var actual_delivery_datetime = delivery_time;
                                      }

                                      var date_d = new Date(delivery_date);
                                      context.setGlobalPickUptime(pickup_time);
                                      context.setGlobaldeliverydate(delivery_date);
                                      context.setGlobaldeliverytime(new Date(actual_delivery_datetime));
                                      this.setState({
                                        pickup_time: pickup_time,
                                        orgin_delivery_time: actual_delivery_datetime,
                                        delivery_date: delivery_date,
                                        min_delivery_date: delivery_date,
                                        delivery_time: actual_delivery_datetime,
                                        delivery_specific_date: date_d.getDay(),
                                      });
                                    }}
                                  />
                                  {errors.pickup_time && touched.pickup_time ? <div className="invalid-feedback d-block">{t(errors.pickup_time)}</div> : null}
                                  {this.state.pickup_validation_error ? <div className="invalid-feedback d-block">{this.state.pickup_validation_error}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={3}>
                                <Label className="mb-3 mt-3">{t("commons.Delivery_Date")}</Label>
                                <FormGroup>
                                  <DatePicker
                                    className="form-control custom-cls"
                                    autoComplete="off"
                                    selected={this.state.delivery_date}
                                    name="delivery_date"
                                    onChange={(date) => {
                                      context.setGlobaldeliverydate(date);
                                      this.setState({ delivery_specific_date: date.getDay(), delivery_date: date, delivery_option_validation: 0 }, () => this.handleMinTime_delivery(this.state.delivery_date));
                                    }}
                                    minDate={new Date(this.state.min_delivery_date)}
                                    placeholderText="MM/DD/YYYY"
                                  />
                                  {errors.delivery_date && touched.delivery_date ? <div className="invalid-feedback d-block">{t(errors.delivery_date)}</div> : null}
                                </FormGroup>
                              </Col>
                              {this.state.auto_delivery_time == 1 ? (
                                <Col xs={12} md={3}>
                                  <Label className="mb-3 mt-3">{t("commons.Delivery_Time")}</Label>
                                  <FormGroup>
                                    <DatePicker
                                      className="form-control"
                                      selected={this.state.delivery_time}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={30}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                      // minTime={this.state.minTime_delivery}
                                      minTime={this.state.minTime_delivery ? this.state.minTime_delivery : new Date()}
                                      maxTime={moment().endOf("day").toDate() ? moment().endOf("day").toDate() : ""}
                                      filterTime={this.filterPassedTime__delivery}
                                      placeholderText={t("commons.Select_Time")}
                                      onChange={(date) => {
                                        let delivery_time = new Date(date).getTime();
                                        context.setGlobaldeliverytime(delivery_time);
                                        this.setState({
                                          delivery_time: delivery_time,
                                        });
                                      }}
                                    />
                                    {errors.delivery_time && touched.delivery_time ? <div className="invalid-feedback d-block">{t(errors.delivery_time)}</div> : null}
                                  </FormGroup>
                                </Col>
                              ) :
                                <Col xs={12} md={3}>
                                  <Label className="mb-3 mt-3">{t("commons.Delivery_Time")}</Label>
                                  <FormGroup>
                                    <DatePicker
                                      className="form-control"
                                      selected={this.state.delivery_time}
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={30}
                                      timeCaption="Time"
                                      dateFormat="h:mm aa"
                                      // minTime={this.state.minTime_delivery ? this.state.minTime_delivery : new Date()}
                                      minTime={this.state.orgin_delivery_time}
                                      maxTime={moment().endOf("day").toDate()}
                                      filterTime={this.filterPassedTime__delivery}
                                      placeholderText={t("commons.Select_Time")}
                                      onChange={(date) => {
                                        let delivery_time = new Date(date).getTime();
                                        context.setGlobaldeliverytime(delivery_time);
                                        this.setState({
                                          delivery_time: delivery_time,
                                        });
                                      }}
                                    />
                                    {errors.delivery_time && touched.delivery_time ? <div className="invalid-feedback d-block">{t(errors.delivery_time)}</div> : null}
                                  </FormGroup>
                                </Col>
                              }
                            </Row>
                            <Row>
                              <Col xs={12} md={6}>
                                <Label className="mb-3 mt-3">{t("commons.Pick_Up_Address")}</Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="pickup_address"
                                    value={
                                      this.state.pickup_address
                                        ? this.state.pickup_address.appartment +
                                        ", " +
                                        this.state.pickup_address.street +
                                        ", " +
                                        this.state.pickup_address.landmark +
                                        this.state.pickup_address.city +
                                        ", " +
                                        this.state.pickup_address.state +
                                        ", " +
                                        this.state.pickup_address.country +
                                        ", " +
                                        this.state.pickup_address.pincode
                                        : ""
                                    }
                                    as="textarea"
                                    placeholder={t("commons.Select_an_address")}
                                    onClick={() => {
                                      this.toggleAddressModal();
                                    }}
                                  />
                                  {errors.pickup_address && touched.pickup_address ? <div className="invalid-feedback d-block">{t(errors.pickup_address)}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={12} md={6}>
                                <Label className="mb-3 mt-3">{t("commons.Delivery_Address")}</Label>
                                <FormGroup>
                                  <Field
                                    className="form-control"
                                    name="delivery_address"
                                    value={
                                      this.state.delivery_address
                                        ? this.state.delivery_address.appartment +
                                        ", " +
                                        this.state.delivery_address.street +
                                        ", " +
                                        this.state.delivery_address.landmark +
                                        this.state.delivery_address.city +
                                        ", " +
                                        this.state.delivery_address.state +
                                        ", " +
                                        this.state.delivery_address.country +
                                        ", " +
                                        this.state.delivery_address.pincode
                                        : ""
                                    }
                                    as="textarea"
                                    placeholder={t("commons.Select_an_address")}
                                    onClick={() => {
                                      this.toggleDeliveryAddressModal();
                                    }}
                                  />
                                  {errors.delivery_address && touched.delivery_address ? <div className="invalid-feedback d-block">{t(errors.delivery_address)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            {(state && state.wash != "" && state.wash.name != "no") ||
                              (state && state.laundrybag && Object.keys(state.laundrybag).length != 0 && state.laundrybag.value != "No") ||
                              (state && state.addShampoos && Object.keys(state.addShampoos).length != 0) ||
                              (state && state.addBleaches && Object.keys(state.addBleaches).length != 0) ||
                              (state && state.addPerfumes && Object.keys(state.addPerfumes).length != 0) ||
                              (state && state.addSofteners && Object.keys(state.addSofteners).length != 0) ||
                              (state && state.addSanitizers && Object.keys(state.addSanitizers).length != 0) ? (
                              <Row className="selected_adonsouter ml-0 mr-0 mb-3 mt-3 ">
                                <Col md="12">
                                  <h5>{t("commons.Selected_AddOns")}</h5>
                                </Col>
                                {state && state.wash != "" && state.wash.name != "no" ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Separate_Wash")}:
                                      <span className="themeColor" style={{ textTransform: "capitalize" }}>
                                        {" " + state.wash && state.wash.name == "yes" && lang == "en" ? "Yes" : "نعم"}
                                      </span>
                                      <span className="themeColor">{state.wash && state.wash.name == "yes" ? t("commons.OMR") + " " + state.wash.price : ""}</span>
                                    </h6>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {state && Object.keys(state.laundrybag).length != 0 && state.laundrybag.value != "No" ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Laundry_Bags")}:
                                      <span className="themeColor">
                                        {" " + state.laundrybag && state.laundrybag.value == "Yes" && lang == "en" ? "Yes" : "نعم"}
                                      </span>
                                      <span className="themeColor">
                                        {"  " + state.laundrybag && state.laundrybag.bags && settingInfo
                                          ? t("commons.OMR") + " " + (state.laundrybag.bags * settingInfo.laundry_bag_charge).toFixed(3)
                                          : ""}
                                      </span>
                                    </h6>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {state && Object.keys(state.addShampoos).length != 0 ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Selected_Shampoo")}:<span className="themeColor">{" " + state.addShampoos && lang && state.addShampoos.name[lang]}</span>
                                      <span className="themeColor">{"  " + state.addShampoos && t("commons.OMR") + " " + state.addShampoos.price.toFixed(3)}</span>
                                    </h6>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {state && Object.keys(state.addBleaches).length != 0 ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Selected_Bleach")}:<span className="themeColor">{" " + state.addBleaches && lang && state.addBleaches.name[lang]}</span>
                                      <span className="themeColor">{"  " + state.addBleaches && t("commons.OMR") + " " + state.addBleaches.price.toFixed(3)}</span>
                                    </h6>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {state && Object.keys(state.addPerfumes).length != 0 ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Selected_Perfume")}:<span className="themeColor">{" " + state.addPerfumes && lang && state.addPerfumes.name[lang]}</span>
                                      <span className="themeColor">{"  " + state.addPerfumes && t("commons.OMR") + " " + state.addPerfumes.price.toFixed(3)}</span>
                                    </h6>
                                  </Col>
                                ) : (
                                  " "
                                )}
                                {state && Object.keys(state.addSofteners).length != 0 ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Selected_Softener")}:<span className="themeColor">{" " + state.addSofteners && lang && state.addSofteners.name[lang]}</span>
                                      <span className="themeColor">{"  " + state.addSofteners && t("commons.OMR") + " " + state.addSofteners.price.toFixed(3)}</span>
                                    </h6>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                {state && Object.keys(state.addSanitizers).length != 0 ? (
                                  <Col xs={12} md={6}>
                                    <h6 className="mb-3 mt-3">
                                      {t("commons.Selected_Sanitizer")}:<span className="themeColor">{" " + state.addSanitizers && lang && state.addSanitizers.name[lang]}</span>
                                      <span className="themeColor">{"  " + state.addSanitizers && t("commons.OMR") + " " + state.addSanitizers.price.toFixed(3)}</span>
                                    </h6>
                                  </Col>
                                ) : (
                                  " "
                                )}
                              </Row>
                            ) : (
                              ""
                            )}
                            {itemData.length == 0 ? (
                              <Row>
                                <Col xs={12} md={3}>
                                  <Label className="mb-3 mt-3">{t("commons.Service_Type")}</Label>
                                  <FormGroup>
                                    <Select
                                      id="service_type"
                                      name="service_type"
                                      isMulti={false}
                                      placeholderText={t("commons.Select_Service_Type")}
                                      options={this.state.service_type_array}
                                      onChange={(event) => { this.setState({ service_type: event.value }); }}
                                      onBlur={setFieldTouched}
                                    />
                                    {errors.service_type && touched.service_type ? <div className="invalid-feedback d-block">{t(errors.service_type)}</div> : null}
                                  </FormGroup>
                                </Col>
                                <Col xs={12} md={3}>
                                  <Label className="mb-3 mt-3">{t("commons.Items_Return_back")}</Label>
                                  <FormGroup>
                                    <Select
                                      id="items_return_back"
                                      name="items_return_back"
                                      isMulti={false}
                                      placeholderText={t("commons.Select_Items_Return_back")}
                                      options={this.state.clothe_return_types}
                                      onChange={(event) => { this.setState({ item_return_back: event.value }); }}
                                      onBlur={setFieldTouched}
                                    />
                                    {errors.item_return_back && touched.item_return_back ? <div className="invalid-feedback d-block">{t(errors.item_return_back)}</div> : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            ) : (null)
                            }
                            {itemData.length == 0 && this.state.show_repeated_order ? (
                              <Row>
                                <Col xs={12} md={6}>
                                  <Label className="mb-3 mt-3">{t("commons.Repeated_Order")}</Label>
                                  <div class="form-check display_flex form-group repeated_order">
                                    <div className={this.state.lang == 'en' ? "margin-right" : ""}>
                                      <input
                                        checked={this.state.repeated_order == 'yes'}
                                        value="yes"
                                        class="form-check-radio"
                                        onChange={(event) => this.handleRepeatedOrderRadioChange(event)}
                                        type="radio"
                                        name="repeated_order"
                                        id="repeated_order_yes"
                                      />
                                      <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="repeated_order_yes">
                                        {lang == 'en' ? 'Yes' : 'نعم'}
                                      </label>{" "}
                                    </div>
                                    <div className="margin-right">
                                      <input
                                        checked={this.state.repeated_order == 'no'}
                                        value="no"
                                        class="form-check-radio"
                                        onChange={(event) => this.handleRepeatedOrderRadioChange(event)}
                                        type="radio"
                                        name="repeated_order"
                                        id="repeated_order_no"
                                      />
                                      <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="repeated_order_no">
                                        {lang == 'en' ? 'No' : 'لا'}
                                      </label>{" "}
                                    </div>
                                  </div> {" "}
                                  {errors.repeated_order && touched.repeated_order ? <div className="invalid-feedback d-block">{t(errors.repeated_order)}</div> : null}
                                </Col>
                              </Row>
                            ) : (null)}
                            {this.state.repeated_order == 'yes' ?
                              <Row>
                                <Col xs={12} md={7}>
                                  <div className="schedule_type_icon">
                                    <Label className="mb-3 mt-3">{t("commons.Choose_schedule_type")}</Label>
                                    <ReactToolTip theme="dark" isDom={true} position="top" title={this.state.lang == 'en' ? title : title1}>
                                      <i class="fas fa-info" ></i>
                                    </ReactToolTip>
                                  </div>
                                  <div class="form-check display_flex form-group">
                                    <div className={this.state.lang == 'en' ? "margin-right positions-relative" : ""}>
                                      <input
                                        value="monthly"
                                        checked={this.state.schedule_type == 'monthly'}
                                        class="form-check-radio"
                                        onChange={this.handleScheduleTypeRadioChange}
                                        type="radio" />
                                      <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                        {lang == 'en' ? 'Monthly' : 'شهريا'}
                                      </label>{" "}
                                    </div>
                                    <div className="margin-right positions-relative">
                                      <input
                                        value="weekly"
                                        checked={this.state.schedule_type == 'weekly'}
                                        class="form-check-radio"
                                        onChange={this.handleScheduleTypeRadioChange}
                                        type="radio" />
                                      <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                        {lang == 'en' ? 'Weekly' : 'أسبوعي'}
                                      </label>{" "}
                                    </div>
                                    <div className="margin-right positions-relative">
                                      <input
                                        value="bi-weekly"
                                        checked={this.state.schedule_type == 'bi-weekly'}
                                        class="form-check-radio"
                                        onChange={this.handleScheduleTypeRadioChange}
                                        type="radio" />
                                      <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                        {lang == 'en' ? 'Bi-Weekly' : 'نصف أسبوعي'}
                                      </label>{" "}
                                    </div>
                                    <div className="margin-right positions-relative">
                                      <input
                                        value="daily"
                                        checked={this.state.schedule_type == 'daily'}
                                        class="form-check-radio"
                                        onChange={this.handleScheduleTypeRadioChange}
                                        type="radio" />
                                      <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                        {lang == 'en' ? 'Daily' : 'يوميًا'}
                                      </label>{" "}
                                    </div>
                                  </div> {" "}
                                  {errors.schedule_type && touched.schedule_type ? <div className="invalid-feedback d-block">{t(errors.schedule_type)}</div> : null}
                                </Col>
                              </Row>
                              : ""}
                            <Row>
                              {this.state.schedule_type == 'monthly' && this.state.repeated_order == 'yes' ?
                                <>
                                  <Col xs={12} md={3}>
                                    <Label className="mb-3 mt-3">{t("labels.Schedule Date")}</Label>
                                    <FormGroup>
                                      <DatePicker
                                        autoComplete="off"
                                        placeholderText="MM/DD/YYYY"
                                        className="form-control"
                                        name="repeated_order_pickup_date"
                                        minDate={new Date().setDate(new Date().getDate() + 3)}
                                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(new Date(new Date().setMonth(new Date().getMonth() + 1)).getDate() + 2)}
                                        selected={this.state.repeated_order_pickup_date}
                                        onChange={(date) => {
                                          let repeated_order_pickup_date = new Date(date);
                                          let repeated_order_delivery_date = this.state.is_express_delivery_available == 1 ? new Date(date) : new Date(date).setDate(new Date(date).getDate() + actual_delivery_time);
                                          let max_repeated_order_delivery_date = new Date(repeated_order_delivery_date).setDate(new Date(repeated_order_delivery_date).getDate() + 7);
                                          this.setState({
                                            min_repeated_order_delivery_date: repeated_order_delivery_date,
                                            repeated_order_pickup_date: repeated_order_pickup_date,
                                            repeated_order_delivery_date: repeated_order_delivery_date,
                                            max_repeated_order_delivery_date: max_repeated_order_delivery_date,
                                            repeated_order_pickup_specific_date: repeated_order_pickup_date.getDay(),
                                            repeated_order_delivery_specific_date: new Date(repeated_order_delivery_date).getDay(),
                                          }, () => this.repeated_order_pickup_handleMinTime(this.state.repeated_order_pickup_date))
                                        }}
                                      />
                                      {errors.repeated_order_pickup_date && touched.repeated_order_pickup_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_pickup_date)}</div> : null}
                                    </FormGroup>
                                  </Col>
                                </>
                                : (null)}
                              {(this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly') && this.state.repeated_order == 'yes' ?
                                <>
                                  <Col xs={12} md={3}>
                                    <FormGroup
                                      style={{ flex: '4' }}
                                      className='form-group has-float-label'
                                    >
                                      <Label className="mb-3 mt-3">{t("lables.Schedule Day")}</Label>
                                      <Select
                                        name="repeated_order_pickup_date"
                                        value={this.state.repeated_order_pickup_date}
                                        isMulti={false}
                                        options={this.state.lang == 'en' ? weeklyOpts : weeklyOpts1}
                                        onChange={(e) => {
                                          var current_day = new Date().getDay();
                                          var current_day_plus_one = (current_day + 1) > 6 ? ((current_day + 1) % 7) : (current_day + 1);
                                          var current_day_plus_two = (current_day + 1) > 6 ? ((current_day + 2) % 7) : (current_day + 2);
                                          this.setState({ show_next_week_pickup_date: true });
                                          var repeated_order_delivery_date_temp = {};
                                          var options = this.state.lang == 'en' ? weeklyOpts : weeklyOpts1;
                                          var actual_delivery_days = this.state.is_express_delivery_available == 1 ? 0 : actual_delivery_time;
                                          var days_temp = (parseInt(e.value) + actual_delivery_days);
                                          var days = 0;
                                          if (days_temp > 6) {
                                            days = days_temp % 7;
                                            var d = new Date();
                                            d.setDate(d.getDate() + (((parseInt(e.value) + 7 - d.getDay()) % 7) || 7));
                                            if (current_day_plus_one == parseInt(e.value) || current_day_plus_two == parseInt(e.value)) {
                                              d.setDate(d.getDate() + 7)
                                            };
                                            this.setState({
                                              show_next_week_delviery_date: true,
                                              next_week_delviery_date: new Date(d).setDate(d.getDate() + actual_delivery_days),
                                              next_week_pickup_date: new Date(d),
                                            });
                                          } else {
                                            days = days_temp;
                                            var d = new Date();
                                            d.setDate(d.getDate() + (((parseInt(e.value) + 7 - d.getDay()) % 7) || 7));
                                            if (current_day_plus_one == parseInt(e.value) || current_day_plus_two == parseInt(e.value)) {
                                              d.setDate(d.getDate() + 7)
                                            };
                                            this.setState({
                                              show_next_week_delviery_date: true,
                                              next_week_delviery_date: new Date(d).setDate(d.getDate() + actual_delivery_days),
                                              next_week_pickup_date: new Date(d),
                                            });
                                          };
                                          options.map((optionsTemp) => {
                                            if (parseInt(optionsTemp.value) == days) {
                                              repeated_order_delivery_date_temp = optionsTemp
                                            }
                                          });
                                          this.setState({
                                            repeated_order_pickup_date: e,
                                            repeated_order_pickup_specific_date: parseInt(e.value),
                                            repeated_order_delivery_specific_date: parseInt(repeated_order_delivery_date_temp.value),
                                            repeated_order_delivery_date: repeated_order_delivery_date_temp,
                                          }, () => this.repeated_order_pickup_handleMinTime(this.state.repeated_order_pickup_date.value))
                                        }}
                                        onBlur={setFieldTouched}
                                      />
                                      {errors.repeated_order_pickup_date && touched.repeated_order_pickup_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_pickup_date)}</div> : null}
                                    </FormGroup>
                                  </Col>
                                </>
                                : (null)
                              }
                              {(this.state.schedule_type == 'daily' || this.state.schedule_type == 'bi-weekly' || this.state.schedule_type == 'weekly' || this.state.schedule_type == 'monthly') && this.state.repeated_order == 'yes' ?
                                <>
                                  <Col xs={12} md={3}>
                                    <Label className="mb-3 mt-3">{t("commons.Pick_Up_Time")}</Label>
                                    <FormGroup>
                                      <DatePicker
                                        className="form-control"
                                        selected={this.state.repeated_order_pickup_time}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={30}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        placeholderText={t("commons.Select_Time")}
                                        minTime={this.state.repeated_order_pickup_minTime}
                                        maxTime={moment().endOf("day").toDate()}
                                        filterTime={this.repeated_order_pickup_filterPassedTime}
                                        onChange={(date) => {
                                          this.setState({
                                            repeated_order_pickup_time: new Date(date),
                                            repeated_order_delivery_time: new Date(date),
                                          });
                                        }}
                                      />
                                      {errors.repeated_order_pickup_time && touched.repeated_order_pickup_time ? <div className="invalid-feedback d-block">{t(errors.repeated_order_pickup_time)}</div> : null}
                                    </FormGroup>
                                  </Col>
                                  {this.state.schedule_type == 'monthly' ?
                                    <Col xs={12} md={3}>
                                      <Label className="mb-3 mt-3">{t("commons.Delivery_Date")}</Label>
                                      <FormGroup>
                                        <DatePicker
                                          className="form-control custom-cls"
                                          autoComplete="off"
                                          selected={this.state.repeated_order_delivery_date}
                                          name="repeated_order_delivery_date"
                                          maxDate={new Date(this.state.max_repeated_order_delivery_date)}
                                          minDate={new Date(this.state.min_repeated_order_delivery_date)}
                                          onChange={(date) => {
                                            if (this.state.is_express_delivery_available == 1 && (this.state.repeated_order_pickup_date == moment(date).format("MM/DD/YYYY"))) {
                                              this.setState({
                                                repeated_order_schedule_popup: true,
                                                repeated_order_schedule_popup_text: "Every month on the following day, your order will be delivered",
                                              })
                                            }
                                            this.setState({
                                              repeated_order_delivery_specific_date: date.getDay(),
                                              repeated_order_delivery_date: date,
                                              repeated_order_delivery_option_validation: 0,
                                            },
                                              () => this.repeated_order_delivery_handleMinTime(this.state.repeated_order_delivery_date));
                                          }}
                                          placeholderText="MM/DD/YYYY"
                                        />
                                        {errors.repeated_order_delivery_date && touched.repeated_order_delivery_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_date)}</div> : null}
                                      </FormGroup>
                                    </Col>
                                    : ""
                                  }
                                  {this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly' ?
                                    <Col xs={12} md={3}>
                                      <FormGroup
                                        style={{ flex: '4' }}
                                        className='form-group has-float-label'
                                      >
                                        <Label className="mb-3 mt-3">{t("lables.Delivery_day")}</Label>
                                        <Select
                                          name='repeated_order_delivery_date'
                                          id='weekly'
                                          value={this.state.repeated_order_delivery_date}
                                          isMulti={false}
                                          options={this.state.lang == 'en' ? weeklyOpts : weeklyOpts1}
                                          onChange={(e) => {
                                            var actual_delivery_days = this.state.is_express_delivery_available == 1 ? 0 : actual_delivery_time;
                                            var d1 = new Date(this.state.next_week_pickup_date).setDate(new Date(this.state.next_week_pickup_date).getDate() + actual_delivery_days);
                                            var d = new Date(d1);
                                            if (new Date(d1).getDay() == parseInt(e.value)) {
                                              this.setState({ next_week_delviery_date: d });
                                            } else {
                                              d.setDate(d.getDate() + (((parseInt(e.value) + 7 - d.getDay()) % 7) || 7));
                                              this.setState({ next_week_delviery_date: new Date(d) });
                                            }
                                            this.setState({
                                              repeated_order_delivery_date: e,
                                              repeated_order_delivery_specific_date: parseInt(e.value),
                                            }, () => this.repeated_order_delivery_handleMinTime(this.state.repeated_order_delivery_date.value))
                                          }}
                                          onBlur={setFieldTouched}
                                        />
                                        {errors.repeated_order_delivery_date && touched.repeated_order_delivery_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_date)}</div> : null}
                                      </FormGroup>
                                    </Col>
                                    : ""
                                  }
                                  {this.state.repeated_order_auto_delivery_time == 1 ? (
                                    <Col xs={12} md={3}>
                                      <Label className="mb-3 mt-3">{t("commons.Delivery_Time")}</Label>
                                      <FormGroup>
                                        <DatePicker
                                          className="form-control"
                                          selected={this.state.repeated_order_delivery_time}
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={30}
                                          timeCaption="Time"
                                          dateFormat="h:mm aa"
                                          // minTime={this.state.repeated_order_delivery_time}
                                          minTime={this.state.repeated_order_delivery_minTime ? this.state.repeated_order_delivery_minTime : new Date()}
                                          maxTime={moment().endOf("day").toDate() ? moment().endOf("day").toDate() : ""}
                                          filterTime={this.repeated_order_delivery_filterPassedTime}
                                          placeholderText={t("commons.Select_Time")}
                                          onChange={(date) => {
                                            this.setState({
                                              repeated_order_delivery_time: new Date(date),
                                            });
                                          }}
                                        />
                                        {errors.repeated_order_delivery_time && touched.repeated_order_delivery_time ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_time)}</div> : null}
                                      </FormGroup>
                                    </Col>
                                  ) : (
                                    <Col xs={12} md={3}>
                                      <Label className="mb-3 mt-3">{t("commons.Delivery_Time")}</Label>
                                      <FormGroup>
                                        <DatePicker
                                          className="form-control"
                                          selected={this.state.repeated_order_delivery_time}
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={30}
                                          timeCaption="Time"
                                          dateFormat="h:mm aa"
                                          minTime={this.state.repeated_order_pickup_time}
                                          // minTime={this.state.repeated_order_delivery_minTime ? this.state.repeated_order_delivery_minTime : new Date()}
                                          maxTime={moment().endOf("day").toDate() ? moment().endOf("day").toDate() : ""}
                                          filterTime={this.repeated_order_delivery_filterPassedTime}
                                          placeholderText={t("commons.Select_Time")}
                                          onChange={(date) => {
                                            this.setState({
                                              repeated_order_delivery_time: new Date(date),
                                            });
                                          }}
                                        />
                                        {errors.repeated_order_delivery_time && touched.repeated_order_delivery_time ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_time)}</div> : null}
                                      </FormGroup>
                                    </Col>
                                  )}

                                </>
                                : (null)
                              }
                            </Row>
                            {(this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly') && this.state.repeated_order == 'yes' ? (
                              <Row>
                                <Col xs={12} md={6} >
                                  {this.state.show_next_week_pickup_date ? <p>{t("Your order will be created from") + " " + this.state.repeated_order_pickup_date.label + " " + moment(this.state.next_week_pickup_date).format("DD/MM/YYYY")}</p> : ""}
                                </Col>
                                <Col xs={12} md={6}>
                                  {this.state.show_next_week_delviery_date ? (<p>{t("Your Order will be delivery on next") + " " + this.state.repeated_order_delivery_date.label + " " + moment(this.state.next_week_delviery_date).format("DD/MM/YYYY")}</p>) : ""}
                                </Col>
                              </Row>
                            ) : ""}
                            {this.state.schedule_type == 'daily' && this.state.repeated_order == 'yes' ? (
                              <Row>
                                <Col xs={12} md={3} >
                                  <p>{t("Your order will be created from") + " " + moment(this.state.next_week_pickup_date).format("DD/MM/YYYY")}</p>
                                </Col>
                                <Col xs={12} md={3}>
                                  <p>{t("Your Order will be delivery on next")} {moment(new Date().setDate(new Date().getDate() + this.state.vendorData.normal_delivery_time + 1)).format("DD/MM/YYYY")}</p>
                                </Col>
                              </Row>
                            ) : ""}
                            {this.state.customerPackage == 1 && this.state.itemData.length == 0 ?
                              <Row>
                                <Col xs={12} md={4} className="customer_package">
                                  <FormGroup>
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      onChange={(e) => { this.setState({ use_package: e.target.checked }) }}
                                    />{" "}
                                    <label htmlFor="input_check" className="mb-3 mt-3">{t("commons.apply-available-package")} </label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              : ""}
                            <Row>
                              <Col md={12} className="special_request">
                                <div className="copuncode ">
                                  <Row className="align-items-center">
                                    <Col xs={4} md={2} className="custom_coupon_cls">
                                      <h5>{t("commons.Special-Request")}</h5>
                                    </Col>
                                    <Col xs={20} md={10}>
                                      {this.state.adon_data.length != 0 ? (
                                        <Row className="justify-content-end">
                                          <Col xs={12} md={6}>
                                            <FormGroup>
                                              <Select
                                                options={this.state.adon_data}
                                                isMulti={true}
                                                value={this.state.adon_type}
                                                onChange={(event) => { this.adon_type(event) }}
                                                onBlur={setFieldTouched}
                                              >
                                              </Select>
                                            </FormGroup>
                                          </Col>
                                          {this.state.show_adon_text_filed == true ? (
                                            <Col xs={8} md={4}>
                                              <FormGroup>
                                                <Field
                                                  value={this.state.adon_text}
                                                  id="adon_text"
                                                  className="form-control"
                                                  name="adon_text"
                                                  type="text"
                                                  style={{ textTransform: 'capitalize' }}
                                                  placeholder={t("commons.Special-Request")}
                                                  onChange={(event) => {
                                                    const context = this.context;
                                                    this.setState({ adon_text: event.target.value })
                                                    setFieldValue("adon_text", event.target.value.trimStart());
                                                    this.setState({ adon_text: event.target.value, addressModal: false }, () => context.setSpecialRequestText(this.state.adon_text));
                                                  }}
                                                />
                                              </FormGroup>
                                              {errors.adon_text && touched.adon_text ? <div className="invalid-feedback d-block">{t(errors.adon_text)}</div> : null}
                                            </Col>
                                          ) : (null)
                                          }
                                        </Row>
                                      ) : (null)}
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                            {itemData.length != 0 ? (
                              <Row className="selected_orders ml-0 mr-0 mt-4">
                                <Col md={12}>
                                  <h5 className="mb-3 ">{t("commons.Order_Items")}</h5>
                                  <Row>
                                    {itemData &&
                                      itemData.map((item, index) => {
                                        return (
                                          <Col md={6} key={index}>
                                            <div className="catgoryproductouter">
                                              <div>
                                                <div className="d-flex">
                                                  <div className="productimgleft">
                                                    <img src={item.service.subcategory.image} alt={lang && item.service.subcategory.name[lang]}></img>
                                                  </div>
                                                  <div className="productdetails">
                                                    <h4>{lang && item.service.subcategory.name[lang]}</h4>
                                                    <p>
                                                      {t("labels.Category")} : {lang && item.service.category.name[lang]}{" "}
                                                    </p>
                                                    <p>
                                                      {item.delivery_method != 'normal' && item.delivery_time && (
                                                        <p>
                                                          {t("filters.Delivery_Time")}:{" "}
                                                          {lang &&
                                                            <span className="themeColor">
                                                              {t(item.delivery_time) + " " + t('commons.Days')}
                                                            </span>
                                                          }
                                                        </p>
                                                      )}
                                                      {item.delivery_method != 'normal' && item.express_delivery_time && (
                                                        <p>
                                                          {t("labels.Express_Delivery_time")}:{" "}
                                                          {lang &&

                                                            item.delivery_method == 'medium' ? (<span className="themeColor">

                                                              {t(item.express_delivery_time) + " " + t('commons.Hours')}
                                                            </span>) : (<span className="themeColor">

                                                              {t(item.express_delivery_time) + " " + t('commons.Days')}
                                                            </span>)
                                                          }
                                                        </p>
                                                      )}
                                                    </p>{" "}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row mt-3">
                                                <div className="col-md-12">
                                                  <ul>
                                                    <Slider {...sliderSettings}>
                                                      {item.service_items.map((service_type, i) => {
                                                        var totalprice = "";
                                                        if (service_type.measure_type == 'piece') {
                                                          totalprice = service_type.unit_quantity * service_type.price_by_measure + (service_type.unit_quantity * service_type.clothe_return_type.hanging_charge);
                                                        } else {
                                                          totalprice = service_type.unit_quantity * service_type.price_by_measure + service_type.clothe_return_type.hanging_charge;
                                                        }
                                                        return (
                                                          <div className="dryclean" key={i}>
                                                            <h5>{lang && service_type.service_type.name[lang]}</h5>
                                                            <p>
                                                              {t("commons.Qty")}:{" "}
                                                              <span className="themeColor" style={{ color: theme.colors.primary }}>
                                                                {service_type.measure_type === "weight"
                                                                  ? parseInt(service_type.quantity_by_measure) / 1000 + " " + t("commons.KG.")
                                                                  : service_type.measure_type === "meter"
                                                                    ? service_type.quantity_by_measure + " " + t("commons.Sq_Mts.")
                                                                    : service_type.quantity_by_measure + " " + t("commons.Pcs.")}
                                                              </span>
                                                            </p>
                                                            <p>
                                                              {t("labels.Item_Return_Type")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{lang && service_type.clothe_return_type.name[lang]}</span>
                                                            </p>{" "}

                                                            {service_type.PricePerUnit == 0 && service_type.PricePerUnit != undefined ? (
                                                              <p>
                                                                {" "}
                                                                {service_type.measure_type == "piece"
                                                                  ? t("commons.Price_per_piece")
                                                                  : service_type.measure_type == "weight"
                                                                    ? t("commons.Price_per_weight")
                                                                    : t("commons.Price_per_square_meter")}
                                                                : <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + service_type.PricePerUnit.toFixed(3)}</span>
                                                              </p>
                                                            ) : (
                                                              <p>
                                                                {" "}
                                                                {service_type.measure_type == "piece"
                                                                  ? t("commons.Price_per_piece")
                                                                  : service_type.measure_type == "weight"
                                                                    ? t("commons.Price_per_weight")
                                                                    : t("commons.Price_per_square_meter")}
                                                                : <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + service_type.price_by_measure.toFixed(3)}</span>
                                                              </p>
                                                            )

                                                            }

                                                            {service_type.clothe_return_type.hanging_charge >= 0 ? (
                                                              <p>
                                                                {" "}
                                                                {t("commons.Hanging_Charges")}:{" "}
                                                                <span className="themeColor" style={{ color: theme.colors.primary }}>
                                                                  {service_type.clothe_return_type.hanging_charge > 0
                                                                    ? t("commons.OMR") + " " + service_type.clothe_return_type.hanging_charge.toFixed(3)
                                                                    : t("commons.OMR") + " " + "0.000"}
                                                                </span>
                                                              </p>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {service_type.is_discount_subcategory == 'available' && service_type.DiscountPrice != 0 && <p className="subcategory_discount">
                                                              <span className="themeColor discount_latest">{" "}
                                                                {t("commons.Discount")} ({service_type.subcategory_discount_name}):</span> <span className="themeColor discount">{t("commons.OMR") + " " + service_type.DiscountPrice.toFixed(3)}</span>
                                                            </p>}
                                                            <p>
                                                              {" "}
                                                              {t("commons.Total_Price")}:
                                                              <span className="themeColor" style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + (service_type.TotalPrice.toFixed(3) - service_type.DiscountPrice.toFixed(3)).toFixed(3)}</span>
                                                            </p>
                                                          </div>
                                                        );
                                                      })}
                                                    </Slider>
                                                  </ul>{" "}
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        );
                                      })}{" "}
                                  </Row>
                                </Col>
                              </Row>
                            ) : (null)}
                            {this.state.itemData.length == 0 ?
                              <Row>
                                <Col xs={12} className="text-align-center">
                                  <input
                                    type="checkbox"
                                    checked={this.state.easy_pickup_terms_and_conditions == true}
                                    className="mr-2"
                                    onChange={(e) => { this.easy_pickup_onChecked(e) }}
                                  />{" "}
                                  <label className="mb-3 mt-3 cursor-pointer" onClick={(e) => this.easy_pickup_onClick()}>{t("commons.Easy pickup will not be applicable of")} <span style={{ fontWeight: 500, color: "#2f7dc9", textDecoration: "underline" }}>{t("longer delivery")}</span> {t("items")}<span style={{ color: "red" }}> *</span></label>
                                </Col>
                              </Row>
                              : ""
                            }
                            <center>
                              {itemData.length != 0 ? (
                                // <Button type="submit" className="mt-3">
                                //   {t("commons.Payment")}
                                // </Button>
                                <StyledButtondiv1 type="submit" className="mt-3">{t("commons.Payment")}</StyledButtondiv1>
                              ) : (
                                <>{this.state.isLoading ? (
                                  <div className="loading" />
                                ) : (this.state.show_topup_button ? (
                                  // <Button onClick={() => this.proceedTopupEPocket()}>
                                  //   {t("buttons.Topup ePocket")}
                                  // </Button>
                                  <StyledButtondiv1 onClick={() => this.proceedTopupEPocket()}>{t("buttons.Payment")}</StyledButtondiv1>
                                ) : (
                                  <StyledButtondiv1 type="submit" disabled={this.state.button_disable}>
                                    {t("commons.Place_order")}
                                  </StyledButtondiv1>
                                ))}
                                </>
                              )}
                            </center>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Container>
                )
                }
                <Modal className="modal_view_sec" isOpen={this.state.addressModal} backdrop={"static"} keyboard={false} toggle={this.toggleAddressModal}>
                  <div className="filter_modal_header">
                    <center>
                      <span>{t("commons.Select_Address")}</span>
                    </center>
                    <span onClick={this.hideaddressModal} style={{ cursor: "pointer" }} className="btn1">
                      {t("commons.Cancel")}
                    </span>
                  </div>
                  <ModalBody>
                    <FormGroup className="mb-5">
                      <ul className="daycheck_type_list_sec">
                        {addressList &&
                          addressList.map((item, index) => {
                            var address =
                              item.appartment + ", " + item.street + ", " + item.landmark + ", " + item.city + ", " + item.state + ", " + item.country + ", " + item.pincode;

                            return (
                              <div key={index} className="customer-radio">
                                <span
                                  style={{ cursor: "pointer" }}
                                  autoComplete="off"
                                  onClick={(e) =>
                                    this.onChangePickUpAddress(
                                      e,
                                      item.title,
                                      item.appartment,

                                      item.street,
                                      item.landmark,
                                      item.city,
                                      item.state,
                                      item.country,
                                      item.pincode,
                                      item.latitude,
                                      item.longitude
                                    )
                                  }
                                >
                                  {" "}
                                  <strong>{lang && item.title && item.title[lang]} -</strong> {address}
                                </span>

                                <hr />
                                <br />
                              </div>
                            );
                          })}

                        <Link
                          to={{
                            pathname: "/manage-address",
                          }}
                        >
                          <Button>{t("commons.Add_Address")}</Button>
                        </Link>
                      </ul>
                    </FormGroup>
                  </ModalBody>
                </Modal>
                <Modal className="modal_view_sec" isOpen={this.state.deliveryAddressModal} backdrop={"static"} keyboard={false} toggle={this.toggleDeliveryAddressModal}>
                  <div className="filter_modal_header">
                    <center>
                      <span>{t("commons.Select_Address")}</span>
                    </center>
                    <span onClick={this.hidedeliveryaddressModal} style={{ cursor: "pointer" }} className="btn1">
                      {t("commons.Cancel")}
                    </span>
                  </div>
                  <ModalBody>
                    <FormGroup className="mb-5">
                      <ul className="daycheck_type_list_sec">
                        {addressList &&
                          addressList.map((item, index) => {
                            var address =
                              item.appartment + ", " + item.street + ", " + item.landmark + ", " + item.city + ", " + item.state + ", " + item.country + ", " + item.pincode;
                            return (
                              <div key={index}>
                                <span
                                  style={{ cursor: "pointer" }}
                                  autoComplete="off"
                                  onClick={(e) =>
                                    this.onChangedeliveryAddress(
                                      e,
                                      item.title,
                                      item.appartment,
                                      item.street,
                                      item.landmark,
                                      item.city,
                                      item.state,
                                      item.country,
                                      item.pincode,
                                      item.latitude,
                                      item.longitude
                                    )
                                  }
                                >
                                  {" "}
                                  <strong>{lang && item.title && item.title[lang]} -</strong> {address}
                                </span>

                                <hr />
                                <br />
                              </div>
                            );
                          })}

                        <Link
                          to={{
                            pathname: "/manage-address",
                          }}
                        >
                          <Button>{t("commons.Add_Address")}</Button>
                        </Link>
                      </ul>
                    </FormGroup>
                  </ModalBody>
                </Modal>
                {/* Popup for Adon */}
                <Modal isOpen={this.state.openotherModal} backdrop={"static"} keyboard={false} style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "25%",
                }}>
                  <ModalBody>
                    <FormGroup>
                      <p style={{ margin: "15px 0", }}>{t("We try our best to fullfill your expected special request!!!")}</p>
                    </FormGroup>
                    <div className="with-100 text-align-right">
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.hideothersModal()}>
                        {t("OK")}
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
                {/* Popup for Repeated Order */}
                <Modal isOpen={this.state.repeated_order_popup} backdrop={"static"} keyboard={false} style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "25%",
                }}>
                  <ModalBody>
                    <FormGroup>
                      <p style={{ lineHeight: '20px' }}>{t("your order will be created repeated as per the schedule")}</p>
                    </FormGroup>
                    <div className="with-100 text-align-right">
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px", padding: "10px 15px" }} className="btn" onClick={() => this.setState({ repeated_order_popup: false })}>
                        {t("OK")}
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
                {/* Popup for easy pickup */}
                <Modal isOpen={this.state.easy_pickup_popup} backdrop={"static"} keyboard={false} style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "32%",
                }}>
                  <div className="filter_modal_header">
                    <center>
                      <span>{t("service items that are applicable for longer delivery method")}</span>
                    </center>
                  </div>
                  <ModalBody style={{ padding: "15px" }}>
                    <div style={{ padding: "15px", maxHeight: "175px", overflowX: "hidden" }}>
                      <ul>
                        {this.state.longer_delivery_method_array.map((longer_delivery_method_array_temp, index) => {
                          return (
                            <li style={{ marginBottom: '1rem' }}>{longer_delivery_method_array_temp.subcategory.name[this.state.lang]}</li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="with-100 text-align-right ">
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px", padding: "10px 20px" }} className="btn" onClick={() => this.setState({ easy_pickup_popup: !this.state.easy_pickup_popup })}>
                        {t("OK")}
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal isOpen={this.state.repeated_order_schedule_popup} backdrop={"static"} keyboard={false} style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "32%",
                }}>
                  <ModalBody>
                    <FormGroup>
                      <p style={{ margin: "15px 0" }}>{t(this.state.repeated_order_schedule_popup_text)}</p>
                    </FormGroup>
                    <div className="with-100 text-align-right">
                      <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ repeated_order_schedule_popup: false })}>
                        {t("OK")}
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
                {/* Modal for free delivery pop up */}
                {/* <Modal isOpen={this.state.freeDeliveryModal} backdrop={"static"} keyboard={false}>
              <ModalBody>{this.showPopUpBox(this.state.expressBackend)}</ModalBody>
              <ModalFooter>
                <div>
                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="btn"
                    onClick={() => {
                      this.setState({ freeDeliveryModal: false });
                    }}
                  >
                    {t("commons.OK")}
                  </button>
                </div>
              </ModalFooter>
            </Modal> */}
                {/* Modal for faster laundry express */}
                {/* <Modal isOpen={this.state.freeDeliveryModal} backdrop={"static"} keyboard={false}>
              <ModalBody>{this.showPopUpBox(this.state.expressBackend)}</ModalBody>
              <ModalFooter>
                <div>
                  <button
                    type="submit"
                    style={{ cursor: "pointer" }}
                    className="btn"
                    onClick={() => {
                      this.setState({ freeDeliveryModal: false });
                    }}
                  >
                    {t("commons.OK")}
                  </button>
                </div>
              </ModalFooter>
            </Modal> */}
                {/* Modal if express is not supported */}
                <Modal isOpen={this.state.isAvailable} backdrop={"static"} keyboard={false}>
                  <ModalBody>{response && response.props && response.props.children}</ModalBody>

                  <ModalFooter>
                    <div>
                      <button
                        type="submit"
                        style={{ cursor: "pointer" }}
                        className="btn"
                        onClick={() => {
                          this.setState({ isAvailable: false }, () => this.props.history.push("/cart"));
                        }}
                      >
                        {t("commons.OK")}
                      </button>
                    </div>
                  </ModalFooter>
                </Modal>
                <ModalLayoutAlert onClose={this.showModal} show={this.state.show}>
                  {t("commons.Your_cart_contains_the_item") + " - ( "}
                  {this.state.myArr && this.state.myArr + " ) "}
                  {t("commons.that_does_not_support_Express_Delivery._Please_remove_the_item_from_the_cart")}
                </ModalLayoutAlert>
              </div >
            )}
          </ThemeContext.Consumer>
        )
        }
      </Translation >
    );
  }
}
