import { baseUrl, apiAccessKey } from "../config/config";
import cryptLib from "@skavinvarnan/cryptlib";
import AuthService from "../services/auth.service";

export default (method, path, formData = undefined) => {
  var currentTimeStamp = Math.floor(Date.now() / 1000); // In seconds
  const accessToken = cryptLib.encryptPlainTextWithRandomIV(currentTimeStamp, apiAccessKey);
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const authToken = AuthService.getAuthToken();
  const apiUrl = baseUrl + path;

  const token = AuthService.getDeviceToken();
  const lang = localStorage.getItem("lang") || "en";

  return fetch(apiUrl, {
    method: method,
    body: formData,
    headers: {
      "X-Access-Token": accessToken ? accessToken : "",
      device_token: token ? token : "",
      Authorization: authToken ? "Bearer " + authToken : "",
      device_type: "web",
      language: lang,
      timezone: timeZone,
    },
  })
    .then((apiRes) => {
      // on login session expire error
      if ([401, 408].includes(apiRes.status)) {
        AuthService.clearAuthData();
        AuthService.clearDeviceData();
        window.location.reload();
      }

      return apiRes;
    })
    .catch((err) => {
      console.log(err.toString());
    });
};
