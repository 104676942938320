import React, { Component, useContext } from "react";
import { Container, Navbar, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";
import { NavLink, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";
import i18next from "i18next";
import { baseUrl } from "../../config/config";
import StyledNavLink from '../../helpers/StyledNavLink';
import StyledExternalLink from '../../helpers/StyledExternalLink';
import {StyledCartLink} from '../../helpers/StyledCartLink';
import StyledWithoutHoverLink from '../../helpers/StyledWithoutHoverLink';

//javascript scroll function
document.getElementById("OnscrollBody").onscroll = function () {
  var element = document.getElementById("navbar_scroll_fixed");
  if (element) {
    var scroll = window.scrollY;
    if (scroll <= 100) {
      element.classList.remove("fixed-top");
    } else {
      element.classList.add("fixed-top");
    }
  }
};

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

export default class Header extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      itemData: [],
      cartItems: {},
    };
  }

  handleLanguage = async (e) => {
    localStorage.setItem("lang", e.target.value);
    localStorage.setItem("wallet_location", 0);
    let formData = new FormData();
    formData.append("user_lang", e.target.value);
    let path = ApiRoutes.UPDATE_LANGUAGE;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        window.location.reload();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("validations.Server_Error"), "Error!", 3000);
    }
  };

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onLogout = async () => {
    let formData = new FormData();
    formData.append("device_udid", "");

    let path = ApiRoutes.LOGOUT;
    const res = await Http("PUT", path, formData);
    const resJson = await res.json();

    if (res.status == 200) {
      AuthService.clearAuthData();

      window.location.reload();
    } else {
      NotificationManager.error(resJson.message, "Error!", 3000);
    }
  };

  render() {
    const { authUser } = this.props;
    const context = this.context;

    this.state.cartItems = context.cartItems;
    const lang = localStorage.getItem("lang") || "en";

    let path = baseUrl + "/corporate";

    return (
      <Translation>
        {(t) => (
          <>
            <header id="navbar_scroll_fixed">
              <Container>
                <Navbar color="transparent" light expand="md">
                  <Link className="logo d-inline-block d-md-none" to="/">
                    <img src="./assets/img/logo.png" alt="LaundryHUB" loading="lazy" />
                  </Link>
                  <NavbarToggler onClick={this.toggle} />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="mr-auto custom-header-user" navbar>
                      <NavItem>
                        {/* <NavLink to="/" exact activeClassName="active" title="Home">
                          {t("menu.home")}
                        </NavLink> */}
                        <StyledNavLink to="/" exact activeClassName="active" title="Home">
                          {t("menu.home")}
                        </StyledNavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink to="/about-us" exact activeClassName="active" title="About Us">
                          {t("menu.aboutUs")}
                        </NavLink> */}
                        <StyledNavLink to="/about-us" exact activeClassName="active" title="About Us">
                          {t("menu.aboutUs")}
                        </StyledNavLink>
                      </NavItem>
                    </Nav>
                    <Link className="web_logo d-none d-md-inline-block" to="/">
                      <img src="./assets/img/web_logo.png" alt="LaundryHUB" />
                    </Link>
                    <Nav className="ml-auto" navbar>
                      <NavItem>
                        {/* <a href={path} target="_blank">
                          {t("menu.corporateAccess")}
                        </a> */}
                        <StyledExternalLink href={path} target="_blank">
                          {t("menu.corporateAccess")}
                        </StyledExternalLink>
                      </NavItem>

                      <NavItem>
                        {/* <NavLink to="/contact-us" exact activeClassName="active" title="Contact Us">
                          {t("menu.contactUs")}
                        </NavLink> */}
                        <StyledNavLink to="/contact-us" exact activeClassName="active" title="Contact Us">
                          {t("menu.contactUs")}
                        </StyledNavLink>
                      </NavItem>
                      <NavItem>
                        {/* <NavLink to="/FAQ" exact activeClassName="active" title="FAQ">
                          {t("menu.faq")}
                        </NavLink> */}
                        <StyledNavLink to="/FAQ" exact activeClassName="active" title="FAQ">
                          {t("menu.faq")}
                        </StyledNavLink>
                      </NavItem>

                      <NavItem className="login_signup d-flex">
                        {!authUser && (
                          <>
                            {/* <NavLink to="/login">{t("menu.login")}</NavLink> */}
                            <StyledCartLink to="/login">{t("menu.login")}</StyledCartLink>
                            <StyledWithoutHoverLink to="/register" className="sign_up ml-2 mr-1">{t("menu.signup")}</StyledWithoutHoverLink>
                            {/* <NavLink to="/register" className="sign_up ml-2 mr-1">
                              {t("menu.signup")}
                            </NavLink> */}
                          </>
                        )}
                        {authUser && (
                          <>
                            {/* <NavLink to="/cart" className="cartOuterbtn mr-2 ml-1">
                              {" "}
                              <i className="sign_up fas fa-shopping-cart" title="Cart">
                                {" "}
                                {this.state.cartItems && this.state.cartItems > 0 ? <span>{this.state.cartItems}</span> : ""}
                              </i>{" "}
                            </NavLink>{" "} */}
                            <StyledCartLink to="/cart" className="cartOuterbtn mr-2 ml-1">
                              <i className="fas fa-shopping-cart icon" title="Cart" />
                              {this.state.cartItems && this.state.cartItems > 0 ?
                                <span className="count">{this.state.cartItems}</span> : ""
                              }
                            </StyledCartLink>
                            {/* <NavLink to="/dashboard">{t("menu.dashboard")}</NavLink> */}
                            <StyledCartLink to="/dashboard">{t("menu.dashboard")}</StyledCartLink>
                            {/* <a className="sign_up ml-2 mr-1" onClick={this.onLogout}>
                              {t("menu.logout")}
                            </a> */}
                            <StyledWithoutHoverLink onClick={this.onLogout}>
                              {t("menu.logout")}
                            </StyledWithoutHoverLink>
                          </>
                        )}
                        <select onChange={this.handleLanguage} value={lang} className="select_lang m-0">
                          <option value="en">English</option>
                          <option value="ar">عربي</option>
                        </select>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </Container>
            </header>
          </>
        )}
      </Translation>
    );
  }
}
