import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import MainContext from "../../helpers/MainContext";
import {StyledCartLink} from '../../helpers/StyledCartLink';
import { NotificationManager } from "react-notifications";
import { Translation } from "react-i18next";

export default class Header extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      itemData: [],
      cartItems: {},
    };
  }

  componentDidMount() {
    var { authUser } = this.props;
    if (authUser) {
      this.getCartData();
    }
  }

  getCartData = async (currentPage = 1) => {
    const context = this.context;

    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            itemData: resJson.data.cartItems,
          },
          () => context.setCartItem(resJson.data.cartItems.length)
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    const { authUser } = this.props;

    const context = this.context;

    this.state.cartItems = context.cartItems;
    return (
      <Translation>
        {(t) => (
          <div className="after_login_header px-md-5 px-4">
            <nav className="navbar navbar-expand-md">
              <Link to="/" className="logo d-md-none">
                <img src="./assets/img/logo.png" alt="LaundryHUB" />
              </Link>
              <ul className="d-inline-flex justify-content-end ml-auto arabic_change">
                <li className="top_user_profile position-relative">
                  {/* <NavLink to="/cart" className="cartOuterbtn">
                    {" "}
                    <i className="sign_up fas fa-shopping-cart" title="Cart"></i> {this.state.cartItems && this.state.cartItems > 0 ? <span>{this.state.cartItems} </span> : ""}
                  </NavLink>{" "} */}
                    <StyledCartLink to="/cart" className="cartOuterbtn mr-2 ml-1">
                              <i className="fas fa-shopping-cart icon" title="Cart" />
                              {this.state.cartItems && this.state.cartItems > 0 ?
                                <span className="count">{this.state.cartItems}</span> : ""
                              }
                            </StyledCartLink>
                  <span className="user_pic_after_login">
                    <img src={authUser.user_image_url} alt="User" />
                  </span>
                  <span className="user_pic_name">{authUser.firstName + " " + authUser.lastName}</span>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </Translation>
    );
  }
}
