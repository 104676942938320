import React, { Component } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";
import { Tabs, Tab } from 'react-tab-view';
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import { Translation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import copyNewLight from '../../assets/img/new-copy-light.png'
import beforeCopy from '../../assets/img/before_copy.png'
import afterCopy from '../../assets/img/after_copy.png'
import AuthService from "../../services/auth.service";
var coords = AuthService.getCurrentLocation();

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

class Notifications extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      //tab section
      itemData: [],
      itemData_freedelivery: [],
      itemData_promocode_freedelivery: [],
      couponData: [],
      SubcategoryData: [],
      freeSortedArr: [],
      isloading: true,
      selectedtab: 1,
      copiedItem: "",
      //tab section
      currentTabIndex: 1,
      //get vendors section
      vendorsNewPackageList: [],
      vendorsActivatedPackageList: [],
      customerExpiredPackagesList: [],
      modal: false,
      service_providers: [],
      cleaning_typeList: [],
      tagsList: [],
      keyword: "",
      cleaning_type: "",
      service_provider: "",
      tag: "",
      delivery_time: "",
      medium_delivery_time: "",
      longer_delivery_time: "",
      rating: "",
      latitude: coords && coords.latitude,
      longitude: coords && coords.longitude,
      max_distance: 0,
      sort_by: "nearest",
      vendor_availablity_km: 25,
      currentPage: 1,
      limit: 10,
      hasMoreNewPackagesItems: true,
      hasMoreActivatedPackagesItems: true,
      hasMoreExpiredPackagesItems: true,
      resetData: false,
      lang: "",
      searchText: "",
      itemData: [],
      measure_type: "",
      freedelivery: '',
      packageData: '',
      vendor_package_data: 0,
      //get vendors section

    };
  }

  componentDidMount() {
    this.getVendorsNewlyCreatedPackage();
    this.getVendorsActivatedPackage();
    this.getAllExpiredCorporatePackages();
    this.setState({ lang: localStorage.getItem("lang") || "en" });

  }

  loadPagination_newly_created_package = async (currentPage) => {
    this.getVendorsNewlyCreatedPackage(currentPage + 1);
  };

  loadPagination_activated_package = async (currentPage) => {
    this.getVendorsActivatedPackage(currentPage + 1);
  };


  loadPagination_expired_package = async (currentPage) => {
    this.getAllExpiredCorporatePackages(currentPage + 1);
  };


  //get vendors newly created corporate package for customer
  getVendorsNewlyCreatedPackage = async (currentPage) => {
    let path =
      ApiRoutes.GET_VENDORS_NEWLY_ASSIGNED_CORPORATE_PACKAGE +
      "?page_no=" +
      `${currentPage || 1}` +
      "&limit=" +
      `${this.state.limit}` +
      "&keyword=" +
      `${this.state.searchText}` +
      "&latitude=" +
      `${this.state.latitude}` +
      "&longitude=" +
      `${this.state.longitude}` +
      "&max_distance=" +
      `${this.state.max_distance}` +
      "&sort_by=" +
      `${this.state.sort_by}`;
    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          isloading: false
        })
        if (currentPage == 1) {
          this.setState({ vendorsNewPackageList: [] }, () =>
            this.setState({
              vendorsNewPackageList: [...this.state.vendorsNewPackageList, ...resJson.data.docs],
              hasMoreNewPackagesItems: currentPage < resJson.data.totalPages ? true : false,
            })
          );
        } else {
          this.setState({
            vendorsNewPackageList: [...this.state.vendorsNewPackageList, ...resJson.data.docs],
            hasMoreNewPackagesItems: currentPage < resJson.data.totalPages ? true : false,
          });
        }
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  //get vendors activated corporate package by customer
  getVendorsActivatedPackage = async (currentPage) => {
    let path =
      ApiRoutes.GET_VENDORS_ACTIVATED_CORPORATE_PACKAGE +
      "?page_no=" +
      `${currentPage || 1}` +
      "&limit=" +
      `${this.state.limit}` +
      "&keyword=" +
      `${this.state.searchText}` +
      "&latitude=" +
      `${this.state.latitude}` +
      "&longitude=" +
      `${this.state.longitude}` +
      "&max_distance=" +
      `${this.state.max_distance}` +
      "&sort_by=" +
      `${this.state.sort_by}`;
    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        // this.setState({
        //   isloading: false
        // })
        if (currentPage == 1) {

          this.setState({ vendorsActivatedPackageList: [] }, () =>
            this.setState({
              vendorsActivatedPackageList: [...this.state.vendorsActivatedPackageList, ...resJson.data.docs],
              hasMoreActivatedPackagesItems: currentPage < resJson.data.totalPages ? true : false,
            })
          );
        } else {
          this.setState({
            vendorsActivatedPackageList: [...this.state.vendorsActivatedPackageList, ...resJson.data.docs],
            hasMoreActivatedPackagesItems: currentPage < resJson.data.totalPages ? true : false,
          });
        }
      }
      else if (res.status == 201) {

      }
      else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };


  //get all expired corporate packages by customer
  getAllExpiredCorporatePackages = async (currentPage) => {
    let path =
      ApiRoutes.GET_ALL_EXPIRED_CORPORATE_PACKAGES +
      "?page_no=" +
      `${currentPage || 1}` +
      "&limit=" +
      `${this.state.limit}` +
      "&keyword=" +
      `${this.state.searchText}` +
      "&latitude=" +
      `${this.state.latitude}` +
      "&longitude=" +
      `${this.state.longitude}` +
      "&max_distance=" +
      `${this.state.max_distance}` +
      "&sort_by=" +
      `${this.state.sort_by}`;
    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        // this.setState({
        //   isloading: false
        // })
        if (currentPage == 1) {
          this.setState({ customerExpiredPackagesList: [] }, () =>
            this.setState({
              customerExpiredPackagesList: [...this.state.customerExpiredPackagesList, ...resJson.data.docs],
              hasMoreExpiredPackagesItems: currentPage < resJson.data.totalPages ? true : false,
            })
          );
        } else {
          this.setState({
            customerExpiredPackagesList: [...this.state.customerExpiredPackagesList, ...resJson.data.docs],
            hasMoreExpiredPackagesItems: currentPage < resJson.data.totalPages ? true : false,
          });
        }
      }
      else if (res.status == 201) {

      }
      else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };


  render() {
    const { itemData, itemData_freedelivery, itemData_promocode_freedelivery, lang, couponData, SubcategoryData, freeSortedArr, vendorsNewPackageList, vendorsActivatedPackageList, customerExpiredPackagesList } = this.state;
    const { intl } = this.props;
    const theme = this.context;
    var headers1 = [];
    var headers2 = [];
    if (lang == 'en') {
      headers2 = ['New Packages', 'Activated Packages', 'Expired Packages'];
    } else {
      headers2 = ["حزم جديدة", "الحزم المنشطة", "الحزم منتهية الصلاحية"];
    }
    var temp = 0;
    // style={{ backgroundColor: "#5c95eb", color: "#fff", fontWeight: "700", padding: "4px 0px" }}
    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec corporate-list-pg py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <div className="customer-corporate-package-list-main py-3 px-5 py-md-4">
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="btn text-white py-2 px-3 toggle_btn d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <div className="d-flex clearCart align-items-center">
                <div className="w-100 d-flex justify-content-between my_cart_content_mb">
                  <div>
                    <h1 className="page_title mb-5 custom-page-tit-phem" style={{ width: "100%" }}>{t("menu.corporate-package")}</h1>
                  </div>
                </div>
              </div>

              {this.state.isloading ? (
                <div className="loading"></div>
              ) : (
                <Paper>
                  <Tabs ClassName="custom-bg-cls" headers={headers2}>
                    <Tab >
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination_newly_created_package} hasMore={this.state.hasMoreNewPackagesItems} loader={<div key={0} className="loader"></div>}>
                        {vendorsNewPackageList && Object.keys(vendorsNewPackageList).length > 0 ? (
                          <div className="customer-page list-sec">
                            <div className="promotions-package-list-customer-package">
                              <div className="promo-list-section-title">
                                <ul>
                                  <li style={{ width: "20%" }}>{t("label_package_name")}</li>
                                  <li style={{ width: "20%" }}>{t("label_vendor_name")}</li>
                                  <li style={{ width: "25%" }}>{t("label_package_category")}</li>
                                  <li style={{ width: "25%" }}>{t("label_package_subcategory")}</li>
                                  <li style={{ width: "15%" }}>{t("label_including_transportation")}</li>
                                  <li style={{ width: "15%" }}>{t("menu.month")}</li>

                                  {/* <li style={{ width: "17%" }}>{t("label_package_expiry_date")}</li> */}
                                </ul>
                              </div>
                              <span><hr /></span>
                              <div className="promo-list-pack">
                                {vendorsNewPackageList && vendorsNewPackageList.map((item, index) => {
                                  console.log(item, 'item');
                                  var vendor_name = item.shop_name && item.shop_name['en'];
                                  var category = [];
                                  var subcategory = [];
                                  item.new_main_categories.map((item1, index1) => {
                                    category.push(item1.name[lang]);
                                  })
                                  item.new_sub_categories.map((item2, index2) => {
                                    subcategory.push(item2.name[lang]);
                                  })
                                  var package_id = item.package_id;
                                  var vendor_id = item.vendor_id;
                                  var corporate_main_admin_id = item.corporate_main_admin_id;
                                  var corporate_sub_admin_id = item.corporate_sub_admin_id;
                                  var branch_location_id = item.branch_location_id;
                                  return (
                                    <div className="promo-list-sec-flex corporate-list-sec-flex">
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls1" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-package-name">{item.package_title[lang]}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls2" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-vendor-name">{vendor_name}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls3" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-category">{category.join(" ,")}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls4" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{subcategory.join(" ,")}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls5" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{t(item.with_or_without_trans)}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls6" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{t('expiry' + item.expiry_month)}</div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="corporate-list-pack">
                                {vendorsActivatedPackageList && vendorsActivatedPackageList.map((item, index) => {
                                  var vendor_name = item.shop_name && item.shop_name['en'];
                                  var category = [];
                                  var subcategory = [];
                                  item.new_main_categories.map((item1, index1) => {
                                    category.push(item1.name[lang]);
                                  })

                                  item.new_sub_categories.map((item2, index2) => {
                                    subcategory.push(item2.name[lang]);
                                  })
                                  var package_id = item.package_id;
                                  var vendor_id = item.vendor_id;
                                  var corporate_main_admin_id = item.corporate_main_admin_id;
                                  var corporate_sub_admin_id = item.corporate_sub_admin_id;
                                  var branch_location_id = item.branch_location_id;
                                  return (
                                    <div className="corporate-pack-list-sec" >
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.package_name")} : </b></span>
                                          <span>{item.package_title[lang]}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.laundry_name")} : </b></span>
                                          <span>{vendor_name}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.main_categories")} : </b></span>
                                          <span>{category.join(" ,")}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("label_including_transportation")} : </b></span>
                                          <span>{item.with_or_without_trans}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.month")} : </b></span>
                                          <span>{t('expiry' + item.corporate_assign_month)}</span>
                                        </p>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>

                            </div>
                          </div>
                        ) : (
                          <div>
                            <h3 className="no-corporate-package-tab" style={{color: theme.colors.primary}}>{t("label_no_Subscribed_package")}</h3>
                          </div>
                        )
                        }
                      </InfiniteScroll>
                    </Tab>
                    <Tab>
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination_activated_package} hasMore={this.state.hasMoreActivatedPackagesItems} loader={<div key={0} className="loader"></div>}>
                        {vendorsActivatedPackageList && Object.keys(vendorsActivatedPackageList).length > 0 ? (
                          <div className="customer-page list-sec">
                            <div className="promotions-package-list-customer-package">
                              <div className="promo-list-section-title">
                                <ul>
                                  <li style={{ width: "20%" }}>{t("label_package_name")}</li>
                                  <li style={{ width: "20%" }}>{t("label_vendor_name")}</li>
                                  <li style={{ width: "25%" }}>{t("label_package_category")}</li>
                                  <li style={{ width: "25%" }}>{t("label_package_subcategory")}</li>
                                  <li style={{ width: "15%" }}>{t("label_including_transportation")}</li>
                                  <li style={{ width: "15%" }}>{t("menu.month")}</li>
                                  {/* <li style={{ width: "17%" }}>{t("label_package_expiry_date")}</li> */}
                                </ul>
                              </div>
                              <span><hr /></span>
                              <div className="promo-list-pack">
                                {vendorsActivatedPackageList && vendorsActivatedPackageList.map((item, index) => {
                                  var vendor_name = item.shop_name && item.shop_name['en'];
                                  var category = [];
                                  var subcategory = [];
                                  item.new_main_categories.map((item1, index1) => {
                                    category.push(item1.name[lang]);
                                  })

                                  item.new_sub_categories.map((item2, index2) => {
                                    subcategory.push(item2.name[lang]);
                                  })
                                  var package_id = item.package_id;
                                  var vendor_id = item.vendor_id;
                                  var corporate_main_admin_id = item.corporate_main_admin_id;
                                  var corporate_sub_admin_id = item.corporate_sub_admin_id;
                                  var branch_location_id = item.branch_location_id;
                                  return (
                                    <div className="promo-list-sec-flex corporate-list-sec-flex">
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls1" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-package-name">{item.package_title[lang]}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls2" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-vendor-name">{vendor_name}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls3" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-category">{category.join(" ,")}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls4" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{subcategory.join(" ,")}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls5" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{t(item.with_or_without_trans)}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls6" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{t('expiry' + item.corporate_assign_month)}</div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="corporate-list-pack">
                                {vendorsActivatedPackageList && vendorsActivatedPackageList.map((item, index) => {
                                  var vendor_name = item.shop_name && item.shop_name['en'];
                                  var category = [];
                                  var subcategory = [];
                                  item.new_main_categories.map((item1, index1) => {
                                    category.push(item1.name[lang]);
                                  })

                                  item.new_sub_categories.map((item2, index2) => {
                                    subcategory.push(item2.name[lang]);
                                  })
                                  var package_id = item.package_id;
                                  var vendor_id = item.vendor_id;
                                  var corporate_main_admin_id = item.corporate_main_admin_id;
                                  var corporate_sub_admin_id = item.corporate_sub_admin_id;
                                  var branch_location_id = item.branch_location_id;
                                  return (
                                    <div className="corporate-pack-list-sec" >
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.package_name")} : </b></span>
                                          <span>{item.package_title[lang]}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.laundry_name")} : </b></span>
                                          <span>{vendor_name}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.main_categories")} : </b></span>
                                          <span>{category.join(" ,")}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("label_including_transportation")} : </b></span>
                                          <span>{item.with_or_without_trans}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.month")} : </b></span>
                                          <span>{t('expiry' + item.corporate_assign_month)}</span>
                                        </p>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>

                            </div>
                          </div>
                        ) : (
                          <div>
                            <h3 className="no-corporate-package-tab" style={{color: theme.colors.primary}}>{t("label_no_Subscribed_package")}</h3>
                          </div>
                        )
                        }
                      </InfiniteScroll>
                    </Tab>

                    <Tab>
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination_expired_package} hasMore={this.state.hasMoreExpiredPackagesItems} loader={<div key={0} className="loader"></div>}>
                        {customerExpiredPackagesList && Object.keys(customerExpiredPackagesList).length > 0 ? (
                          <div className="customer-page list-sec">
                            <div className="promotions-package-list-customer-package">
                              <div className="promo-list-section-title">
                                <ul>
                                  <li style={{ width: "20%" }}>{t("label_package_name")}</li>
                                  <li style={{ width: "20%" }}>{t("label_vendor_name")}</li>
                                  <li style={{ width: "25%" }}>{t("label_package_category")}</li>
                                  <li style={{ width: "25%" }}>{t("label_package_subcategory")}</li>
                                  <li style={{ width: "15%" }}>{t("label_including_transportation")}</li>
                                  <li style={{ width: "15%" }}>{t("menu.month")}</li>
                                  {/* <li style={{ width: "17%" }}>{t("label_package_expiry_date")}</li> */}
                                </ul>
                              </div>
                              <span><hr /></span>
                              <div className="promo-list-pack">
                                {customerExpiredPackagesList && customerExpiredPackagesList.map((item, index) => {
                                  var vendor_name = item.shop_name && item.shop_name['en'];
                                  var category = [];
                                  var subcategory = [];
                                  item.new_main_categories.map((item1, index1) => {
                                    category.push(item1.name[lang]);
                                  })

                                  item.new_sub_categories.map((item2, index2) => {
                                    subcategory.push(item2.name[lang]);
                                  })
                                  var package_id = item.package_id;
                                  var vendor_id = item.vendor_id;
                                  var corporate_main_admin_id = item.corporate_main_admin_id;
                                  var corporate_sub_admin_id = item.corporate_sub_admin_id;
                                  var branch_location_id = item.branch_location_id;
                                  return (
                                    <div className="promo-list-sec-flex corporate-list-sec-flex">
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls1" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-package-name">{item.package_title[lang]}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls2" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-vendor-name">{vendor_name}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls3" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-category">{category.join(" ,")}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls4" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{subcategory.join(" ,")}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls5" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{t(item.with_or_without_trans)}</div>
                                      </div>
                                      <div style={{ cursor: 'pointer' }} className="corporate-lst-pack-comon-sec package_cls6" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <div className="promo-subcategory">{t('expiry' + item.corporate_assign_month)}</div>
                                      </div>
                                      {/* <div className="promo-lst-pack-comon-sec package_cls5" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                            <div className="promo-expiry-date">{expiry_day}</div>
                                                        </div> */}
                                    </div>
                                  )
                                })}
                              </div>
                              <div className="corporate-list-pack">
                                {vendorsActivatedPackageList && vendorsActivatedPackageList.map((item, index) => {
                                  var vendor_name = item.shop_name && item.shop_name['en'];
                                  var category = [];
                                  var subcategory = [];
                                  item.new_main_categories.map((item1, index1) => {
                                    category.push(item1.name[lang]);
                                  })

                                  item.new_sub_categories.map((item2, index2) => {
                                    subcategory.push(item2.name[lang]);
                                  })
                                  var package_id = item.package_id;
                                  var vendor_id = item.vendor_id;
                                  var corporate_main_admin_id = item.corporate_main_admin_id;
                                  var corporate_sub_admin_id = item.corporate_sub_admin_id;
                                  var branch_location_id = item.branch_location_id;
                                  return (
                                    <div className="corporate-pack-list-sec" >
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.package_name")} : </b></span>
                                          <span>{item.package_title[lang]}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.laundry_name")} : </b></span>
                                          <span>{vendor_name}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.main_categories")} : </b></span>
                                          <span>{category.join(" ,")}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("label_including_transportation")} : </b></span>
                                          <span>{item.with_or_without_trans}</span>
                                        </p>
                                      </div>
                                      <div className="coporate-customer-key-details-main" onClick={() => this.props.history.push(`/corporate-package-detail/${package_id}/${vendor_id}/${corporate_main_admin_id}/${corporate_sub_admin_id}/${branch_location_id}`)}>
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.month")} : </b></span>
                                          <span>{t('expiry' + item.corporate_assign_month)}</span>
                                        </p>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>

                            </div>
                          </div>
                        ) : (
                          <div>
                            <h3 className="no-corporate-package-tab" style={{color: theme.colors.primary}}>{t("label_no_Subscribed_package")}</h3>
                          </div>
                        )
                        }
                      </InfiniteScroll>
                    </Tab>
                  </Tabs>
                </Paper>


              )}

              {/* {this.state.isloading && itemData && itemData.length == 0  && itemData_freedelivery && itemData_freedelivery.length > 0 && couponData && couponData.length > 0 ? <div className="noorder">{t("commons.No_new_notifications")}</div> : <></>} */}
            </div>

            <div className="sidebar_overlay" onClick={SidebarRemoveClass}>
              {" "}
            </div>
          </section>
        )}
      </Translation>
    );
  }
}

export default Notifications
