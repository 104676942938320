import styled from 'styled-components';

const StyledExternalLink = styled.a`
 color: ${({ theme }) => theme.colors.textgrey}; /* Initial text color */

 &:hover {
   color: ${({ theme }) => theme.colors.primary} !important; /* Change text color on hover */
 }
`;

export default StyledExternalLink;