import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import { Translation } from "react-i18next";
import authService from "../../services/auth.service";
import { baseUrl } from "../../config/config";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingInfo: authService.getAuthSettings(),
    };
  }

  render() {
    const { settingInfo } = this.state;
    let path = baseUrl + "/laundromats";

    return (
      <Translation>
        {(t) => (
          <>
            <footer className="py-4 py-md-5" style={{ backgroundImage: "url(" + "assets/img/footer_bg.png" + ")" }}>
              <Container>
                <div className="footer_links">
                  <ul className="footer_link">
                    <li>
                      {/* <Link to="/"> {t("menu.home")}</Link> */}
                      <a href="/">{t("menu.home")}</a>
                    </li>
                    <li>
                      {/* <Link to="/about-us">{t("menu.aboutUs")}</Link> */}
                      <a href="/about-us">{t("menu.aboutUs")}</a>
                    </li>
                    <li>
                      {/* <Link to="/contact-us"> {t("menu.contactUs")}</Link> */}
                      <a href="/contact-us">{t("menu.contactUs")}</a>
                    </li>
                    <li>
                      {/* <Link to="/FAQ">{t("menu.faq")}</Link> */}
                      <a href="/FAQ">{t("menu.faq")}</a>
                    </li>
                    <li>
                      {/* <Link to="/terms">{t("menu.terms")}</Link> */}
                      <a href="/terms">{t("menu.terms")}</a>
                    </li>
                    <li>
                      {/* <Link to="/privacy-policy">{t("menu.privacy")}</Link> */}
                      <a href="/privacy-policy">{t("menu.privacy")}</a>
                    </li>
                    <li>
                      <a href={path} target="_blank">
                        {t("menu.Laundry_Access")}
                      </a>
                    </li>

                    <li>&copy; {t("menu.laundryHub")}</li>
                  </ul>
                  <ul className="social_link">
                    <li className="facebook">
                      <a href={settingInfo && settingInfo.social_media_links && settingInfo.social_media_links.facebook} target="blank">
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li className="twitter">
                      <a href={settingInfo && settingInfo.social_media_links && settingInfo.social_media_links.twitter} target="blank">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="linkedin">
                      <a href={settingInfo && settingInfo.social_media_links && settingInfo.social_media_links.linkedin} target="blank">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="instagram">
                      <a href={settingInfo && settingInfo.social_media_links && settingInfo.social_media_links.instagram} target="blank">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="snapchat">
                      <a href={settingInfo && settingInfo.social_media_links && settingInfo.social_media_links.snapchat} target="blank">
                        <i className="fab fa-snapchat-ghost"></i>
                      </a>
                    </li>
                    <li className="youtube">
                      <a href={settingInfo && settingInfo.social_media_links && settingInfo.social_media_links.youtube} target="blank">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Container>
            </footer>
          </>
        )}
      </Translation>
    );
  }
}
