import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBfSSe-Xhq-pOgio0KZF8KChYYDv0szmJw",
  authDomain: "laundryhub-297605.firebaseapp.com",
  projectId: "laundryhub-297605",
  storageBucket: "laundryhub-297605.appspot.com",
  messagingSenderId: "967512242871",
  appId: "1:967512242871:web:fe10145a4e692f55bdeb32",
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export default firebase;
