export default {
  REGISTER: "/api/customer/auth/register",
  REGISTER_CORPORATE_CUSTOMER: "/api/customer/auth/register_corporate_customer",
  LOGIN: "/api/customer/auth/login",
  RESEND_OTP: "/api/customer/auth/send-otp",
  VERIFY_OTP: "/api/customer/auth/verify-otp",
  UPDATE_FORGET_PASSWORD: "/api/customer/auth/update-forgot-password",
  SOCIAL_LOGIN: "/api/customer/auth/social-login",

  LOGOUT: "/api/customer/auth/logout",
  GET_PROFILE: "/api/customer/profile",
  UPDATE_PROFILE: "/api/customer/profile",
  UPDATE_PROFILE_PARTIALLY: "/api/customer/profile",
  CHANGE_PASSWORD: "/api/customer/profile/password",
  UPDATE_EASY_PICKUP_POPUP: "/api/customer/profile/easy-pick-up",

  GET_BANNERS: "/api/customer/banners",

  GET_VENDORS: "/api/customer/vendors",
  GET_VENDOR: "/api/customer/vendors",
  GET_SERVICES: "/api/customer/services",
  GET_PACKAGE_NAME: "/api/customer/package/name",
  GET_CORPORATE_PACKAGE_NAME: "/api/customer/corporate-package/name",

  GET_ALLVENDORS: "/api/customer/vendors",
  GET_SERVICEPROVIDERS: "/api/customer/all/service-providers",
  GET_CLEANINGTYPE: "/api/customer/all/cleaning-types",
  GET_CLOTHERETURNTYPE: "/api/customer/all/clothe-return-types",

  GET_FAVOURITES: "/api/customer/favourites",
  SET_FAVOURITES: "/api/customer/favourites",

  GET_ADDRESSES: "/api/customer/addresses",
  GET_ADDRESSESTITLE: "/api/customer/all/address-titles",
  CREATE_ADDRESS: "/api/customer/addresses",
  UPDATE_ADDRESS: "/api/customer/addresses",
  DELETE_ADDRESS: "/api/customer/addresses",
  SET_DEFAULT_ADDRESS: "/api/customer/addresses",

  CREATE_CART: "/api/customer/cart-items",
  // CHECK_CART_ITEM_AVAILABILITY: "/api/customer/check_cart_item_availability",
  GET_CART: "/api/customer/cart-items",
  DELETE_CARTITEM: "/api/customer/cart-items",
  DELETE_CART: "/api/customer/cart-items",
  UPDATE_CART: "/api/customer/cart-items",
  GET_FREEDELIVERY_CALCUTION: "/api/customer/free-delivery-calculation",

  CREATE_ORDER: "/api/customer/orders",
  CREATE_EASY_PICK_ORDER: "/api/customer/easy-pickup-orders",
  GET_ORDERS: "/api/customer/orders",
  CANCEL_ORDER: "/api/customer/orders",
  RE_ORDER: "/api/customer/re_orders",

  CREATE_PROMOCODE: "/api/customer/promocodes/apply",
  CREATE_PROMOCODE_AUTO: "/api/customer/promocodes/apply_auto",
  CREATE_COUPONCODE: "/api/customer/couponcodes/apply",

  UPDATE_REPEATED_ORDER_STAUTS: "/api/customer/setting/update-repeated-order-status",
  UPDATE_EASY_PICKUP_ORDER: "/api/customer/repeated-order",

  GET_CONTENT: "/api/customer/contents",
  GET_FAQ: "/api/customer/faqs",

  GET_OFFERS: "/api/customer/offers",
  GET_PROMOCODE_FREE_DELIVERY: "/api/customer/promocode-free-delivery",
  GET_FREEDELIVERY_OFFERS: "/api/customer/free-delivery-offers",
  GET_SETTINGS: "/api/customer/settings",
  GET_PROMOCODES: "/api/customer/promocodes",
  GET_COUPONCODES: "/api/customer/couponcodes",
  UPDATE_DELIVERY_STATUS: "/api/customer/orders",
  GET_CATEGORIES: "/api/customer/all/categories",
  GET_PACKAGE_NAME: "/api/customer/package/name",

  GET_TAGS: "/api/customer/all/tags",
  GET_FREEDELVIERY_OFFERS: "/api/customer/free-delivery-offers",
  GET_COUPON_CODES: "/api/customer/coupon_codes",

  CREATE_CONTACT: "/api/customer/enquiries",

  UPDATE_EMAIL: "/api/customer/profile/email",
  UPDATE_PHONE: "/api/customer/profile/phone",
  GET_NOTIFICATIONS: "/api/customer/notifications",
  DELETE_NOTIFICATION: "/api/customer/notifications",
  GET_CARDSLIST: "/api/customer/cards",
  DELETE_CARD: "/api/customer/cards",

  RATINGS: "/api/customer/vendors",
  GET_ALL_DATA: "/api/customer/all",
  GET_FREEDELIVERY_CART: "/api/customer/free-delivery",
  GET_CART_COUPOUN: "/api/customer/coupon-applied",
  GET_PROMOTION_PACKAGE: "/api/customer/promotions",
  GET_VENDOR_PACKAGE: "/api/customer/vendor/promotions",
  GET_VENDOR_NAME: "/api/customer/shopname/promotions",
  CREATE_PACKAGE_ORDER: "/api/customer/promotions",
  GET_ORDERED_PACKAGE: "/api/customer/order/promotions",
  GET_CURRENT_ACTIVE_PACKAGE: "/api/customer/subscribed/promotions",
  CREATE_WALLET: "/api/customer/wallet",
  GET_BALANCE_WALLET: "/api/customer/wallet",
  GET_ADMIN_WALLET: "/api/customer/wallet/admin",
  GET_CUSTOMER_HISTORY: "/api/customer/wallet/history",
  GET_WALLET_AMOUNT: "/api/customer/wallet/payment",
  GET_WALLET_SETTINGS: "/api/customer/wallet/amount-setting",
  GET_SUBCATEGORY_DISCOUNT: "/api/customer/promocode-subcategory",
  GET_SUBCATEGORYDISCOUNT: "/api/customer/promocode-subcategory/discount",

  // USER LANGUAGE
  UPDATE_LANGUAGE: "/api/customer/language",

  UPDATE_ORDER_TIMINGS: "/api/customer/orders/update-timings",

  GET_MONEY_FROM_WALLET: "/api/customer/get_money_from_wallet",
  //CORPORATE PACKAGE
  GET_VENDORS_NEWLY_ASSIGNED_CORPORATE_PACKAGE: "/api/customer/get_vendors_newly_assigned_corporate_package",
  GET_VENDORS_ACTIVATED_CORPORATE_PACKAGE: "/api/customer/get_vendors_activated_corporate_package",
  GET_ALL_EXPIRED_CORPORATE_PACKAGES: "/api/customer/get_all_expired_corporate_packages",
  GET_ADMIN_VENDOR_CORPORATE_PACKAGE: "/api/customer/get_admin_vendor_corporate_package",
  CUSTOMER_ACTIVATE_CORPORATE_PACKAGE: "/api/customer/customer_activate_corporate_package",
  CUSTOMER_REQUEST_FOR_ALTER_CORPORATE_PACKAGE: "/api/customer/customer_request_for_alter_corporate_package",

  GET_CORPORATE_PACKAGE: "/api/customer/get_corporate_package",

  //staff pay create coporate package order
  CREATE_CORPORATE_PACKAGE_ORDER: "/api/customer/create_corporate_package_order",

  CREATE_PAYMENT_URL_FOR_EASY_PICKUP: "/api/customer/create_payment_url_for_easy_pickup",
  GET_MONEY_FROM_WALLET_FOR_CANCEL_ORDER: "/api/customer/get_money_from_wallet_for_cancel_order",
  CANCEL_EXPRESS_DELIVERY: "/api/customer/cancel_express_delivery",
  TRACK_USER_VISIT: "api/customer/track_user_visit"

};
