import React from 'react';
import {StyledLink, StyledLink1}  from './StyledLink'; 

export const ThemeButtonLink = ({ link, buttonText }) => {
  return (
    <StyledLink to={`${link}`}>
      {buttonText}  {/* Dynamic text */}
    </StyledLink>
  );
};

export const ThemeButtonLink1 = ({ link, buttonText }) => {
    return (
      <StyledLink1 to={`${link}`}>
        {buttonText}  {/* Dynamic text */}
      </StyledLink1>
    );
  };

// export default ThemeButtonLink;
