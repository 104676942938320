import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import { NotificationManager } from "react-notifications";

import { baseUrl } from "../../config/config";

import { google_map } from "../../config/config";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import { io } from "socket.io-client";
import { Translation } from "react-i18next";

const mapStyles = {
  width: "100%",
  height: "470px",
  position: "relative",
};

class TrackOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: "",
      longitude: "",
      isloading: false,
      orderId: props.match.params.itemId,
      orderDetails: "",
      directions: null,
    };
  }

  componentDidMount() {
    this.getOrderDetails();
  }

  getOrderDetails = async () => {
    let path = ApiRoutes.GET_ORDERS + "/" + this.state.orderId;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            orderDetails: resJson.data,
            isloading: true,
          },
          () => this.socketConnection()
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  socketConnection = () => {
    const { orderDetails } = this.state;

    const { flag } = this.props.location.state;

    const authToken = AuthService.getAuthToken();

    let options = {
      transports: ["websocket"],
      extraHeaders: { authorization: authToken },
      query: { authorization: authToken },
    };

    var socket = require("socket.io-client")(baseUrl, options);

    var pickup_driver_id = orderDetails && orderDetails.pickup_person && orderDetails.pickup_person._id;
    var delivery_driver_id = orderDetails && orderDetails.delivery_person && orderDetails.delivery_person._id;

    // Create order room
    if (flag == 1) {
      socket.emit("createOrderRoom", {
        order_id: this.state.orderId,
        driver_id: pickup_driver_id,
      });
    } else {
      socket.emit("createOrderRoom", {
        order_id: this.state.orderId,
        driver_id: delivery_driver_id,
      });
    }

    // Give the location of driver
    socket.on("getOrderDriverLocation", (location) => {
      this.setState({ latitude: location.latitude, longitude: location.longitude, isloading: true });
    });
  };

  componentWillUnmount() {
    const authToken = AuthService.getAuthToken();

    let options = {
      transports: ["websocket"],
      extraHeaders: { authorization: authToken },
      query: { authorization: authToken },
    };

    var socket = require("socket.io-client")(baseUrl, options);

    //Remove order room
    socket.emit("removeOrderRoom", {
      order_id: this.state.orderId,
    });
  }
  render() {
    var { authUser } = this.props;
    var { latitude, longitude, isloading, orderDetails } = this.state;

    const triangleCoords = [
      { lat: orderDetails && orderDetails.vendor_address.latitude, lng: orderDetails && orderDetails.vendor_address.longitude },
      { lat: orderDetails && orderDetails.pickup_address.latitude, lng: orderDetails && orderDetails.pickup_address.longitude },
    ];

    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec py-2 py-md-1" style={{ backgroundColor: "#F5FDFE" }}>
            <Container className="py-1 py-md-1 mb-3">
              {!isloading ? (
                <div className="loading"></div>
              ) : (
                <Map
                  google={this.props.google}
                  disableDefaultUI={true}
                  zoomControl={true}
                  fullscreenControl={true}
                  containerStyle={mapStyles}
                  className={"map"}
                  mapTypeControl={true}
                  zoom={14}
                  initialCenter={{ lat: orderDetails && orderDetails.pickup_address.latitude, lng: orderDetails && orderDetails.pickup_address.longitude }}
                >
                  <Marker title={t("commons.Your_Location")} />
                  <Marker position={{ lat: latitude && latitude, lng: longitude && longitude }} title={t("commons.Driver's_Location")} />
                </Map>
              )}
            </Container>
          </section>
        )}
      </Translation>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: google_map,
})(TrackOrder);
