import React, { Component } from "react";
import { Row, Col, Button, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import Select from "react-select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Http from "../../helpers/Http";
import i18next from "i18next";
import ApiRoutes from "../../helpers/ApiRoutes";
import {StyledButtondiv1    } from "../../helpers/StyledButtondiv";
import { Translation } from "react-i18next";
import AuthService from "../../services/auth.service";
import ReactToolTip from '../../helpers/reactToolTip';
import { ThemeContext } from "styled-components";

function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const WorkingDaysOpts = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
var workingDays = "";
var selectedDate = "";

const title = '<p><b>Monthly</b> - Order will be picked up every month on the scheduled pickup date and time chosen on 3rd,<br/> the order will be automatically created 2 days before the scheduled date with the pickup date of 3rd.<br/> Note: Pickup date and time may differ based on the vendor availability.<br/><br/><b>Weekly</b> - Order will be picked up every week on the scheduled pickup day and time<br/> For Example: If the pickup day is chosen on Wednesday,<br/> the order will be automatically created 2 days before the scheduled day with the pickup date of Wednesday.<br/> Note: Pickup day and time may differ based on the vendor availability.<br/><br/><b>Bi-Weekly</b> - Order will be picked up once in a twice a week on the scheduled pickup day and time.<br/> For Example: If the pickup day is chosen on Wednesday,<br/> the order will be created on alternative Wednesday Note: Pickup day and time may differ based on the vendor availability.<br/><br/><b>Daily</b> - Order will be picked up daily on the scheduled pickup time.<br/> For Example: If the pickup time is chosen as 4PM means,<br/> daily the order will be created on the scheduled pick up time.<br/> Note: Pickup date and time may differ based on the vendor availability.</p>';

const title1 = '<p><b>شهريًا</b> - سيتم استلام الطلب كل شهر في تاريخ الاستلام المحدد والوقت المحدد في اليوم الثالث،<br/> سيتم إنشاء الطلب تلقائيًا قبل يومين من التاريخ المحدد للاستلام. تاريخ الثالث.<br/> ملاحظة: قد يختلف تاريخ ووقت الاستلام بناءً على توفر البائع.<br/><br/><b>أسبوعيًا</b> - سيتم استلام الطلب كل أسبوع عند الاستلام المقرر اليوم والوقت<br/> على سبيل المثال: إذا تم اختيار يوم الاستلام يوم الأربعاء،<br/> فسيتم إنشاء الطلب تلقائيًا قبل يومين من اليوم المحدد مع تاريخ الاستلام وهو الأربعاء.<br/> ملاحظة: يوم الاستلام وقد يختلف الوقت بناءً على توفر البائع.<br/><br/><b>كل أسبوعين</b> - سيتم استلام الطلب مرة واحدة في الأسبوع مرتين في الأسبوع في يوم ووقت الاستلام المحددين.<br /> على سبيل المثال: إذا تم اختيار يوم الاستلام يوم الأربعاء،<br/> فسيتم إنشاء الطلب يوم الأربعاء البديل. ملاحظة: قد يختلف يوم الاستلام ووقته بناءً على توفر البائع.<br/><br/><b >يوميًا</b> - سيتم استلام الطلب يوميًا في وقت الاستلام المحدد.<br/> على سبيل المثال: إذا تم اختيار وقت الاستلام على أنه الساعة 4 مساءً،<br/> فسيتم إنشاء الطلب يوميًا في وقت الاستلام المجدول وقت التشغيل.<br/> ملاحظة: قد يختلف تاريخ ووقت الاستلام بناءً على توفر البائع.</p>';
const weeklyOpts = [
    { value: '0', label: 'Sunday' },
    { value: '1', label: 'Monday' },
    { value: '2', label: 'Tuesday' },
    { value: '3', label: 'Wednesday' },
    { value: '4', label: 'Thursday' },
    { value: '5', label: 'Friday' },
    { value: '6', label: 'Saturday' }
]

const weeklyOpts1 = [
    { value: '0', label: 'يوم الأحد' },
    { value: '1', label: 'الاثنين' },
    { value: '2', label: 'يوم الثلاثاء' },
    { value: '3', label: 'الأربعاء' },
    { value: '4', label: 'الخميس' },
    { value: '5', label: 'يوم الجمعة' },
    { value: '6', label: 'يوم السبت' }
];

var coords = AuthService.getCurrentLocation();

export default class ManageRepeatedOrders extends Component {
    static contextType = ThemeContext;
    constructor(props) {
        super(props);
        this.state = {
            itemData: [],
            vendorData: [],
            vendorArray: [],
            lang: "",
            repeated_order: "",
            first_shift_start: "",
            first_shift_end: "",
            second_shift_start: "",
            second_shift_end: "",
            first_shift_start_time_sun: "",
            first_shift_end_time_sun: "",
            second_shift_start_time_sun: "",
            second_shift_end_time_sun: "",
            first_shift_start_time_mon: "",
            first_shift_end_time_mon: "",
            second_shift_start_time_mon: "",
            second_shift_end_time_mon: "",
            first_shift_start_time_tue: "",
            first_shift_end_time_tue: "",
            second_shift_start_time_tue: "",
            second_shift_end_time_tue: "",
            first_shift_start_time_wed: "",
            first_shift_end_time_wed: "",
            second_shift_start_time_wed: "",
            second_shift_end_time_wed: "",
            first_shift_start_time_thur: "",
            first_shift_end_time_thur: "",
            second_shift_start_time_thur: "",
            second_shift_end_time_thur: "",
            first_shift_start_time_fri: "",
            first_shift_end_time_fri: "",
            second_shift_start_time_fri: "",
            second_shift_end_time_fri: "",
            first_shift_start_time_sat: "",
            first_shift_end_time_sat: "",
            second_shift_start_time_sat: "",
            second_shift_end_time_sat: "",
            vendorId: [],
            monthStartDate: "",
            monthEndDate: "",
            latitude: coords && coords.latitude,
            longitude: coords && coords.longitude,
            max_distance: 0,
            isLoading: false,
            repeated_order_pickup_time: "",
            repeated_order_delivery_time: "",
            repeated_order_delivery_date: "",
            repeated_order_pickup_specific_date: "",
            repeated_order_pickup_date: "",
            repeated_order_delivery_specific_date: "",
            max_repeated_order_delivery_date: "",
            min_repeated_order_delivery_date: "",
            repeated_order_pickup_minTime: "",
            repeated_order_delivery_minTime: this.repeated_order_delivery_calculateMinTime(new Date()),
            show_next_week_delviery_date: false,
            next_week_delviery_date: "",
            next_week_pickup_date: "",
            show_next_week_pickup_date: false,
            repeated_order_auto_delivery_time: 0,
            repeated_order_delivery_option_validation: 0,
        };
    }

    componentDidMount() {
        this.getVendors();
        this.setState({ lang: localStorage.getItem("lang") || "en" });
        const firstdate = moment().add(1, 'month').startOf('month').format('MM/DD/YYYY');
        const lastdate = moment().add(1, 'month').endOf('month').format("MM/DD/YYYY");
        this.setState({
            monthStartDate: firstdate,
            monthEndDate: lastdate
        });
    };

    repeated_order_pickup_calculateMinTime = (date) => {
        let isToday = moment(date).isSame(moment(), "day");
        if (isToday) {
            let nowAddOneHour = moment(new Date()).toDate();
            function round(date, duration, method) {
                return moment(Math[method]((+date) / (+duration)) * (+duration));
            }
            var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
            var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
            nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
            return nowAddOneHour1;
        }
        return moment().startOf("day").toDate();
    };

    repeated_order_delivery_calculateMinTime = (date) => {
        let isToday = moment(date).isSame(moment(), "day");
        if (isToday) {
            let nowAddOneHour = moment(new Date()).toDate();
            function round(date, duration, method) {
                return moment(Math[method]((+date) / (+duration)) * (+duration));
            }
            var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
            var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
            nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
            var date = new Date(nowAddOneHour1 * 1000);
            return nowAddOneHour1;
        } else if (this.state.repeated_order_delivery_option_validation == 1) {
            let nowAddOneHour = moment(new Date()).toDate();
            function round(date, duration, method) {
                return moment(Math[method]((+date) / (+duration)) * (+duration));
            }
            var roundedDate = round(nowAddOneHour, moment.duration(30, "minutes"), "ceil");
            var nowAddOneHour1 = moment(new Date(roundedDate)).toDate();
            nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
            var date = new Date(nowAddOneHour1 * 1000);
            return nowAddOneHour1;
        } else {
            this.setState({
                repeated_order_auto_delivery_time: 1,
            });
        }
        return moment().startOf("day").toDate();
    };

    handleScheduleTypeRadioChange = event => {
        if (event.target.value == 'daily') {
            var repeated_order_pickup_specific_date = new Date().getDay() + 1;
            for (let i = 0; i <= 6; i++) {
                if (repeated_order_pickup_specific_date > 6) {
                    repeated_order_pickup_specific_date = repeated_order_pickup_specific_date % 7;
                };
                this.repeated_order_pickup_handleMinTime(new Date().setDate(repeated_order_pickup_specific_date));
                let pickup_first_shift_start;
                let pickup_first_shift_end;
                let pickup_second_shift_start;
                let pickup_second_shift_end;
                if (repeated_order_pickup_specific_date == 0) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
                }
                if (repeated_order_pickup_specific_date == 1) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
                }
                if (repeated_order_pickup_specific_date == 2) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
                }
                if (repeated_order_pickup_specific_date == 3) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
                }
                if (repeated_order_pickup_specific_date == 4) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
                }
                if (repeated_order_pickup_specific_date == 5) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
                }
                if (repeated_order_pickup_specific_date == 6) {
                    pickup_first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
                    pickup_first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
                    pickup_second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
                    pickup_second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
                };
                if (pickup_first_shift_start == 'Invalid Date' && pickup_first_shift_end == 'Invalid Date' && pickup_second_shift_start == 'Invalid Date' && pickup_second_shift_end == 'Invalid Date') {
                    repeated_order_pickup_specific_date++;
                };
            };
            var repeated_order_delivery_specific_date = new Date().setDate(new Date().getDate() + this.state.vendorData.normal_delivery_time + 1);
            repeated_order_delivery_specific_date = new Date(repeated_order_delivery_specific_date).getDay();
            for (let i = 0; i <= 6; i++) {
                this.repeated_order_delivery_handleMinTime(new Date(new Date().getDate() + repeated_order_delivery_specific_date));
                if (repeated_order_delivery_specific_date > 6) {
                    repeated_order_delivery_specific_date = repeated_order_delivery_specific_date % 7;
                };
                let delivery_first_shift_start;
                let delivery_first_shift_end;
                let delivery_second_shift_start;
                let delivery_second_shift_end;
                if (repeated_order_delivery_specific_date == 0) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
                }
                if (repeated_order_delivery_specific_date == 1) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
                }
                if (repeated_order_delivery_specific_date == 2) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
                }
                if (repeated_order_delivery_specific_date == 3) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
                }
                if (repeated_order_delivery_specific_date == 4) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
                }
                if (repeated_order_delivery_specific_date == 5) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
                }
                if (repeated_order_delivery_specific_date == 6) {
                    delivery_first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
                    delivery_first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
                    delivery_second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
                    delivery_second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
                }
                if (delivery_first_shift_start == 'Invalid Date' && delivery_first_shift_end == 'Invalid Date' && delivery_second_shift_start == 'Invalid Date' && delivery_second_shift_end == 'Invalid Date') {
                    repeated_order_delivery_specific_date++;
                };
            };
            var d = new Date();
            d.setDate(d.getDate() + (((repeated_order_pickup_specific_date + 7 - d.getDay()) % 7) || 7));
            var d2 = new Date();
            d2.setDate(d2.getDate() + (((repeated_order_delivery_specific_date + 7 - d2.getDay()) % 7) || 7));
            this.setState({
                repeated_order_pickup_specific_date: repeated_order_pickup_specific_date,
                next_week_delviery_date: new Date(d2),
                repeated_order_delivery_specific_date: repeated_order_delivery_specific_date,
                next_week_pickup_date: new Date(d),
                repeated_order_auto_delivery_time: 0,
            });
        };
        this.setState({
            show_next_week_delviery_date: false,
            show_next_week_pickup_date: false,
            schedule_type: event.target.value,
            repeated_order_delivery_date: "",
            repeated_order_pickup_date: "",
            repeated_order_delivery_time: "",
            repeated_order_pickup_time: "",
        })
    };

    getCustomerData = async () => {
        let path = ApiRoutes.GET_PROFILE;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                resJson.data.allow_repeated_order && this.setState({
                    repeated_order: resJson.data.allow_repeated_order == 1 ? 'yes' : 'no'
                });
                resJson.data.schedule_type && this.setState({
                    schedule_type: resJson.data.schedule_type,
                });
                var repeated_order_delivery_time = moment(resJson.data.repeated_order_delivery_time).subtract(5, 'hours').subtract(30, 'minutes');
                var repeated_order_pickup_time = moment(resJson.data.repeated_order_pickup_time).subtract(5, 'hours').subtract(30, 'minutes');
                var current_year = new Date().getFullYear();
                var current_month = new Date().getMonth();
                var current_day = new Date().getDate();
                var delivery_hour = new Date(repeated_order_delivery_time).getHours();
                var delivery_minutes = new Date(repeated_order_delivery_time).getMinutes();
                var repeated_order_delivery_time_temp = new Date(current_year, current_month, current_day, delivery_hour, delivery_minutes, 0, 0, 0)
                this.setState({
                    repeated_order_pickup_time: new Date(repeated_order_pickup_time),
                    repeated_order_delivery_time: repeated_order_delivery_time_temp,
                    next_week_pickup_date: new Date(resJson.data.repeated_order_pickup_date),
                })
                if (resJson.data.schedule_type == 'weekly' || resJson.data.schedule_type == 'bi-weekly') {
                    var pickup_day = new Date(resJson.data.repeated_order_pickup_date).getDay();
                    var delivery_day = new Date(resJson.data.repeated_order_delivery_date).getDay();
                    var options = this.state.lang == 'en' ? weeklyOpts : weeklyOpts1;
                    options.map((weeklyOptsTemp) => {
                        if (parseInt(weeklyOptsTemp.value) == pickup_day) {
                            this.setState({
                                repeated_order_pickup_date: weeklyOptsTemp,
                                repeated_order_pickup_specific_date: parseInt(weeklyOptsTemp.value),
                            }, () => this.repeated_order_pickup_handleMinTime(resJson.data.repeated_order_pickup_date),)
                        };
                        if (parseInt(weeklyOptsTemp.value) == delivery_day) {
                            this.setState({
                                repeated_order_delivery_date: weeklyOptsTemp,
                                repeated_order_delivery_specific_date: parseInt(weeklyOptsTemp.value),
                            }, () => this.repeated_order_pickup_handleMinTime(resJson.data.repeated_order_pickup_date),)
                            };
                        });
                } else {
                    resJson.data.repeated_order_delivery_date && resJson.data.repeated_order_pickup_date && this.setState({
                        repeated_order_delivery_date: new Date(resJson.data.repeated_order_delivery_date),
                        repeated_order_pickup_date: new Date(resJson.data.repeated_order_pickup_date),
                        repeated_order_pickup_specific_date: new Date(resJson.data.repeated_order_pickup_date).getDay(),
                        repeated_order_delivery_specific_date: new Date(resJson.data.repeated_order_delivery_date).getDay(),
                        max_repeated_order_delivery_date: new Date(resJson.data.repeated_order_pickup_date).setDate(new Date(resJson.data.repeated_order_pickup_date).getDate() + 7),
                        min_repeated_order_delivery_date: new Date(resJson.data.repeated_order_pickup_date).setDate(new Date(resJson.data.repeated_order_pickup_date).getDate() + 1),
                    },)
                };

                if (resJson.data.allow_repeated_order == 1) {
                    if (!resJson.data.repeated_order_vendor_id) {
                        this.setState({ isLoading: true, })
                    }
                    resJson.data.repeated_order_vendor_id && this.state.vendorArray.map((vendorArrayTemp) => {
                        if (vendorArrayTemp.value == resJson.data.repeated_order_vendor_id) {
                            this.get_vendor_data(vendorArrayTemp);
                        }
                    });
                } else {
                    this.setState({
                        vendorArray: [],
                        isLoading: true,
                    })
                };
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    }

    getVendors = async () => {
        let path =
            ApiRoutes.GET_VENDORS +
            "?page_no=" +
            `${1}` +
            "&limit=" +
            `${50}` +
            "&latitude=" +
            `${this.state.latitude}` +
            "&longitude=" +
            `${this.state.longitude}` +
            "&max_distance=" +
            `${this.state.max_distance}`;
        const res = await Http("GET", path);

        if (res) {
            const resJson = await res.json();

            if (res.status == 200) {
                let vendorArrayTemp = []
                resJson.data.docs.map((item) => {
                    vendorArrayTemp.push({
                        value: item._id,
                        label:
                            item.shop_name &&
                            item.branch_name &&
                            item.user_code +
                            " - " +
                            item.shop_name[this.state.lang] +
                            " (" +
                            item.branch_name[this.state.lang] +
                            ")",
                    });

                });
                this.setState({ vendorArray: vendorArrayTemp });
                this.getCustomerData();
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    handleSubmit = async (inputValues, formOptions) => {
        var error = 0;
        if (this.state.schedule_type == 'monthly' || this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly') {
        if (this.state.repeated_order_pickup_date == '' || this.state.repeated_order_pickup_date == undefined || this.state.repeated_order_pickup_date == null) {
            formOptions.setFieldError("repeated_order_pickup_date", "commons.Please_select_Pick_up_date");
            error = 1;
        };
        if (this.state.repeated_order_delivery_date == '' || this.state.repeated_order_delivery_date == undefined || this.state.repeated_order_delivery_date == null) {
            formOptions.setFieldError("repeated_order_delivery_date", "commons.Please_select_Delivery_Date");
            error = 1;
        };
        };
        if (this.state.repeated_order_pickup_time == '' || this.state.repeated_order_pickup_time == undefined || this.state.repeated_order_pickup_time == null) {
            formOptions.setFieldError("repeated_order_pickup_time", "commons.Please_select_pick_up_time");
            error = 1;
        };
        var current_year = new Date().getFullYear();
        var current_month = new Date().getMonth();
        var current_day = new Date().getDate();
        var delivery_hour = new Date(this.state.repeated_order_delivery_time).getHours();
        var delivery_minutes = new Date(this.state.repeated_order_delivery_time).getMinutes();
        var repeated_order_delivery_time_temp = new Date(current_year, current_month, current_day, delivery_hour, delivery_minutes, 0, 0, 0)
        if (this.state.repeated_order_delivery_time == '' || this.state.repeated_order_delivery_time == undefined || this.state.repeated_order_delivery_time == null) {
            formOptions.setFieldError("repeated_order_delivery_time", "commons.Please_select_Delivery_Time");
            error = 1;
        } else if (!this.repeated_order_delivery_filterPassedTime(repeated_order_delivery_time_temp)) {
            formOptions.setFieldError("repeated_order_delivery_time", "commons.Please_select_another_time_as_vendor_is_unavailable_on_selected_time.");
            error = 1;
        }
        if (error == 1) {
            return false;
        };

        let formData = new FormData();
        formData.append("schedule_type", this.state.schedule_type);
        if (this.state.schedule_type == 'daily') {
            formData.append("repeated_order_pickup_date", moment().add(1, 'day').format("YYYY-MM-DD"));
            formData.append("repeated_order_delivery_date", moment().add(this.state.vendorData.normal_delivery_time, 'day').format("YYYY-MM-DD"));
        } else if (this.state.schedule_type == 'monthly') {
            formData.append("repeated_order_pickup_date", moment(this.state.repeated_order_pickup_date).format("YYYY-MM-DD"));
            formData.append("repeated_order_delivery_date", moment(this.state.repeated_order_delivery_date).format("YYYY-MM-DD"));
        } else {
            formData.append("repeated_order_pickup_date", moment().startOf('week').add('days', this.state.repeated_order_pickup_date.value).format("YYYY-MM-DD"));
            formData.append("repeated_order_delivery_date", moment().startOf('week').add('days', this.state.repeated_order_delivery_date.value).format("YYYY-MM-DD"));
        }
        formData.append("repeated_order_pickup_time", moment(this.state.repeated_order_pickup_time, ["h:mm A"]).format("HH:mm"));
        formData.append("repeated_order_delivery_time", moment(this.state.repeated_order_delivery_time, ["h:mm A"]).format("HH:mm"));
        formData.append("vendor_id", this.state.vendorId.value);
        let path = ApiRoutes.UPDATE_EASY_PICKUP_ORDER;
        const res = await Http("PUT", path, formData);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                NotificationManager.success(resJson.message, "Success!", 3000);
                this.props.history.push('manage-profile');
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    get_vendor_data = async (event) => {

        this.setState({ vendorId: event })
        let path = ApiRoutes.GET_VENDOR + "/" + event.value;

        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();

            if (res.status == 200) {
                workingDays = this.getWorkingDaysString(resJson.data && resJson.data.working_days);
                if (resJson.data.sunday_timings) {
                    Object.entries(resJson.data.sunday_timings).forEach(([key, value]) => {
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }
                if (resJson.data.monday_timings) {
                    Object.entries(resJson.data.monday_timings).forEach(([key, value]) => {
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }
                if (resJson.data.tuesday_timings) {
                    Object.entries(resJson.data.tuesday_timings).forEach(([key, value]) => {
                        // for (var key in value) {
                        //   this.setState({ [key]: value[key] ? moment(value[key]).utc().format("HH:mm") : "N/A" });
                        // }
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }
                if (resJson.data.wednesday_timings) {
                    Object.entries(resJson.data.wednesday_timings).forEach(([key, value]) => {
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }
                if (resJson.data.thursday_timings) {
                    Object.entries(resJson.data.thursday_timings).forEach(([key, value]) => {
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }
                if (resJson.data.friday_timings) {
                    Object.entries(resJson.data.friday_timings).forEach(([key, value]) => {
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }
                if (resJson.data.saturday_timings) {
                    Object.entries(resJson.data.saturday_timings).forEach(([key, value]) => {
                        this.setState({ [key]: value ? moment(value).utc().format("HH:mm") : "N/A" });
                    });
                }

                await this.setState(
                    {
                        isLoading: true,
                        vendorData: resJson.data,
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
        }
    };

    getWorkingDaysString(sortArr) {
        if (sortArr) {
            var daysArr = sortArr.sort();
            var str = [];
            if (daysArr && daysArr.length > 0) {
                daysArr.forEach((item) => {
                    str += WorkingDaysOpts[item] + ", ";
                });
            }
            return str;
        } else {
            NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
        }
    };

    // Show Filtered Time For Repated Order pickup
    repeated_order_pickup_filterPassedTime = (time) => {
        let first_shift_start;
        let first_shift_end;
        let second_shift_start;
        let second_shift_end;
        if (this.state.repeated_order_pickup_specific_date == 0) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
        }
        if (this.state.repeated_order_pickup_specific_date == 1) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
        }
        if (this.state.repeated_order_pickup_specific_date == 2) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
        }
        if (this.state.repeated_order_pickup_specific_date == 3) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
        }
        if (this.state.repeated_order_pickup_specific_date == 4) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
        }
        if (this.state.repeated_order_pickup_specific_date == 5) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
        }
        if (this.state.repeated_order_pickup_specific_date == 6) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
        }

        let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
        nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
        first_shift_start = new Date(nowAddOneHour)
        let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
        nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
        second_shift_start = new Date(nowAddOneHour1)
        selectedDate = new Date(time);
        if (selectedDate.getDate() > first_shift_start.getDate()) {
            selectedDate = parseInt(selectedDate.getTime() - 86400000);
        }
        first_shift_start = parseInt(first_shift_start.getTime() / 1000);
        first_shift_end = parseInt(first_shift_end.getTime() / 1000);
        second_shift_start = parseInt(second_shift_start.getTime() / 1000);
        second_shift_end = parseInt(second_shift_end.getTime() / 1000);
        selectedDate = parseInt(selectedDate / 1000);
        var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
        return test;
    };

    // Show Filtered Time For Repated Order delivery
    repeated_order_delivery_filterPassedTime = (time) => {
        let first_shift_start;
        let first_shift_end;
        let second_shift_start;
        let second_shift_end;
        if (this.state.repeated_order_delivery_specific_date == 0) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_sun && this.state.first_shift_start_time_sun, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_sun && this.state.first_shift_end_time_sun, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_sun && this.state.second_shift_start_time_sun, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_sun && this.state.second_shift_end_time_sun, "h:m");
        }
        if (this.state.repeated_order_delivery_specific_date == 1) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_mon && this.state.first_shift_start_time_mon, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_mon && this.state.first_shift_end_time_mon, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_mon && this.state.second_shift_start_time_mon, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_mon && this.state.second_shift_end_time_mon, "h:m");
        }
        if (this.state.repeated_order_delivery_specific_date == 2) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_tue && this.state.first_shift_start_time_tue, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_tue && this.state.first_shift_end_time_tue, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_tue && this.state.second_shift_start_time_tue, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_tue && this.state.second_shift_end_time_tue, "h:m");
        }
        if (this.state.repeated_order_delivery_specific_date == 3) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_wed && this.state.first_shift_start_time_wed, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_wed && this.state.first_shift_end_time_wed, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_wed && this.state.second_shift_start_time_wed, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_wed && this.state.second_shift_end_time_wed, "h:m");
        }
        if (this.state.repeated_order_delivery_specific_date == 4) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_thur && this.state.first_shift_start_time_thur, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_thur && this.state.first_shift_end_time_thur, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_thur && this.state.second_shift_start_time_thur, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_thur && this.state.second_shift_end_time_thur, "h:m");
        }
        if (this.state.repeated_order_delivery_specific_date == 5) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_fri && this.state.first_shift_start_time_fri, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_fri && this.state.first_shift_end_time_fri, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_fri && this.state.second_shift_start_time_fri, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_fri && this.state.second_shift_end_time_fri, "h:m");
        }
        if (this.state.repeated_order_delivery_specific_date == 6) {
            first_shift_start = this.toDate(this.state.first_shift_start_time_sat && this.state.first_shift_start_time_sat, "h:m");
            first_shift_end = this.toDate(this.state.first_shift_end_time_sat && this.state.first_shift_end_time_sat, "h:m");
            second_shift_start = this.toDate(this.state.second_shift_start_time_sat && this.state.second_shift_start_time_sat, "h:m");
            second_shift_end = this.toDate(this.state.second_shift_end_time_sat && this.state.second_shift_end_time_sat, "h:m");
        }
        let nowAddOneHour = moment(new Date(first_shift_start)).toDate();
        nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
        first_shift_start = new Date(nowAddOneHour)
        let nowAddOneHour1 = moment(new Date(second_shift_start)).toDate();
        nowAddOneHour1.setHours(nowAddOneHour1.getHours() + 1);
        second_shift_start = new Date(nowAddOneHour1)
        selectedDate = new Date(time);
        if (selectedDate.getDate() > first_shift_start.getDate()) {
            selectedDate = parseInt(selectedDate.getTime() - 86400000);
        }
        first_shift_start = parseInt(first_shift_start.getTime() / 1000);
        first_shift_end = parseInt(first_shift_end.getTime() / 1000);
        second_shift_start = parseInt(second_shift_start.getTime() / 1000);
        second_shift_end = parseInt(second_shift_end.getTime() / 1000);
        selectedDate = parseInt(selectedDate / 1000);
        var test = (first_shift_start <= selectedDate && first_shift_end >= selectedDate) || (second_shift_start <= selectedDate && second_shift_end >= selectedDate);
        return test;
    };

    // Convert timestamp to date format
    toDate = (dStr, format) => {
        var now = new Date();
        if (format == "h:m") {
            now.setHours(dStr.substr(0, dStr.indexOf(":")));
            now.setMinutes(dStr.substr(dStr.indexOf(":") + 1));
            now.setSeconds(0);
            return now;
        } else return "Invalid Format";
    };

    repeated_order_pickup_handleMinTime = (date) => {
        this.setState({
            repeated_order_pickup_minTime: this.repeated_order_pickup_calculateMinTime(date),
        });
    };

    repeated_order_delivery_handleMinTime = (date) => {
        this.setState({
            repeated_order_delivery_minTime: this.repeated_order_delivery_calculateMinTime(date),
        });
    };

    render() {
        var { lang, addressList, itemData, vendorData, pickup_address, delivery_address, delivery_method, actual_express_delivery_time, vendorArray } = this.state;
        var actual_delivery_time = vendorData.normal_delivery_time;
        const theme = this.context;
        return (
            <Translation>
                {(t) => (
                    <>
                        <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
                            <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                                    <i className="fas fa-bars"></i>
                                </div>
                            </div>
                            <h1 className="page_title mb-5 custom-page-tit-phem">{t("labels.manage_repeated_orders")}</h1>
                            {!this.state.isLoading ?
                                (<div className="loading" />)
                                : <Row className="justify-content-center">
                                    <Col xs={12}>
                                        <div className="manage_account_user_sec p-5 custom-repeate-hd">
                                            <Formik
                                                enableReinitialize
                                                initialValues={{
                                                    vendorData: this.state.vendorData,
                                                    repeated_order_pickup_date: this.state.repeated_order_pickup_date,
                                                    repeated_order_pickup_time: this.state.repeated_order_pickup_time,
                                                    repeated_order_delivery_date: this.state.repeated_order_delivery_date,
                                                    repeated_order_delivery_time: this.state.repeated_order_delivery_time,
                                                }}
                                                onSubmit={this.handleSubmit}
                                            >
                                                {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                                                    <Form className="w-100 login_signup_form">
                                                        <Row>
                                                            {this.state.repeated_order != 'yes' ? (
                                                                <Col xs={12}>
                                                                    <p>{t("Please_enable_repeated_order_in_settings_page")} </p>
                                                                </Col>
                                                            ) : ""}
                                                            <Col xs={12} md={4}>
                                                                <Label className="mb-3 mt-3">{t("commons.Choose_vendor_for_repeated_orders")}</Label>
                                                                <FormGroup>
                                                                    <Select
                                                                        id="vendor"
                                                                        name="vendor"
                                                                        value={this.state.vendorId}
                                                                        isMulti={false}
                                                                        options={vendorArray}
                                                                        onChange={(event) => { this.get_vendor_data(event) }}
                                                                        onBlur={setFieldTouched}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {this.state.repeated_order == 'yes' ?
                                                            <>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <div className="schedule_type_icon">
                                                                            <Label className="mb-3 mt-3">{t("commons.Choose_schedule_type")}</Label>
                                                                            <ReactToolTip theme="dark" isDom={true} position="top" title={title}>
                                                                                <i class="fas fa-info"></i>
                                                                            </ReactToolTip>
                                                                        </div>
                                                                        <div className="form-check display_flex form-group custom-repeated-form-cls">
                                                                            <div className={this.state.lang == 'en' ? "margin-right positions-relative" : ""}>
                                                                                <input
                                                                                    value="monthly"
                                                                                    checked={this.state.schedule_type == 'monthly'}
                                                                                    className="form-check-radio"
                                                                                    onChange={this.handleScheduleTypeRadioChange}
                                                                                    type="radio" />
                                                                                <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                                                                    {lang == 'en' ? 'Monthly' : 'شهريا'}
                                                                                </label>{" "}
                                                                            </div>
                                                                            <div className="margin-right positions-relative">
                                                                                <input
                                                                                    value="weekly"
                                                                                    checked={this.state.schedule_type == 'weekly'}
                                                                                    className="form-check-radio"
                                                                                    onChange={this.handleScheduleTypeRadioChange}
                                                                                    type="radio" />
                                                                                <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                                                                    {lang == 'en' ? 'Weekly' : 'أسبوعي'}
                                                                                </label>{" "}
                                                                            </div>
                                                                            <div className="margin-right positions-relative">
                                                                                <input
                                                                                    value="bi-weekly"
                                                                                    checked={this.state.schedule_type == 'bi-weekly'}
                                                                                    className="form-check-radio"
                                                                                    onChange={this.handleScheduleTypeRadioChange}
                                                                                    type="radio" />
                                                                                <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                                                                    {lang == 'en' ? 'Bi-Weekly' : 'نصف أسبوعي'}
                                                                                </label>{" "}
                                                                            </div>
                                                                            <div className="margin-right positions-relative">
                                                                                <input
                                                                                    value="daily"
                                                                                    className="form-check-radio"
                                                                                    checked={this.state.schedule_type == 'daily'}
                                                                                    onChange={this.handleScheduleTypeRadioChange}
                                                                                    type="radio" />
                                                                                <label style={this.state.lang == 'en' ? { marginRight: '15px' } : { marginRight: '25px' }} class="form-check-label" for="flexRadioDefault1">
                                                                                    {lang == 'en' ? 'Daily' : 'يوميًا'}
                                                                                </label>{" "}
                                                                            </div>
                                                                        </div> {" "}
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    {this.state.schedule_type == 'monthly' ?
                                                                        <>
                                                                            <Col xs={12} md={3}>
                                                                                <Label className="mb-3 mt-3">{t("labels.Schedule Date")}</Label>
                                                                                <FormGroup>
                                                                                    <DatePicker
                                                                                        autoComplete="off"
                                                                                        placeholderText="MM/DD/YYYY"
                                                                                        className="form-control repeated_order_delivery_date"
                                                                                        name="repeated_order_pickup_date"
                                                                                        selected={this.state.repeated_order_pickup_date}
                                                                                        minDate={new Date().setDate(new Date().getDate() + 3)}
                                                                                        maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(new Date(new Date().setMonth(new Date().getMonth() + 1)).getDate() + 2)}
                                                                                        onChange={(date) => {
                                                                                            let repeated_order_pickup_date = new Date(date);
                                                                                            let repeated_order_delivery_date = this.state.is_express_delivery_available == 1 ? new Date(date) : new Date(date).setDate(new Date(date).getDate() + actual_delivery_time);
                                                                                            let max_repeated_order_delivery_date = new Date(repeated_order_delivery_date).setDate(new Date(repeated_order_delivery_date).getDate() + 7);
                                                                                            this.setState({
                                                                                                min_repeated_order_delivery_date: repeated_order_delivery_date,
                                                                                                repeated_order_pickup_date: repeated_order_pickup_date,
                                                                                                repeated_order_delivery_date: repeated_order_delivery_date,
                                                                                                max_repeated_order_delivery_date: max_repeated_order_delivery_date,
                                                                                                repeated_order_pickup_specific_date: repeated_order_pickup_date.getDay(),
                                                                                                repeated_order_delivery_specific_date: new Date(repeated_order_delivery_date).getDay(),
                                                                                            }, () => this.repeated_order_pickup_handleMinTime(this.state.repeated_order_pickup_date))
                                                                                        }}
                                                                                    />
                                                                                    {errors.repeated_order_pickup_date && touched.repeated_order_pickup_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_pickup_date)}</div> : null}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </>
                                                                        : (null)}
                                                                    {this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly' ?
                                                                        <>
                                                                            <Col xs={12} md={3}>
                                                                                <FormGroup>
                                                                                    <Label className="mb-3 mt-3">{t("lables.Schedule Day")}</Label>
                                                                                    <Select
                                                                                        name="repeated_order_pickup_date"
                                                                                        value={this.state.repeated_order_pickup_date}
                                                                                        isMulti={false}
                                                                                        options={this.state.lang == 'en' ? weeklyOpts : weeklyOpts1}
                                                                                        onChange={(e) => {
                                                                                            var current_day = new Date().getDay();
                                                                                            var current_day_plus_one = (current_day + 1) > 6 ? ((current_day + 1) % 7) : (current_day + 1);
                                                                                            var current_day_plus_two = (current_day + 1) > 6 ? ((current_day + 2) % 7) : (current_day + 2);
                                                                                            this.setState({ show_next_week_pickup_date: true });
                                                                                            var repeated_order_delivery_date_temp = {};
                                                                                            var options = this.state.lang == 'en' ? weeklyOpts : weeklyOpts1;
                                                                                            var actual_delivery_days = this.state.is_express_delivery_available == 1 ? 0 : actual_delivery_time;
                                                                                            var days_temp = (parseInt(e.value) + actual_delivery_days);
                                                                                            var days = 0;
                                                                                            if (days_temp > 6) {
                                                                                                days = days_temp % 7;
                                                                                                var d = new Date();
                                                                                                d.setDate(d.getDate() + (((parseInt(e.value) + 7 - d.getDay()) % 7) || 7));
                                                                                                if (current_day_plus_one == parseInt(e.value) || current_day_plus_two == parseInt(e.value)) {
                                                                                                    d.setDate(d.getDate() + 7)
                                                                                                };
                                                                                                this.setState({
                                                                                                    show_next_week_delviery_date: true,
                                                                                                    next_week_delviery_date: new Date(d).setDate(d.getDate() + actual_delivery_days),
                                                                                                    next_week_pickup_date: new Date(d),
                                                                                                });
                                                                                            } else {
                                                                                                days = days_temp;
                                                                                                var d = new Date();
                                                                                                d.setDate(d.getDate() + (((parseInt(e.value) + 7 - d.getDay()) % 7) || 7));
                                                                                                if (current_day_plus_one == parseInt(e.value) || current_day_plus_two == parseInt(e.value)) {
                                                                                                    d.setDate(d.getDate() + 7)
                                                                                                };
                                                                                                this.setState({
                                                                                                    show_next_week_delviery_date: true,
                                                                                                    next_week_delviery_date: new Date(d).setDate(d.getDate() + actual_delivery_days),
                                                                                                    next_week_pickup_date: new Date(d),
                                                                                                });
                                                                                            };
                                                                                            options.map((optionsTemp) => {
                                                                                                if (parseInt(optionsTemp.value) == days) {
                                                                                                    repeated_order_delivery_date_temp = optionsTemp
                                                                                                }
                                                                                            });
                                                                                            this.setState({
                                                                                                repeated_order_pickup_date: e,
                                                                                                repeated_order_pickup_specific_date: parseInt(e.value),
                                                                                                repeated_order_delivery_specific_date: parseInt(repeated_order_delivery_date_temp.value),
                                                                                                repeated_order_delivery_date: repeated_order_delivery_date_temp,
                                                                                            }, () => this.repeated_order_pickup_handleMinTime(this.state.repeated_order_pickup_date.value))
                                                                                        }}
                                                                                        onBlur={setFieldTouched}
                                                                                    />
                                                                                    {errors.repeated_order_pickup_date && touched.repeated_order_pickup_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_pickup_date)}</div> : null}
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </>
                                                                        : (null)}
                                                                    {this.state.schedule_type == 'daily' || this.state.schedule_type == 'bi-weekly' || this.state.schedule_type == 'weekly' || this.state.schedule_type == 'monthly' ?
                                                                        <>
                                                                            <Col xs={12} md={3}>
                                                                                <Label className="mb-3 mt-3">{t("commons.Pick_Up_Time")}</Label>
                                                                                <FormGroup>
                                                                                    <DatePicker
                                                                                        className="form-control repeated_order_delivery_date"
                                                                                        selected={this.state.repeated_order_pickup_time}
                                                                                        showTimeSelect
                                                                                        showTimeSelectOnly
                                                                                        timeIntervals={30}
                                                                                        timeCaption="Time"
                                                                                        dateFormat="h:mm aa"
                                                                                        placeholderText={t("commons.Select_Time")}
                                                                                        // minTime={this.state.repeated_order_pickup_minTime}
                                                                                        // maxTime={moment().endOf("day").toDate()}
                                                                                        filterTime={this.repeated_order_pickup_filterPassedTime}
                                                                                        onChange={(date) => {
                                                                                            this.setState({
                                                                                                repeated_order_pickup_time: new Date(date),
                                                                                                repeated_order_delivery_time: new Date(date),
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    {errors.repeated_order_pickup_time && touched.repeated_order_pickup_time ? <div className="invalid-feedback d-block">{t(errors.repeated_order_pickup_time)}</div> : null}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            {this.state.schedule_type == 'monthly' ?
                                                                                <Col xs={12} md={3}>
                                                                                    <Label className="mb-3 mt-3">{t("commons.Delivery_Date")}</Label>
                                                                                    <FormGroup>
                                                                                        <DatePicker
                                                                                            className="form-control custom-cls repeated_order_delivery_date"
                                                                                            autoComplete="off"
                                                                                            selected={this.state.repeated_order_delivery_date}
                                                                                            name="repeated_order_delivery_date"
                                                                                            maxDate={new Date(this.state.max_repeated_order_delivery_date)}
                                                                                            minDate={new Date(this.state.min_repeated_order_delivery_date)}
                                                                                            onChange={(date) => {
                                                                                                this.setState({
                                                                                                    repeated_order_delivery_specific_date: date.getDay(),
                                                                                                    repeated_order_delivery_date: date
                                                                                                },
                                                                                                    () => this.repeated_order_delivery_handleMinTime(this.state.repeated_order_delivery_date));
                                                                                            }}
                                                                                            placeholderText="MM/DD/YYYY"
                                                                                        />
                                                                                        {errors.repeated_order_delivery_date && touched.repeated_order_delivery_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_date)}</div> : null}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                : ""
                                                                            }
                                                                            {this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly' ?
                                                                                <Col xs={12} md={3}>
                                                                                    <FormGroup>
                                                                                        <Label className="mb-3 mt-3">{t("lables.Delivery_day")}</Label>
                                                                                        <Select
                                                                                            value={this.state.repeated_order_delivery_date}
                                                                                            isMulti={false}
                                                                                            options={this.state.lang == 'en' ? weeklyOpts : weeklyOpts1}
                                                                                            onChange={(e) => {
                                                                                                var actual_delivery_days = this.state.is_express_delivery_available == 1 ? 0 : actual_delivery_time;
                                                                                                var d1 = new Date(this.state.next_week_pickup_date).setDate(new Date(this.state.next_week_pickup_date).getDate() + actual_delivery_days);
                                                                                                var d = new Date(d1);
                                                                                                if (new Date(d1).getDay() == parseInt(e.value)) {
                                                                                                    this.setState({ next_week_delviery_date: d });
                                                                                                } else {
                                                                                                    d.setDate(d.getDate() + (((parseInt(e.value) + 7 - d.getDay()) % 7) || 7));
                                                                                                    this.setState({ next_week_delviery_date: new Date(d) });
                                                                                                }
                                                                                                this.setState({
                                                                                                    repeated_order_delivery_date: e,
                                                                                                    repeated_order_delivery_specific_date: parseInt(e.value),
                                                                                                }, () => this.repeated_order_delivery_handleMinTime(this.state.repeated_order_delivery_date.value))
                                                                                            }}
                                                                                            onBlur={setFieldTouched}
                                                                                        />
                                                                                        {errors.repeated_order_delivery_date && touched.repeated_order_delivery_date ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_date)}</div> : null}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                : ""
                                                                            }
                                                                            {this.state.repeated_order_auto_delivery_time == 1 ? (
                                                                                <Col xs={12} md={3}>
                                                                                    <Label className="mb-3 mt-3">{t("commons.Delivery_Time")}</Label>
                                                                                    <FormGroup>
                                                                                        <DatePicker
                                                                                            className="form-control custom-cls repeated_order_delivery_date"
                                                                                            selected={this.state.repeated_order_delivery_time}
                                                                                            showTimeSelect
                                                                                            showTimeSelectOnly
                                                                                            timeIntervals={30}
                                                                                            timeCaption="Time"
                                                                                            dateFormat="h:mm aa"
                                                                                            // minTime={this.state.repeated_order_delivery_time}
                                                                                            minTime={this.state.repeated_order_delivery_minTime ? this.state.repeated_order_delivery_minTime : new Date()}
                                                                                            maxTime={moment().endOf("day").toDate() ? moment().endOf("day").toDate() : ""}
                                                                                            filterTime={this.repeated_order_delivery_filterPassedTime}
                                                                                            placeholderText={t("commons.Select_Time")}
                                                                                            onChange={(date) => {
                                                                                                this.setState({
                                                                                                    repeated_order_delivery_time: new Date(date),
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        {errors.repeated_order_delivery_time && touched.repeated_order_delivery_time ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_time)}</div> : null}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            ) : (
                                                                                <Col xs={12} md={3}>
                                                                                    <Label className="mb-3 mt-3">{t("commons.Delivery_Time")}</Label>
                                                                                    <FormGroup>
                                                                                        <DatePicker
                                                                                            className="form-control repeated_order_delivery_date"
                                                                                            selected={this.state.repeated_order_delivery_time}
                                                                                            showTimeSelect
                                                                                            showTimeSelectOnly
                                                                                            timeIntervals={30}
                                                                                            timeCaption="Time"
                                                                                            dateFormat="h:mm aa"
                                                                                            minTime={this.state.repeated_order_pickup_time}
                                                                                            // minTime={this.state.repeated_order_delivery_minTime ? this.state.repeated_order_delivery_minTime : new Date()}
                                                                                            maxTime={moment().endOf("day").toDate() ? moment().endOf("day").toDate() : ""}
                                                                                            filterTime={this.repeated_order_delivery_filterPassedTime}
                                                                                            placeholderText={t("commons.Select_Time")}
                                                                                            onChange={(date) => {
                                                                                                this.setState({
                                                                                                    repeated_order_delivery_time: new Date(date),
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                        {errors.repeated_order_delivery_time && touched.repeated_order_delivery_time ? <div className="invalid-feedback d-block">{t(errors.repeated_order_delivery_time)}</div> : null}
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            )}
                                                                        </>
                                                                        : (null)}
                                                                </Row>
                                                                {(this.state.schedule_type == 'weekly' || this.state.schedule_type == 'bi-weekly') && this.state.repeated_order == 'yes' ? (
                                                                    <Row>
                                                                        <Col xs={12} md={6} >
                                                                            {this.state.show_next_week_pickup_date ? <p>{t("Your order will be created from") + " " + this.state.repeated_order_pickup_date.label + " " + moment(this.state.next_week_pickup_date).format("DD/MM/YYYY")}</p> : ""}
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.show_next_week_delviery_date ? (<p>{t("Your Order will be delivery on next") + " " + this.state.repeated_order_delivery_date.label + " " + moment(this.state.next_week_delviery_date).format("DD/MM/YYYY")}</p>) : ""}
                                                                        </Col>
                                                                    </Row>
                                                                ) : ""}
                                                                {this.state.schedule_type == 'daily' && this.state.repeated_order == 'yes' ? (
                                                                    <Row>
                                                                        <Col xs={12} md={3} >
                                                                            <p>{t("Your order will be created from") + " " + moment(this.state.next_week_pickup_date).format("DD/MM/YYYY")}</p>
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            <p>{t("Your Order will be delivery on next")} {moment(new Date().setDate(new Date().getDate() + this.state.vendorData.normal_delivery_time + 1)).format("DD/MM/YYYY")}</p>
                                                                        </Col>
                                                                    </Row>
                                                                ) : ""}
                                                            </>
                                                            : (null)
                                                        }
                                                        {/* <Button className="btn text-uppercase" disabled={this.state.repeated_order == 'yes' ? false : true} type="submit">
                                                            {t("buttons.submit")}
                                                        </Button> */}
                                                         <StyledButtondiv1 className="btn text-uppercase" disabled={this.state.repeated_order == 'yes' ? false : true} type="submit">{t("buttons.submit")}</StyledButtondiv1>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                        <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
                    </>
                )}
            </Translation>
        );
    }
}
