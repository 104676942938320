import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.textgrey}; /* Initial text color */
  &:hover {
    color: ${({ theme }) => theme.colors.primary} !important; /* Change text color on hover */
  }
`;

export default StyledNavLink;
