import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, FormGroup, Form, Label, Input, CustomInput } from "reactstrap";
import ApiRoutes from "../../helpers/ApiRoutes";
import Http from "../../helpers/Http";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { Formik, Field } from "formik";
import Select from "react-select";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";
import i18next from "i18next";
import { Route, Redirect } from "react-router-dom";
import authService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { StyledButtondiv1, StyledSpan, StyledStrong } from "../../helpers/StyledButtondiv";
import StyledInputButton from "../../helpers/StyledInputButton";


var totalDeliveryCharge = "";
var subtotal = "";
var settingInfo = authService.getAuthSettings();
var expressDelivery = "";
var Freedelivery = "";
export default class Payment extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      // subcategory_discount_name: "",
      // subcategory_discount_id: "",
      product_discount: "",
      automatic_couon: "",
      // subcategory_discount_distance: 0,
      lang: localStorage.getItem("lang") || "en",
      vendorId: props.match.params.vendor_id,
      expressDeliveryCharge: "",
      itemExpressDeliveryCharge: "",
      itemData: [],
      carttotal: "",
      separateWashCharges: "",
      laundrybagCharge: "",
      addonCharges: "",
      orderVatpercent: "",
      isLoading: false,
      isLoading_page: false,
      pickUpDistance: "",
      deliveryDistance: "",
      driver_transport_charge: "",
      transport_vat: "",
      free_delivery_status: "",
      free_delivery_duration: "",
      min_order_amount: "",
      showModal: false,
      promocodeList: [],
      vendorData: [],
      subtotal: "",
      expressDelivery: "",
      discountedPrice: "",
      discountedPriceNew: "",
      discount_amt: "",
      discount_type_new: "",
      minamt: "",
      // max_amount_applicable: "",
      mindis: "",
      orderVatAmount: "",
      orderVatCharges: "",
      totalDeliveryCharge: "",
      deliveryVat: "",
      grandTotal: "",
      subtotal_new: "",
      wallet_total: "",
      promocode: "",
      cartItems: [],
      payment_method: "",
      card_id: "",
      cardList: [],
      coupon_applied_logic: "custom",
      show_promocode_section: true,
      Wallet_SubTotal: "",
      walletUsed: "no",
      reduced_amount: "",
      walletApplied: 0,
      total_subtotal: 0,
      custom_promocode: "",
      button_disable: false,
      pickup_validation_error: "",
      FreeDelivery_3: 0,
      enable_place_order: 0,
      OfferType: "",
      adon_data: [],
      adon_text: "",
      adon_type: [],
      no_free_delivery_content: "",
      transportationCart: 1 //1 - yes, 0 - no
      // show_adon_text_filed: false
    };
  }
  handlePromoCode_automatic = async (carttotal) => {
    let formData = new FormData();
    formData.append("vendor_id", this.state.vendorId);
    formData.append("pickup_distance", this.state.pickUpDistance && this.state.deliveryDistance);
    formData.append("delivery_distance", this.state.deliveryDistance && this.state.pickUpDistance);
    if (this.state.product_discount == 'removed') {
      formData.append("sub_total_amount", carttotal);
    } else {
      formData.append("sub_total_amount", carttotal - this.state.subcategory_discount_price);

    }
    if (this.state.automatic_couon == '') {
      this.setState({ button_disable: true });
      let path = ApiRoutes.CREATE_PROMOCODE_AUTO;
      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();
        if (res.status == 200) {
          this.setState({ isLoading_page: false });
          if (resJson.data.is_discount == 'available') {
            this.handleCalculation(this.state.vendorData, this.state.carttotal, resJson.data.discounted_amount, "showfreedelivery");
            this.setState({
              coupon_applied_logic: "auto",
              show_promocode_section: false,
              automatic_couon: "available",
              // showModal: false,
              promocode: resJson.data.promocode,
              couponcode_1: resJson.data.promocode,
              discountedPrice: parseFloat(resJson.data.discounted_amount),
            });
            this.setState({ button_disable: false });
            // NotificationManager.success(resJson.message, "Success!", 3000);
          }
        } else {
          this.handleCalculation(this.state.vendorData, this.state.carttotal, 0, "showfreedelivery");
          this.setState({
            show_promocode_section: true,
            promocode: "",
            discountedPrice: 0,
          });
          this.setState({ button_disable: false });
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }

    }
  };
  async componentDidMount() {
    if (this.props.authUser) {
      this.setState({ isLoading_page: true });
      // await this.getCouponCodes(); //Auto coupon removed previous logic
      await this.getWalletAmount();
      await this.getCartData('');
      await this.getWalletData('');
      // this.getPromoCodes();

      await this.showCardList();

      const context = this.context;
    }
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };
  getWalletData = async (val) => {
    if (val != '') {
      this.setState({
        walletUsed: "no",
        reduced_amount: "",
        wallet_total: "",
      },
        () => this.handleCalculation(),
      );
    }
  }
  getCartData = async (val) => {
    this.setState({ button_disable: true });
    if (val == 'remove_subcategory_discount') {
      this.setState({
        product_discount: 'removed',
        // subcategory_discount_distance:null,
        // subcategory_discount_id: null,
        // subcategory_discount_name: null
      });
    }
    else if (val != '') {
      this.setState({
        automatic_couon: "removed",
        custom_promocode: '',
        coupon_applied_logic: 'custom',
        show_promocode_section: true,
        discountedPriceNew: 0,
        promocode: '',
        discount_type_new: '',
        discount_amt: 0,
        minamt: '',
        // max_amount_applicable: '',
        mindis: '',
        discountAmount1: '',
        couponcode_1: ""
      });
    }
    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {

        var totalprice = 0;
        var total_subtotal = 0;
        var totalprice_without_product_discount = 0;
        var subcategory_discount_price = 0;
        // var subcategory_discount_name = "";
        // var subcategory_discount_id = "";
        // var subcategory_discount_distance = 0;

        let transportationCart = resJson.data.transportationCart;

        resJson.data.cartItems.map((item) => {
          item.service_items.map((service_type) => {
            if (val != 'remove_subcategory_discount') {
              if (service_type.DiscountPrice == 0 || service_type.DiscountPrice == undefined) {
                totalprice_without_product_discount += service_type.TotalPrice;
              }
              // subcategory_discount_distance = service_type.max_distance_coupon_subcategory
              subcategory_discount_price += service_type.DiscountPrice;
              // subcategory_discount_name = service_type.subcategory_discount_name;
              // subcategory_discount_id = service_type.subcategory_discount_id;
            }
            total_subtotal = service_type.unit_quantity * service_type.price_by_measure + service_type.clothe_return_type.hanging_charge;
            if (service_type.TotalPrice != undefined) {
              totalprice += service_type.TotalPrice;
            } else {
              totalprice += service_type.unit_quantity * service_type.price_by_measure + service_type.clothe_return_type.hanging_charge;
            }
          });
        });
        console.log(resJson.data.amountInfo.sub_total, 'resJson.data.amountInfo.sub_total');
        this.setState(
          {
            // subcategory_discount_name: subcategory_discount_name,
            // subcategory_discount_id: subcategory_discount_id,
            totalprice_without_product_discount: totalprice_without_product_discount,
            subcategory_discount_price: subcategory_discount_price,
            // subcategory_discount_distance: subcategory_discount_distance,
            cartItems: resJson.data.cartItems,
            carttotal: totalprice,
            total_subtotal: total_subtotal,
            subtotalexpress: resJson.data.amountInfo.sub_total,
            transportationCart: transportationCart,
            // is_corporate_customer: resJson.data.is_corporate_customer,
            corporatePackageAvailable: resJson.data.corporatePackageAvailable,
            corporate_admin_package_transport_commission: resJson.data.corporate_admin_package_transport_commission
          },
          () => {
            this.getVendorData(); if (val == 'remove_subcategory_discount') {
              if (this.state.promocode != '' && this.state.coupon_applied_logic != 'auto') {
                this.handlePromoCode(this.state.promocode, totalprice);
              }
            }
          }
        );

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  hideModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  getVendorData = async () => {
    const { state } = this.props.location;

    let path = ApiRoutes.GET_VENDOR + "/" + this.state.vendorId;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        var Express_Delivery_Charge = ''
        if (state.delivery_method == 'longer') {
          Express_Delivery_Charge = resJson.data.longer_express_delivery_charge;
        } else if (state.delivery_method == 'medium') {
          Express_Delivery_Charge = resJson.data.medium_express_delivery_charge;
        } else {
          Express_Delivery_Charge = resJson.data.normal_express_delivery_charge;
        }
        console.log(Express_Delivery_Charge, 'Express_Delivery_Charge');
        this.setState(
          {
            vendorData: resJson.data,
            expressDeliveryCharge: resJson.data.is_express_delivery ? Express_Delivery_Charge : "",
            itemExpressDeliveryCharge: Express_Delivery_Charge,
            separateWashCharges: (resJson.data.seperate_wash_charge / 100) * this.state.carttotal,
          },
          async () => await this.handleCalculation(this.state.vendorData, this.state.carttotal),
          await this.getPickUpDistancebyLatLng(
            resJson.data.default_address.latitude,
            resJson.data.default_address.longitude,
            state.pickup_address.latitude,
            state.pickup_address.longitude
          ),
          await this.getDeliveryDistancebyLatLng(
            resJson.data.default_address.latitude,
            resJson.data.default_address.longitude,
            state.delivery_address.latitude,
            state.delivery_address.longitude
          ), await this.handlePromoCode_automatic(this.state.carttotal)

        );
        // const adon_temp = [
        //   { value: "", label: "select" },
        // ];
        // resJson.data.adon && resJson.data.adon.map((value) => {
        //   adon_temp.push({ value: value.adon_type.en, label: value.adon_type.en })
        // });
        // adon_temp.push({ value: "Others", label: "Others" });
        // this.setState({
        //   adon_data: adon_temp
        // })
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleCalculation = async (vendor, carttotal = this.state.carttotal, discount = "", showfreedelivery = "", walletTotal = this.state.wallet_total) => {
    this.setState({ button_disable: true });

    if (this.state.product_discount != 'removed') {
      // if (this.state.pickUpDistance <= this.state.subcategory_discount_distance && this.state.deliveryDistance <= this.state.subcategory_discount_distance) {
      // if (this.state.totalprice_without_product_discount != 0) {
      this.setState({ product_discount: "enabled" })
      // }
      carttotal = carttotal - this.state.subcategory_discount_price;
      // }
    }
    walletTotal = this.state.wallet_total;
    const { state } = this.props.location;
    const { authUser } = this.props;
    const { cartItems } = this.state;
    var discountedPrice = 0;
    var discountedPriceNew = 0;
    var PriceafterDiscount = 0;
    var orderVatCharges = 0;
    var subtotal1 = 0;
    var orderVatAmount = 0;
    var expressDelivery_1 = 0;
    // Adding cart and addoncharges
    // subtotal = state && state.addonCharges >= 0 && carttotal && parseFloat(state.addonCharges) + parseFloat(carttotal);
    subtotal = state && state.addonCharges >= 0 && parseFloat(state.addonCharges) + parseFloat(carttotal);

    this.setState({
      subtotal: subtotal,
      adon_text: state.adon_text || '',
      adon_type: state.adon_type || [],
    });
    // calculating Express_Delivery charges
    expressDelivery_1 = state.is_express_delivery && this.state.subtotalexpress && parseFloat(this.state.itemExpressDeliveryCharge / 100) * parseFloat(this.state.subtotalexpress);
    console.log(this.state.subtotalexpress, 'subtotalexpress');
    console.log(this.state.itemExpressDeliveryCharge, 'itemExpressDeliveryCharge');
    console.log(expressDelivery_1, 'expressDelivery_1');
    this.setState({ expressDelivery: expressDelivery_1 });
    // calculating discounted price after promocode
    discountedPriceNew = discount ? discount : 0;
    this.setState({ discountedPriceNew: discountedPriceNew });
    // adding cart, addoncharges and Express_Delivery charges
    subtotal1 = parseFloat(subtotal) + parseFloat(expressDelivery_1);
    this.setState({ subtotal_new: subtotal1 });
    if (discountedPriceNew == 0) {
      // new calculating discounted price after 
      if (this.state.coupon_applied_logic == 'auto') {
        discountedPriceNew = this.state.discount_amt ? this.state.discount_amt : 0;
        this.setState({ discountedPriceNew: discountedPriceNew });
      }
      // else{
      //   discountedPriceNew = 0;
      //   this.setState({ discountedPriceNew: discountedPriceNew });
      // }
      // if (subtotal1 >= this.state.minamt) {
      //   if (this.state.pickUpDistance <= this.state.mindis && this.state.deliveryDistance <= this.state.mindis) {
      //     if (this.state.discount_type_new == "flat") {
      //       discountedPriceNew = this.state.discount_amt ? this.state.discount_amt : 0;
      //       // if (discountedPriceNew > this.state.max_amount_applicable) {
      //       //   discountedPriceNew = this.state.max_amount_applicable;
      //       // }
      //       this.setState({ discountedPriceNew: discountedPriceNew });
      //     }
      //     else if (this.state.discount_type_new == "percent") {
      //       discountedPriceNew = this.state.discount_amt ? this.state.discount_amt : 0;
      //       discountedPriceNew = (discountedPriceNew / 100) * subtotal1;
      //       discountedPriceNew = discountedPriceNew.toFixed(3);
      //       // if (discountedPriceNew > this.state.max_amount_applicable) {
      //       //   discountedPriceNew = this.state.max_amount_applicable;
      //       // }
      //       this.setState({ discountedPriceNew: discountedPriceNew });

      //     }
      //     else {
      //       discountedPriceNew = 0;
      //       this.setState({ discountedPriceNew: discountedPriceNew });

      //     }

      //   }
      //   else {
      //     discountedPriceNew = 0;
      //     console.log("g1")
      //     this.setState({
      //       discountedPriceNew: discountedPriceNew,
      //       promocode: '',
      //       coupon_applied_logic: "custom",
      //       couponcode_1: "",
      //     });
      //   }
      // }
      else {
        discountedPriceNew = 0;
        this.setState({
          discountedPriceNew: discountedPriceNew,
          promocode: '',
          coupon_applied_logic: "custom",
          show_promocode_section: true,
          couponcode_1: "",
        });
      }

    }
    if (this.state.discountAmount1 && discountedPriceNew == 0) {
      var discountedPriceNew = this.state.discountAmount1;
      this.setState({ discountedPriceNew: discountedPriceNew });
    }
    if (this.state.couponcode_1) {
      this.setState({ promocode: this.state.couponcode_1, couponcode_1: this.state.promocode });
    }
    this.setState({ discountAmount1: discountedPriceNew, couponcode_1: this.state.promocode });
    // new cart + addoncharges + Express_Delivery charges - discountedPrice 
    PriceafterDiscount = subtotal1 - discountedPriceNew;

    // cart + addoncharges + Express_Delivery charges - discountedPrice
    // PriceafterDiscount = subtotal1 - discountedPrice;

    // calculating order vat
    orderVatAmount = settingInfo && settingInfo.order_vat && parseFloat(settingInfo.order_vat / 100) * PriceafterDiscount;
    this.setState({ orderVatAmount: orderVatAmount });

    orderVatCharges = parseFloat(orderVatAmount) + parseFloat(PriceafterDiscount);
    this.setState({ orderVatCharges: orderVatCharges });

    var requiredVehicleSize = "small";
    var additionalTransportCharge = 0;

    for (const cartItem of cartItems) {
      if (cartItem.service.category.allowed_vehicle_size && cartItem.service.category.allowed_vehicle_size == "large") {
        requiredVehicleSize = "large";
        additionalTransportCharge = cartItem.service.category.additional_transport_charge;
      }
    }

    //  calcuation for pickup charges
    var pickupCharges = settingInfo && settingInfo.driver_transport_charge && this.state.pickUpDistance && (settingInfo.driver_transport_charge * this.state.pickUpDistance).toFixed(3);

    pickupCharges = pickupCharges > settingInfo.driver_min_transport_charge ? pickupCharges : settingInfo.driver_min_transport_charge;
    // pickupCharges += this.state.pickUpDistance * additionalTransportCharge;
    pickupCharges = parseFloat(pickupCharges) + parseFloat(this.state.pickUpDistance * additionalTransportCharge);

    //  calcuation for delivery charges
    var deliveryCharge =
      settingInfo && settingInfo.driver_transport_charge && this.state.deliveryDistance && (settingInfo.driver_transport_charge * this.state.deliveryDistance).toFixed(3);

    deliveryCharge = deliveryCharge > settingInfo.driver_min_transport_charge ? deliveryCharge : settingInfo.driver_min_transport_charge;
    deliveryCharge = parseFloat(deliveryCharge) + parseFloat(this.state.deliveryDistance * additionalTransportCharge);

    // total tarnsport charge
    // totalDeliveryCharge = parseFloat(pickupCharges) + parseFloat(deliveryCharge);
    totalDeliveryCharge = pickupCharges + deliveryCharge;

    if (this.state.is_corporate_customer == 1 && this.state.corporatePackageAvailable == true) {
      let total_corporate_transport_commission = 100;
      let corporate_admin_package_transport_commission = parseFloat(this.state.corporate_admin_package_transport_commission);

      let customer_corporate_package_transport_commission = total_corporate_transport_commission - corporate_admin_package_transport_commission;

      totalDeliveryCharge = (totalDeliveryCharge * customer_corporate_package_transport_commission) / 100;
      // console.log(totalDeliveryCharge, '8765436ytjghhgfc');
    }

    var orderamount = 0
    if (subtotal1 == 0) {
      orderamount = this.state.total_subtotal;
    } else {
      orderamount = subtotal1;
    }
    var newDate = new Date(authUser.createdAt);
    var customer_id = this.props.authUser._id;
    let formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("vendor_id", this.state.vendorId);
    formData.append("pickup_distance", this.state.pickUpDistance);
    formData.append("delivery_distance", this.state.deliveryDistance);
    formData.append("amount", orderamount);
    let path = ApiRoutes.GET_FREEDELIVERY_CART;
    const res = await Http("POST", path, formData);
    var FreeDelivery_1 = "";
    var FreeDelivery_2 = "";
    var FreeDelivery_3 = "";
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({
          no_free_delivery_content: resJson.data.bear_cost.data
        })
        if (resJson.data.freedleivery_eligble == '1') {
          totalDeliveryCharge = 0;
          Freedelivery = "admin_settings";
          FreeDelivery_1 = "admin_settings";
          if (showfreedelivery == '') {
            if (this.state.lang == 'en') {
              NotificationManager.success("Free delivery is applicable", "Success", 3000);
            } else {
              NotificationManager.success("التوصيل المجاني قابل للتطبيق", "Success", 3000);
            }
          }
        } else {
          // console.log(';bvcbnm,');
          totalDeliveryCharge = Number(resJson.data.bear_cost.customer_bear_cost);
          console.log(resJson.data.bear_cost.customer_bear_cost, 'resJson.data.bear_cost.customer_bear_cost');
          console.log(totalDeliveryCharge, 'kljhgfdsew37m3486toliuh');

          if (this.state.is_corporate_customer == 1 && this.state.corporatePackageAvailable == true) {
            let total_corporate_transport_commission = 100;
            let corporate_admin_package_transport_commission = parseFloat(this.state.corporate_admin_package_transport_commission);
            let customer_corporate_package_transport_commission = total_corporate_transport_commission - corporate_admin_package_transport_commission;

            totalDeliveryCharge = (totalDeliveryCharge * customer_corporate_package_transport_commission) / 100
          }

        }
      }
      console.log(totalDeliveryCharge, 'totalDeliveryChargetotalDeliveryChargetotalDeliveryCharge');
      if (cartItems) {
        cartItems.map((item) => {
          item.service_items.map((items) => {
            if (item.free_delivery == 0 && items.measure_type == 'piece') {
              totalDeliveryCharge = 0;
              Freedelivery = "package_settings";
              FreeDelivery_2 = "package_settings";
              if (showfreedelivery == '') {
                if (this.state.lang == 'en') {
                  NotificationManager.success("Free delivery is applicable", "Success", 3000);
                } else {
                  NotificationManager.success("التوصيل المجاني قابل للتطبيق", "Success", 3000);
                }
              }
            }
          });
        });
      } if (this.state.FreeDelivery_3 == 1) {
        totalDeliveryCharge = 0;
        Freedelivery = "global_coupon";
        FreeDelivery_3 = "global_coupon";
        if (showfreedelivery == '') {
          if (this.state.lang == 'en') {
            NotificationManager.success("Free delivery is applicable", "Success", 3000);
          } else {
            NotificationManager.success("التوصيل المجاني قابل للتطبيق", "Success", 3000);
          }
        }
      }
    }
    if (FreeDelivery_1 == "admin_settings" && FreeDelivery_2 == "package_settings" && FreeDelivery_3 == "global_coupon") {
      Freedelivery = "all";
    } else if (FreeDelivery_1 == "admin_settings" && FreeDelivery_2 == "package_settings" && FreeDelivery_3 == "") {
      Freedelivery = "freedelivery_package";
    } else if (FreeDelivery_1 == "" && FreeDelivery_2 == "package_settings" && FreeDelivery_3 == "global_coupon") {
      Freedelivery = "package_coupon";
    } else if (FreeDelivery_1 == "admin_settings" && FreeDelivery_2 == "" && FreeDelivery_3 == "global_coupon") {
      Freedelivery = "freedelivery_coupon";
    }

    if (this.state.transportationCart == 0) {
      totalDeliveryCharge = 0;
    }
    console.log(totalDeliveryCharge, 'setstatetotalDeliveryCharge');

    this.setState({ totalDeliveryCharge: totalDeliveryCharge, Freedelivery: Freedelivery });


    // calculation for delivery vat
    var deliveryVat = settingInfo && settingInfo.transport_vat && parseFloat(settingInfo.transport_vat / 100) * parseFloat(totalDeliveryCharge);
    this.setState({ deliveryVat: deliveryVat });

    var totalTransportVat = parseFloat(totalDeliveryCharge) + parseFloat(deliveryVat);

    // Final total
    var grandTotal = parseFloat(orderVatCharges) + parseFloat(totalTransportVat) + parseFloat(state.laundry_bag_charges);
    // grandTotal = parseFloat(grandTotal).toFixed(3);
    grandTotal = roundTo(grandTotal, 3);
    function roundTo(n, digits) {
      var multiplier = Math.pow(10, digits);
      return Math.round(n * multiplier) / multiplier;
    }

    if (walletTotal != '' && walletTotal >= grandTotal) {
      var walletTotal = 0;
      var walletApplied = 1;
      this.setState({ grandTotal: walletTotal, reduced_amount: grandTotal, walletUsed: "yes", totalprice: grandTotal, walletApplied: walletApplied });

    } else if (walletTotal < grandTotal && walletTotal != 0) {
      var wallet_total = grandTotal - walletTotal;
      var reducedAmount = grandTotal - wallet_total;
      // if(this.state.minusAmount && this.state.minusAmount != 0){
      //   var wallet_total = wallet_total - this.state.minusAmount;
      // var reducedAmount = grandTotal - wallet_total;

      // }
      this.setState({ grandTotal: wallet_total, reduced_amount: reducedAmount, walletUsed: "yes", totalprice: grandTotal });

    }
    else {
      this.setState({ grandTotal: grandTotal });
    }
    this.setState({ button_disable: false });

  };

  handleSubmit = async (e, state) => {
    const context = this.context;
    e.preventDefault();
    var WalletAmount = this.state.walletAmount;
    if (this.state.walletUsed == 'yes') {
      if (this.state.wallet_total > this.state.totalprice) {
        var payment_method = "wallet";

      } else if (this.state.wallet_total < this.state.totalprice) {
        if (this.state.payment_method == "") {
          NotificationManager.error(i18next.t("commons.Please_select_payment_method"), "Error!", 3000);
          return false;
        }
        var payment_method = this.state.payment_method;
      }
    } else {
      if (this.state.payment_method == "") {
        NotificationManager.error(i18next.t("commons.Please_select_payment_method"), "Error!", 3000);
        return false;
      }
      var payment_method = this.state.payment_method;
    }
    if (WalletAmount == null) {
      WalletAmount = 0;
    }
    var total_amount = this.state.grandTotal;
    this.setState({ isLoading: true });

    // Validate the valid pickup time
    let isToday = moment(state.pickup_date).isSame(moment(), "day");
    if (isToday) {
      var OrderPickupTime = state.pickup_time;
      var CurrentDate = moment(new Date());
      function round(date, duration, method) {
        return moment(Math[method]((+date) / (+duration)) * (+duration));
      }
      var roundedDate = round(CurrentDate, moment.duration(30, "minutes"), "ceil");
      var nowAddOneHour = moment(new Date(roundedDate)).toDate();
      nowAddOneHour.setHours(nowAddOneHour.getHours() + 1);
      var ValidPickupTime = moment(nowAddOneHour).format("HH:mm");
      if (ValidPickupTime > OrderPickupTime) {
        if (this.state.lang == 'en') {
          var error_message = "Choosen Pickup Slot is not available. Please choose another pickup slot";
        } else {
          var error_message = "فتحة الالتقاط المختارة غير متاحة. الرجاء اختيار فتحة أخرى";
        }
        this.setState(
          {
            pickup_validation_error: error_message,
            enable_place_order: 1,
            isLoading: false,
          },
        );
        return false;
      } else {
        console.log('khj');
      }
    }
    let formData = new FormData();
    formData.append("adon_others", this.state.adon_text);
    formData.append("adon_request", JSON.stringify(this.state.adon_type));
    formData.append("is_express_delivery", state.is_express_delivery ? 1 : 0);
    formData.append("pickup_address", JSON.stringify(state.pickup_address));
    formData.append("delivery_address", JSON.stringify(state.delivery_address));
    formData.append("pickup_date", state.pickup_date);
    formData.append("pickup_time", state.pickup_time);
    formData.append("is_easy_pickup", 0);
    formData.append("delivery_date", state.delivery_date);
    formData.append("delivery_time", state.delivery_time);
    formData.append("shampoo", state.shampoo ? JSON.stringify(state.shampoo) : "");
    formData.append("perfume", state.perfume ? JSON.stringify(state.perfume) : "");
    formData.append("softener", state.softener ? JSON.stringify(state.softener) : "");
    formData.append("bleach", state.bleach ? JSON.stringify(state.bleach) : "");
    formData.append("sanitizer", state.sanitizer ? JSON.stringify(state.sanitizer) : "");
    formData.append("is_seperate_wash", state.is_seperate_wash ? 1 : 0);
    formData.append("laundry_bag_count", state.laundry_bag_count != "" && state.laundry_bag_count.bags != "" ? state.laundry_bag_count.bags : "");
    formData.append("promocode", ((this.state.discountedPriceNew != 0 && this.state.discountedPriceNew != '') || this.state.OfferType == "freedelivery") ? this.state.promocode ? this.state.promocode : "" : "");
    formData.append("payment_method", payment_method || "card");
    formData.append("card_id", this.state.payment_method == "card" ? this.state.card_id : "");
    formData.append("freedelivery", this.state.totalDeliveryCharge == 0 ? 'freedelivery' : 'nofreedelivery');
    formData.append("Freedelivery_logic", this.state.Freedelivery);
    formData.append("coupon_applied_logic", this.state.coupon_applied_logic);
    formData.append("OfferType", this.state.coupon_applied_logic == "custom" ? this.state.OfferType : "");
    formData.append("walletUsed", this.state.walletUsed);
    formData.append("walletAmount", WalletAmount);
    formData.append("total_amount", total_amount);
    formData.append("product_discount", this.state.product_discount);
    formData.append("addonCharges", state.addonCharges.toFixed(3));
    formData.append("delivery_method", state.delivery_method);
    // formData.append("subcategory_discount_name", this.state.subcategory_discount_name != undefined ? this.state.subcategory_discount_name : "");
    // formData.append("subcategory_discount_id", this.state.subcategory_discount_id != undefined ? this.state.subcategory_discount_id : "");

    let path = ApiRoutes.CREATE_ORDER;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        const addonDetails = {
          wash: "",
          laundrybag: "",
          addShampoos: "",
          addBleaches: "",
          addPerfumes: "",
          addSofteners: "",
          addSanitizers: "",
        };

        const checkoutDetails = {
          is_express_delivery: "",
          delivery_method: "",
          pickup_address: "",
          delivery_address: "",
          pickup_date: "",
          pickup_time: "",
          delivery_date: "",
          delivery_time: "",
          shampoo: "",
          perfume: "",
          softener: "",
          bleach: "",
          sanitizer: "",
          is_seperate_wash: "",
          laundry_bag_count: "",
        };

        context.setCheckOut(checkoutDetails);
        context.setAdditionalInfo(addonDetails);

        if (this.state.payment_method == "cash") {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.props.history.push("/manage-orders");
        }
        else if (resJson.data.wallet == 'yes') {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.props.history.push("/manage-orders");
        }
        else {
          window.location.replace(resJson.data.payment_url);
        }
      } else {
        this.setState({ isLoading: false });
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      this.setState({ isLoading: false });
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleWallet = async (walletAmount) => {
    this.setState({
      wallet_total: walletAmount,
    },
      () => this.handleCalculation(this.state.vendorData, this.state.carttotal, "", "", walletAmount),

    );
  };

  getCouponCodes = async () => {
    var customer_id = this.props.authUser._id;
    let formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("vendor_id", this.state.vendorId);
    let path = ApiRoutes.GET_CART_COUPOUN;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        if (resJson.data.is_discount == 'available') {
          this.setState({
            discount_type_new: resJson.data.discount_type,
            discount_amt: resJson.data.discount_amount,
            promocode: resJson.data.coupon,
            couponcode_1: resJson.data.coupon,
            minamt: resJson.data.min_amount_coupon,
            // max_amount_applicable: resJson.data.max_amount_applicable,
            mindis: resJson.data.max_distance_coupon,
            coupon_applied_logic: "auto",
            show_promocode_section: false,
          });
        }
      }
    }
  };

  getWalletAmount = async () => {
    let path = ApiRoutes.GET_WALLET_AMOUNT;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({
          walletAmount: resJson.data.wallet_amount,
          is_corporate_customer: resJson.data.is_corporate_customer,
        });
      }
    }
  };

  deleteCard = async (cards) => {
    let path = ApiRoutes.DELETE_CARD;
    let formData = new FormData();
    formData.append("cardData", JSON.stringify(cards));
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.showCardList();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  showCardList = async () => {
    let path = ApiRoutes.GET_CARDSLIST;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          cardList: resJson.data,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getPickUpDistancebyLatLng = (lat1, lon1, lat2, lon2) => {
    if (lat1 == lat2 && lon1 == lon2) {
      this.setState(
        { pickUpDistance: 0 },

        () =>
          this.getDeliveryDistancebyLatLng(
            this.state.lat1,
            this.state.lng1,
            this.props.location.state.delivery_address.latitude,
            this.props.location.state.delivery_address.longitude
          )
      );
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      this.setState({ pickUpDistance: dist });
      return dist;
    }
  };

  getDeliveryDistancebyLatLng = (lat1, lon1, lat2, lon2) => {
    if (lat1 == lat2 && lon1 == lon2) {
      this.setState({ deliveryDistance: 0 });
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344;
      this.setState({ deliveryDistance: dist });
      return dist;
    }
  };

  handlePromoCode = async (promocode, totalprice) => {
    this.setState({ button_disable: true });
    promocode = promocode.toUpperCase();
    let formData = new FormData();
    formData.append("promocode", promocode);
    formData.append("vendor_id", this.state.vendorId);
    formData.append("pickup_distance", this.state.pickUpDistance && this.state.deliveryDistance);
    formData.append("delivery_distance", this.state.deliveryDistance && this.state.pickUpDistance);
    formData.append("sub_total_amount", totalprice);

    let path = ApiRoutes.CREATE_PROMOCODE;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({ button_disable: false });
        if (resJson.data.offer_type == 'discount') {
          this.handleCalculation(this.state.vendorData, this.state.carttotal, resJson.data.discounted_amount, "showfreedelivery");
        } else {
          this.handleCalculation(this.state.vendorData, this.state.carttotal, resJson.data.discounted_amount, "",);
        }
        this.setState({
          show_promocode_section: false,
          // showModal: false,
          promocode: promocode,
          couponcode_1: promocode,
          FreeDelivery_3: resJson.data.free_delivery,
          OfferType: resJson.data.offer_type,
          discountedPrice: parseFloat(resJson.data.discounted_amount),
        });
        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        this.handleCalculation(this.state.vendorData, this.state.carttotal, 0, "showfreedelivery");
        this.setState({
          promocode: "",
          discountedPrice: 0,
          show_promocode_section: true,
        });
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  // handleDiscount = () => {
  //   this.setState({ promocode: "", discountedPrice: 0 }, () => this.handleCalculation(this.state.vendorData, this.state.carttotal, this.state.discountedPrice));
  // };
  // adon_type(event) {
  //   this.state.adon_type = [];
  //   this.setState({ show_adon_text_filed: false });
  //   var data = []
  //   event.map((items) => {
  //     data.push({ "value": items.value, "label": items.label });
  //     if (items.value == "Others") {
  //       this.setState({ show_adon_text_filed: true })
  //     }
  //   })
  //   console.log(data, 'sdksdj')
  //   this.setState({ adon_type: data })
  // }
  addCardById = (event) => {
    var elems = document.getElementsByClassName("shampoo_opts");
    var currentState = event.target.checked;
    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      this.setState({
        card_id: event.target.value,
      });
    } else {
      this.setState({
        card_id: "",
      });
    }
  };

  onChangePaymentMethod = (payment_method) => {
    this.setState({
      payment_method: payment_method,
    });
  };


  render() {
    // console.log(this.state.show_promocode_section,"coupon_applied_logic");
    var { promocodeList, promocode, cardList } = this.state;
    const { authUser } = this.props;
    const { state } = this.props.location;
    return (
      this.state.isLoading_page ?
        <div className="loading" /> :
        <Translation>
          {(t) => (
            <div className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
              {!authUser && this.props.history.push("/login")}
              {authUser && (
                <Container className="pt-md-5">
                  <div className="pl-4 pt-3 pr-4">
                    <div className="headingouter">
                      <h2>{t("commons.Payment")}</h2>
                    </div>
                    {this.state.no_free_delivery_content != "" ? (
                      <Row className="mt-3">
                        <Col lg={8} md={12} className="d-flex">
                          <div className="d-flex justify-content-between align-items-center">
                            <>
                              <p className="margin color-green">{this.state.no_free_delivery_content}</p>
                            </>
                          </div>
                        </Col>
                      </Row>
                    ) : (null)}
                    <Row className="mt-3">
                      <Col lg={8} md={12} className="d-flex">
                        <div className="copuncode d-flex justify-content-between align-items-center">
                          <h5>{t("commons.VAT_Number")}</h5>{" "}
                          <b>
                            <span style={{ color: "#2f7dc9", fontWeight: "600" }}>{settingInfo && settingInfo.admin_vat_number}</span>
                          </b>
                        </div>
                      </Col>
                      {/* <Col lg={8} md={12} className="d-flex">
                        <div className="copuncode d-flex justify-content-between align-items-center">
                          <h5>{t("commons.Special-Request")}</h5>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              adon_type: this.state.adon_type
                            }}
                          >
                            {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                              <Form className="adon">
                                <Row className="coupon_row">
                                  <Col xs={12} md={6}>
                                    <FormGroup>
                                      <Select
                                        options={this.state.adon_data}
                                        isMulti={true}
                                        onChange={(event) => { this.adon_type(event) }}
                                        onBlur={setFieldTouched}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {
                                    this.state.show_adon_text_filed == true ? (
                                      <Col xs={12} md={6}>
                                        <FormGroup>
                                          <Field
                                            value={this.state.adon_text}
                                            id="adon_text"
                                            className="form-control"
                                            name="adon_text"
                                            type="text"
                                            pattern="[A-Z]*"
                                            style={{ textTransform: 'uppercase' }}
                                            placeholder={t("commons.Special-Request")}
                                            onChange={(event) => {
                                              this.setState({ adon_text: event.target.value })
                                              setFieldValue("adon_text", event.target.value.trimStart());
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                    ) : (null)
                                  }
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </Col> */}
                      {this.state.coupon_applied_logic == 'custom' && this.state.show_promocode_section == true ? <Col lg={8} md={12} className="d-flex">
                        <div className="copuncode d-flex justify-content-between align-items-center">
                          <h5 style={{ width: '50%' }}>{t("commons.Apply_Coupon")}</h5>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              custom_promocode: this.state.custom_promocode
                            }}
                          >
                            {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                              <Form className="applycoupon">
                                <Row className="coupon_row">
                                  <Col xs={12} md={6}>
                                    <FormGroup>
                                      <Field
                                        value={this.state.custom_promocode}
                                        id="custom_promocode"
                                        className="form-control"
                                        name="custom_promocode"
                                        type="text"
                                        pattern="[A-Z]*"
                                        placeholder={t("commons.Apply_Coupon")}
                                        style={{ textTransform: 'uppercase' }}
                                        onChange={(event) => {
                                          this.setState({ custom_promocode: event.target.value.trimStart() })
                                          setFieldValue("custom_promocode", event.target.value.trimStart());
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  {/* <Button className="btn text-uppercase" type="button" onClick={() => this.handlePromoCode(values.custom_promocode, this.state.subtotal_new)}>
                                    {t("commons.apply")}
                                  </Button> */}
                                  <StyledButtondiv1 className="btn text-uppercase" type="button" onClick={() => this.handlePromoCode(values.custom_promocode, this.state.subtotal_new)}>{t("commons.apply")}
                                  </StyledButtondiv1>
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </Col> : null}



                      {this.state.walletAmount && this.state.walletAmount > 0 ? <Col lg={8} md={12} className="d-flex">
                        <div className="copuncode d-flex justify-content-between align-items-center">
                          <h5 style={{ width: '50%' }}>{t("commons.use_wallet")}</h5>
                          <Formik
                            enableReinitialize
                            initialValues={{
                            }}
                          >
                            {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                              <Form className="applycoupon" onSubmit={(event) => event.preventDefault()}>
                                <Row className="coupon_row">
                                  <Col xs={12} md={6}>
                                    {/* <FormGroup>
                                    <Field
                                      className="form-control"
                                      name="custom_promocode"
                                      type="text"
                                      placeholder={t("commons.Apply_Coupon")}
                                      onChange={(event) => {
                                        setFieldValue("custom_promocode", event.target.value.trimStart());
                                      }}
                                    />
                                  </FormGroup> */}
                                    <p>{this.state.walletAmount.toFixed(3) + " " + t("commons.balanceomr")}</p>
                                  </Col>
                                  {/* <Button className="btn text-uppercase" type="button" onClick={() => this.handleWallet(this.state.walletAmount)}>
                                    {t("commons.apply")}
                                  </Button> */}
                                  <StyledButtondiv1 className="btn text-uppercase" type="button" onClick={() => this.handleWallet(this.state.walletAmount)}>{t("commons.apply")}</StyledButtondiv1>
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </Col> : null}


                      {this.state.walletApplied == 0 ? (
                        <Col lg={8} md={12} className="d-flex">
                          <div className="copuncode">
                            <div className="d-flex justify-content-between align-items-center custom_payment_mode">
                              <h5>{t("commons.Select_Payment_Mode")}</h5>{" "}
                              <Form inline className="card_payment ml-2 mr-2">
                                {settingInfo && settingInfo.allow_cod == 1 && (
                                  <span className="custom_radio">
                                    <p className="m-0">{t("commons.Cash")}</p>
                                    <input
                                      type="radio"
                                      value="cash"
                                      name="payment_method"
                                      onClick={(e) => {
                                        this.onChangePaymentMethod(e.target.value);
                                      }}
                                    />
                                    <span className="radio_indicator">&nbsp;</span>
                                  </span>
                                )}

                                <span className="custom_radio">
                                  <p className="ml-2 m-0">{t("commons.Credit_Debit_Card")}</p>
                                  <input
                                    type="radio"
                                    value="card"
                                    name="payment_method"
                                    onClick={(e) => {
                                      this.onChangePaymentMethod(e.target.value);
                                    }}
                                  />
                                  <span className="radio_indicator ml-2 mr-2">&nbsp;</span>
                                </span>
                              </Form>
                            </div>

                            {this.state.payment_method == "cash" && (
                              <div className="d-flex justify-content-between mt-2">
                                <span> {t("commons.Maximum_Payable_Cash_Limit")}</span>
                                <span className="cash_omr_amount"> {settingInfo && settingInfo.cod_limit && t("commons.OMR") + " " + settingInfo.cod_limit.toFixed(3)}</span>
                              </div>
                            )}

                            {this.state.payment_method == "card" &&
                              cardList &&
                              cardList.length > 0 &&
                              cardList.map((item, index) => {
                                return (
                                  <span key={index} className="addonouter d-flex justify-content-between align-items-center credit_card_arabic_amount">
                                    {
                                      this.state.lang == 'en' ? (
                                        <>
                                          <span className="custom_radio">
                                            <p className="m-0">{item.brand + " - " + item.masked_card}</p>
                                            <input
                                              type="checkbox"
                                              className="shampoo_opts "
                                              name="shampoo"
                                              value={item.id}
                                              onClick={(event) => {
                                                this.addCardById(event);
                                              }}
                                            />
                                            <span className="radio_indicator">&nbsp;</span>
                                          </span>
                                          <p className="m-0">{t("commons.Expire_on") + " -- " + item.expiry_month + "/" + item.expiry_year}</p>
                                          <span className="btn_cancel btn_cancel_close" onClick={(e) => this.deleteCard(item)}>
                                            <i className="fas fa-trash-alt"></i>
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="btn_cancel btn_cancel_close" onClick={(e) => this.deleteCard(item)}>
                                            <i className="fas fa-trash-alt"></i>
                                          </span>
                                          <p className="m-0">{t("commons.Expire_on") + " -- " + item.expiry_month + "/" + item.expiry_year}</p>

                                          <span className="custom_radio">
                                            <p className="m-0">{item.brand + " - " + item.masked_card}</p>
                                            <input
                                              type="checkbox"
                                              className="shampoo_opts "
                                              name="shampoo"
                                              value={item.id}
                                              onClick={(event) => {
                                                this.addCardById(event);
                                              }}
                                            />
                                            <span className="radio_indicator">&nbsp;</span>
                                          </span>
                                        </>
                                      )
                                    }
                                  </span>
                                );
                              })}
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )
                      }
                    </Row>

                    <div className="col-lg-8 p-0 mt-3">
                      <div className="">
                        <h6 className="price_break_common">{t("commons.Price_Breakup")}</h6>
                      </div>

                      <Row className="priceBreakup">
                        <Col md={12}>
                          <p>
                            {/* <strong>
                              {t("commons.Sub_Total")} <span>{this.state.subtotal_new && t("commons.OMR") + " " + parseFloat(this.state.subtotal_new).toFixed(3)}</span>
                            </strong> */}
                            <StyledStrong>
                              {t("commons.Sub_Total")}
                              <StyledSpan>
                                {this.state.subtotal_new && t("commons.OMR") + " " + parseFloat(this.state.subtotal_new).toFixed(3)}
                              </StyledSpan>
                            </StyledStrong>
                          </p>
                          {this.state.product_discount == 'enabled' && this.state.subcategory_discount_price != 0 &&
                            <p>
                              <strong style={{ color: "red" }}>{t("commons.SubcategoryDiscount")}{" "}
                                {/* {" "}({this.state.subcategory_discount_name}) */}
                                <span>
                                  {this.state.subcategory_discount_price && this.state.subcategory_discount_price != 0
                                    ? "-" + t("commons.OMR") + " " + parseFloat(this.state.subcategory_discount_price).toFixed(3)
                                    : "-" + t("commons.OMR") + " " + "0.000"} {this.state.subcategory_discount_price != 0 ? <span style={{ cursor: "pointer" }} className="close" onClick={() => {
                                      this.getCartData('remove_subcategory_discount');
                                    }}>X</span> : null}
                                </span>
                              </strong>
                            </p>}
                          {this.state.discountedPriceNew != 0 &&
                            <p><strong style={{ color: "red" }}>
                              {t("commons.Discount")}{"  "}({this.state.promocode})
                              <span>
                                {this.state.discountedPriceNew && this.state.discountedPriceNew
                                  ? "-" + t("commons.OMR") + " " + parseFloat(this.state.discountedPriceNew).toFixed(3)
                                  : "-" + t("commons.OMR") + " " + "0.000"} {this.state.discountedPriceNew != 0 ? <span style={{ cursor: "pointer" }} className="close" onClick={() => {
                                    this.getCartData('remove_auto_coupon');
                                  }}>X</span> : null}
                              </span>
                            </strong>
                            </p>}

                          <p>
                            {t("commons.Order_VAT")}{" "}
                            <span>{this.state.orderVatAmount ? t("commons.OMR") + " " + this.state.orderVatAmount.toFixed(3) : t("commons.OMR") + " " + "0.000"}</span>
                          </p>

                          {/* Check for free delivery */}
                          <p>
                            {t("commons.Transport_Charges")}{" "}
                            <span>{this.state.totalDeliveryCharge ? t("commons.OMR") + " " + this.state.totalDeliveryCharge.toFixed(3) : t("commons.OMR") + " " + "0.000"}</span>
                          </p>

                          <p>
                            {t("commons.Transport_VAT")}{" "}
                            <span>{this.state.deliveryVat ? t("commons.OMR") + " " + this.state.deliveryVat.toFixed(3) : t("commons.OMR") + " " + "0.000"}</span>
                          </p>
                          <p>
                            {t("commons.Laundry_Bag_Charges")}{" "}
                            <span>
                              {state.laundry_bag_charges && state.laundry_bag_charges > 0
                                ? t("commons.OMR") + " " + state.laundry_bag_charges.toFixed(3)
                                : t("commons.OMR") + " " + "0.000"}
                            </span>
                          </p>
                          {this.state.wallet_total != "" && (
                            <p>
                              <strong style={{ color: "red" }}>
                                {t("commons.deducted_wallet_amount")}
                                <div className="wallet_amount_deduction">
                                  {this.state.reduced_amount && " - " + " " + t("commons.OMR") + " " + this.state.reduced_amount.toFixed(3)}
                                  {this.state.walletUsed == 'yes' ? <span className="close" onClick={() => {
                                    this.getWalletData('remove_wallet');
                                  }}>x</span> : null}
                                </div>
                              </strong>
                            </p>
                          )}
                          <p>
                            {/* <strong>
                              {t("commons.Total")} <span>{this.state.grandTotal && t("commons.OMR") + " " + this.state.grandTotal.toFixed(3)}</span>
                            </strong> */}
                            <StyledStrong>
                              {t("commons.Total")}
                              <StyledSpan>
                                {this.state.grandTotal && t("commons.OMR") + " " + parseFloat(this.state.grandTotal).toFixed(3)}
                              </StyledSpan>
                            </StyledStrong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                    <div>{this.state.pickup_validation_error ?
                      <div className="Pickup_Validation_button">
                        <div className="invalid-feedback d-block" style={{ fontSize: "15px" }}>{this.state.pickup_validation_error}</div>
                        <Link to={{ pathname: "/checkout/" + this.state.vendorId, }}>{t("reset_pickup_time")}</Link>
                      </div>
                      : null}</div>
                    {this.state.isLoading ? (
                      <div className="loading" />
                    ) : this.state.enable_place_order == 0 ? 
                    // <input type="button" className="btn" onClick={(e) => this.handleSubmit(e, state)} value={t("commons.Place_Order")} disabled={this.state.button_disable} />
                    <StyledInputButton type="button" className="btn" onClick={(e) => this.handleSubmit(e, state)} value={t("commons.Place_Order")} disabled={this.state.button_disable} />
                      : <></>
                    }

                  </div>

                  {/* <Modal
                  isOpen={this.state.showModal}
                  backdrop={"static"}
                  keyboard={false}
                  toggle={() => {
                    this.toggleModal();
                  }}
                >
                  <Form>
                    <div className="filter_modal_header">
                      <span onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                        {t("commons.Cancel")}
                      </span>
                      <div className="filter_modal_heading">{t("commons.Coupon_List")}</div>
                    </div>

                    <div className="coupon_sec">
                      {promocodeList &&
                        promocodeList.map((item, index) => {
                          return (
                            <div className="d-flex mb-3" key={index}>
                              <div className="coupon_code">
                                <h3>{item.promocode}</h3>
                                <p>
                                  {t("commons.Validity_till")}: {moment(item.expiry_date).format("MMM DD, YYYY")}
                                </p>
                                <p>
                                  {t("commons.Discount")}:{" "}
                                  <span>
                                    {item.discount_value && item.discount_type && item.discount_type == "flat"
                                      ? t("commons.OMR") + " " + item.discount_value.toFixed(3)
                                      : item.discount_value + " %"}
                                  </span>
                                </p>
                              </div>

                              <div className="ml-auto apply_coupon text-center">
                                <a onClick={() => this.handlePromoCode(item.promocode)}>{t("filters.Apply")}</a>
                              </div>
                            </div>
                          );
                        })}
                      {promocodeList && promocodeList.length == 0 && (
                        <h4>
                          <center>{t("commons.no_coupons")}</center>
                        </h4>
                      )}
                    </div>
                  </Form>
                </Modal> */}
                </Container>
              )}
            </div>
          )}
        </Translation>
    );
  }
}
