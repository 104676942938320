import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "./partials/Header";
import Footer from "./partials/Footer";

const LoginLayout = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !authUser ? (
          <>
            <Header />
            <Component {...props} authUser={authUser} />
            <Footer />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default LoginLayout;
