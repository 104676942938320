import React, { Component } from "react";
import { Row, Col, Button, Label, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ThemeContext } from "styled-components";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import {StyledButtondiv1} from "../../helpers/StyledButtondiv";
import { Translation } from "react-i18next";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}
const Genders = [
  { value: "", label: "selects.select" },
  { value: "male", label: "commons.Male" },
  { value: "female", label: "commons.Female" },
];

const nameRegex = /^[A-Za-z]+$/;

const FormSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("commons.Please_enter_first_name")
    .min(2, "commons.Fisrt_Name_must_contain_atleast_2_letters.")
    .max(30, "commons.First_name_must_contain_atmost_30_letters.")
    .matches(nameRegex, "commons.First_Name_accepts_only_alphabets"),
  lastName: Yup.string()
    .required("commons.Please_enter_last_name")
    .min(2, "commons.Last_name_must_contain_atleast_2_letters.")
    .max(30, "commons.Last_name_must_contain_atmost_30_letters.")
    .matches(nameRegex, "commons.Last_Name_accepts_only_alphabets"),
  gender: Yup.string().required("commons.Please_select_gender"),
});

export default class ProfileEdit extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      gender: "",
      password: "",
      editGender: "",
      userData: null,
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = async (refreshPage = false) => {
    let path = ApiRoutes.GET_PROFILE;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.setAuthData(resJson.data);

        this.setState(
          {
            userData: resJson.data,
            editGender: resJson.data.editable_fields.find((val) => val == "gender"),
          },
          () => {
            if (refreshPage) {
              this.props.history.push("/manage-profile");
              setTimeout(window.location.reload(), 4000);
            }
          }
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("firstName", inputValues.firstName);
    formData.append("lastName", inputValues.lastName);
    formData.append("gender", inputValues.gender);

    let path = ApiRoutes.UPDATE_PROFILE;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.getUserData(true);
        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            formOptions.setFieldError(error.errField, error.errText);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { userData, editGender } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <h1 className="page_title mb-5">{t("commons.Edit_Profile")}</h1>

              <Row className="justify-content-center">
                <Col xs={12}>
                  <div className="manage_account_user_sec p-5">
                    <Formik
                      enableReinitialize
                      initialValues={{
                        firstName: userData ? userData.firstName : "",
                        lastName: userData ? userData.lastName : "",

                        gender: userData ? userData.gender : "",
                        user_image: undefined,
                      }}
                      validationSchema={FormSchema}
                      onSubmit={this.handleSubmit}
                    >
                      {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                        <Form className="login_signup_form">
                          <Row>
                            <Col xs={12} md={6}>
                              <Label>{t("labels.firstName")}</Label>
                              <FormGroup>
                                <Field
                                  className="form-control"
                                  name="firstName"
                                  type="text"
                                  placeholder={t("labels.firstName")}
                                  onChange={(event) => {
                                    setFieldValue("firstName", event.target.value.trimStart());
                                  }}
                                />
                                {errors.firstName && touched.firstName ? <div className="invalid-feedback d-block">{t(errors.firstName)}</div> : null}
                              </FormGroup>
                            </Col>

                            <Col xs={12} md={6}>
                              <Label>{t("labels.lastName")}</Label>
                              <FormGroup>
                                <Field
                                  className="form-control"
                                  name="lastName"
                                  type="text"
                                  placeholder={t("labels.lastName")}
                                  onChange={(event) => {
                                    setFieldValue("lastName", event.target.value.trimStart());
                                  }}
                                />
                                {errors.lastName && touched.lastName ? <div className="invalid-feedback d-block">{t(errors.lastName)}</div> : null}
                              </FormGroup>
                            </Col>

                            <Col xs={12} md={6}>
                              <Label>{t("labels.gender")}</Label>
                              <FormGroup>
                                <Field className="form-control" name="gender" as="select" disabled={editGender && editGender ? false : true}>
                                  {Genders.map((item, index) => {
                                    return (
                                      <option key={index} value={item.value}>
                                        {t(item.label)}
                                      </option>
                                    );
                                  })}
                                </Field>
                                {errors.gender && touched.gender ? <div className="invalid-feedback d-block">{t(errors.gender)}</div> : null}
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* <Button className="btn text-uppercase" type="submit">
                            {t("commons.Save")}
                          </Button> */}
                            <StyledButtondiv1 type="submit" className="btn text-uppercase">{t("commons.Save")}</StyledButtondiv1>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
          </>
        )}
      </Translation>
    );
  }
}
