
import React, { Component } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";
import { Tabs, Tab } from 'react-tab-view';
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import { Translation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import copyNewLight from '../../assets/img/new-copy-light.png'
import beforeCopy from '../../assets/img/before_copy.png'
import afterCopy from '../../assets/img/after_copy.png'
import { ThemeButtonLink } from '../../helpers/Button';

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

class Notifications extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      itemData: [],
      itemData_freedelivery: [],
      itemData_promocode_freedelivery: [],
      couponData: [],
      SubcategoryData: [],
      freeSortedArr: [],
      isloading: false,
      selectedtab: 1,
      copiedItem: ""
    };
  }

  componentDidMount() {
    this.getPromoCodes();
    this.getFreedelivery();
    this.getCouponCode();
    this.getSubCategory();
    // this.getPromoCodeFreeDelivery();
    this.setState({ lang: localStorage.getItem("lang") || "en" });

  }

  loadPagination_Freedelivery = async (currentPage) => {
    console.log('loadfree');
    this.getFreedelivery(currentPage + 1);

  }
  // loadPagination_Promocode_Freedelivery = async (currentPage) => {
  //   this.getPromoCodeFreeDelivery(currentPage + 1);

  // }
  getFreedelivery = async (currentPage = 1) => {
    let path = ApiRoutes.GET_FREEDELIVERY_OFFERS + "?page_no=" + `${currentPage}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      var sortedFreeDeliveryArr = [];

      if (res.status == 200) {
        if (this.state.lang == 'en') {
          moment.locale('en');
        } else {
          moment.locale('ar');
        }
        this.setState(
          {
            itemData_freedelivery: [...this.state.itemData_freedelivery, ...resJson.data.docs],
            // itemData_promocode_freedelivery: [...this.state.itemData_promocode_freedelivery, ...resJson.data.discount],
            hasMoreItems_Freedelivery: currentPage < resJson.data.totalPages ? true : false,
          },
          () => this.setState({ isloading: true })
        );

        // console.log(this.state.itemData_freedelivery,'this.state.itemData_freedelivery')
        var sortedArr = this.state.itemData_freedelivery.sort(function (a, b) {
          var c = new Date(a.end_date || a.expiry_date_to);
          var d = new Date(b.end_date || b.expiry_date_to);
          return c - d;
        });

        console.log('sortedArr', sortedArr);

        this.setState({
          freeSortedArr: sortedArr
        })

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  loadPagination = async (currentPage) => {
    this.getPromoCodes(currentPage + 1);
  };
  getPromoCodes = async (currentPage = 1) => {
    let path = ApiRoutes.GET_PROMOCODES + "?page_no=" + `${currentPage}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            itemData: [...this.state.itemData, ...resJson.data.docs],

            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };


  //FOR PROMOCODE FREE DELIVERY
  // getPromoCodeFreeDelivery = async (currentPage = 1) => {
  //   let path = ApiRoutes.GET_PROMOCODE_FREE_DELIVERY + "?page_no=" + `${currentPage}`;
  //   const res = await Http("GET", path);
  //   if (res) {
  //     const resJson = await res.json();

  //     if (res.status == 200) {
  //       this.setState(
  //         {
  //           itemData_promocode_freedelivery: [...this.state.itemData_promocode_freedelivery, ...resJson.data.docs],

  //           hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
  //         },
  //         () => this.setState({ isloading: true })
  //       );
  //     } else {
  //       NotificationManager.error(resJson.message, "Error!", 3000);
  //     }
  //   }
  // };



  loadPagination_CouponCode = async (currentPage) => {
    this.getCouponCode(currentPage + 1);
  };
  getCouponCode = async (currentPage = 1) => {
    let path = ApiRoutes.GET_COUPON_CODES + "?page_no=" + `${currentPage}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            couponData: [...this.state.couponData, ...resJson.data.docs],

            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  loadPagination_SubCategory = async (currentPage) => {
    this.getSubCategory(currentPage + 1);
  };
  getSubCategory = async (currentPage = 1) => {
    let path = ApiRoutes.GET_SUBCATEGORY_DISCOUNT + "?page_no=" + `${currentPage}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            SubcategoryData: [...this.state.SubcategoryData, ...resJson.data.docs],

            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  copyToClipboard = async (item) => {
    this.setState({
      copiedItem: item
    })
    // alert(`You have copied ${item}`);
  }

  render() {
    const { itemData, itemData_freedelivery, itemData_promocode_freedelivery, lang, couponData, SubcategoryData, freeSortedArr } = this.state;
    const { intl } = this.props;
    const theme = this.context;
    var headers1 = [];
    var headers2 = [];
    if (lang == 'en') {
      headers1 = ['Free Delivery', 'General Offers', 'Individual Discount', "Subcategory Discount"];
    } else {
      headers1 = ["توصيل مجاني", "العروض العامة", "خصم مخصص", "خصم فئة فرعية"]

    }
    if (lang == 'en') {
      headers2 = ['Free Delivery', 'Promocode Free Delivery'];
    } else {
      headers2 = ["توصيل مجاني", 'بروموكود التوصيل المجاني'];

    }
    var temp = 0;
    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <Container className="py-3 py-md-4">
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="btn text-white py-2 px-3 toggle_btn d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <div className="d-flex clearCart align-items-center">
                <div className="w-100 d-flex justify-content-between my_cart_content_mb">
                  <div>
                    <h1 className="page_title mb-5 custom-page-tit-phem" style={{ width: "100%" }}>{t("menu.promocodes")}</h1>
                  </div>
                </div>
              </div>

              {!this.state.isloading ? (
                <div className="loading"></div>
              ) : (
                <Paper square>
                  <Tabs headers={headers1}>
                    <Tab>
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination_Freedelivery} hasMore={this.state.hasMoreItems_Freedelivery} loader={<div key={0} className="loader"></div>}>
                        <div className="list_notification offers">
                          <ul>
                            {freeSortedArr &&
                              freeSortedArr.map((item, index) => {
                                // if (item.is_freedelivery == "available") {
                                temp = 1;
                                return (
                                  <li className="mb-3" key={index}>
                                    {
                                      item && item.type_of_offer == undefined ? (<div className="notification_content align-items-center p-2">
                                        <div className="notification_item" style={{ width: "70%" }}>
                                          <div className="d-flex">
                                            <h3 style={{ color: theme.colors.primary }}>
                                              <b>{item && item.shop_name && item.shop_name[lang]}</b>{" "}
                                            </h3>
                                          </div>
                                          <p className="mt-2 m-0">
                                            {t("maximum_distance")}:{" "}
                                            <span className="themeColor" style={{ color: theme.colors.primary }}>
                                              <b>
                                                {" "}
                                                {item.maximum_distance ? item.maximum_distance
                                                  : ""}
                                              </b>
                                            </span>
                                          </p>
                                          <p className="mt-2 m-0">
                                            {t("labels.Minimum_order")} :{" "}
                                            <span className="themeColor" style={{ color: theme.colors.primary }}>
                                              <b> {item.minimum_order ? item.minimum_order : t("commons.N/A")}</b>
                                            </span>
                                          </p>{" "}
                                          <small style={{ color: 'green', fontSize: '11px' }}>{" "}{t("*promocode will be applied automatically in payment page")}</small>
                                        </div>
                                        <div className="timing_sec text-center">
                                          <div className="btn_cancel  mx-1 mb-4">
                                            {/* <Link to={"promocode/" + item._id} className="btn">
                                        {t("buttons.View_Detail")}
                                      </Link> */}
                                            {/* <NavLink class="btn" to={{
                                        pathname: `/promocode/${item._id}/${'free_delivery'}`,
                                      }}>
                                        {t("buttons.View_Detail")}
                                      </NavLink> */}
                                            <ThemeButtonLink link={`/promocode/${item._id}/${'free_delivery'}`} exact activeClassName="active" buttonText={t("buttons.View_Detail")} />
                                          </div>
                                          <div className='custom_validity_cls'>{t("Validity")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{moment(item.start_date).format("MMM DD, YYYY") + "-" + moment(item.end_date).format("MMM DD, YYYY")}</span></div>
                                        </div>
                                      </div>) : (
                                        <div className="notification_content align-items-center p-2">
                                          <div className="notification_item" style={{ width: "70%" }}>
                                            <div className="d-flex">
                                              <h3 style={{ color: "#2f7dc8", cursor: 'pointer' }}>
                                                <b>{item.promocode}</b>{" "}
                                                {
                                                  this.state.copiedItem == item.promocode ?
                                                    <img width="25px" src={afterCopy} style={{ cursor: 'pointer', margin: '0px 4px' }} onClick={() => { this.setState({ copiedItem: "" }) }}
                                                    /> :
                                                    <img width="25px" src={beforeCopy} style={{ cursor: 'pointer', margin: '0px 4px' }} onClick={() => { navigator.clipboard.writeText(item.promocode); this.copyToClipboard(item.promocode) }} />
                                                }

                                                <small style={{ color: 'green', fontSize: '11px' }}>{" "}{t("*click the icon to copy promocode")}</small>
                                              </h3>
                                            </div>
                                            <p className="mt-2 m-0">
                                              {t("commons.Type of Offer")}:{" "}
                                              <span className="themeColor">
                                                <b style={{ textTransform: "capitalize" }}>
                                                  {" "}
                                                  {
                                                    item && item.type_of_offer &&
                                                      item.type_of_offer == 'both' ? t('both') : item.type_of_offer == 'freedelivery' ? t('freedelivery') : t("discount")
                                                  }
                                                </b>
                                              </span>
                                            </p>
                                            <p className="mt-2 m-0">
                                              {t("labels.Minimum_Order_Value")} :{" "}
                                              <span className="themeColor">
                                                <b> {item.minimum_order ? t("commons.OMR") + " " + item.minimum_order.toFixed(3) : t("commons.N/A")}</b>
                                              </span>
                                            </p>{" "}
                                          </div>
                                          <div className="timing_sec text-center">
                                            <div className="btn_cancel  mx-1 mb-4">
                                              {/* <Link to={"promocode/" + item._id} className="btn">
                                        {t("buttons.View_Detail")}
                                      </Link> */}
                                              {/* <NavLink class="btn" to={{
                                                pathname: `/promocode/${item._id}/${'global_discount'}`,
                                              }}>
                                                {t("buttons.View_Detail")}
                                              </NavLink> */}
                                              <ThemeButtonLink link={`/promocode/${item._id}/${'global_discount'}`} exact activeClassName="active" buttonText={t("buttons.View_Detail")} />
                                            </div>
                                            <div className='custom_validity_cls'>{t("Validity")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{moment(item.start_date).format("MMM DD, YYYY") + "-" + moment(item.end_date).format("MMM DD, YYYY")}</span></div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </li>
                                );
                                // }
                              })}
                            {temp == 0 ?
                              <p style={{ textAlign: "center", paddingTop: "2%", paddingBottom: "2%", color: theme.colors.primary, fontWeight: 600 }}>{t('free_delivery_not_available')}</p>
                              : <></>}
                          </ul>
                        </div>
                      </InfiniteScroll>
                    </Tab>
                    <Tab>
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                        <div className="list_notification offers">
                          <ul>
                            {itemData &&
                              itemData.map((item, index) => {
                                return (
                                  <li className="mb-3" key={index}>
                                    <div className="notification_content align-items-center p-2">
                                      <div className="notification_item" style={{ width: "70%" }}>
                                        <div className="d-flex">
                                          <h3 style={{ color: theme.colors.primary }}>
                                            <b>{item.promocode}</b>{" "}
                                            {
                                              this.state.copiedItem == item.promocode ?
                                                <img width="25px" src={afterCopy} style={{ cursor: 'pointer', margin: '0px 4px' }} onClick={() => { this.setState({ copiedItem: "" }) }}
                                                /> :
                                                <img width="25px" src={beforeCopy} style={{ cursor: 'pointer', margin: '0px 4px' }} onClick={() => { navigator.clipboard.writeText(item.promocode); this.copyToClipboard(item.promocode) }} />
                                            }

                                            <small style={{ color: 'green', fontSize: '11px' }}>{" "}{t("*click the icon to copy promocode")}</small>
                                          </h3>
                                        </div>
                                        <p className="mt-2 m-0">
                                          {t("commons.Type of Offer")}:{" "}
                                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                                            <b style={{ textTransform: "capitalize" }}>
                                              {" "}
                                              {
                                                item && item.type_of_offer &&
                                                  item.type_of_offer == 'both' ? t('both') : item.type_of_offer == 'freedelivery' ? t('freedelivery') : t("discount")
                                              }
                                            </b>
                                          </span>
                                        </p>
                                        <p className="mt-2 m-0">
                                          {t("labels.Minimum_Order_Value")} :{" "}
                                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                                            <b> {item.min_amount_to_applicable ? t("commons.OMR") + " " + item.min_amount_to_applicable.toFixed(3) : t("commons.N/A")}</b>
                                          </span>
                                        </p>{" "}
                                      </div>
                                      <div className="timing_sec text-center">
                                        <div className="btn_cancel  mx-1 mb-4">
                                          {/* <Link to={"promocode/" + item._id} className="btn">
                                            {t("buttons.View_Detail")}
                                          </Link> */}
                                          {/* <NavLink class="btn" to={{
                                            pathname: `/promocode/${item._id}/${'global_discount'}`,
                                          }}>
                                            {t("buttons.View_Detail")}
                                          </NavLink> */}
                                          <ThemeButtonLink link={`/promocode/${item._id}/${'global_discount'}`} exact activeClassName="active" buttonText={t("buttons.View_Detail")} />
                                        </div>
                                        <div className='custom_validity_cls'>{t("Validity")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{moment(item.expiry_date_from).format("MMM DD, YYYY") + "-" + moment(item.expiry_date_to).format("MMM DD, YYYY")}</span></div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            {itemData.length == 0 ?
                              <p style={{ textAlign: "center", paddingTop: "2%", paddingBottom: "2%", color: "#348ad2", fontWeight: 600 }}>{t("discount_not_available")}</p>
                              : <></>}
                          </ul>
                        </div>
                      </InfiniteScroll>
                    </Tab>
                    <Tab>
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination_CouponCode} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                        <div className="list_notification offers">
                          <ul>
                            {couponData &&
                              couponData.map((item, index) => {
                                // if (item.discount_available == 'available') {
                                return (
                                  // item.discount_available == 'not_availabe' ? (
                                  <li className="mb-3" key={index}>
                                    <div className="notification_content align-items-center p-2">
                                      <div className="notification_item" style={{ width: "70%" }}>
                                        <div className="d-flex">
                                          <h3 style={{ color: theme.colors.primary }}>
                                            <b>{item.couponcode}</b>{" "}
                                          </h3>
                                        </div>
                                        <p className="mt-2 m-0">
                                          {t("commons.Discount")}:{" "}
                                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                                            <b>
                                              {" "}
                                              {item.discount_value && item.discount_type && item.discount_type == "flat"
                                                ? t("commons.OMR") + " " + item.discount_value.toFixed(3)
                                                : item.discount_value + " %"}
                                            </b>
                                          </span>
                                        </p>
                                        <p className="mt-2 m-0">
                                          {t("labels.Minimum_Order_Value")} :{" "}
                                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                                            <b> {item.min_amount_to_applicable ? t("commons.OMR") + " " + item.min_amount_to_applicable.toFixed(3) : t("commons.N/A")}</b>
                                          </span>
                                        </p>{" "}
                                        {/* <p className="mt-2 m-0">
                                            {t("maximum_distance")}:{" "}
                                            <span className="themeColor">
                                              <b>
                                                {" "}
                                                {item.max_distance_to_applicable ? item.max_distance_to_applicable
                                                  : ""}
                                              </b>
                                            </span>
                                          </p>
                                          <p className="mt-2 m-0">
                                            {t("labels.Coupon_usage")} :{" "}
                                            <span className="themeColor">
                                              <b> {item.no_of_times_used}</b>
                                            </span>
                                          </p>{" "} */}
                                        <small style={{ color: 'green', fontSize: '11px' }}>{" "}{t("*promocode will be applied automatically in payment page")}</small>
                                      </div>
                                      <div className="timing_sec text-center">
                                        <div className="btn_cancel  mx-1 mb-4">
                                          {/* <Link to={"promocode/" + item._id} className="btn">
                                            {t("buttons.View_Detail")}
                                          </Link> */}
                                          {/* <NavLink class="btn" to={{
                                            pathname: `/promocode/${item._id}/${'individual_discount'}`,
                                          }}>
                                            {t("buttons.View_Detail")}
                                          </NavLink> */}
                                          <ThemeButtonLink link={`/promocode/${item._id}/${'individual_discount'}`} exact activeClassName="active" buttonText={t("buttons.View_Detail")} />
                                        </div>
                                        <div className='custom_validity_cls'>{t("Validity")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{moment(item.from_expiry_date).format("MMM DD, YYYY") + "-" + moment(item.expiry_date).format("MMM DD, YYYY")}</span></div>
                                      </div>
                                    </div>
                                  </li>

                                  // ) :
                                  //   <>
                                  //   </>
                                );
                                // }
                              })}
                            {couponData.length == 0 ?
                              <p style={{ textAlign: "center", paddingTop: "2%", paddingBottom: "2%", color: theme.colors.primary, fontWeight: 600 }}>{t("discount_not_available")}</p>
                              : <></>}
                          </ul>
                        </div>
                      </InfiniteScroll>
                    </Tab>
                    <Tab>
                      <InfiniteScroll pageStart={0} loadMore={this.loadPagination_SubCategory} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                        <div className="list_notification offers">
                          <ul>
                            {SubcategoryData &&
                              SubcategoryData.map((item, index) => {
                                var category = [];
                                var subcategory = [];
                                item.categoryname.map((item1, index1) => {
                                  category.push(item1.name[lang]);
                                })
                                item.subcategoryname.map((item2, index2) => {
                                  subcategory.push(item2.name[lang]);
                                })
                                return (
                                  <li className="mb-3" key={index}>
                                    <div className="notification_content align-items-center p-2">
                                      <div className="notification_item" style={{ width: "70%" }}>
                                        <div className="d-flex">
                                          <h3 style={{ color: theme.colors.primary }}>
                                            <b>{item.promocode}</b>{" "}
                                          </h3>
                                        </div>
                                        <p className="mt-2 m-0">
                                          {t("label_package_category")} :{" "}
                                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                                            <b>
                                              {category.join(", ")}
                                            </b>
                                          </span>
                                        </p>
                                        <p className="mt-2 m-0">
                                          {t("label_package_subcategory")} :{" "}
                                          <span className="themeColor" style={{ color: theme.colors.primary }}>
                                            <b>{subcategory.join(", ")}</b>
                                          </span>
                                        </p>{" "}
                                        <small style={{ color: 'green', fontSize: '11px' }}>{" "}{t("*promocode will be applied automatically in payment page")}</small>
                                      </div>
                                      <div className="timing_sec text-center">
                                        <div className="btn_cancel  mx-1 mb-4">
                                          {/* <Link to={"promocode/" + item._id} className="btn">
                                            {t("buttons.View_Detail")}
                                          </Link> */}
                                          {/* <NavLink class="btn" to={{
                                            pathname: `/promocode/${item._id}/${'subcategory_discount'}`,
                                          }}>
                                            {t("buttons.View_Detail")}
                                          </NavLink> */}
                                          <ThemeButtonLink link={`/promocode/${item._id}/${'subcategory_discount'}`} exact activeClassName="active" buttonText={t("buttons.View_Detail")} />
                                        </div>
                                        <div className='custom_validity_cls'>{t("Validity")}: <span className="themeColor" style={{ color: theme.colors.primary }}>{moment(item.expiry_date_from).format("MMM DD, YYYY") + "-" + moment(item.expiry_date_to).format("MMM DD, YYYY")}</span></div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            {SubcategoryData.length == 0 ?
                              <p style={{ textAlign: "center", paddingTop: "2%", paddingBottom: "2%", color: theme.colors.primary, fontWeight: 600 }}>{t("discount_not_available")}</p>
                              : <></>}
                          </ul>
                        </div>
                      </InfiniteScroll>
                    </Tab>
                  </Tabs>
                </Paper>


              )}

              {/* {this.state.isloading && itemData && itemData.length == 0  && itemData_freedelivery && itemData_freedelivery.length > 0 && couponData && couponData.length > 0 ? <div className="noorder">{t("commons.No_new_notifications")}</div> : <></>} */}
            </Container>

            <div className="sidebar_overlay" onClick={SidebarRemoveClass}>
              {" "}
            </div>
          </section>
        )}
      </Translation>
    );
  }
}

export default Notifications
