import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { StyledButtondiv1, StyledButtondiv2, StyledButtondiv3 } from "../../helpers/StyledButtondiv";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MainContext from "../../helpers/MainContext";
import { Translation } from "react-i18next";
import i18next from "i18next";

var priceRegExp = /^[1-9]\d*$/;

const ServiceTypeFormSchema = Yup.object().shape({
  // measure_type: Yup.string().required("commons.Please_select_service_add_in"),
  quantity_by_measure: Yup.string()
    .required("commons.Please_add_numeric_value")

    .min(1, "commons.It_must_contain_atleast_1_number.")
    .max(6, "commons.It_must_contain_atmost_6_numbers.")
  // .matches(priceRegExp, "commons.Please_enter_positive_quantity"),
});

export default class Service extends Component {
  static contextType = MainContext;
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      itemId: props.match.params.itemId,
      vendor_id: props.match.params.vendor_id,
      itemData: null,
      serviceItemModal: false,
      service_type: "",
      quantity: "",
      vendorData_new: null,
      price_by_piece: "",
      price_by_weight: "",
      price_by_meter: "",

      serviceItems: [],
      selectedServiceItems: [],
      serviceItemid: "",

      service_item_id: "",
      measure_type: "",
      quantity_by_measure: "",
      serviceModal: false,
      cartItems: [],
      clothe_return_types: "",
      isloading: false,
      clothe_return_type: [],
      vendorData: [],
      lang: "",
    };
  }
  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
    this.getServiceData();
    this.getVendorData();
    this.getVendroNavigateData();
  }
  getVendroNavigateData = async () => {
    let path = ApiRoutes.GET_VENDOR + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        await this.setState({
          vendorData_new: resJson.data,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  }

  getServiceData = async (currentPage = 1) => {
    let path = ApiRoutes.GET_SERVICES + "/" + this.state.itemId;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        var return_typesss = resJson.data['clothe_return_type'];
        if (return_typesss.length == 1) {
          this.setState({ clothe_return_types: return_typesss[0]['_id'], clothe_return_type: return_typesss[0] });
        }
        await this.setState(
          {
            itemData: resJson.data,
            serviceItems: resJson.data.service_items,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getVendorData = async (currentPage = 1) => {

    let path = ApiRoutes.GET_ALLVENDORS + "/" + this.state.vendor_id;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        await this.setState({
          vendorData: resJson.data,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  // get cart data

  getCartData = async (currentPage = 1) => {
    const context = this.context;
    this.props.history.goBack();
    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    var vendor_details = this.state.vendorData_new;
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        context.setCartItem(resJson.data.cartItems.length);
        // this.props.history.push("/cart");
        this.props.history.push({
          pathname: `/service-list/${this.state.vendor_id}`,
          state: { shop: vendor_details && vendor_details.shop_name && vendor_details.shop_name[this.state.lang], branch: vendor_details && vendor_details.branch_name && vendor_details.branch_name[this.state.lang] },
        });

        // return context.setCartItem(resJson.data.cartItems.length);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  handleSubmit = async (e) => {
    let formData = new FormData();
    formData.append("service_id", this.state.itemId);
    formData.append("service_items", JSON.stringify(this.state.selectedServiceItems));
    if (this.props.authUser) {
      let path = ApiRoutes.CREATE_CART;

      const res = await Http("POST", path, formData);
      if (res) {
        const resJson = await res.json();

        if (res.status == 400) {
          var cartaction = window.confirm(i18next.t("commons.Your_cart_contains_the_items_of_other_vendor._Do_you_want_to_clear_the_cart_before_browsing_other_vendors?"));
          if (cartaction == true) {
            this.deleteCart();
          }
        } else if (this.state.selectedServiceItems.length === 0) {
          NotificationManager.error("Please add atleast one service", "Error!", 3000);
        } else if (res.status == 200) {
          this.getCartData();
          var success = { ar: "النجاح!", en: "Success!" };
          NotificationManager.success(resJson.message, success[this.state.lang], 3000);
        }
        else if (res.status == 422) {
          // var cartaction = window.confirm(i18next.t("commons.Item_cannot_be_added_with_normal_or_medium_delivery_method_Do_you_want_to_clear_the_cart_before_adding_the_item?"));
          if (resJson.data.mixedItemAlert) {
            var cartaction = window.confirm(resJson.data.mixedItemAlert)
          }

          if (cartaction == true) {
            this.deleteCart();
            this.handleSubmit();
          }
        }
        else if (res.status == 423) {
          // var cartaction = window.confirm(i18next.t("commons.Item_cannot_be_added_with_longer_delivery_method_Do_you_want_to_clear_the_cart_before_adding_the_item?"));

          if (resJson.data.mixedItemAlert) {
            var cartaction = window.confirm(resJson.data.mixedItemAlert)
          }


          if (cartaction == true) {
            this.deleteCart();
            this.handleSubmit();
          }
        }
        else if (res.status == 424) {
          // var cartaction = window.confirm(i18next.t("commons.Item_cannot_be_added_with_longer_delivery_method_Do_you_want_to_clear_the_cart_before_adding_the_item?"));

          if (resJson.data.mixedItemAlert) {
            var cartaction = window.confirm(resJson.data.mixedItemAlert)
          }


          if (cartaction == true) {
            this.deleteCart();
            this.handleSubmit();
          }
        }
      } else {
        NotificationManager.error("Server Error", "Error!", 3000);
      }
    } else {
      this.props.history.push("/login");
    }
  };

  deleteCart = async () => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_CART;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        const context = this.context;
        context.setCartItem(0);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  hideModal = () => {
    if (this.state.itemData.clothe_return_type.length > 1) {
      this.setState((prevState) => ({
        serviceItemModal: !prevState.serviceItemModal,
        clothe_return_types: "",
        clothe_return_type: []
      }));
    } else {
      this.setState((prevState) => ({
        serviceItemModal: !prevState.serviceItemModal,
      }));
    }
  };

  hideEditModal = () => {
    this.setState((prevState) => ({
      serviceModal: !prevState.serviceModal,
    }));
  };

  toggleAddServiceItem = (serviceObj = "", index = "") => {
    this.setState((prevState) => ({
      serviceItemModal: !prevState.serviceItemModal,
      price_by_weight: serviceObj.price_by_weight ? serviceObj.price_by_weight : "",
      price_by_piece: serviceObj.price_by_piece ? serviceObj.price_by_piece : "",
      price_by_meter: serviceObj.price_by_meter ? serviceObj.price_by_meter : "",
      service_item_id: serviceObj._id,
    }));
  };

  toggleServiceModal = (item_id) => {
    let selectedItem = this.state.selectedServiceItems.filter((i) => i.service_item_id === item_id);
    this.setState((prevState) => ({
      serviceModal: !prevState.serviceModal,
      measure_type: selectedItem && selectedItem.length && selectedItem[0].measure_type,
      quantity_by_measure: selectedItem && selectedItem.length && selectedItem[0].quantity_by_measure,
      service_item_id: item_id,
      clothe_return_type: selectedItem && selectedItem.length && selectedItem[0].clothe_return_type,
    }));
  };

  toggleServiceItem = (sericeItemObj) => {
    this.setState((prevState) => ({
      serviceModal: !prevState.serviceModal,
    }));
  };

  handleAddServiceItem = (inputValues, formOptions) => {
    this.state.itemData && this.state.itemData.service_items.map((item, index) => {
      if (item._id == inputValues.service_item_id) {
        // setting the transportation method while adding cart items
        this.setState({ with_transportation: 'meter' });
        // setting the transportation method while adding cart items
        if (item.price_by_piece == null && item.price_by_weight == null && item.price_by_meter != null) {
          this.setState({ measure_type: 'meter' });
        } else if (item.price_by_piece == null && item.price_by_weight != null && item.price_by_meter == null) {
          this.setState({ measure_type: 'weight' });
        } else if (item.price_by_piece != null && item.price_by_weight == null && item.price_by_meter == null) {
          this.setState({ measure_type: 'piece' });
        }
      }
    });
    if (this.state.measure_type == '') {
      formOptions.setFieldError("measure_type", "commons.Please_select_service_add_in");
      return false;
    }
    if (this.state.clothe_return_type.length == 0) {
      formOptions.setFieldError("clothe_return_types", "commons.Please_Select_Item_Return_Type");
      return false;
    }
    if (inputValues.measure_type === "" && inputValues.measure_type === "weight") {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_add_weight_in_grams");
      return false;
    }

    if (inputValues.measure_type === "weight" && parseInt(inputValues.quantity_by_measure) < 200) {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_weight_more_than_200_grams");
      return false;
    }
    if (inputValues.measure_type != "meter") {
      if (!/^\+?(0|[1-9]\d*)$/.test(parseFloat(inputValues.quantity_by_measure))) {
        console.log('56789');
        formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_positive_quantity");
        return false;

      }
    }
    if (inputValues.quantity_by_measure <= 0) {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_value_grater_than_0");
      return false;
    } else {
      if (this.state.clothe_return_type.name.en == "Hanging") {
        if (this.state.measure_type == "piece") {
          this.state.clothe_return_type.hanging_charge = this.state.vendorData.hanging_charges_by_piece;
        } else {
          this.state.clothe_return_type.hanging_charge = this.state.vendorData.hanging_charges_by_weight;
        }
      } else {
        this.state.clothe_return_type.hanging_charge = 0;
      }
      const item = {
        measure_type: this.state.measure_type,
        quantity_by_measure: inputValues.quantity_by_measure,
        clothe_return_type: this.state.clothe_return_type,
        service_item_id: inputValues.service_item_id,
        delivery_method: this.state.itemData.delivery_method
      };
      this.setState({
        serviceItemModal: false,
        selectedServiceItems: [...this.state.selectedServiceItems, item],
      });
    }
  };

  handleEditServiceItem = (inputValues, formOptions) => {
    this.state.itemData && this.state.itemData.service_items.map((item, index) => {
      if (item._id == inputValues.service_item_id) {
        if (item.price_by_piece == null && item.price_by_weight == null && item.price_by_meter != null) {
          this.setState({ measure_type: 'meter' });
        } else if (item.price_by_piece == null && item.price_by_weight != null && item.price_by_meter == null) {
          this.setState({ measure_type: 'weight' });
        } else if (item.price_by_piece != null && item.price_by_weight == null && item.price_by_meter == null) {
          this.setState({ measure_type: 'piece' });
        }
      }
    });
    console.log(this.state.measure_type, "edittt")
    if (this.state.measure_type == '') {
      formOptions.setFieldError("measure_type", "commons.Please_select_service_add_in");
      return false;
    }
    if (inputValues.measure_type === "" && inputValues.measure_type === "weight") {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_add_weight_in_grams");
      return false;
    }

    if (inputValues.measure_type === "weight" && parseInt(inputValues.quantity_by_measure) < 200) {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_weight_more_than_200_grams");
      return false;
    }
    if (inputValues.quantity_by_measure <= 0) {
      formOptions.setFieldError("quantity_by_measure", "commons.Please_enter_value_grater_than_0");
      return false;
    } else {
      let selectedServiceItems = this.state.selectedServiceItems;

      let index = selectedServiceItems.findIndex((i) => i.service_item_id === this.state.service_item_id);

      if (this.state.clothe_return_type.name.en == "Hanging") {
        if (this.state.measure_type == "piece") {
          this.state.clothe_return_type.hanging_charge = this.state.vendorData.hanging_charges_by_piece;
        } else {
          this.state.clothe_return_type.hanging_charge = this.state.vendorData.hanging_charges_by_weight;
        }
      } else {
        this.state.clothe_return_type.hanging_charge = 0;
      }
      const item = {
        measure_type: this.state.measure_type,
        quantity_by_measure: inputValues.quantity_by_measure,
        clothe_return_type: this.state.clothe_return_type,

        service_item_id: inputValues.service_item_id,
      };

      selectedServiceItems[index] = item;

      this.setState({
        serviceModal: false,
        selectedServiceItems: selectedServiceItems,
      });
    }
  };

  deleteServiceItem = (id) => {
    var serviceItem = "";
    serviceItem = this.state.selectedServiceItems.filter((val) => val.service_item_id !== id);
    if (this.state.itemData.clothe_return_type.length > 1) {
      this.setState({
        selectedServiceItems: serviceItem,
        measure_type: "",
        quantity_by_measure: "",
        clothe_return_types: "",
        clothe_return_type: []
      });
    } else {
      this.setState({
        selectedServiceItems: serviceItem,
        measure_type: "",
        quantity_by_measure: "",
      });
    }

  };

  handleChange = (e) => {
    var value = this.state.itemData.clothe_return_type.find(function (item) {
      return item._id == e.target.value;
    });

    var clothe_return_typess = "";

    if (value) {
      clothe_return_typess = {
        _id: value._id,
        name: value.name,
      };
      this.setState({ clothe_return_type: clothe_return_typess });
    }
  };



  render() {
    var { itemData, isloading, lang } = this.state;
    const theme = this.context;
    const { state } = this.props.location;
    var vendor_details = this.state.vendorData_new;
    var filteredValue = '';
    return (
      <Translation>
        {(t) => (
          <>
            <section className="vendor_list_detail_sec py-5" style={{ backgroundColor: "#F5FDFE" }}>
              <Container className="pt-md-5">
                {!isloading ? (
                  <div className="loading"></div>
                ) : (
                  <>
                    <Link
                      to={{
                        pathname: `/service-list/${this.state.vendor_id}`,
                        state: { shop: vendor_details && vendor_details.shop_name && vendor_details.shop_name[this.state.lang], branch: vendor_details && vendor_details.branch_name && vendor_details.branch_name[this.state.lang] },
                      }}
                    >
                      <h1 className="section_title mb-4" style={{ textTransform: "capitalize" }}>
                        {this.state.vendorData_new && this.state.vendorData_new.shop_name[lang]}
                      </h1>
                    </Link>
                    <h3 className="section_title mb-4" style={{ textTransform: "capitalize" }}>
                      {" "}
                      {t("headings.Service")} - {lang && itemData ? itemData.subcategory.name[lang] : ""}
                    </h3>
                    <Row>
                      <Col md={5} className="mb-3">
                        <div className="vendor_list_pic_sec">
                          <img src={itemData ? itemData.subcategory.image : ""}></img>
                        </div>
                      </Col>
                      <Col md={7}>
                        <div className="checkoutproductdetails">
                          <div className="productdetails pl-0 w-100 mb-4">
                            <p>
                              {t("labels.Category")} : <span className="themeColor" style={{ color: theme.colors.primary }}>{itemData && lang && itemData.category.name[lang]}</span>
                            </p>

                            {/* <p>
                              {t("labels.Item_Return_Type")}:{" "}
                              {itemData &&
                                lang &&
                                itemData.clothe_return_type.map((item, index) => {
                                  return (
                                    <span key={index} className="themeColor">
                                      {(index ? ", " : "") + item.name[lang]}
                                    </span>
                                  );
                                })}
                            </p> */}
                            {itemData.delivery_method != 'normal' && itemData.delivery_time && (
                              <p>
                                {t("labels.Delivery_time")}:{" "}
                                {lang &&
                                  <span className="themeColor" style={{ color: theme.colors.primary }}>
                                    {t(itemData.delivery_time) + " " + t('commons.Days')}
                                  </span>
                                }
                              </p>
                            )}
                            {itemData.delivery_method != 'normal' && itemData.express_delivery_time && (
                              <p>
                                {t("labels.Express_Delivery_time")}:{" "}
                                {lang &&

                                  itemData.delivery_method == 'medium' ? (<span className="themeColor" style={{ color: theme.colors.primary }}>

                                    {t(itemData.express_delivery_time) + " " + t('commons.Hours')}
                                  </span>) : (<span className="themeColor" style={{ color: theme.colors.primary }}>

                                    {t(itemData.express_delivery_time) + " " + t('commons.Days')}
                                  </span>)
                                }
                              </p>
                            )}
                          </div>

                          <div>
                            {itemData &&
                              itemData.service_items.map((item, index) => {
                                let selectedServiceItem = this.state.selectedServiceItems.findIndex((i) => i.service_item_id === item._id);
                                return (
                                  <div className="checkoutadd" key={index}>

                                    {(item.OtherPieces > 0 || item.TotalPieces > 0) && (
                                      <div className="MangageList-Package">
                                        <div className="ManageList-section">
                                          {item.PackageName.length > 1 ?
                                            <p className="packageName">{t('multiple-package')}</p> :
                                            <p className="packageName" style={{ color: item.PackageName[0].color }}>{item.PackageName[0].PackageName[lang]}</p>
                                          }
                                        </div>
                                        {lang == 'en' ?
                                          <div className="Package-Service-List1">
                                            {item && item.OtherPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", right: "5%" }}>{t("promotion_remain_pieces") + " : " + item.OtherPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : item && item.TotalPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", right: "5%" }}>{t("promotion_remain_pieces") + " : " + item.TotalPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                            }
                                          </div>
                                          :
                                          <div className="Package-Service-List1">
                                            {item && item.OtherPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", left: "5%" }}>{t("promotion_remain_pieces") + " : " + item.OtherPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : item && item.TotalPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", left: "5%" }}>{t("promotion_remain_pieces") + " : " + item.TotalPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                            }
                                          </div>
                                        }
                                      </div>
                                    )}
                                    {(item.OtherPieces > 0 || item.TotalPieces > 0) && (
                                      <div style={{ paddingBottom: '20px' }} className="packageFreedelivery">
                                        {item.customer_minimum_order_amount > 0 ?
                                          <>
                                            <p>{t("Min. amount for free delivery") + ": " + item.customer_minimum_order_amount + " " + t("contents.OMR")}</p>
                                          </>
                                          :
                                          <></>
                                        }
                                      </div>
                                    )}


                                    {/* for corporate package count */}
                                    {(item.corporateOtherPieces > 0 || item.corporateTotalPieces > 0) && (
                                      <div className="MangageList-Package">
                                        <div className="ManageList-section">
                                          {item.corporatePackageName.length > 1 ?
                                            <p className="packageName">{t('Corporate Multiple Package')}</p> :
                                            <p className="packageName" style={{ color: item.corporatePackageName[0].color }}>{item.corporatePackageName[0].PackageName[lang]}</p>
                                          }
                                        </div>
                                        {lang == 'en' ?
                                          <div className="Package-Service-List1">
                                            {item && item.corporateOtherPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", right: "5%" }}>{t("promotion_remain_pieces") + " : " + item.corporateOtherPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : item && item.corporateTotalPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", right: "5%" }}>{t("promotion_remain_pieces") + " : " + item.corporateTotalPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                            }
                                          </div>
                                          :
                                          <div className="Package-Service-List1">
                                            {item && item.corporateOtherPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", left: "5%" }}>{t("promotion_remain_pieces") + " : " + item.corporateOtherPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : item && item.corporateTotalPieces > 0 ? (
                                              <>
                                                <p style={{ color: "#228a02", left: "5%" }}>{t("promotion_remain_pieces") + " : " + item.corporateTotalPieces + " " + t("labels.Pcs")}</p>
                                              </>
                                            ) : (
                                              <></>
                                            )
                                            }
                                          </div>
                                        }
                                      </div>
                                    )}

                                    {/* for corporate package customer minimum order amount */}
                                    {(item.corporateOtherPieces > 0 || item.corporateTotalPieces > 0) && (
                                      <div className="packageFreedelivery">
                                        {item.corporate_customer_minimum_order_amount > 0 ?
                                          <>
                                            <p>{t("Min. amount for free delivery") + ": " + item.corporate_customer_minimum_order_amount + " " + t("contents.OMR")}</p>
                                          </>
                                          :
                                          <></>
                                        }
                                      </div>
                                    )}

                                    <div className="d-flex justify-content-between">
                                      <h3>{lang && item.service_type.name[lang]}</h3>

                                      <span>
                                        {this.state.selectedServiceItems &&
                                          this.state.selectedServiceItems.map((service, i) => {
                                            return item._id === service.service_item_id ? (
                                              <span key={i}>
                                                {service.measure_type === "meter"
                                                  ? t("commons.Qty") + ": " + service.quantity_by_measure + " " + t("commons.Sq_Mts.")
                                                  : service.measure_type === "weight"
                                                    ? t("commons.Qty") + ": " + parseInt(service.quantity_by_measure) / 1000 + " " + t("commons.KG.")
                                                    : t("commons.Qty") + ": " + service.quantity_by_measure + " " + t("commons.Pcs.")}
                                              </span>
                                            ) : (
                                              ""
                                            );
                                          })}

                                        {selectedServiceItem >= 0 ? (
                                          <>
                                            {" "}
                                            <Button className="btn" onClick={() => this.toggleServiceModal(item._id)}>
                                              <i className="fas fa-pencil-alt" title={t("commons.Edit")} style={{ cursor: "pointer" }}></i>
                                            </Button>{" "}
                                            <Button className="btn" onClick={() => this.deleteServiceItem(item._id)}>
                                              <i className="fas fa-times text-danger" title={t("commons.Delete")} style={{ cursor: "pointer" }}></i>
                                            </Button>
                                          </>
                                        ) : (
                                          // <Button
                                          //   className="btn"
                                          //   onClick={() => {
                                          //     this.toggleAddServiceItem(item, item.service_type._id);
                                          //   }}
                                          // >
                                          //   {t("buttons.Add")}
                                          // </Button>
                                          <StyledButtondiv2 className="btn"
                                            onClick={() => {
                                              this.toggleAddServiceItem(item, item.service_type._id);
                                            }}>
                                            {t("buttons.Add")}
                                          </StyledButtondiv2>
                                        )}

                                      </span>
                                    </div>
                                    <div className="subcategory_alignment">
                                      {item && item.is_discount == 'available' &&
                                        <div className="subcategory_discounts sub_discountcategory">
                                          {item && item.is_discount == 'available' && (
                                            lang == 'en' ?
                                              <span className={lang}>{item && item.discount_type == 'percent' ? item.discount_amount + "% " + t("contents.Discount") : t("contents.OMR") + item.discount_amount + " " + t("contents.Discount")}</span> :
                                              <span className={lang}>{item && item.discount_type == 'percent' ? t("contents.Discount") + item.discount_amount + " % " : t("contents.Discount") + item.discount_amount + " " + t("contents.OMR")}</span>
                                          )}
                                          {item && item.is_discount == 'available' && (
                                            <span className={lang}>{t("labels.Minimum_Order_Value") + ": " + t("contents.OMR") + item.min_amount_coupon}</span>
                                          )}
                                        </div>}
                                      <span>
                                        {item.price_by_meter > 0 && (
                                          <p>
                                            {t("labels.Price_by_Meter")}:{" "}
                                            <span className="themeColor" style={{ color: theme.colors.primary }}>{item.price_by_meter > 0 && t("commons.OMR") + " " + item.price_by_meter.toFixed(3)}</span>
                                          </p>
                                        )}

                                        {item.price_by_weight > 0 && (
                                          <p>
                                            {t("labels.Price_by_Weight")}:{" "}
                                            <span className="themeColor" style={{ color: theme.colors.primary }}>{item.price_by_weight > 0 && t("commons.OMR") + " " + item.price_by_weight.toFixed(3)}</span>
                                          </p>
                                        )}

                                        {item.price_by_piece > 0 && (
                                          <p>
                                            {t("labels.Price_by_Piece")}:{" "}
                                            <span className="themeColor" style={{ color: theme.colors.primary }}> {item.price_by_piece > 0 && t("commons.OMR") + " " + item.price_by_piece.toFixed(3)}</span>
                                          </p>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="text-center">
                            {/* <Button className="btn" onClick={this.handleSubmit} disabled={this.state.selectedServiceItems.length == 0}>
                              {t("buttons.Add_to_Cart")}
                            </Button> */}
                            <StyledButtondiv1 className="btn" onClick={this.handleSubmit} disabled={this.state.selectedServiceItems.length == 0}>
                              {t("buttons.Add_to_Cart")}
                            </StyledButtondiv1>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>

              <Modal
                isOpen={this.state.serviceItemModal}
                backdrop={"static"}
                keyboard={false}
                toggle={() => {
                  this.toggleAddServiceItem();
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={{
                    measure_type: "",
                    quantity_by_measure: "",

                    service_item_id: this.state.service_item_id,
                    clothe_return_types: this.state.clothe_return_types,
                  }}
                  validationSchema={ServiceTypeFormSchema}
                  onSubmit={this.handleAddServiceItem}
                >
                  {({ handleAddServiceItem, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                    <Form>
                      <div className="filter_modal_header" style={{ backgroundColor: theme.colors.primary }}>
                        {/* <span onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                          {t("commons.Cancel")}
                        </span> */}
                        <StyledButtondiv3 onClick={() => this.hideModal()} style={{ cursor: "pointer" }} className="btn1">
                          {t("commons.Cancel")}
                        </StyledButtondiv3>
                        <div className="filter_modal_heading">{t("filters.Service_Item")}</div>
                        <StyledButtondiv3 type="submit" style={{ cursor: "pointer" }} className="btn1">
                          {t("buttons.Add")}
                        </StyledButtondiv3>
                        {/* <button type="submit" style={{ cursor: "pointer" }} className="btn1">
                          {t("buttons.Add")}
                        </button> */}
                      </div>

                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <select
                          className="form-control"
                          name="measure_type"
                          value={values.measure_type}
                          onChange={(event) => {
                            setFieldValue("measure_type", event.target.value);
                            this.setState({ measure_type: event.target.value });
                          }}
                        >
                          {/* <option disabled value="">
                            {t("filters.Measure_Type")}
                          </option> */}
                          {this.state.price_by_meter && this.state.price_by_piece && this.state.price_by_weight ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="meter">{t("commons.Meter")}</option>
                              <option value="piece">{t("commons.Piece")}</option>
                              <option value="weight">{t("commons.Weight")}</option>
                            </>
                          ) : this.state.price_by_piece && this.state.price_by_weight ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="piece">{t("commons.Piece")}</option>
                              <option value="weight">{t("commons.Weight")}</option>
                            </>
                          ) : this.state.price_by_meter && this.state.price_by_weight ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="meter">{t("commons.Meter")}</option>
                              <option value="weight">{t("commons.Weight")}</option>
                            </>
                          ) : this.state.price_by_meter && this.state.price_by_piece ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="meter">{t("commons.Meter")}</option>
                              <option value="piece">{t("commons.Piece")}</option>
                            </>
                          ) : this.state.price_by_piece ? (
                            <option value="piece">{t("commons.Piece")}</option>
                          ) : this.state.price_by_meter ? (
                            <option value="meter">{t("commons.Meter")}</option>
                          ) : (
                            <option value="weight">{t("commons.Weight")}</option>
                          )}
                        </select>
                        {errors.measure_type && touched.measure_type && <div className="invalid-feedback d-block">{t(errors.measure_type)}</div>}
                      </FormGroup>

                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <select
                          disabled={
                            // values.measure_type != undefined ||values.measure_type.length  ||
                            ((values.measure_type == undefined || values.measure_type == '') && this.state.price_by_piece) || values.measure_type != undefined
                              // ||  this.state.price_by_meter ||  this.state.price_by_piece
                              ? false : true}
                          className="form-control"
                          name="clothe_return_types"
                          value={values.clothe_return_types}
                          onChange={(event) => {
                            setFieldValue("clothe_return_types", event.target.value);
                            this.handleChange(event);
                          }}
                        >
                          {/* {itemData && itemData.clothe_return_type && itemData.clothe_return_type.length > 0 &&  */}
                          <option disabled value="">
                            {t("filters.Select_Item_Return_Type")}
                          </option>
                          {/* // } */}

                          {(values.measure_type == undefined || values.measure_type == '') && this.state.price_by_piece && !this.state.price_by_meter && <>
                            {
                              itemData &&
                              itemData.clothe_return_type.map((item, index) => {
                                return (
                                  <option key={index} value={item._id}>
                                    {lang && item.name[lang]}
                                  </option>
                                );
                              })
                            }
                          </>}
                          {
                            !values.measure_type == 'piece' || values.measure_type == undefined || values.measure_type == null && this.state.price_by_piece && !this.state.price_by_meter &&
                            <>
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {

                                  return (
                                    <option key={index} value={item._id}>
                                      {lang && item.name[lang]}
                                    </option>
                                  );
                                })
                              }
                            </>
                          }
                          {
                            (values.measure_type == 'piece' && !this.state.price_by_piece) && !this.state.price_by_meter &&
                            <>
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {

                                  return (
                                    <option key={index} value={item._id}>
                                      {lang && item.name[lang]}
                                    </option>
                                  );
                                })
                              }
                            </>
                          }

                          {
                            values.measure_type == 'piece' && this.state.price_by_piece && !this.state.price_by_meter &&
                            <>
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {

                                  return (
                                    <option key={index} value={item._id}>
                                      {lang && item.name[lang]}
                                    </option>
                                  );


                                })

                              }

                            </>
                          }

                          {
                            values.measure_type == 'weight' &&
                            <>
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {
                                  if (lang == 'en' ? item.name[lang] !== 'Hanging' : item.name[lang] !== 'معلقة') {
                                    return (
                                      <option key={index} value={item._id}>
                                        {lang && item.name[lang]}
                                      </option>
                                    );
                                  }


                                })
                              }
                            </>
                          }
                          {
                            this.state.price_by_meter &&
                            <>
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {
                                  if (lang == 'en' ? item.name[lang] !== 'Hanging' : item.name[lang] !== 'معلقة') {
                                    return (
                                      <option key={index} value={item._id}>
                                        {lang && item.name[lang]}
                                      </option>
                                    );
                                  }

                                })

                              }

                            </>
                          }
                        </select>
                        {errors.clothe_return_types && touched.clothe_return_types && <div className="invalid-feedback d-block">{t(errors.clothe_return_types)}</div>}
                      </FormGroup>

                      <FormGroup className="pl-3 pr-3">
                        <Field
                          className="form-control"
                          name="quantity_by_measure"
                          type="text"
                          placeholder={values.measure_type && values.measure_type === "weight" ? t("commons.Enter_Quantity_in_Grams") : t("filters.Enter_Quantity")}
                        />
                        {errors.quantity_by_measure && touched.quantity_by_measure ? <div className="invalid-feedback d-block">{t(errors.quantity_by_measure)}</div> : null}
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </Modal>

              <Modal
                isOpen={this.state.serviceModal}
                backdrop={"static"}
                keyboard={false}
                toggle={() => {
                  this.toggleServiceModal();
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={{
                    measure_type: this.state.measure_type,
                    quantity_by_measure: this.state.quantity_by_measure,
                    clothe_return_type: this.state.clothe_return_type._id,
                    service_item_id: this.state.service_item_id,
                  }}
                  validationSchema={ServiceTypeFormSchema}
                  onSubmit={this.handleEditServiceItem}
                >
                  {({ handleAddServiceItem, setFieldValue, setFieldTouched, values, errors, touched, isSubmitting }) => (
                    <Form>
                      <div className="filter_modal_header">
                        <span onClick={() => this.hideEditModal()} style={{ cursor: "pointer" }} className="btn1">
                          {t("commons.Cancel")}
                        </span>
                        <div className="filter_modal_heading">{t("filters.Service_Item")}</div>
                        <button type="submit" style={{ cursor: "pointer" }} className="btn1">
                          {t("commons.Edit")}
                        </button>
                      </div>

                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <select
                          className="form-control"
                          name="measure_type"
                          value={values.measure_type}
                          onChange={(event) => {
                            setFieldValue("measure_type", event.target.value);
                            this.setState({ measure_type: event.target.value });
                          }}
                        >
                          {/* <option disabled value="">
                            {t("filters.Measure_Type")}
                          </option> */}
                          {this.state.price_by_meter && this.state.price_by_piece && this.state.price_by_weight ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="meter">{t("commons.Meter")}</option>
                              <option value="piece">{t("commons.Piece")}</option>
                              <option value="weight">{t("commons.Weight")}</option>
                            </>
                          ) : this.state.price_by_piece && this.state.price_by_weight ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="piece">{t("commons.Piece")}</option>
                              <option value="weight">{t("commons.Weight")}</option>
                            </>
                          ) : this.state.price_by_meter && this.state.price_by_weight ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="meter">{t("commons.Meter")}</option>
                              <option value="weight">{t("commons.Weight")}</option>
                            </>
                          ) : this.state.price_by_meter && this.state.price_by_piece ? (
                            <>
                              <option disabled value="">{t("filters.Measure_Type")}</option>
                              <option value="meter">{t("commons.Meter")}</option>
                              <option value="piece">{t("commons.Piece")}</option>
                            </>
                          ) : this.state.price_by_piece ? (
                            <option value="piece">{t("commons.Piece")}</option>
                          ) : this.state.price_by_meter ? (
                            <option value="meter">{t("commons.Meter")}</option>
                          ) : (
                            <option value="weight">{t("commons.Weight")}</option>
                          )}
                        </select>
                        {errors.measure_type && touched.measure_type && <div className="invalid-feedback d-block">{t(errors.measure_type)}</div>}
                      </FormGroup>

                      <FormGroup className="mb-3 pl-3 pr-3 mt-3">
                        <select
                          disabled={
                            // values.measure_type != undefined ||values.measure_type.length  ||
                            ((values.measure_type == undefined || values.measure_type == '') && this.state.price_by_piece) || values.measure_type != undefined
                              // ||  this.state.price_by_meter ||  this.state.price_by_piece
                              ? false : true}
                          className="form-control"
                          name="clothe_return_types"
                          defaultValue={values.clothe_return_type}
                          onChange={(event) => {
                            setFieldValue("clothe_return_types", event.target.value);
                            this.handleChange(event);
                          }}
                        >
                          {/* {itemData && itemData.clothe_return_type && itemData.clothe_return_type.length > 0 &&  */}
                          <option disabled value="">
                            {t("filters.Select_Item_Return_Type")}
                          </option>
                          {/* // } */}

                          {
                            console.log('aaaaaaaa', values.measure_type)}
                          {console.log('aaaaaaaa', this.state.price_by_piece)
                          }
                          {(values.measure_type == undefined || values.measure_type == '') && this.state.price_by_piece && !this.state.price_by_meter && <>
                            {console.log('yyyyyy')}
                            {console.log('yyyyyy', values.measure_type)}
                            {console.log('yyyyyy', this.state.price_by_piece)}
                            {
                              itemData &&
                              itemData.clothe_return_type.map((item, index) => {

                                return (
                                  <option key={index} value={item._id}>
                                    {lang && item.name[lang]}
                                  </option>
                                );


                              })

                            }

                          </>}

                          {
                            !values.measure_type == 'piece' || values.measure_type == undefined || values.measure_type == null && this.state.price_by_piece && !this.state.price_by_meter &&
                            <>
                              {console.log('123')}
                              {console.log('123', values.measure_type)}
                              {console.log('123', this.state.price_by_piece)}
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {

                                  return (
                                    <option key={index} value={item._id}>
                                      {lang && item.name[lang]}
                                    </option>
                                  );


                                })

                              }

                            </>


                          }


                          {
                            (values.measure_type == 'piece' && !this.state.price_by_piece) && !this.state.price_by_meter &&
                            <>
                              {console.log('456')}
                              {console.log('456', values.measure_type)}
                              {console.log('456', this.state.price_by_piece)}
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {

                                  return (
                                    <option key={index} value={item._id}>
                                      {lang && item.name[lang]}
                                    </option>
                                  );


                                })

                              }

                            </>


                          }

                          {
                            values.measure_type == 'piece' && this.state.price_by_piece && !this.state.price_by_meter &&
                            <>
                              {console.log('222')}
                              {console.log('222', values.measure_type)}
                              {console.log('222', this.state.price_by_piece)}
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {

                                  return (
                                    <option key={index} value={item._id}>
                                      {lang && item.name[lang]}
                                    </option>
                                  );


                                })

                              }

                            </>


                          }


                          {
                            values.measure_type == 'weight' &&
                            <>
                              {console.log('two')}
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {
                                  if (lang == 'en' ? item.name[lang] !== 'Hanging' : item.name[lang] !== 'معلقة') {
                                    return (
                                      <option key={index} value={item._id}>
                                        {lang && item.name[lang]}
                                      </option>
                                    );
                                  }


                                })

                              }

                            </>


                          }
                          {
                            this.state.price_by_meter &&
                            <>
                              {console.log('three')}
                              {
                                itemData &&
                                itemData.clothe_return_type.map((item, index) => {
                                  if (lang == 'en' ? item.name[lang] !== 'Hanging' : item.name[lang] !== 'معلقة') {
                                    return (
                                      <option key={index} value={item._id}>
                                        {lang && item.name[lang]}
                                      </option>
                                    );
                                  }

                                })

                              }

                            </>


                          }
                        </select>
                        {errors.clothe_return_types && touched.clothe_return_types && <div className="invalid-feedback d-block">{t(errors.clothe_return_types)}</div>}
                      </FormGroup>

                      <FormGroup className="pl-3 pr-3">
                        <Field
                          className="form-control"
                          name="quantity_by_measure"
                          type="text"
                          placeholder={values.measure_type && values.measure_type === "weight" ? t("commons.Enter_Quantity_in_Grams") : t("commons.Enter_Quantity")}
                        />
                        {errors.quantity_by_measure && touched.quantity_by_measure ? <div className="invalid-feedback d-block">{t(errors.quantity_by_measure)}</div> : null}
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </Modal>
            </section>
          </>
        )}
      </Translation>
    );
  }
}
