import React, { Component } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";
import Slider from "react-slick";
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { StyledButtondiv3 } from "../../helpers/StyledButtondiv";
import moment from "moment";
import { Translation } from "react-i18next";

var sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: false,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [{ breakpoint: 767, settings: { dots: false } }],
};

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

export default class Notifications extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      itemData: [],
      isloading: false,
      isDelete: false,
      isDeleteAll: false,
      objId: "",
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  loadPagination = async (currentPage) => {
    this.getNotifications(currentPage + 1);
  };

  showDeleteModal = (id) => {
    this.setState((prevState) => ({
      isDelete: !prevState.isDelete,
      objId: id,
    }));
  };

  showDeleteAll = () => {
    this.setState((prevState) => ({
      isDeleteAll: !prevState.isDeleteAll,
    }));
  };

  getNotifications = async (currentPage = 1) => {
    let path = ApiRoutes.GET_NOTIFICATIONS + "?page_no=" + `${currentPage}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            itemData: [...this.state.itemData, ...resJson.data.docs],

            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    }
  };

  deleteNotification = async (itemId) => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_NOTIFICATION + "/" + itemId;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.setState({ itemData: [] }, () => this.getNotifications());
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  // delete all notifications

  deleteAllNotification = async () => {
    let formData = new FormData();

    let path = ApiRoutes.DELETE_NOTIFICATION;

    const res = await Http("DELETE", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.setState({ itemData: [] }, () => this.getNotifications());
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    const { itemData } = this.state;
    const theme = this.context;

    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <Container className="py-3 py-md-4">
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="btn text-white py-2 px-3 toggle_btn d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <div className="d-flex clearCart align-items-center">
                <div className="w-100 d-flex justify-content-between my_cart_content_mb">
                  <div>
                    <h2 className="section_title mb-sm-4">{t("commons.Notifications")}</h2>
                  </div>
                  <div>
                    {itemData && itemData.length > 0 && (
                      // <Button className="ml-auto text-uppercase" onClick={() => this.showDeleteAll()}>
                      //   {t("commons.CLEAR_All")}
                      // </Button>
                      <StyledButtondiv3 className="ml-auto text-uppercase" onClick={() => this.showDeleteAll()} style={{float: "right"}}>
                        {t("commons.CLEAR_All")}
                      </StyledButtondiv3>
                    )}
                  </div>
                </div>
              </div>

              {!this.state.isloading ? (
                <div className="loading"></div>
              ) : (
                <InfiniteScroll pageStart={0} loadMore={this.loadPagination} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                  <div className="list_notification">
                    <ul>
                      {itemData &&
                        itemData.map((item, index) => {
                          return (
                            <li className="mb-3" key={index}>
                              <div className="notification_content align-items-center p-2">
                                <div className="icon_tag mr-3 ml-3">
                                  <span>
                                    <i className="fas fa-info" style={{ color: theme.colors.primary, border: `1px solid`, borderColor: theme.colors.primary }}></i>
                                  </span>
                                </div>

                                <div className="notification_item">
                                  <div className="d-flex">
                                    <h3>{t("commons.Laundry_Hub")} </h3>
                                  </div>
                                  <p className="mt-2 m-0">{item.message}</p>
                                </div>
                                <div className="timing_sec text-center">
                                  <div onClick={() => this.showDeleteModal(item._id)} className="btn_cancel btn_cancel_close px-3 mx-1 mb-4 m-4">
                                    <i className="fas fa-trash-alt"></i>
                                  </div>
                                  <span style={{ color: theme.colors.primary }}>{moment(item.createdAt).format("DD MMMM YYYY, HH:mm A")}</span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </InfiniteScroll>
              )}

              {this.state.isloading && itemData && itemData.length == 0 && <div className="noorder">{t("commons.No_new_notifications")}</div>}
            </Container>
            {/* Modal for clear notification */}
            <Modal isOpen={this.state.isDelete} backdrop={"static"} keyboard={false}>
              <ModalBody>
                <h6>{t("commons.Are_you_sure_you_want_to_delete_the_selected_notification??")}</h6>
              </ModalBody>
              <ModalFooter>
                <div>
                  <button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    className="btn"
                    onClick={() => {
                      this.deleteNotification(this.state.objId);
                      this.setState({ isDelete: false });
                    }}
                  >
                    {t("commons.OK")}
                  </button>
                  <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isDelete: false })}>
                    {t("commons.Cancel")}
                  </button>
                </div>
              </ModalFooter>
            </Modal>

            {/* Modal for clear all notifications */}
            <Modal isOpen={this.state.isDeleteAll} backdrop={"static"} keyboard={false}>
              <ModalBody>
                <h6>{t("commons.Are_you_sure_you_want_to_delete_all_the_notifications??")}</h6>
              </ModalBody>
              <ModalFooter>
                <div>
                  <button
                    type="submit"
                    style={{ cursor: "pointer", marginLeft: "8px" }}
                    className="btn"
                    onClick={() => {
                      this.deleteAllNotification();
                      this.setState({ isDeleteAll: false });
                    }}
                  >
                    {t("commons.OK")}
                  </button>
                  <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={() => this.setState({ isDeleteAll: false })}>
                    {t("commons.Cancel")}
                  </button>
                </div>
              </ModalFooter>
            </Modal>

            <div className="sidebar_overlay" onClick={SidebarRemoveClass}>
              {" "}
            </div>
          </section>
        )}
      </Translation>
    );
  }
}
