import React, { Component } from "react";
import { Container, Row, Col, Button, textarea, FormGroup, Card, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { NotificationManager } from "react-notifications";
import Collapsible from "react-collapsible";
import MainContext from "../../helpers/MainContext";
import { number } from "yup";
import { Translation } from "react-i18next";
import i18next from "i18next";
import authService from "../../services/auth.service";

const Options = [
  { value: "yes", label: "commons.Yes" },
  { value: "no", label: "commons.No" },
];

var settingInfo = authService.getAuthSettings();

export default class AdditionalInformation extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      vendorData: [],
      vendorId: props.match.params.vendor_id,
      shampoo: "",
      shampooName: "",
      bleach: "",
      bleachName: "",
      softener: "",
      softenerName: "",
      perfume: "",
      perfumeName: "",
      sanitizer: "",

      wash: "",

      numberofbags: "",
      bagsprice: "",

      isOpenShampoo: false,
      isOpenPerfume: false,
      isOpenBleach: false,
      isOpenSoftener: false,
      isOpenSanitizer: false,
      isOpenWash: true,
      isOpenBag: false,

      isloading: false,
      addSanitizers: "",
      addSofteners: "",
      addBleaches: "",
      addPerfumes: "",
      addShampoos: "",
      laundrybag: "",
      subtotal: "",
      checked: false,
      lang: "",
    };
  }
  componentDidMount() {
    if (this.props.authUser) {
      this.getVendorData();
      this.getCartData();

      const context = this.context;

      const addonDetails = context.additional_info;

      this.setState({
        wash: addonDetails && addonDetails.wash,
        laundrybag: addonDetails && addonDetails.laundrybag,
        addShampoos: addonDetails && addonDetails.addShampoos,
        addBleaches: addonDetails && addonDetails.addBleaches,
        addPerfumes: addonDetails && addonDetails.addPerfumes,
        addSofteners: addonDetails && addonDetails.addSofteners,
        addSanitizers: addonDetails && addonDetails.addSanitizers,
        lang: localStorage.getItem("lang") || "en",
      });
    }
  }

  

  getCartData = async (currentPage = 1) => {
    const context = this.context;

    let path = ApiRoutes.GET_CART;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({ subtotal: resJson.data.amountInfo.sub_total });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  getVendorData = async () => {
    let path = ApiRoutes.GET_ALLVENDORS + "/" + `${this.state.vendorId}`;

    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState(
          {
            vendorData: resJson.data,
          },
          () => this.setState({ isloading: true })
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  handleSubmit = async () => {
    const { vendorData, laundrybag, addShampoos, addBleaches, addPerfumes, addSofteners, addSanitizers, wash } = this.state;

    const context = this.context;

    if (laundrybag && laundrybag.value == "Yes" && laundrybag.bags == "") {
      NotificationManager.error(i18next.t("commons.Please_add_number_of_bags"), "Error!", 3000);
      return false;
    }

    // set values to context

    const addonDetails = {
      wash: wash ? wash : "",
      laundrybag: laundrybag ? laundrybag : "",
      addShampoos: addShampoos ? addShampoos : "",
      addBleaches: addBleaches ? addBleaches : "",
      addPerfumes: addPerfumes ? addPerfumes : "",
      addSofteners: addSofteners ? addSofteners : "",
      addSanitizers: addSanitizers ? addSanitizers : "",
    };

    context.setAdditionalInfo(addonDetails);

    // push values to next page
    this.props.history.push({
      pathname: "/checkout/" + vendorData._id,
      state: {
        addShampoos: addShampoos ? addShampoos : "",
        addBleaches: addBleaches ? addBleaches : "",
        addPerfumes: addPerfumes ? addPerfumes : "",
        addSofteners: addSofteners ? addSofteners : "",
        addSanitizers: addSanitizers ? addSanitizers : "",
        laundrybag: laundrybag ? laundrybag : "",
        wash: wash ? wash : "",
      },
    });
  };

  // for shampoos
  addShampoos = (event, item, price) => {
    var elems = document.getElementsByClassName("shampoo_opts");

    var currentState = event.target.checked;

    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      var addShampoos = "";
      var addShampoos = {
        _id: event.target.value,
        name: item.name,
        price: price,
      };
      this.setState({
        addShampoos: addShampoos,
      });
    } else {
      var addShampoos = "";
      this.setState({
        addShampoos: addShampoos,
      });
    }
  };

  // for blecahes
  addBleaches = (event, item, price) => {
    var elems = document.getElementsByClassName("bleach_opts");

    var currentState = event.target.checked;

    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      var addBleaches = "";
      var addBleaches = {
        _id: event.target.value,
        name: item.name,
        price: price,
      };
      this.setState({
        addBleaches: addBleaches,
      });
    } else {
      var addBleaches = "";
      this.setState({
        addBleaches: addBleaches,
      });
    }
  };

  // for perfumes

  addPerfumes = (event, item, price) => {
    var elems = document.getElementsByClassName("perfume_opts");
    var currentState = event.target.checked;
    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      var addPerfumes = "";
      var addPerfumes = {
        _id: event.target.value,
        name: item.name,
        price: price,
      };
      this.setState({
        addPerfumes: addPerfumes,
      });
    } else {
      var addPerfumes = "";
      this.setState({
        addPerfumes: addPerfumes,
      });
    }
  };

  // for softeners

  addSofteners = (event, item, price) => {
    var elems = document.getElementsByClassName("softener_opts");
    var currentState = event.target.checked;
    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      var addSofteners = "";
      var addSofteners = {
        _id: event.target.value,
        name: item.name,
        price: price,
      };
      this.setState({
        addSofteners: addSofteners,
      });
    } else {
      var addSofteners = "";
      this.setState({
        addSofteners: addSofteners,
      });
    }
  };

  // for sanitizers

  addSanitizers = (event, item, price) => {
    var elems = document.getElementsByClassName("sanitizer_opts");
    var currentState = event.target.checked;
    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      var addSanitizers = "";
      var addSanitizers = {
        _id: event.target.value,
        name: item.name,
        price: price,
      };
      this.setState({
        addSanitizers: addSanitizers,
      });
    } else {
      var addSanitizers = "";
      this.setState({
        addSanitizers: addSanitizers,
      });
    }
  };

  buildOptions = () => {
    var arr = [];

    for (let i = 1; i <= 50; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  };
  render() {
    var { authUser } = this.props;

    var {
      vendorData,

      bleach,

      perfume,
      sanitizer,
      shampooName,
      bleachName,
      softenerName,
      perfumeName,
      laundrybag,
      numberofbags,
      wash,
      addSanitizers,
      addSofteners,
      addBleaches,
      addPerfumes,
      addShampoos,
      subtotal,
      lang,
    } = this.state;

    const context = this.context;

    return (
      <Translation>
        {(t) => (
          <div className="vendor_list_detail_sec py-md-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <Container>
              <div className="pl-md-4 pt-3 pr-md-4">
                {!authUser && this.props.history.push("/login")}

                <div className="headingouter">{authUser && <h2>{t("commons.Service_Ad-Ons")}</h2>}</div>
                {!this.state.isloading && authUser ? (
                  <div className="loading"></div>
                ) : (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="availableouter">
                        <div className="tab_inner_div">
                          <h5
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                isOpenWash: !this.state.isOpenWash,
                              })
                            }
                          >
                            {t("commons.Do_you_want_to_wash_separately?")}
                          </h5>
                          {this.state.isOpenWash ? (
                            <i
                              className="fas fa-minus"
                              onClick={() =>
                                this.setState({
                                  isOpenWash: !this.state.isOpenWash,
                                })
                              }
                            ></i>
                          ) : (
                            <i
                              className="fas fa-plus"
                              onClick={() =>
                                this.setState({
                                  isOpenWash: !this.state.isOpenWash,
                                })
                              }
                            ></i>
                          )}
                        </div>
                        <Collapse isOpen={this.state.isOpenWash}>
                          {Options.map((item, index) => {
                            return (
                              <span key={index} className="ml-2">
                                <span className="custom_radio">
                                  <p>{t(item.label)} &nbsp; </p>
                                  <input
                                    type="radio"
                                    name="wash"
                                    value={item.value}
                                    defaultChecked={context.additional_info && context.additional_info.wash.name == item.value}
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        var wash = "";
                                        var wash = {
                                          name: event.target.value,
                                          price: vendorData && vendorData.seperate_wash_charge > 0 && ((vendorData.seperate_wash_charge / 100) * subtotal).toFixed(3),
                                        };
                                        this.setState({
                                          wash: wash,
                                        });
                                      }
                                    }}
                                  />
                                  <span className="radio_indicator">&nbsp;</span>
                                </span>{" "}
                              </span>
                            );
                          })}
                          <span className="float-right priceaddon1">
                            {vendorData && vendorData.seperate_wash_charge > 0 && t("commons.OMR") + " " + ((vendorData.seperate_wash_charge / 100) * subtotal).toFixed(3)}
                          </span>
                        </Collapse>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="availableouter">
                        <div className="tab_inner_div">
                          <h5
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                isOpenBag: !this.state.isOpenBag,
                              })
                            }
                          >
                            {t("commons.Do_you_want_pickup_laundry_bag?")}
                          </h5>
                          {this.state.isOpenBag ? (
                            <i
                              className="fas fa-minus"
                              onClick={() =>
                                this.setState({
                                  isOpenBag: !this.state.isOpenBag,
                                })
                              }
                            ></i>
                          ) : (
                            <i
                              className="fas fa-plus"
                              onClick={() =>
                                this.setState({
                                  isOpenBag: !this.state.isOpenBag,
                                })
                              }
                            ></i>
                          )}
                        </div>
                        <Collapse isOpen={this.state.isOpenBag}>
                          <span className="addonouter d-flex justify-content-between align-items-center">
                            <span className="custom_radio">
                              <p>{t("commons.Yes")} &nbsp; </p>
                              <input
                                type="radio"
                                name="laundrybag"
                                value="Yes"
                                defaultChecked={context.additional_info && context.additional_info.laundrybag.value == "Yes"}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    var laundrybag = "";
                                    var laundrybag = {
                                      value: event.target.value,
                                      bags: "",
                                      bagsprice: "",
                                    };
                                    this.setState({ laundrybag: laundrybag });
                                  }
                                }}
                              />
                              <span className="radio_indicator">&nbsp;</span>
                            </span>{" "}
                            {laundrybag && laundrybag.value && laundrybag.value == "Yes" ? (
                              <span className="priceaddon">
                                <select
                                  className="form-control"
                                  defaultValue={context.additional_info && context.additional_info.laundrybag.bags}
                                  onChange={(event) => {
                                    var numberofbags = "";
                                    var bagsprice = "";
                                    numberofbags = event.target.value;
                                    laundrybag.bags = numberofbags;
                                    laundrybag.bagsprice = settingInfo && (this.state.numberofbags.bags * settingInfo.laundry_bag_charge).toFixed(3);
                                    bagsprice = laundrybag.bagsprice;
                                    this.setState({
                                      numberofbags: laundrybag,
                                      bagsprice: bagsprice,
                                    });
                                  }}
                                >
                                  <option>{t("commons.Number_of_bags")}</option>
                                  {this.buildOptions()}
                                </select>
                              </span>
                            ) : (
                              ""
                            )}
                            {laundrybag && laundrybag.value && laundrybag.value == "Yes" ? (
                              <strong>
                                {" "}
                                <span className="priceaddon" value={context.additional_info && context.additional_info.laundrybag.bagsprice}>
                                  {this.state.numberofbags && settingInfo ? t("commons.OMR") + " " + (this.state.numberofbags.bags * settingInfo.laundry_bag_charge).toFixed(3) : ""}
                                </span>
                              </strong>
                            ) : (
                              ""
                            )}
                          </span>

                          <span className="addonouter d-flex justify-content-between align-items-center">
                            <span className="custom_radio">
                              <p>{t("commons.No")} &nbsp; </p>
                              <input
                                type="radio"
                                name="laundrybag"
                                value="No"
                                defaultChecked={context.additional_info && context.additional_info.laundrybag.value == "No"}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    var laundrybag = "";
                                    var laundrybag = {
                                      value: event.target.value,
                                    };
                                    this.setState({ laundrybag: laundrybag });
                                  }
                                }}
                              />
                              <span className="radio_indicator">&nbsp;</span>
                            </span>{" "}
                          </span>
                        </Collapse>
                      </div>
                    </div>

                    {vendorData && vendorData.shampoos && vendorData.shampoos.length > 0 && (
                      <div className="col-md-12">
                        <div className="availableouter ">
                          <div className="tab_inner_div">
                            <h5
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  isOpenShampoo: !this.state.isOpenShampoo,
                                })
                              }
                            >
                              {t("commons.Available_Shampoos")}
                            </h5>
                            {this.state.isOpenShampoo ? (
                              <i
                                className="fas fa-minus"
                                onClick={() =>
                                  this.setState({
                                    isOpenShampoo: !this.state.isOpenShampoo,
                                  })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fas fa-plus"
                                onClick={() =>
                                  this.setState({
                                    isOpenShampoo: !this.state.isOpenShampoo,
                                  })
                                }
                              ></i>
                            )}
                          </div>
                          <Collapse isOpen={this.state.isOpenShampoo}>
                            {vendorData.shampoos.map((item, index) => {
                              let price = "";
                              price = (item.price / 100) * subtotal;
                              return (
                                <span key={index} className="addonouter d-flex justify-content-between align-items-center">
                                  <span className="custom_radio">
                                    <p>{lang && item.name[lang]}</p>
                                    <input
                                      type="checkbox"
                                      className="shampoo_opts"
                                      name="shampoo"
                                      value={item._id}
                                      defaultChecked={context.additional_info && context.additional_info.addShampoos._id == item._id ? true : false}
                                      onClick={(event) => {
                                        this.addShampoos(event, item, price);
                                      }}
                                    />
                                    <span className="radio_indicator">&nbsp;</span>
                                  </span>
                                  <span className="priceaddon">
                                    <h6>{t("commons.OMR") + " " + price.toFixed(3)}</h6>
                                  </span>
                                </span>
                              );
                            })}
                          </Collapse>
                        </div>
                      </div>
                    )}

                    {vendorData && vendorData.bleaches && vendorData.bleaches.length > 0 && (
                      <div className="col-md-12">
                        <div className="availableouter">
                          <div className="tab_inner_div">
                            <h5
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  isOpenBleach: !this.state.isOpenBleach,
                                })
                              }
                            >
                              {t("commons.Available_Bleaches")}
                            </h5>
                            {this.state.isOpenBleach ? (
                              <i
                                className="fas fa-minus"
                                onClick={() =>
                                  this.setState({
                                    isOpenBleach: !this.state.isOpenBleach,
                                  })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fas fa-plus"
                                onClick={() =>
                                  this.setState({
                                    isOpenBleach: !this.state.isOpenBleach,
                                  })
                                }
                              ></i>
                            )}
                          </div>
                          <Collapse isOpen={this.state.isOpenBleach}>
                            {vendorData.bleaches &&
                              vendorData.bleaches.map((item, index) => {
                                let price = "";
                                price = (item.price / 100) * subtotal;
                                return (
                                  <span key={index} className="addonouter d-flex justify-content-between align-items-center">
                                    <span className="custom_radio">
                                      <p>{lang && item.name[lang]}</p>
                                      <input
                                        type="checkbox"
                                        name="bleach"
                                        className="bleach_opts"
                                        value={item._id}
                                        defaultChecked={context.additional_info && context.additional_info.addBleaches._id == item._id}
                                        onClick={(event) => {
                                          this.addBleaches(event, item, price);
                                        }}
                                      />
                                      <span className="radio_indicator">&nbsp;</span>
                                    </span>
                                    <span className="priceaddon">
                                      <h6>{t("commons.OMR") + " " + price.toFixed(3)}</h6>
                                    </span>
                                  </span>
                                );
                              })}
                          </Collapse>
                        </div>
                      </div>
                    )}

                    {vendorData && vendorData.perfumes && vendorData.perfumes.length > 0 && (
                      <div className="col-md-12">
                        <div className="availableouter">
                          <div className="tab_inner_div">
                            <h5
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  isOpenPerfume: !this.state.isOpenPerfume,
                                })
                              }
                            >
                              {t("commons.Available_Perfumes")}
                            </h5>
                            {this.state.isOpenPerfume ? (
                              <i
                                className="fas fa-minus"
                                onClick={() =>
                                  this.setState({
                                    isOpenPerfume: !this.state.isOpenPerfume,
                                  })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fas fa-plus"
                                onClick={() =>
                                  this.setState({
                                    isOpenPerfume: !this.state.isOpenPerfume,
                                  })
                                }
                              ></i>
                            )}
                          </div>
                          <Collapse isOpen={this.state.isOpenPerfume}>
                            {vendorData.perfumes &&
                              vendorData.perfumes.map((item, index) => {
                                let price = "";
                                price = (item.price / 100) * subtotal;
                                return (
                                  <span key={index} className="addonouter d-flex justify-content-between align-items-center">
                                    <span className="custom_radio">
                                      <p>{lang && item.name[lang]}</p>
                                      <input
                                        type="checkbox"
                                        name="perfume"
                                        className="perfume_opts"
                                        value={item._id}
                                        defaultChecked={context.additional_info && context.additional_info.addPerfumes._id == item._id}
                                        onClick={(event) => {
                                          this.addPerfumes(event, item, price);
                                        }}
                                      />
                                      <span className="radio_indicator">&nbsp;</span>
                                    </span>
                                    <span className="priceaddon">
                                      <h6>{t("commons.OMR") + " " + price.toFixed(3)}</h6>
                                    </span>
                                  </span>
                                );
                              })}
                          </Collapse>
                        </div>
                      </div>
                    )}

                    {vendorData && vendorData.softeners && vendorData.softeners.length > 0 && (
                      <div className="col-md-12">
                        <div className="availableouter">
                          <div className="tab_inner_div">
                            <h5
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  isOpenSoftener: !this.state.isOpenSoftener,
                                })
                              }
                            >
                              {t("commons.Available_Softeners")}
                            </h5>
                            {this.state.isOpenSoftener ? (
                              <i
                                className="fas fa-minus"
                                onClick={() =>
                                  this.setState({
                                    isOpenSoftener: !this.state.isOpenSoftener,
                                  })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fas fa-plus"
                                onClick={() =>
                                  this.setState({
                                    isOpenSoftener: !this.state.isOpenSoftener,
                                  })
                                }
                              ></i>
                            )}
                          </div>
                          <Collapse isOpen={this.state.isOpenSoftener}>
                            {vendorData &&
                              vendorData.softeners &&
                              vendorData.softeners.map((item, index) => {
                                let price = "";
                                price = (item.price / 100) * subtotal;
                                return (
                                  <span key={index} className="addonouter d-flex justify-content-between align-items-center">
                                    <span className="custom_radio">
                                      <p>{lang && item.name[lang]}</p>
                                      <input
                                        type="checkbox"
                                        name="softener"
                                        className="softener_opts"
                                        value={item._id}
                                        defaultChecked={context.additional_info && context.additional_info.addSofteners._id == item._id}
                                        onClick={(event) => {
                                          this.addSofteners(event, item, price);
                                        }}
                                      />
                                      <span className="radio_indicator">&nbsp;</span>
                                    </span>
                                    <span className="priceaddon">
                                      <h6>{t("commons.OMR") + " " + price.toFixed(3)}</h6>
                                    </span>
                                  </span>
                                );
                              })}
                          </Collapse>
                        </div>
                      </div>
                    )}

                    {vendorData && vendorData.sanitizers && vendorData.sanitizers.length > 0 && (
                      <div className="col-md-12">
                        <div className="availableouter">
                          <div className="tab_inner_div">
                            <h5
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  isOpenSanitizer: !this.state.isOpenSanitizer,
                                })
                              }
                            >
                              {t("commons.Available_Sanitizers")}
                            </h5>
                            {this.state.isOpenSanitizer ? (
                              <i
                                className="fas fa-minus"
                                onClick={() =>
                                  this.setState({
                                    isOpenSanitizer: !this.state.isOpenSanitizer,
                                  })
                                }
                              ></i>
                            ) : (
                              <i
                                className="fas fa-plus"
                                onClick={() =>
                                  this.setState({
                                    isOpenSanitizer: !this.state.isOpenSanitizer,
                                  })
                                }
                              ></i>
                            )}
                          </div>
                          <Collapse isOpen={this.state.isOpenSanitizer}>
                            {vendorData &&
                              vendorData.sanitizers &&
                              vendorData.sanitizers.map((item, index) => {
                                let price = "";
                                price = (item.price / 100) * subtotal;
                                return (
                                  <span key={index} className="addonouter d-flex justify-content-between align-items-center">
                                    <span className="custom_radio">
                                      <p>{lang && item.name[lang]}</p>
                                      <input
                                        type="checkbox"
                                        name="sanitizer"
                                        className="sanitizer_opts"
                                        value={item._id}
                                        defaultChecked={context.additional_info && context.additional_info.addSanitizers._id == item._id}
                                        onClick={(event) => {
                                          this.addSanitizers(event, item, price);
                                        }}
                                      />
                                      <span className="radio_indicator">&nbsp;</span>
                                    </span>
                                    <span className="priceaddon">
                                      <h6>{t("commons.OMR") + " " + price.toFixed(3)}</h6>
                                    </span>
                                  </span>
                                );
                              })}
                          </Collapse>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {authUser && (
                  <center>
                    <Button className="mb-3" onClick={this.handleSubmit}>
                      {t("commons.Checkout")}
                    </Button>
                  </center>
                )}
              </div>
            </Container>
          </div>
        )}
      </Translation>
    );
  }
}
