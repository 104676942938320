import React from "react";

const defaultVal = {
  cartItems: 0,
  checkoutDetails: {},
  additional_info: {},
  settingsInfo: {},
  globalPickUpdate: "",
  globalPickUptime: "",
  globaldeliverydate: "",
  globaldeliverytime: "",
  globalExpress: false,
  globalPickUpaddress: "",
  globaldeliveryaddress: "",
}; //Insert the default value here.
const MainContext = React.createContext(defaultVal);

export default MainContext;
