import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Translation } from "react-i18next";

import InfiniteScroll from "react-infinite-scroller";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

export default class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemdata: "",
      isloading: false,
      hasMoreItems: true,
      lang: "",
    };
  }

  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
  }

  dataRender = async (currentPage = 1) => {
    let path = ApiRoutes.GET_FAQ + "?page_no=" + `${currentPage}`;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          itemdata: [...this.state.itemdata, ...resJson.data.docs],
          hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  loadFunc = (currentPage) => {
    this.dataRender(currentPage);
  };

  render() {
    var { authUser } = this.props;
    const { itemdata, isloading, lang } = this.state;

    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <Container className="py-3 py-md-4">
              <h2 className=" section_title mb-4">{t("headings.Frequently Asked Questions")}</h2>

              <div className="laundries_list laundries_quest">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadFunc}
                  hasMore={this.state.hasMoreItems}
                  loader={
                    <div className="loader" key={0}>
                      Loading ...
                    </div>
                  }
                >
                  <Accordion>
                    {itemdata &&
                      itemdata.map((item, index) => {
                        return (
                          <AccordionItem key={index}>
                            <AccordionItemHeading>
                              <AccordionItemButton>{lang && item.question[lang]}</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>{lang && item.answer[lang]}</p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        );
                      })}
                  </Accordion>
                </InfiniteScroll>
              </div>
            </Container>
          </section>
        )}
      </Translation>
    );
  }
}
