import React, { Component } from "react";
import { Col, Container, Row, Modal, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import { Translation } from "react-i18next";

export default class PackageDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemId: props.match.params.itemId,
            itemData: [],
            isloading: false,
            lang: "",
            show: false,
            showRatingModal: false,
            ratingData: [],
            pack_title: "",
            expired: ""
        };
    }


    async componentDidMount() {
        this.setState({ lang: localStorage.getItem("lang") || "en" });
        this.getSubscribedPackage();
    }


    getSubscribedPackage = async () => {
        let path = ApiRoutes.GET_CURRENT_ACTIVE_PACKAGE + "/" + this.state.itemId;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                var item = resJson.data;
                var used_subCategory = [];
                if (item.all_subcategories) {
                    Object.entries(item.all_subcategories).map(([key, value]) => {
                        used_subCategory.push(value.key);
                    })
                }
                var expired = "";
                var main_category = resJson.data.new_main_categories;
                var sub_category = resJson.data.new_sub_categories;
                var excluded_subcategory = resJson.data.excluded_subcategory;
                var otherSubcategory = resJson.data.otherSubcategory;
                var vendor_name = item.vendor_details && item.vendor_details[0].shopname["en"];
                var lang = this.state.lang;
                var category = [];
                var subcategory = [];
                var exclude_category = [];
                var other_subcategory = [];
                main_category && main_category.map((item2, index2) => {
                    category.push(item2.name[lang]);
                })
                sub_category && sub_category.map((item1, index1) => {
                    subcategory.push(item1[lang]);
                })
                excluded_subcategory && excluded_subcategory.map((item3, index31) => {
                    // if (item3.label != undefined) {
                    //     exclude_category.push(item3.label);
                    // } else {
                    //     exclude_category.push(item3.key);
                    // }
                    exclude_category.push(item3[this.state.lang])
                })
                otherSubcategory && otherSubcategory.map((item4, index4) => {
                    other_subcategory.push(item4[lang]);
                });
                exclude_category = exclude_category.join(", ");
                category = category.join(" , ");
                subcategory = subcategory.join(" , ");
                other_subcategory = other_subcategory.join(", ");
                var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
                var pack_created_at = item.createdAt;
                var pack_buy_date = pack_created_at && pack_created_at.substring(0, 10);
                var expiry_date = item.expiry_date;
                var date_buyed = new Date(pack_buy_date);
                var dated_buyed_with_addition = date_buyed.setMonth(date_buyed.getMonth() + expiry_date);
                var date_expired = new Date(dated_buyed_with_addition);
                var package_expiry_day = moment(date_expired).format('YYYY-MM-DD[T00:00:00.000Z]');
                var PackageExpiry = package_expiry_day;
                if (this.state.lang == 'en') {
                    moment.locale('en');
                } else {
                    moment.locale('ar');
                }
                var expiry_day = moment(date_expired).format(" Do MMMM  YYYY ");
                var service_type = resJson.data.services_new && resJson.data.services_new[0].name[this.state.lang];

                var balCarryValidity = resJson.data.balanceCarry_validity;
                var balCarryDate = new Date(balCarryValidity);
                var bal_expiry_day = moment(balCarryDate).format(" Do MMMM YYYY ");
                var pack_title = resJson.data.package_title;
                if (item.balance_carry == 0) {
                    var bal_expiry_day1 = moment(balCarryDate).format('YYYY-MM-DD[T00:00:00.000Z]');
                    PackageExpiry = bal_expiry_day1;
                }
                if (current_date > PackageExpiry) {
                    if (this.state.lang == 'en') {
                        expired = 'Expired';
                    } else {
                        expired = 'منتهي الصلاحية';
                    }
                }
                this.setState({
                    itemData: resJson.data,
                    vendor_name: vendor_name,
                    category: category,
                    subcategory: subcategory,
                    service_type: service_type,
                    free_delivery: resJson.data.free_delivery,
                    balance_carry: resJson.data.balance_carry,
                    expiry_day: expiry_day,
                    bal_expiry_day: bal_expiry_day,
                    exclude_category: exclude_category,
                    otherSubcategory: other_subcategory,
                    lang: localStorage.getItem("lang") || "en",
                    pack_title: pack_title,
                    used_subCategory: used_subCategory,
                    expired: expired,
                });
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);

        }
    };



    render() {
        var { authUser } = this.props;
        var { lang, itemData, vendor_name, free_delivery, balance_carry, category, subcategory, service_type, expiry_day, pack_title, used_subCategory } = this.state;
        return (
            <>
                <Translation>
                    {(t) => (
                        <section className="vendor_list_detail_sec py-11 py-md-11" style={{ backgroundColor: "#F5FDFE" }}>
                            <h3 className="promotional-package-title">{pack_title && pack_title[lang]}{this.state.expired != "" ? <span>{" (" + this.state.expired + ") "}</span> : <></>}</h3>
                            <Container className="py-3 py-md-4">
                                <div className="promotions-package-view-package">
                                    <div className="promo-pack-details">
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_vendor")}</div>
                                            <div className="package-information">{vendor_name}</div>
                                        </div>
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_type")}</div>
                                            {itemData && itemData.add_promotion_type == 1 ? (
                                                <>
                                                    <div className="package-information">{t("package_pieces_count")}</div>
                                                </>
                                            ) : (
                                                <div className="package-information">{t("package_sub_category")}</div>
                                            )}
                                        </div>
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_price")}</div>
                                            <div className="package-information">{itemData.PackagePrice} {t("commons.OMR")}</div>
                                        </div>
                                        {itemData && itemData.add_promotion_type == 0 && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_total_count")}</div>
                                                <div className="package-information">{itemData.SubcatTotPcsCount} {t("promotion_pieces_count")}</div>
                                            </div>
                                        )}
                                        {itemData && itemData.add_promotion_type == 0 && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("minimum_subcategory_count")}</div>
                                                <div className="package-information">{itemData.customer_minimum_order_limit} {t("promotion_pieces_count")}</div>
                                            </div>
                                        )}
                                        {itemData && itemData.add_promotion_type == 1 && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_total_count")}</div>
                                                <div className="package-information">{itemData.SubcatTotPcsCount +  itemData.excluded_pieces} {t("promotion_pieces_count")}</div>
                                            </div>
                                        )}
                                        {itemData && itemData.free_delivery == 0 && itemData.customer_minimum_order_amount && itemData.customer_minimum_order_amount != "" && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("filters.Minimum_Order_Amount") + ": "}</div>
                                                <div className="package-information">{itemData.customer_minimum_order_amount} {t("commons.balanceomr")}</div>
                                            </div>
                                        )}
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_category")}</div>
                                            <div className="package-information">{category}</div>
                                        </div>
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_subcategory")}</div>

                                            <div className="package-information">{subcategory}</div>
                                        </div>

                                        {itemData && itemData.all_subcategories != null && (
                                            itemData.all_subcategories.map(function (value, key) {
                                                return (
                                                    <div className="promo-pack-flex">
                                                        <div className="package-name">{value.name[lang]}{(" :")}</div>
                                                        {value.UsedCount ? (
                                                            <>
                                                                <div className="package-information" style={{ width: "25%" }}>{value.value} {t("promotion_pieces_count")}</div>
                                                                <div className="usedCount">
                                                                    {"(" + t("package-used") + " " + value.UsedCount + " " + t("promotion_pieces_count") + ")"}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="package-information">{value.value} {t("promotion_pieces_count")}</div>
                                                        )

                                                        }
                                                    </div>
                                                );
                                            })
                                        )}
                                        {/* {itemData && itemData.individual_subCategories != null && used_subCategory.length > 0 && (
                                            itemData.individual_subCategories.map(function (value, key) {
                                                if (!used_subCategory.includes(value._id)) {
                                                    return (
                                                        <div className="promo-pack-flex">
                                                            <div className="package-name">{value.name[lang]}{(" :")}</div>
                                                            <div className="package-information">{value.value} {t("promotion_pieces_count")}</div>
                                                        </div>
                                                    );
                                                }
                                            })
                                        )} */}
                                        {itemData && itemData.all_subcategories == undefined && itemData.individual_subCategories != null && (
                                            itemData.individual_subCategories.map(function (value, key) {
                                                return (
                                                    <div className="promo-pack-flex">
                                                        <div className="package-name">{value.name[lang]}{(" :")}</div>
                                                        <div className="package-information">{value.value} {t("promotion_pieces_count")}</div>
                                                    </div>
                                                );
                                            })
                                        )}
                                        {itemData && itemData.add_promotion_type == 1 && itemData.excluded_pieces > 0 && itemData.RemainPieces ? (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_excluded_count")}</div>
                                                <div className="package-information" style={{ width: "25%" }}>{itemData.excluded_pieces} {t("promotion_pieces_count")}</div>
                                                <div className="usedCount">
                                                    {"(" + t("package-used") + " " + itemData.RemainPieces + " " + t("promotion_pieces_count") + ")"}
                                                </div>
                                            </div>
                                        ) : itemData && itemData.add_promotion_type == 1 && itemData.excluded_pieces > 0 ? (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_excluded_count")}</div>
                                                <div className="package-information">{itemData.excluded_pieces} {t("promotion_pieces_count")}</div>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                        }
                                        {itemData && itemData.add_promotion_type == 1 && itemData.otherSubcategory != undefined && itemData.otherSubcategory.length > 0 && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_included_subcategory") + ": "}</div>
                                                <div className="package-information">{this.state.otherSubcategory}</div>
                                            </div>
                                        )}
                                        {itemData && itemData.add_promotion_type == 1 && itemData.excluded_subcategory.length > 0 && (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_excluded_subcategory")}</div>
                                                <div className="package-information">{this.state.exclude_category}</div>
                                            </div>
                                        )}
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_service_type")}</div>
                                            <div className="package-information">{service_type}</div>
                                        </div>
                                        {free_delivery == 0 ? (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_free_delivery")}</div>
                                                <div className="package-information">{t("free_delivery")}</div>
                                            </div>
                                        ) : (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_free_delivery")}</div>
                                                <div className="package-information">{t("no_free_delivery")}</div>
                                            </div>
                                        )}
                                        {balance_carry == 1 ? (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_balance_carry")}</div>
                                                <div className="package-information">{t("no_balance_carry")}</div>
                                            </div>
                                        ) : (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("promotion_package_balance_carry")}</div>
                                                <div className="package-information">{t("balance_carry")}</div>
                                            </div>

                                        )}
                                        {balance_carry == 0 ? (
                                            <div className="promo-pack-flex">
                                                <div className="package-name">{t("balance_carry_validity")}</div>
                                                <div className="package-information">{this.state.bal_expiry_day}</div>
                                            </div>

                                        ) : (
                                            <></>
                                        )}
                                        <div className="promo-pack-flex">
                                            <div className="package-name">{t("promotion_package_expiry_date")}</div>
                                            <div className="package-information">{expiry_day}</div>
                                        </div>
                                    </div>

                                </div>
                            </Container>

                        </section>
                    )}
                </Translation>
            </>
        );
    }
}