import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import DashboardHeader from "./partials/DashboardHeader";
import Sidebar from "./partials/Sidebar";
import Footer from "./partials/Footer";

const DashboardLayout = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <>
            <Sidebar {...props} authUser={authUser} />
            <div className="main">
              <DashboardHeader authUser={authUser} />
              <Component {...props} authUser={authUser} />
              <Footer />
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default DashboardLayout;
