import React, { Component } from "react";
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import StyledButtonSpan from "../../helpers/StyledButtonSpan";
import { NotificationManager } from "react-notifications";
import { Translation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

import Slider from "react-slick";
var sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  centerPadding: "30",
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1000,

      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

export default class ServiceList extends Component {
  static contextType = ThemeContext; 
  constructor(props) {
    super(props);
    this.state = {
      itemId: props.match.params.itemId,
      vendorId: props.match.params.vendor_id,
      itemData: [],
      responseData: [],
      includedArray: [],
      excludedArray: null,
      isloading: false,
      categoriesdata: [],
      category_id: "",
      customer_id: "",

      lang: "",
      currentPage: 1,
      limit: 10,
      hasMoreItems: true,

      packageTitle: [],
      corporatepackageTitle: [],

      mostFrequent: "false",
      showMostFrequent: "false",
      packageService: 'false',
      corporatepackageService: 'false',
      packageId: "",
      corporatepackageId: "",
      firstPackageId: "",
      corporatefirstPackageId: "",
      min_order_amount: '0',
      showCommentBox: false,
      showCommentBoxTab: false,
      overallPiecesCount: '0',
      excludedSubCategory: null,
      includesTotalPieces: '0',
      includesOtherPieces: '0',
      packageInfoTab: [],
      TotalPackageCount: 0,
      deliveryDistance: "",
      pickUpDistance: "",
      no_free_delivery: [],
    };
  }

  componentDidMount() {

    this.setState({ lang: localStorage.getItem("lang") || "en" });

    this.getCategoriesData();
    this.getPackagesName();
    this.freeDeliveryCalculation();
    this.getCorporatePackagesName();
  }

  freeDeliveryCalculation = async () => {


    let path = ApiRoutes.GET_FREEDELIVERY_CALCUTION
    var customer_id = this.props.authUser._id;
    let formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("vendor_id", this.state.itemId);
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      console.log(resJson.data.length)
      resJson.data && this.setState({
        no_free_delivery: resJson.data
      })
    }
  }

  loadPagination = async (currentPage) => {
    this.getServicesData(currentPage);
  };

  getServicesData = async (currentPage) => {

    let path =
      ApiRoutes.GET_SERVICES +
      "?vendor_id=" +
      `${this.state.itemId}` +
      `${this.state.category_id ? "&category_id=" + `${this.state.category_id}` : ""}` +
      "&limit=" +
      `${this.state.limit}` +
      "&page_no=" +
      `${currentPage}` + "&most_frequent=" + `${this.state.mostFrequent}`
      + "&package_service=" + `${this.state.packageService}` + "&corporate_package_service=" + `${this.state.corporatepackageService}` + "&package_id=" +
      `${this.state.packageId == '' ? this.state.firstPackageId : this.state.packageId}` + "&coporate_package_id=" +
      `${this.state.corporatepackageId == '' ? this.state.corporatefirstPackageId : this.state.corporatepackageId}` + "&lang=" + `${this.state.lang}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();


      if (resJson.data && resJson.data.docs && resJson.data.docs[0] && resJson.data.docs[0].customer_minimum_order_amount) {
        this.setState({
          min_order_amount: resJson.data.docs[0].customer_minimum_order_amount
        })
      }

      if (resJson.data && resJson.data.overallPiecesCount) {
        this.setState({
          overallPiecesCount: resJson.data.overallPiecesCount
        })
      }
      if (resJson.data && resJson.data.excludedSubCategory && resJson.data.excludedSubCategory.length > 0) {
        var splitvalue;
        // if(resJson.data && resJson.data.excludedSubCategory.length > 1){
        //     splitvalue = resJson.data && resJson.data.excludedSubCategory.split(',').replace(/,/g, ', ')
        // }else{
        splitvalue = resJson.data && resJson.data.excludedSubCategory
        // }
        var newExcludePackage = []
        await Promise.all(splitvalue.map((val) => {
          newExcludePackage.push(val[this.state.lang])
        }))
        this.setState({
          excludedSubCategory: newExcludePackage
        })
      }

      if (res.status == 200) {

        if (currentPage == 1) {

          this.setState({ itemData: [], responseData: [] }, () =>
            this.setState({
              itemData: [...this.state.itemData, ...resJson.data.docs],
              responseData: [...this.state.responseData, ...resJson.data.docs],
              hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
            })
          );
        } else {

          this.setState({
            itemData: [...this.state.itemData, ...resJson.data.docs],
            responseData: [...this.state.responseData, ...resJson.data.docs],
            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          });
        }

        var includeArr = [];
        var excludeArr = [];
        var includesTotalPieces = 0;
        var includesOtherPieces = 0;
        if (this.state.itemData && this.state.itemData.length) {
          this.state.itemData.map((item) => {
            if (item.TotalPieces > 0) {
              includesTotalPieces += item.TotalPieces
              includeArr.push({ "subcategory": item.subcategory.name[this.state.lang], "totalPieces": item.TotalPieces })
            }
            if (item.OtherPieces > 0) {
              includesOtherPieces = item.OtherPieces
              excludeArr.push(item.subcategory.name[this.state.lang])
            }
          })
        }

        var splitexcludevalue;
        if (excludeArr && excludeArr.length > 1) {
          var excArr = excludeArr && excludeArr.toString()
          splitexcludevalue = excArr.replace(/,/g, ', ');
        } else {
          splitexcludevalue = excludeArr
        }

        this.setState({
          includedArray: includeArr,
          excludedArray: splitexcludevalue,
          includesTotalPieces: includesTotalPieces,
          includesOtherPieces: includesOtherPieces
        })

      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }


  };

  getCategoriesData = async () => {
    let path = ApiRoutes.GET_CATEGORIES + "?vendor_id=" + `${this.state.itemId}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          categoriesdata: resJson.data.docs,
          showMostFrequent: resJson.data.orders,
          mostFrequent: resJson.data.orders,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  getPackagesName = async () => {

    let path =
      ApiRoutes.GET_PACKAGE_NAME +
      "?vendor_id=" +
      `${this.state.itemId}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          packageTitle: [...this.state.packageTitle, ...resJson.data],
          packageId: resJson.data && resJson.data.length > 0 && resJson.data[0].vendor_package_id ? resJson.data[0].vendor_package_id : "",
          firstPackageId: resJson.data && resJson.data.length > 0 && resJson.data[0].vendor_package_id ? resJson.data[0].vendor_package_id : ""
        });
      }
    }
  }

  //for getting the corporate package name
  getCorporatePackagesName = async () => {

    let path =
      ApiRoutes.GET_CORPORATE_PACKAGE_NAME +
      "?vendor_id=" +
      `${this.state.itemId}`;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          corporatepackageTitle: [...this.state.corporatepackageTitle, ...resJson.data],
          corporatepackageId: resJson.data && resJson.data.length > 0 && resJson.data[0].package_id ? resJson.data[0].package_id : "",
          corporatefirstPackageId: resJson.data && resJson.data.length > 0 && resJson.data[0].package_id ? resJson.data[0].package_id : ""
        });
      }
    }
  }


  commentBoxToggle = () => {
    this.setState((prevState) => ({
      showCommentBox: !prevState.showCommentBox,
    }));
  };


  commentBoxToggleTab = () => {
    this.setState((prevState) => ({
      showCommentBoxTab: !prevState.showCommentBoxTab,
    }));
  };

  // getPackageInfoTab = (itemId) => {
  //   var PromotionType ;
  //   var PackageName ;
  //   var PackageCount ;
  //   var TotalPackageCount = 0 ;

  //   var PackageInfoTab = [];
  //     let filterInfoTab = this.state.itemData.filter((item) => item._id == itemId )

  //     filterInfoTab[0].Packages.map((test) => {
  //       PackageName = test.PackageName[this.state.lang]
  //       PackageCount = test.Count
  //       TotalPackageCount += test.Count
  //       if(test.PackageType == 1 ){
  //         PromotionType = 'Pieces Count'
  //       }else{
  //         PromotionType = 'Subcategory'
  //       }
  //       PackageInfoTab.push({"PackageName" : PackageName, "PackageCount":PackageCount, "PackageType" :  PromotionType, "PackageColor": })
  //     })

  //     this.setState({
  //       packageInfoTab : PackageInfoTab,
  //       TotalPackageCount : TotalPackageCount
  //     })
  //     this.commentBoxToggleTab()

  // }

  render() {
    var { authUser } = this.props;
    const theme = this.context; 
    var { itemData, isloading, categoriesdata, lang, includedArray,
      excludedArray, excludedSubCategory, includesTotalPieces, includesOtherPieces, packageInfoTab, TotalPackageCount } = this.state;
    const { state } = this.props.location;
    const loader = <div className="loading"></div>;
    return (
      <Translation>
        {(t) => (
          <div>
            <style>
              {
                '\
          .modal-dialog  {\
           max-width : fit-content !important;\
           width : 100% !important;\
        }'
              }
              {
                '\
          .modal_partition_section  {\
           width : 460px !important;\
        }'
              }
              {
                '\
            @media (min-width: 576px) \
              .modal-dialog  {\
              max-width : 70% !important;\
          }'
              }
            </style>
            <section className="vendor_list_detail_sec py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
              <Container className="pt-md-5">
                <h2 className="section_title">
                  {state && state.shop && state.shop ? state.shop : this.props.history.push("/vendors")} - {t("headings.Services")}
                </h2>
                <p className="branch_title mb-2 ml-0">
                  <b>{state && state.branch && state.branch && state.branch}</b>
                </p>

                <Row>
                  {" "}
                  <ul className="daycheck_type_list_sec mb-4 ml-3 ">
                    {
                      this.state.showMostFrequent == "true" && (
                        <li>
                          <input
                            type="radio"
                            name="estimate"
                            defaultChecked="true"
                            value={""}
                            onChange={(event) => {
                              this.scroll.pageLoaded = 0;
                              this.setState(
                                {
                                  mostFrequent: "true",
                                  packageService: "false",
                                  corporatepackageService: "false",
                                  itemData: [],
                                  packageId: "",
                                  min_order_amount: '0'
                                },
                                () => this.getServicesData(1)
                              );
                            }}
                          />
                          <div className="daycheck_type_list">{t("commons.Most_Frequent_Orders")}</div>
                        </li>
                      )}


                    {
                      categoriesdata && categoriesdata.length > 0 && this.state.packageTitle.length > 0 &&
                      <li>
                        <input
                          type="radio"
                          name="estimate"
                          value={""}
                          onChange={(event) => {
                            // this.scroll.pageLoaded = 0;
                            this.setState(
                              {
                                packageService: "true",
                                mostFrequent: "false",
                                corporatepackageService: "false",
                                itemData: [],
                                min_order_amount: '0',
                              },
                              () => this.getServicesData(1)
                            );
                          }}
                        />
                        <div className="daycheck_type_list">{t("commons.Package Services")}</div>
                      </li>
                    }


                    {/* for setting the corporate package type */}
                    {
                      categoriesdata && categoriesdata.length > 0 && this.state.corporatepackageTitle.length > 0 &&
                      <li>
                        <input
                          type="radio"
                          name="estimate"
                          value={""}
                          onChange={(event) => {
                            // this.scroll.pageLoaded = 0;
                            this.setState(
                              {
                                corporatepackageService: "true",
                                packageService: "false",
                                mostFrequent: "false",
                                itemData: [],
                                min_order_amount: '0',
                              },
                              () => this.getServicesData(1)
                            );
                          }}
                        />
                        <div className="daycheck_type_list">{t("commons.Corporate Package Services")}</div>
                      </li>
                    }



                    {categoriesdata && categoriesdata.length > 0 && this.state.showMostFrequent == 'true' ?
                      <li>
                        <input
                          type="radio"
                          name="estimate"
                          value={""}
                          onChange={(event) => {
                            this.scroll.pageLoaded = 0;
                            this.setState(
                              {
                                category_id: event.target.value,
                                itemData: [],
                                mostFrequent: "false",
                                packageService: "false",
                                corporatepackageService: "false",
                                min_order_amount: '0',
                                packageId: "",
                              },
                              () => this.getServicesData(1)
                            );
                          }}
                        />
                        <div className="daycheck_type_list">{t("commons.All")}</div>
                      </li>
                      : categoriesdata && categoriesdata.length > 0 && this.state.showMostFrequent == 'false' ?
                        <li>
                          <input
                            type="radio"
                            name="estimate"
                            value={""}
                            defaultChecked="true"
                            onChange={(event) => {
                              this.scroll.pageLoaded = 0;
                              this.setState(
                                {
                                  category_id: event.target.value,
                                  itemData: [],
                                  mostFrequent: "false",
                                  packageService: "false",
                                  corporatepackageService: "false",
                                  packageId: "",
                                  min_order_amount: '0'
                                },
                                () => this.getServicesData(1)
                              );
                            }}
                          />
                          <div className="daycheck_type_list">{t("commons.All")}</div>
                        </li>
                        :
                        <></>
                    }

                    {categoriesdata &&
                      categoriesdata.map((item, index) => {
                        return (
                          <li key={index}>
                            <input
                              type="radio"
                              name="estimate"
                              value={item._id}
                              onChange={(event) => {
                                this.scroll.pageLoaded = 0;
                                let cate = event.target.value;
                                this.setState(
                                  {
                                    category_id: cate,
                                    itemData: [],
                                    mostFrequent: "false",
                                    packageService: "false",
                                    corporatepackageService: "false",
                                    min_order_amount: '0',
                                    packageId: "",
                                  },
                                  () => {
                                    this.getServicesData(1);
                                  }
                                );
                              }}
                            />
                            <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                          </li>
                        );
                      })}
                  </ul>
                </Row>
                {""}
                {this.state.no_free_delivery.length != 0 ? (
                  <>
                    <p className="margin">{this.state.no_free_delivery}</p>
                  </>
                ) : (null)}
                {this.state.categoriesdata && this.state.categoriesdata.length > 0 && (
                  <InfiniteScroll
                    ref={(scroll) => {
                      this.scroll = scroll;
                    }}
                    pageStart={0}
                    loadMore={this.getServicesData.bind(this)}
                    hasMore={this.state.hasMoreItems}
                    loader={loader}
                  >
                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                      <Row>
                        {
                          this.state.packageService == 'true' && (
                            <>
                              <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                  <div>
                                    <select
                                      onChange={(event) => {
                                        this.setState(
                                          {
                                            packageId: event.target.value,
                                            itemData: [],
                                            mostFrequent: "false",
                                            packageService: "true",
                                            min_order_amount: '0',
                                            excludedSubCategory: null
                                          },
                                          () => this.getServicesData(1)
                                        );
                                      }} style={{ padding: '10px', marginBottom: '20px', marginRight: lang == 'en' ? '0px' : '14px', marginLeft: lang == 'en' ? '34px' : '0px', border: '1px solid #808080a3', borderRadius: '5px' }}

                                    >
                                      {/* <option value="">Choose Package</option> */}
                                      {
                                        this.state.packageTitle && this.state.packageTitle.map((item) => {
                                          return (
                                            <option
                                              value={item.vendor_package_id}>{lang && item.package_title[lang]}</option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                  <div style={{ marginTop: '4px' }}>
                                    <button style={{
                                      border: 'none', background: '#fc9803', borderRadius
                                        : '6px', height: '35px', padding: '0px 10px'
                                    }} onClick={() => this.commentBoxToggle()}>
                                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p style={{ margin: 0, color: 'white' }}>{t("commons.Package Info")} </p>
                                        <i style={{ color: 'white' }} className="fa fa-info-circle" />
                                      </div>
                                    </button>
                                  </div>
                                  <div>
                                    {
                                      this.state.min_order_amount != '0' && this.state.packageId != '' &&
                                      (
                                        <div style={{
                                          marginLeft: '0px', marginTop: '10px', fontSize: '15px',
                                          color: 'green', width: '100%'
                                        }} className="package-name">{t("filters.Minimum_Order_Amount")}{this.state.min_order_amount} {t("commons.balanceomr")}</div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }


                        {/* for getting the corporate package service listing page */}
                        {
                          this.state.corporatepackageService == 'true' && (
                            <>
                              <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                  <div>
                                    <select
                                      onChange={(event) => {
                                        this.setState(
                                          {
                                            corporatepackageId: event.target.value,
                                            itemData: [],
                                            mostFrequent: "false",
                                            corporatepackageService: "true",
                                            min_order_amount: '0',
                                            excludedSubCategory: null
                                          },
                                          () => this.getServicesData(1)
                                        );
                                      }} style={{ padding: '10px', marginBottom: '20px', marginRight: lang == 'en' ? '0px' : '14px', marginLeft: lang == 'en' ? '34px' : '0px', border: '1px solid #808080a3', borderRadius: '5px' }}

                                    >
                                      {/* <option value="">Choose Package</option> */}
                                      {
                                        this.state.corporatepackageTitle && this.state.corporatepackageTitle.map((item) => {
                                          return (
                                            <option
                                              value={item.package_id}>{lang && item.package_title[lang]}</option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                  <div style={{ marginTop: '4px' }}>
                                    <button style={{
                                      border: 'none', background: '#fc9803', borderRadius
                                        : '6px', height: '35px', padding: '0px 10px'
                                    }} onClick={() => this.commentBoxToggle()}>
                                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <p style={{ margin: 0, color: 'white' }}>{t("commons.Package Info")} </p>
                                        <i style={{ color: 'white' }} className="fa fa-info-circle" />
                                      </div>
                                    </button>
                                  </div>
                                  <div>
                                    {
                                      this.state.min_order_amount != '0' && this.state.packageId != '' &&
                                      (
                                        <div style={{
                                          marginLeft: '0px', marginTop: '10px', fontSize: '15px',
                                          color: 'green', width: '100%'
                                        }} className="package-name">{t("filters.Minimum_Order_Amount")}{this.state.min_order_amount} {t("commons.balanceomr")}</div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }


                      </Row>

                      <Row className="custom_service_list_cat">
                        {itemData.map((item, index) => {
                          return (
                            <Col md={5} key={index}>
                              <div className="catgoryproductouter">
                                <div>
                                  <div className="d-flex">
                                    <div className="productimgleft">
                                      <Link
                                        to={{
                                          pathname: "/service/" + item._id + "/" + this.state.itemId,
                                        }}
                                      >
                                        {" "}
                                        <img src={item.subcategory.image} alt={lang && item.subcategory.name[lang]}></img>
                                      </Link>
                                    </div>
                                    <div className="productdetails">
                                      <div className="d-flex justify-content-between p-2">
                                        <div>
                                          <div className="MangageList-Package">
                                            <div className="ManageList-section">
                                              <Link
                                                to={{
                                                  pathname: "/service/" + item._id + "/" + this.state.itemId,
                                                }}
                                              >
                                                <h4>{lang && item.subcategory.name[lang]}</h4>
                                              </Link>
                                              <p>
                                                {t("labels.Category")} : <span className="themeColor" style={{ color: theme.colors.primary }}>{lang && item.category.name[lang]}</span>{" "}
                                              </p>
                                              {item.delivery_method != 'normal' && item.delivery_time && (
                                                <p>
                                                  {t("labels.Delivery_time")}:{" "}
                                                  {lang &&
                                                    <span className="themeColor" style={{ color: theme.colors.primary }}>
                                                      {t(item.delivery_time) + " " + t('commons.Days')}
                                                    </span>
                                                  }
                                                </p>
                                              )}
                                              {item.delivery_method != 'normal' && item.express_delivery_time && (
                                                <p>
                                                  {t("labels.Express_Delivery_time")}:{" "}
                                                  {lang &&

                                                    item.delivery_method == 'medium' ? (<span className="themeColor" style={{ color: theme.colors.primary }}>

                                                      {t(item.express_delivery_time) + " " + t('commons.Hours')}
                                                    </span>) : (<span className="themeColor" style={{ color: theme.colors.primary }}>

                                                      {t(item.express_delivery_time) + " " + t('commons.Days')}
                                                    </span>)


                                                  }
                                                </p>
                                              )}
                                            </div>
                                            {/* overall promotional package and corporate package div */}
                                            <div className="packages-service-sec">

                                              {
                                                this.state.packageService == 'false' &&
                                                <>
                                                  {
                                                    this.state.lang == 'en' ?
                                                      <>
                                                        {
                                                          item.PackageName && item.PackageName.length > 1 &&
                                                          <div className="Package-Service-List" style={{
                                                            right: '4px', display: 'flex',
                                                            flexDirection: 'column', justifyContent: 'end', alignItems: 'start',
                                                            fontWeight: '600',
                                                            top: item.corporatePackageName && item.corporatePackageName.length > 0 ? '53px' : '0px'
                                                          }}>
                                                            <p style={{
                                                              fontWeight: '600'
                                                            }}>{t("Multiple Package")}</p>
                                                            {item.OtherPieces > 0 ?
                                                              <p style={{
                                                                fontWeight: '600', color: 'green'
                                                              }}>
                                                                {t("OtherPieces")} {item.OtherPieces}
                                                                {" "}{t("Pieces")}
                                                              </p>
                                                              :
                                                              <>
                                                                {
                                                                  item.TotalPieces > 0 ?
                                                                    <p style={{
                                                                      fontWeight: '600', color: 'green'
                                                                    }}>
                                                                      {t("TotalPieces")} {item.TotalPieces}
                                                                      {" "}{t("Pieces")}
                                                                    </p> : ""
                                                                }
                                                              </>
                                                            }
                                                          </div>
                                                        }
                                                        {
                                                          item.PackageName && item.PackageName.length == 1 &&
                                                          <>
                                                            <div className="Package-Service-List" style={{
                                                              right: '4px', display: 'flex',
                                                              flexDirection: 'column', justifyContent: 'end', alignItems: 'start',
                                                              fontWeight: '600',
                                                              top: item.corporatePackageName && item.corporatePackageName.length > 0 ? '53px' : '0px'
                                                            }}>
                                                              {(item.OtherPieces > 0 || item.TotalPieces > 0) && (
                                                                <p style={{
                                                                  color: item.PackageName[0].color,
                                                                  fontWeight: '600'
                                                                }}>{item.PackageName[0].PackageName[lang]}</p>
                                                              )}


                                                              {item.OtherPieces > 0 ?
                                                                <p style={{
                                                                  fontWeight: '600', color: 'green'
                                                                }}>
                                                                  {t("OtherPieces")} {item.OtherPieces}
                                                                  {" "}{t("Pieces")}
                                                                </p>
                                                                :
                                                                <>
                                                                  {
                                                                    item.TotalPieces > 0 ?
                                                                      <p style={{
                                                                        fontWeight: '600', color: 'green'
                                                                      }}>
                                                                        {t("TotalPieces")} {item.TotalPieces}
                                                                        {" "}{t("Pieces")}
                                                                      </p> : ""
                                                                  }
                                                                </>
                                                              }
                                                            </div>
                                                          </>

                                                        }
                                                        {
                                                          item.PackageName && item.PackageName.length == 0 &&
                                                          <p></p>
                                                        }
                                                      </> :

                                                      <>
                                                        {
                                                          item.Packages && item.Packages.length > 1 &&
                                                          <div className="Package-Service-List" style={{
                                                            right: '4px', display: 'flex',
                                                            flexDirection: 'column', justifyContent: 'end', alignItems: 'start',
                                                            fontWeight: '600',
                                                            top: item.corporatePackageName && item.corporatePackageName.length > 0 ? '53px' : '0px'
                                                          }}>
                                                            <p style={{
                                                              fontWeight: '600'
                                                            }}>{t("Multiple Package")}</p>
                                                            {item.OtherPieces > 0 ?
                                                              <p style={{
                                                                fontWeight: '600', color: 'green'
                                                              }}>
                                                                {t("OtherPieces")} {item.OtherPieces}
                                                                {" "}{t("Pieces")}
                                                              </p>
                                                              :
                                                              <>
                                                                {
                                                                  item.TotalPieces > 0 ?
                                                                    <p style={{
                                                                      fontWeight: '600', color: 'green'
                                                                    }}>
                                                                      {t("TotalPieces")} {item.TotalPieces}
                                                                      {" "}{t("Pieces")}
                                                                    </p> : ""
                                                                }
                                                              </>


                                                            }
                                                          </div>
                                                        }
                                                        {
                                                          item.Packages && item.Packages.length == 1 &&
                                                          <>
                                                            <div className="Package-Service-List" style={{
                                                              right: '4px', display: 'flex',
                                                              flexDirection: 'column', justifyContent: 'end', alignItems: 'start',
                                                              fontWeight: '600',
                                                              top: item.corporatePackageName && item.corporatePackageName.length > 0 ? '53px' : '0px'
                                                            }}>
                                                              <p style={{
                                                                color: item.Packages[0].PackageColor,
                                                                fontWeight: '600'
                                                              }}>{item.Packages[0].PackageName[lang]}</p>

                                                              {item.OtherPieces && item.OtherPieces > 0 ?
                                                                <p style={{
                                                                  fontWeight: '600', color: 'green'
                                                                }}>
                                                                  {t("OtherPieces")} {item.OtherPieces}
                                                                  {" "}{t("Pieces")}
                                                                </p>
                                                                :
                                                                <>
                                                                  {
                                                                    item.TotalPieces > 0 ?
                                                                      <p style={{
                                                                        fontWeight: '600', color: 'green'
                                                                      }}>
                                                                        {t("TotalPieces")} {item.TotalPieces}
                                                                        {" "}{t("Pieces")}
                                                                      </p> : ""
                                                                  }
                                                                </>
                                                              }
                                                            </div>

                                                          </>
                                                        }
                                                        {
                                                          item.Packages && item.Packages.length == 0 &&
                                                          <p></p>
                                                        }
                                                      </>

                                                  }
                                                  <>
                                                  </>
                                                </>
                                              }


                                              {
                                                this.state.corporatepackageService == 'false' &&
                                                <>
                                                  {
                                                    this.state.lang == 'en' ?
                                                      <>
                                                        {
                                                          item.corporatePackageName && item.corporatePackageName.length > 1 &&
                                                          <div className="Corporte-Package-Service-List" style={{
                                                            right: '0px', display: 'flex',
                                                            flexDirection: 'column', justifyContent: 'end', alignItems: 'start',
                                                            fontWeight: '600'
                                                          }}>
                                                            <p style={{
                                                              fontWeight: '600'
                                                            }}>{t("Corporate Multiple Package")}</p>
                                                            {item.corporateOtherPieces > 0 ?
                                                              <p style={{
                                                                fontWeight: '600', color: 'green'
                                                              }}>
                                                                {t("CorporateOtherPieces")} {item.corporateOtherPieces}
                                                                {" "}{t("Pieces")}
                                                              </p>
                                                              :
                                                              <>
                                                                {
                                                                  item.corporateTotalPieces > 0 ?
                                                                    <p style={{
                                                                      fontWeight: '600', color: 'green'
                                                                    }}>
                                                                      {t("CorporteTotalPieces")} {item.corporateTotalPieces}
                                                                      {" "}{t("Pieces")}
                                                                    </p> : ""
                                                                }
                                                              </>
                                                            }
                                                          </div>
                                                        }
                                                        {
                                                          item.corporatePackageName && item.corporatePackageName.length == 1 &&
                                                          <>
                                                            <div className="Package-Service-List" style={{
                                                              right: '0px', display: 'flex',
                                                              flexDirection: 'column', justifyContent: 'end', alignItems: 'start'
                                                            }}>
                                                              {(item.corporateOtherPieces > 0 || item.corporateTotalPieces > 0) && (
                                                                <p style={{
                                                                  color: item.corporatePackageName[0].color,
                                                                  fontWeight: '600'
                                                                }}>{item.corporatePackageName[0].PackageName[lang]}</p>
                                                              )}


                                                              {item.corporateOtherPieces > 0 ?
                                                                <p style={{
                                                                  fontWeight: '600', color: 'green'
                                                                }}>
                                                                  {t("CorporateOtherPieces")} {item.corporateOtherPieces}
                                                                  {" "}{t("Pieces")}
                                                                </p>
                                                                :
                                                                <>
                                                                  {
                                                                    item.corporateTotalPieces > 0 ?
                                                                      <p style={{
                                                                        fontWeight: '600', color: 'green'
                                                                      }}>
                                                                        {t("CorporteTotalPieces")} {item.corporateTotalPieces}
                                                                        {" "}{t("Pieces")}
                                                                      </p> : ""
                                                                  }
                                                                </>
                                                              }
                                                            </div>
                                                          </>

                                                        }
                                                        {
                                                          item.corporatePackageName && item.corporatePackageName.length == 0 &&
                                                          <p></p>
                                                        }
                                                      </> :

                                                      <>
                                                        {
                                                          item.corporatePackageName && item.corporatePackageName.length > 1 &&
                                                          <div className="Package-Service-List" style={{
                                                            left: '0px', display: 'flex',
                                                            flexDirection: 'column', justifyContent: 'end', alignItems: 'end',
                                                            fontWeight: '600'
                                                          }}>
                                                            <p style={{
                                                              fontWeight: '600'
                                                            }}>{t("Multiple Package")}</p>
                                                            {item.corporateOtherPieces > 0 ?
                                                              <p style={{
                                                                fontWeight: '600', color: 'green'
                                                              }}>
                                                                {t("CorporateOtherPieces")} {item.corporateOtherPieces}
                                                                {" "}{t("Pieces")}
                                                              </p>
                                                              :
                                                              <>
                                                                {
                                                                  item.corporateTotalPieces > 0 ?
                                                                    <p style={{
                                                                      fontWeight: '600', color: 'green'
                                                                    }}>
                                                                      {t("CorporteTotalPieces")} {item.corporateTotalPieces}
                                                                      {" "}{t("Pieces")}
                                                                    </p> : ""
                                                                }
                                                              </>


                                                            }
                                                          </div>
                                                        }
                                                        {
                                                          item.corporatePackageName && item.corporatePackageName.length == 1 &&
                                                          <>
                                                            <div className="Package-Service-List" style={{
                                                              left: '0px', display: 'flex',
                                                              flexDirection: 'column', justifyContent: 'end', alignItems: 'end'
                                                            }}>
                                                              <p style={{
                                                                color: item.corporatePackageName[0].PackageColor,
                                                                fontWeight: '600'
                                                              }}>{item.corporatePackageName[0].PackageName[lang]}</p>

                                                              {item.corporateOtherPieces && item.corporateOtherPieces > 0 ?
                                                                <p style={{
                                                                  fontWeight: '600', color: 'green'
                                                                }}>
                                                                  {t("CorporateOtherPieces")} {item.corporateOtherPieces}
                                                                  {" "}{t("Pieces")}
                                                                </p>
                                                                :
                                                                <>
                                                                  {
                                                                    item.corporateTotalPieces > 0 ?
                                                                      <p style={{
                                                                        fontWeight: '600', color: 'green'
                                                                      }}>
                                                                        {t("CorporteTotalPieces")} {item.corporateTotalPieces}
                                                                        {" "}{t("Pieces")}
                                                                      </p> : ""
                                                                  }
                                                                </>
                                                              }
                                                            </div>

                                                          </>
                                                        }
                                                        {
                                                          item.corporatePackageName && item.corporatePackageName.length == 0 &&
                                                          <p></p>
                                                        }
                                                      </>

                                                  }
                                                </>

                                              }



                                            </div>
                                            {/* for corporate package total pieces and other pieces */}
                                            {/* overall promotional package and corporate package div */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* {(item.OtherPieces > 0 || item.TotalPieces > 0) && (
                                      <div className="packageFreedelivery">
                                        {item.customer_minimum_order_amount > 0 ?
                                          <>
                                            <p>{t("Min. amount for free delivery") + ": " + item.customer_minimum_order_amount + " " + t("contents.OMR")}</p>
                                          </>
                                          :
                                          <></>
                                        }
                                      </div>
                                    )} */}
                                {/* <div className="packageFreedelivery">
                                {item.customer_minimum_order_amount > 0 ?
                                  <>
                                    <p>{t("Min. amount for free delivery") + ": " + item.customer_minimum_order_amount + " " + t("contents.OMR")}</p>
                                  </>
                                  :
                                  <></>
                                }
                              </div> */}
                                <div className="row mt-3">
                                  <div className="col-md-12">
                                    <ul>
                                      <Slider {...sliderSettings}>
                                        {item.service_items.map((item, index) => {
                                          return (
                                            <div key={index}>
                                              <div className="dryclean">
                                                <h5>{lang && item.service_type.name[lang]}</h5>
                                                {item && item.is_discount == 'available' && (
                                                  lang == 'en' ?
                                                    <span className={"discount_subcategory " + lang}>{item && item.discount_type == 'percent' ? item.discount_amount + "% " + t("contents.Discount") : t("contents.OMR") + item.discount_amount + " " + t("contents.Discount")}</span> :
                                                    <span className={"discount_subcategory " + lang}>{item && item.discount_type == 'percent' ? t("contents.Discount") + item.discount_amount + " % " : t("contents.Discount") + item.discount_amount + " " + t("contents.OMR")}</span>
                                                )}
                                                {item.price_by_meter != 0 && item.price_by_meter != null && (
                                                  <p className="d-flex justify-content-between">
                                                    {t("labels.Price_by_Meter")}
                                                    <span style={{ color: theme.colors.primary }}>{t("commons.OMR") + " " + item.price_by_meter.toFixed(3)}</span>
                                                  </p>
                                                )}

                                                {item.price_by_piece != 0 && item.price_by_piece != null && (
                                                  <p className="d-flex justify-content-between">
                                                    {t("labels.Price_by_Piece")}
                                                    <span style={{ color: theme.colors.primary }}> {t("commons.OMR") + " " + item.price_by_piece.toFixed(3)}</span>
                                                  </p>
                                                )}
                                                {item.price_by_weight != 0 && item.price_by_weight != null && (
                                                  <p className="d-flex justify-content-between">
                                                    {t("labels.Price_by_Weight")}
                                                    <span style={{ color: theme.colors.primary }}> {t("commons.OMR") + " " + item.price_by_weight.toFixed(3)}</span>
                                                  </p>
                                                )}

                                              </div>
                                            </div>
                                          );
                                        })}
                                      </Slider>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })}




                      </Row>


                      <Col md={12} className="text-center">
                        {itemData.length === 0 && (
                          <center>
                            <h6>{t("commons.No_available_services")}</h6>{" "}
                          </center>
                        )}
                      </Col>


                    </Row>
                  </InfiniteScroll>
                )}
              </Container>
            </section>
            <Modal isOpen={this.state.showCommentBox} toggle={this.commentBoxToggle}>
              <ModalHeader style={{
                background: '#5c95eb',
                color: 'white'
              }} className="modal-manual-header" toggle={this.commentBoxToggle}>
                {/* <IntlMessages id="labels.Comments" /> */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <p>{t("commons.Total Package Count")}</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h1>{this.state.overallPiecesCount}</h1> <span
                      style={{ marginTop: '11px', paddingLeft: '8px' }}>
                      {t("commons.Pieces")}</span>
                  </div>

                </div>

              </ModalHeader>
              <ModalBody>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                  < >
                    {
                      // includedArray,
                      // excludedArray
                      includedArray && includedArray.length > 0 &&
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                        <h4>{t("commons.Sub Categories Count")}</h4>
                        <h4 style={{ paddingBottom: '20px', fontWeight: 'bold' }}>{includesTotalPieces} <span>{t("commons.Pieces")}</span></h4>
                        <table>
                          <tbody>
                            {includedArray.map((item) => {
                              return (
                                <>
                                  {item.totalPieces > 0 &&

                                    <tr style={{ alignItems: 'center' }}>
                                      <td style={{ paddingRight: '20px' }}>{item.subcategory}</td>
                                      <td style={{ fontWeight: 'bold' }}>{item.totalPieces} {t("commons.Pieces")}</td>
                                    </tr>
                                  }
                                </>

                              )
                            })}
                          </tbody>
                        </table>

                      </div>

                    }
                  </>
                  < >
                    {

                      excludedArray && excludedArray.length > 0 &&
                      (
                        <>
                          {
                            this.state.lang == 'en' ? (<div style={{ borderLeft: includedArray && includedArray.length > 0 && excludedArray && excludedArray.length > 0 && '1px dotted', padding: excludedArray && excludedArray.length > 0 && '10px', display: 'flex', flexDirection: 'column', gap: '10px', width: '40%' }}>
                              <h4>{t("commons.Inclusive of Sub Categories")}</h4>
                              <h4 style={{ paddingBottom: '20px', fontWeight: 'bold' }}>{includesOtherPieces} <span>{t("commons.Pieces")}</span></h4>
                              <div>{excludedArray}</div>
                            </div>) : (
                              <div style={{ borderRight: includedArray && includedArray.length > 0 && excludedArray && excludedArray.length > 0 && '1px dotted', padding: excludedArray && excludedArray.length > 0 && '10px', display: 'flex', flexDirection: 'column', gap: '10px', width: '40%' }}>
                                <h4>{t("commons.Inclusive of Sub Categories")}</h4>
                                <h4 style={{ paddingBottom: '20px', fontWeight: 'bold' }}>{includesOtherPieces} <span>{t("commons.Pieces")}</span></h4>
                                <div>{excludedArray}</div>
                              </div>
                            )
                          }
                        </>

                      )

                    }
                  </>

                  <>

                    {

                      excludedSubCategory && excludedSubCategory.length > 0 && excludedSubCategory != null
                      && excludedSubCategory.length > 0 && (
                        <>
                          {
                            this.state.lang == 'en' ? (<div style={{
                              borderLeft: excludedSubCategory && excludedSubCategory.length > 0 && '1px dotted',
                              padding: excludedSubCategory && excludedSubCategory.length > 0 && '10px', display: 'flex', flexDirection: 'column', gap: '10px'
                            }}>
                              <h4 style={{ paddingBottom: '20px' }}>{t("commons.Excluded Sub Categories")}</h4>
                              <p>{excludedSubCategory.toString()}</p>
                            </div>) : (<div style={{
                              borderRight: excludedSubCategory && excludedSubCategory.length > 0 && '1px dotted',
                              padding: excludedSubCategory && excludedSubCategory.length > 0 && '10px', display: 'flex', flexDirection: 'column', gap: '10px'
                            }}>
                              <h4 style={{ paddingBottom: '20px' }}>{t("commons.Excluded Sub Categories")}</h4>
                              <p>{excludedSubCategory.toString()}</p>
                            </div>)
                          }
                        </>
                      )
                    }
                  </>
                </div>

              </ModalBody>
            </Modal>


            {/* package single tab modal */}
            <Modal isOpen={this.state.showCommentBoxTab} toggle={this.commentBoxToggleTab}>
              <ModalHeader style={{
                background: '#5c95eb',
                color: 'white'
              }} className="modal-manual-header" toggle={this.commentBoxToggleTab}>
                {/* <IntlMessages id="labels.Comments" /> */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    <p>{t("commons.Total Package Count")}</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h1>{this.state.TotalPackageCount}</h1> <span
                      style={{ marginTop: '11px', paddingLeft: '8px' }}>
                      {t("commons.Pieces")}</span>
                  </div>

                </div>

              </ModalHeader>
              <ModalBody>
                <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                  < >
                    {
                      // includedArray,
                      // excludedArray
                      packageInfoTab && packageInfoTab.length > 0 &&
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '10px' }}>
                        <table>
                          <tbody>
                            {packageInfoTab.map((item, index) => {
                              return (
                                <>
                                  <tr style={{ alignItems: 'start', display: 'flex', flexDirection: 'column', padding: '20px' }}>
                                    <td style={{ fontWeight: '500' }}>{item.PackageName} - {item.PackageCount} {t("commons.Pieces")}</td>
                                  </tr>

                                </>

                              )
                            })}
                          </tbody>
                        </table>

                      </div>

                    }
                  </>
                </div>

              </ModalBody>
            </Modal>
          </div>
        )}
      </Translation>
    );
  }
}
