import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Translation } from "react-i18next";

export default class ModalLayout extends React.Component {
  constructor(props) {
    super(props);
  }
  onCloseMe = (e) => {
    this.props.onClose && this.props.onClose(e);
  };

  onConfirmation = (e) => {
    this.props.onConfirmation && this.props.onConfirmation(e);
  };

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Translation>
        {(t) => (
          <Modal isOpen={this.props.show} backdrop={"static"} keyboard={false}>
            <ModalBody>
              <h6>{this.props.children}</h6>
            </ModalBody>
            <ModalFooter>
              <div>
                <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={this.onConfirmation}>
                  {t("commons.OK")}
                </button>
                <button type="submit" style={{ cursor: "pointer", marginLeft: "8px" }} className="btn" onClick={this.onCloseMe}>
                  {t("commons.Cancel")}
                </button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </Translation>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
