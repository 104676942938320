import React, { Component } from "react";
import { Col, Container, Row, Button, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";
import i18next from "i18next";

const phoneRegExp = /^[1-9][0-9]{6,15}$/;
const nameRegex = /^[a-zA-Z ]*$/;

const FormSchema = Yup.object().shape({
  name: Yup.string().required("commons.Please_enter_Full_Name").matches(nameRegex, "commons.Only_characters_are_allowed"),
  email: Yup.string().required("commons.Please_enter_email_address").email("commons.Please_enter_valid_email_id"),
  phone: Yup.string()
    .required("commons.Please_enter_phone_number")

    .min(7, "commons.Phone_number_must_contain_atleast_7_letters.")
    .max(15, "commons.Phone_number must_contain_atmost_15_letters.")
    .matches(phoneRegExp, "commons.Please_enter_valid_phone_number"),
  message: Yup.string().required("commons.Please_enter_your_message"),
});

export default class ConatctUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }

  componentDidMount() {}

  handleSubmit = async (inputValues, formOptions) => {
    let formData = new FormData();
    formData.append("name", inputValues.name);
    formData.append("email", inputValues.email);
    formData.append("phone", inputValues.phone);
    formData.append("message", inputValues.message);

    let path = ApiRoutes.CREATE_CONTACT;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        formOptions.resetForm();
        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          resJson.data.errors.forEach((error) => {
            formOptions.setFieldError(error.errField, error.errText);
          });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };

  render() {
    var { authUser } = this.props;
    const { isloading } = this.state;

    return (
      <Translation>
        {(t) => (
          <section className="vendor_list_detail_sec py-5 py-md-5" style={{ backgroundColor: "#F5FDFE" }}>
            <Container className="py-3 py-md-4">
              <h2 className=" section_title mb-4">{t("headings.Contact Us")}</h2>
              <div className="laundries_list">
                <Row className="justify-content-center">
                  <Col xs={12}>
                    <div>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          name: this.state.name,
                          email: this.state.email,
                          phone: this.state.phone,
                          message: this.state.message,
                        }}
                        validationSchema={FormSchema}
                        onSubmit={this.handleSubmit}
                      >
                        {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                          <Form className="w-200 login_signup_form">
                            <Row>
                              <Col xs={12} md={6}>
                                <FormGroup>
                                  <Field className="form-control" name="name" type="text" placeholder={t("commons.Full_Name")} />
                                  {errors.name && touched.name ? <div className="invalid-feedback d-block">{t(errors.name)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <FormGroup>
                                  <Field className="form-control" name="email" type="email" placeholder={t("labels.email")} />
                                  {errors.email && touched.email ? <div className="invalid-feedback d-block">{t(errors.email)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <FormGroup>
                                  <Field className="form-control" name="phone" type="text" placeholder={t("labels.phone")} />
                                  {errors.phone && touched.phone ? <div className="invalid-feedback d-block">{t(errors.phone)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={12} md={6}>
                                <FormGroup>
                                  <Field className="form-control" name="message" as="textarea" rows="7" placeholder={t("commons.Write_your_query_here")} />
                                  {errors.message && touched.message ? <div className="invalid-feedback d-block">{t(errors.message)}</div> : null}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Button className="btn text-uppercase" type="submit">
                              {t("commons.Submit")}
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        )}
      </Translation>
    );
  }
}
