import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Switch from 'react-switch';


export const StyledCartLink = styled(NavLink)`
  background-color: ${({ theme }) => theme.colors.secondary} !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  min-width: auto !important;
  border: 1px solid ${({ theme }) => theme.colors.primary} !important;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary} !important;
    color: ${({ theme }) => theme.colors.secondary} !important;
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .count {
    background-color: ${({ theme }) => theme.colors.primary}; /* Background color for item count */
    color: ${({ theme }) => theme.colors.secondary};
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 0.8rem; /* Font size for item count */
    margin-left: 4px;
  }
`;


export const StyledTab = styled.a`
 padding: 10px;
 cursor: pointer;
 text-decoration: none;
 color: ${({ theme, isActive }) => (isActive ? theme.colors.secondary : theme.colors.primary)} !important;
 background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : 'transparent')} !important;
 border: 1px solid ${({ theme }) => theme.colors.primary} !important;
 

 &:hover {
   color: ${({ theme }) => theme.colors.primary};
   background-color: ${({ theme }) => theme.colors.secondary};
 }
   `;


export const StyledSwitch = styled(Switch)`
  .react-switch-bg {
    background-color: #d5d3d3 !important; /* Background color when unchecked */
  }
  .react-switch-handle {
    background-color: ${({ theme }) => theme.colors.primary} !important; /* Handle color */
  }
  .react-switch .react-switch-handle {
    background-color: ${({ theme }) => theme.colors.primary} !important; /* Handle color when checked */
  }
  .react-switch .react-switch-bg {
    background-color: ${({ theme }) => theme.colors.secondary} !important; /* Background color when checked */
  }
  .react-switch .react-switch-handle {
    background-color: ${({ theme }) => theme.colors.primary} !important; /* Handle color when checked */
  }
`;

// export default StyledCartLink;
