import React, { Component } from "react";
import { Col, Container, Row, Modal, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import { Translation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { ThemeContext } from "styled-components";

function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

export default class PackageDetails extends Component {
    static contextType = ThemeContext;
    constructor(props) {
        super(props);

        this.state = {
            itemData: [],
            isloading: false,
            lang: "",
            expired: "",
            show: false,
            showRatingModal: false,
            ratingData: [],
        };
    }


    async componentDidMount() {
        this.setState({ lang: localStorage.getItem("lang") || "en" });
        this.getVendorPackage();
    }

    getVendorPackage = async (currentPage = 1) => {
        let path = ApiRoutes.GET_ORDERED_PACKAGE + "?page_no=" + `${currentPage}`;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                if (this.state.lang == 'en') {
                    moment.locale('en');
                } else {
                    moment.locale('ar');
                }
                if (resJson.data.docs.length > 0) {
                    this.setState({
                        itemData: [...this.state.itemData, ...resJson.data.docs],
                        lang: localStorage.getItem("lang") || "en",
                        hasMoreItems: currentPage < resJson.data.totalPages ? true : false
                    });
                } else {
                    this.setState({
                        itemData: [],
                        lang: localStorage.getItem("lang") || "en",
                        hasMoreItems: false
                    });
                }

            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);

        }
    };

    loadPagination = async (currentPage) => {
        this.getVendorPackage(currentPage + 1);
    };

    render() {
        var { lang, itemData } = this.state;
        const theme = this.context;
        return (
            <>
                <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
                    <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                        <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                            <i className="fas fa-bars"></i>
                        </div>
                    </div>
                    <Translation>
                        {(t) => (
                            <section className="vendor_list_detail_sec py-11 py-md-11" style={{ backgroundColor: "#F5FDFE" }}>
                                <h1 className="page_title mb-5 custom-page-tit-phem promotional-package-title">{t("label_Subscribed_package")}</h1>
                                <InfiniteScroll pageStart={0} loadMore={this.loadPagination} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                                    {itemData && Object.keys(itemData).length > 0 ? (
                                        <div className="customer-page list-sec">
                                            <div className="promotions-package-list-customer-package">
                                                <div className="promo-list-section-title">
                                                    <ul>
                                                        <li style={{ width: "16%" }}>{t("label_package_name")}</li>
                                                        <li style={{ width: "16%" }}>{t("label_vendor_name")}</li>
                                                        <li style={{ width: "27%" }}>{t("label_package_category")}</li>
                                                        <li style={{ width: "27%" }}>{t("label_package_subcategory")}</li>
                                                        <li style={{ width: "17%" }}>{t("label_package_expiry_date")}</li>
                                                    </ul>
                                                </div>
                                                <span><hr /></span>
                                                {/* Desktop Data */}
                                                <div className="promo-list-pack">
                                                    {itemData && itemData.map((item, index) => {
                                                        var expired = "";
                                                        var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
                                                        var vendor_name = item.vendor_details[0] && item.vendor_details[0].shopname['en'];
                                                        var pack_created_at = item.createdAt;
                                                        var pack_buy_date = pack_created_at;
                                                        var expiry_date = item.expiry_date;
                                                        var date_buyed = new Date(pack_buy_date);
                                                        var dated_buyed_with_addition = date_buyed.setMonth(date_buyed.getMonth() + expiry_date);
                                                        var date_expired = new Date(dated_buyed_with_addition);
                                                        var expiry_day = moment(date_expired).format(" Do MMMM  YYYY ");
                                                        var package_expiry_day = moment(date_expired).format('YYYY-MM-DD[T00:00:00.000Z]');
                                                        var PackageExpiry = package_expiry_day;
                                                        if (item.balance_carry == 0) {
                                                            var balCarryValidity = item.balanceCarry_validity;
                                                            var balCarryDate = new Date(balCarryValidity);
                                                            var bal_expiry_day = moment(balCarryDate).format('YYYY-MM-DD[T00:00:00.000Z]');
                                                            PackageExpiry = bal_expiry_day;
                                                        }
                                                        if (current_date > PackageExpiry) {
                                                            if (this.state.lang == 'en') {
                                                                expired = 'Expired';
                                                            } else {
                                                                expired = 'منتهي الصلاحية';
                                                            }
                                                        }

                                                        var category = [];
                                                        var subcategory = [];
                                                        item.new_main_categories.map((item1, index1) => {
                                                            category.push(item1.name[lang]);
                                                        })
                                                        item.new_sub_categories.map((item2, index2) => {
                                                            subcategory.push(item2[lang]);
                                                        })

                                                        return (
                                                            <div className="promo-list-sec-flex">
                                                                <div className="promo-lst-pack-comon-sec package_cls1" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="promo-package-name">{item.package_title[lang]}</div>
                                                                    {expired != "" ?
                                                                        <p className="package_expired">{"(" + expired + ")"}</p>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                                <div className="promo-lst-pack-comon-sec package_cls2" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="promo-vendor-name">{vendor_name}</div>
                                                                </div>
                                                                <div className="promo-lst-pack-comon-sec package_cls3" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="promo-category">{category.join(" ,")}</div>
                                                                </div>
                                                                <div className="promo-lst-pack-comon-sec package_cls4" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="promo-subcategory">{subcategory.join(" ,")}</div>
                                                                </div>
                                                                <div className="promo-lst-pack-comon-sec package_cls5" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="promo-expiry-date">{expiry_day}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {/* Mobile Data */}
                                                <div className="promo-list-pack-mobile">
                                                    {itemData && itemData.map((item, index) => {
                                                        var expired = "";
                                                        var current_date = moment(new Date()).format('YYYY-MM-DD[T00:00:00.000Z]');
                                                        var vendor_name = item.vendor_details[0] && item.vendor_details[0].shopname['en'];
                                                        var pack_created_at = item.createdAt;
                                                        var pack_buy_date = pack_created_at;
                                                        var expiry_date = item.expiry_date;
                                                        var date_buyed = new Date(pack_buy_date);
                                                        var dated_buyed_with_addition = date_buyed.setMonth(date_buyed.getMonth() + expiry_date);
                                                        var date_expired = new Date(dated_buyed_with_addition);
                                                        var expiry_day = moment(date_expired).format(" Do MMMM  YYYY ");
                                                        var package_expiry_day = moment(date_expired).format('YYYY-MM-DD[T00:00:00.000Z]');
                                                        var PackageExpiry = package_expiry_day;
                                                        if (item.balance_carry == 0) {
                                                            var balCarryValidity = item.balanceCarry_validity;
                                                            var balCarryDate = new Date(balCarryValidity);
                                                            var bal_expiry_day = moment(balCarryDate).format('YYYY-MM-DD[T00:00:00.000Z]');
                                                            PackageExpiry = bal_expiry_day;
                                                        }
                                                        if (current_date > PackageExpiry) {
                                                            if (this.state.lang == 'en') {
                                                                expired = 'Expired';
                                                            } else {
                                                                expired = 'منتهي الصلاحية';
                                                            }
                                                        }
                                                        var category = [];
                                                        var subcategory = [];
                                                        item.new_main_categories.map((item1, index1) => {
                                                            category.push(item1.name[lang]);
                                                        })
                                                        item.new_sub_categories.map((item2, index2) => {
                                                            subcategory.push(item2[lang]);
                                                        })

                                                        return (
                                                            <div className="package-data-spec">
                                                                <div className="promo-pack-flex" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="package-name">{t("promotion_package_name")}</div>
                                                                    <div className="package-information">{item.package_title[lang]}</div>
                                                                    {expired != "" ?
                                                                        <p className="package_expired">{"(" + expired + ")"}</p>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>
                                                                <div className="promo-pack-flex" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="package-name">{t("label_vendor_name")}</div>
                                                                    <div className="package-information">{vendor_name}</div>
                                                                </div>
                                                                <div className="promo-pack-flex" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="package-name">{t("promotion_package_category")}</div>
                                                                    <div className="package-information">{category.join(" ,")}</div>
                                                                </div>
                                                                <div className="promo-pack-flex" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="package-name">{t("promotion_package_subcategory")}</div>
                                                                    <div className="package-information">{subcategory.join(" ,")}</div>
                                                                </div>
                                                                <div className="promo-pack-flex" onClick={() => this.props.history.push(`promotion/${item._id}`)}>
                                                                    <div className="package-name">{t("label_package_expiry_date")}</div>
                                                                    <div className="package-information">{expiry_day}</div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <h3 className="promotional-package-title1">{t("label_no_Subscribed_package")}</h3>
                                        </div>
                                    )
                                    }
                                </InfiniteScroll>

                            </section>
                        )}
                    </Translation>
                    <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
                </div>
            </>
        );
    }
}