import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const StyledLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: ${({ theme }) => theme.button.size};
  padding: 10px 14px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.primary};
 &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.primary} !important; /* Change text color on hover */
     border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const StyledLink1 = styled(Link)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 13px;
  padding: 15px 10px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .25rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
 &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary} !important; /* Change text color on hover */
     border-color: ${({ theme }) => theme.colors.primary};
  }
`;

