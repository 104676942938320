import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";
import { ThemeContext } from "styled-components";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";
import { ThemeButtonLink } from "../../helpers/Button";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}
export default class Dashboard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);

    this.state = {
      vendorsList: [],
      limit: 10,
      hasMoreItems: true,
      lang: "",
    };
  }

  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
  }

  getVendors = async (currentPage = 1) => {
    let path = ApiRoutes.GET_FAVOURITES + "?page_no=" + `${currentPage}` + "&limit=" + `${this.state.limit}`;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          vendorsList: [...resJson.data.docs, ...this.state.vendorsList],
          hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  loadPagination = async (currentPage) => {
    this.getVendors(currentPage);
  };

  setFavourite = async (vendor_id, is_favourite, index) => {
    var action = is_favourite ? "unfavourite" : "favourite";
    var new_favourite = is_favourite ? 0 : 1;

    let formData = new FormData();
    formData.append("vendor_id", vendor_id);
    formData.append("action", action);

    let path = ApiRoutes.SET_FAVOURITES;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        let vendorsList = Object.assign([], this.state.vendorsList);
        vendorsList.splice(index, 1);

        this.setState({ vendorsList: vendorsList });

        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var { authUser } = this.props;
    const { lang } = this.state;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
              <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                  <i className="fas fa-bars"></i>
                </div>
              </div>
              <h1 className="page_title mb-md-5">{t("headings.Favourite_Laundries")}</h1>

              <InfiniteScroll
                pageStart={0}
                loadMore={this.loadPagination}
                hasMore={this.state.hasMoreItems}
                loader={
                  <div key={0} className="loader">
                    Loading...
                  </div>
                }
              >
                <ul className="laundries_list inner_laundries_list">
                  {this.state.vendorsList.map((item, index) => {
                    return (
                      <li key={index} className="d-block position-relative">
                        <div className="w-100 laundries_desc">
                          <div className="laundries_desc_img">
                            <img src={item.user_image_thumb_url} alt={item.shop_name && item.shop_name[lang]} />
                          </div>
                          <div className="laundries_desc_data ">
                            <div className="like_rate_view">
                              {authUser && (
                                <div
                                  className={"" + (item.is_favourite ? "like_view" : "unlike_view")}
                                  onClick={() => {
                                    this.setFavourite(item._id, item.is_favourite, index);
                                  }}
                                >
                                  <i className="fas fa-heart"></i>
                                </div>
                              )}
                            </div>

                            <ul className="laundries_desc_data_list flex-wrap">
                              <h3 className="laundries_desc_data_title ml-3">{item.shop_name && item.shop_name[lang]}</h3>
                              <p className="branch_title mb-2">
                                <b> {item.branch_name && item.branch_name[lang]}</b>
                              </p>
                              {item.customerPackage == 1 ?
                                <div className="overlay-ribbon"><span>
                                  <Link to={"/promotions"}>{t("purchased_package")}</Link>
                                </span></div>
                                : <></>
                              }
                              <li>
                                {t("labels.Service_Provider")} :{" "}
                                {lang && item.service_providers.length > 0 ? (
                                  item.service_providers.map((item, index) => {
                                    return (
                                      <span key={index} className="themeColor">
                                        {(index ? ", " : "") + " " + item.name[lang]}
                                      </span>
                                    );
                                  })
                                ) : (
                                  <span className="themeColor">{t("commons.N/A")}</span>
                                )}
                              </li>

                              {/* <li>
                                {t("labels.Express_Delivery")} : <span className="themeColor">{item.is_normal_express_delivery ? t("commons.Yes") : t("commons.No")}</span>
                              </li> */}

                              <li>
                                {t("labels.Express_Delivery_Time")} :{" "}
                                <span className="themeColor">{item.normal_express_delivery_time ? item.normal_express_delivery_time + " " + t("commons.Hours") : t("commons.N/A")}</span>
                              </li>
                              {/* <li>
                                {t("labels.Minimum_Order_Value")} :{" "}
                                <span className="themeColor">{item.minimum_order_amount ? t("commons.OMR") + " " + item.minimum_order_amount.toFixed(3) : t("commons.N/A")}</span>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="laundries_rates">
                          <div className="lable_tag_vendor new">
                            <ul>
                              {authUser && item && item.is_freedelivery == 'available' && (
                                <li className="freedelivery">{t("contents.Free-delivery")}</li>
                              )}
                              {authUser && item && item.is_discount == 'available' && (
                                lang == 'en' ?
                                  <li className="discount">{item && item.discount_type == 'percent' ? item.discount_amount + "% " + t("contents.Discount") : t("contents.OMR") + item.discount_amount + " " + t("contents.Discount")}</li> :
                                  <li className="discount">{item && item.discount_type == 'percent' ? t("contents.Discount") + item.discount_amount + " % " : t("contents.Discount") + item.discount_amount + " " + t("contents.OMR")}</li>
                              )}
                              {item && item.is_discount_subcategory == 'available' && (
                                <li className="discount_subcategory">{t("contents.Sub_Discount")}</li>
                              )}
                              {item && item.vendorPackage == 1 && (
                                <li className="package">{t("package-available-section")}</li>
                              )}
                              {/* display corporate package tag */}
                              {item && item.vendorCorporatePackage == 1 && (
                                <li className="corporate-package">{t("corporate-package-available-section")}</li>
                              )}
                              <li className="viewdetails">
                                {/* <Link to={"vendor-details/" + item._id} className="btn">
                                  {t("buttons.View_Detail")}
                                </Link> */}
                                <ThemeButtonLink
                                  link={"vendor-details/" + item._id} className="btn"
                                  buttonText={t("buttons.View_Detail")} />
                              </li>
                            </ul>
                          </div>

                        </div>
                      </li>
                    );
                  })}
                </ul>
              </InfiniteScroll>

              {this.state.vendorsList.length == 0 && <div className="noorder">{t("commons.No_favourites_are_available")}</div>}
            </div>
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
          </>
        )}
      </Translation>
    );
  }
}
