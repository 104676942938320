import React, { Component } from "react";
import * as Yup from "yup";
import InfiniteScroll from "react-infinite-scroller";
import { NotificationManager } from "react-notifications";
import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";
import { ThemeContext } from "styled-components";
import moment from "moment";
import { Col, Container, Row, Button, ModalBody, ModalFooter, Modal, ModalHeader } from "reactstrap";
import DatePicker from "react-datepicker";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { StyledButtondiv1 } from "../../helpers/StyledButtondiv";

function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const priceRegExp = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;

const FormSchema = Yup.object().shape({
    walletAmount: Yup.string().required("Please enter the Wallet Amount").matches(priceRegExp, "Please enter amount greater than or equal to 0"),
});

export default class ProfileEdit extends Component {
    static contextType = ThemeContext;
    constructor(props) {
        super(props);

        this.state = {
            walletAmount: "",
            password: "",
            editGender: "",
            userData: null,
            itemData: "",
            isloading: false,
            FromDate: "",
            ToDate: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getBalanceWallet();
        this.getCustomerHistory();
    }
    handleSubmit = async (inputValues) => {
        if (this.state.FromDate != "" && this.state.ToDate != "") {
            this.setState({ itemData: "", });
            this.getCustomerHistory();
        } else {
            return false;
        }

    }
    loadPagination = async (currentPage) => {
        this.getCustomerHistory(currentPage + 1);
    };

    getBalanceWallet = async (refreshPage = false) => {
        let path = ApiRoutes.GET_BALANCE_WALLET;
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                function addZeroes(num) {
                    // Cast as number
                    var num = Number(num);
                    // If not a number, return 0
                    if (isNaN(num)) {
                        return 0;
                    }
                    // If there is no decimal, or the decimal is less than 2 digits, toFixed
                    if (String(num).split(".").length < 2 || String(num).split(".")[1].length <= 2) {
                        num = num.toFixed(2);
                    }
                    return num;
                }
                var walletsAmount = resJson.data.wallet.wallet_amount;
                var balanceAmount = addZeroes(walletsAmount);
                var originAmount = parseFloat(balanceAmount);
                this.setState(
                    {
                        balanceAmount: originAmount.toFixed(3),
                    },
                );
            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    getCustomerHistory = async (currentPage = 1) => {
        var path = ApiRoutes.GET_CUSTOMER_HISTORY;
        var FromDate = this.state.FromDate;
        var ToDate = this.state.ToDate;
        if (FromDate != '' && ToDate != "") {
            path = ApiRoutes.GET_CUSTOMER_HISTORY + '?page_no=' + `${currentPage}` + '&from_date=' + moment(FromDate).format("YYYY-MM-DD") + '&to_date=' + moment(ToDate).format("YYYY-MM-DD");
        } else {
            path = ApiRoutes.GET_CUSTOMER_HISTORY + '?page_no=' + `${currentPage}`;
        }
        const res = await Http("GET", path);
        if (res) {
            const resJson = await res.json();
            if (res.status == 200) {
                // console.log(resJson.data, 'history');
                this.setState({
                    itemData: [...this.state.itemData, ...resJson.data.docs],
                    // itemData: resJson.data.docs,
                    hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
                },
                    () => this.setState({ isloading: true })
                );

            } else {
                NotificationManager.error(resJson.message, "Error!", 3000);
            }
        } else {
            NotificationManager.error("Server Error", "Error!", 3000);
        }
    };

    render() {
        var { userData, editGender, balanceAmount, wallet_admin_amount, itemData } = this.state;
        const theme = this.context;
        return (
            <Translation>
                {(t) => (
                    <>
                        <div className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
                            <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
                                <div onClick={SidebarAddClass} className="toggle_btn btn px-3 py-2 d-lg-none" style={{backgroundColor: theme.colors.primary, borderColor: theme.colors.primary}}>
                                    <i className="fas fa-bars"></i>
                                </div>
                            </div>
                            <div className="wallet-display-bag-price-sec">
                                <h1 className="page_title mb-5">{t("commons.Wallet_Title")}</h1>
                                <div className="bag-sec-block-sec">
                                    <i className="fas fa-shopping-bag"></i>
                                    <p className="balance-wallet">{balanceAmount} {t("commons.balanceomr")}</p>
                                </div>
                            </div>
                            <Row className="wallet-mobile">
                                <Col xs={6} md={3}>
                                    <Link
                                        to={{
                                            pathname: `/wallet-pay`,
                                        }}
                                    >
                                        <Button className="btn text-uppercase" type="button">
                                            {t("filter.add_amount")}
                                        </Button>
                                    </Link>

                                </Col>
                            </Row>
                            <div className="wallet-filter-date-sec">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                    }}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, event, values, errors, touched, isSubmitting }) => (
                                        <Form>
                                            <Row>
                                                <Col xs={6} md={3}>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={this.state.FromDate}
                                                        name="FromDate"
                                                        autoComplete="off"
                                                        // minDate={moment().toDate()}
                                                        onChange={(date) => {
                                                            this.setState({ FromDate: date });
                                                        }}
                                                        placeholderText={t("orderList.filter.fromDate")}
                                                    ></DatePicker>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={this.state.ToDate}
                                                        name="ToDate"
                                                        autoComplete="off"
                                                        minDate={new Date(this.state.FromDate)}
                                                        onChange={(date1) => {
                                                            this.setState({ ToDate: date1 });
                                                        }}
                                                        placeholderText={t("orderList.filter.toDate")}
                                                    ></DatePicker>
                                                </Col>
                                                <Col xs={6} md={3}>
                                                    {/* <Button className="btn text-uppercase" type="submit">
                                                        {t("filter.apply")}
                                                    </Button> */}
                                                    <StyledButtondiv1 type="submit" className="btn text-uppercase">{t("filter.apply")}</StyledButtondiv1>
                                                </Col>
                                                <Col xs={6} md={3} className="wallet-desktop">
                                                    <Link to={{ pathname: `/wallet-pay`, }}>
                                                        <StyledButtondiv1 type="button" className="btn text-uppercase">{t("filter.add_amount")}</StyledButtondiv1>
                                                        {/* <Button className="btn text-uppercase" type="button">
                                                            {t("filter.add_amount")}
                                                        </Button> */}
                                                    </Link>
                                                    {/* <ThemeButtonLink
                                                        link={"/wallet-pay"} className="btn"
                                                        buttonText={t("filter.add_amount")} /> */}

                                                </Col>
                                            </Row>
                                        </Form>

                                    )}
                                </Formik>
                            </div>
                            {!this.state.isloading ? (
                                <div className="loading"></div>
                            ) : (
                                <InfiniteScroll pageStart={0} loadMore={this.loadPagination} hasMore={this.state.hasMoreItems} loader={<div key={0} className="loader"></div>}>
                                    <div className="wallet-page-display-section">
                                        {/* {Object.entries(this.state.itemData).map(([key, value]) => ( */}
                                        {this.state.itemData && this.state.itemData.map((key, value) => {
                                            var walletAmount = parseFloat(key.walletAmount);
                                            var rechargeDate = moment(key.createdAt).add(4, 'hours').utc().format("DD MMM YYYY hh:mm A");

                                            return (
                                                <div className="wallet-history-flex">
                                                    <div className="wallet-date">
                                                        <div className="wallet-date-sec" style={{ color: theme.colors.primary }}>
                                                            {/* {moment(value.createdAt).format(" DD MMMM YYYY ") + ", " + moment.utc(value.createdAt).format("HH:mm A")} */}
                                                            {rechargeDate}

                                                        </div>

                                                        <div className="wallet-order-id"><span>{t("vendor_transactionNumber") + " / "}</span>{key.transaction_number}</div>
                                                    </div>
                                                    <div className="wallet-amount-credit">
                                                        <div className="wallet-amount-sec">{walletAmount.toFixed(3) + " " + t("commons.balanceomr")}</div>
                                                        {key.wallet_type && key.wallet_type == 'credit' ? (
                                                            <div className="wallet-credited-debit">{t("amount_credit") + " "}{key.payment_status == 'refunded' ? '(' + key.payment_status + ')' : ""}</div>

                                                        ) : (
                                                            <div className="wallet-credit-debited">{t("amount_debit")}</div>

                                                        )}
                                                    </div>
                                                    <div className="wallet-payment-status">
                                                        {key.payment_status && key.payment_status == 'paid' ? (
                                                            <div className="wallet-payment-stus" style={{ color: "green" }}>{t(key.payment_status)}</div>

                                                        ) : key.payment_status && key.payment_status == 'failed' ? (
                                                            <div className="wallet-payment-stus" style={{ color: "red" }}>{t(key.payment_status)}</div>

                                                        ) :
                                                            <div className="wallet-payment-stus" style={{ color: "#307dc9" }}>{t(key.payment_status)}</div>
                                                        }
                                                    </div>
                                                </div>
                                                // ))}
                                            )
                                        })}
                                        {this.state.itemData.length <= 0 ?
                                            <p className="transaction_history">{t('no_transction_history')}</p>
                                            : ""}

                                    </div>

                                </InfiniteScroll>
                            )}

                        </div>
                        <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
                    </>
                )}
            </Translation>
        );
    }
}
