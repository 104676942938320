import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import Header from "./partials/Header";
import Footer from "./partials/Footer";

const MainLayout = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Header authUser={authUser} />
          <Component {...props} authUser={authUser} />
          <Footer />
        </>
      )}
    />
  );
};

export default MainLayout;
