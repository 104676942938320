import React, { Component } from "react";
import { Col, Container, Row, Button, Modal, ModalBody, ModalFooter, Form } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import AuthService from "../../services/auth.service";
import { Formik, Field } from "formik";
// import moment from "moment";
import moment from 'moment/min/moment-with-locales';

import { Translation } from "react-i18next";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

export default class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: "",
      main_category: "",
      actual_corporate_package_price: "",
      package_id: props.match.params.package_id,
      vendor_id: props.match.params.vendor_id,
      corporate_main_admin_id: props.match.params.corporate_main_admin_id,
      corporate_sub_admin_id: props.match.params.corporate_sub_admin_id,
      branch_location_id: props.match.params.branch_location_id,
      // promocode_type: props.match.params.promocode_type,
      isloading: false,
      lang: localStorage.getItem("lang") || "en",
      walletTotal: "",
      cardList: [],
      card_id: "",
      origin_amount: 0,
      walletUsed: 'no',
      payment_method: "",
    };
  }

  componentDidMount() {
    this.get_admin_vendor_corporate_package();
    this.getUserData();
    // this.getWalletAmount();
    this.showCardList();
    this.getWalletData('');
    this.setState({ lang: localStorage.getItem("lang") || "en" });

  }


  get_admin_vendor_corporate_package = async () => {
    let formData = new FormData();
    formData.append("package_id", this.state.package_id);
    formData.append("vendor_id", this.state.vendor_id);
    formData.append("corporate_main_admin_id", this.state.corporate_main_admin_id);
    formData.append("corporate_sub_admin_id", this.state.corporate_sub_admin_id);
    formData.append("branch_location_id", this.state.branch_location_id);
    let path = ApiRoutes.GET_ADMIN_VENDOR_CORPORATE_PACKAGE;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {

        //setting the vendor service type 
        const servicearray = [];
        if (resJson.data && resJson.data.vendorServiceTypes && resJson.data.vendorServiceTypes.length > 0) {
          await resJson.data.vendorServiceTypes.map((value) => {
            servicearray.push(value);
          });
        }

        this.setState({
          serviceData: servicearray,
          servicetypeOpts: servicearray
        });
        //setting the vendor service type
        var main_category = resJson.data.categories;
        var main_category_new = resJson.data.new_main_categories;
        var service_new = resJson.data.services_new;
        var new_sub_categories = resJson.data.new_sub_categories;
        var other_sub_categories = resJson.data.otherIncludedSubcategory;
        var excluded_subcategory = resJson.data.excluded_subcategory;
        var is_vendor_package_confirmed = resJson.data.is_vendor_package_confirmed;
        var individual_subCategories = resJson.data.individual_subCategories;
        //corporate assign package month and year
        var corporate_package_assign_month = resJson.data.corporate_package_assign_month;
        var corporate_package_assign_year = resJson.data.corporate_package_assign_year;
        //actual_corporate_package_price
        var corporate_package_commision_for = resJson.data.corporate_package_commision_for;
        var actual_corporate_package_price = resJson.data.actual_corporate_package_price;
        var UserWalletAmount = resJson.data.UserWalletAmount;
        var is_admin_approved_package = null;
        if (resJson.data.is_admin_approved_package != undefined && resJson.data.is_admin_approved_package != 0) {
          is_admin_approved_package = resJson.data.is_admin_approved_package
        }

        var purchase_expiry_date = resJson.data.vendor_package_date;
        var corporate_package_validity = resJson.data.corporate_package_validity;
        var admin_category = [];
        await main_category.map((items, index) => {
          admin_category.push(items);
        });
        var main_category_types = [];
        var mainCategoryIds = [];
        let main_categories = [];
        main_category_new.map((item, index) => {
          main_category_types.push({ 'value': item._id, "label": item.name[this.state.lang] });
          mainCategoryIds.push(item._id);
          main_categories.push(item.name[this.state.lang]);
        })

        var sub_category_types = [];
        var subCategoryIds = [];
        let sub_categories = [];
        new_sub_categories.map((item, index) => {
          sub_category_types.push({ 'value': item._id, "label": item.name[this.state.lang] });
          subCategoryIds.push(item._id);
          sub_categories.push(item.name[this.state.lang]);
        })

        //checking the other subcategory
        let other_subcategories = [];

        if (other_sub_categories && other_sub_categories.length > 0) {
          other_sub_categories.map((item, index) => {
            other_subcategories.push(item.name[this.state.lang]);
          })
        }
        //checking the other subcategory
        var service_types = [];
        var serviceId = [];
        let service_type = [];
        service_new.map((item, index) => {
          service_types.push({ 'value': item._id, "label": item.name[this.state.lang] });
          serviceId.push(item._id);
          service_type.push(item.name[this.state.lang]);
        })

        let inclu_subcategory = [];
        individual_subCategories.map((item) => {
          inclu_subcategory.push(item.name[this.state.lang]);
        });

        let excludedSubcategorymainArr = [];

        if (resJson.data && resJson.data.excluded_subcategory && resJson.data.excluded_subcategory.length > 0) {
          await Promise.all(resJson.data.excluded_subcategory.map((exclu_sub) => {
            excludedSubcategorymainArr.push(exclu_sub[this.state.lang]);
          }));
        }

        var subcategory = resJson.data.subcategories;
        var excluded_category = resJson.data.excluded_subcategory;
        var excluded_pieces_count = resJson.data.excluded_pieces;
        var excluded_pieces = "Excluded from above selected subcategories : " + excluded_pieces_count;
        // var package_date = resJson.data.vendor_package_date;
        var categoryOpts = [];
        var subcategoryOpts = [];
        var excludeOpts = [];
        var excludeOption = [];
        var selected_category_ids = [];
        var excluded_category_ids = [];
        var CatOptions = [];



        var subcat_text_box_data = [];
        var individual_subCategory = resJson.data.individual_subCategory;
        individual_subCategory.forEach(items => {
          sub_category_types.forEach(item => {
            if (item.value == items.key) {
              subcat_text_box_data.push({ "value": items.key, "dbvalue": items.value, "key": item.label });
            }
          })
        });
        this.setState({
          is_vendor_package_confirmed: is_vendor_package_confirmed,
          is_admin_approved_package: is_admin_approved_package,
          package_title: resJson.data.title,
          corporate_package_type: resJson.data.corporate_package_type,
          balance_carry: resJson.data.balance_carry_forward,
          free_del: resJson.data.free_delivery,
          lang: localStorage.getItem("lang") || "en",
          serviceData: resJson.data.services_new,
          itemDataServiceType: service_types,
          main_cat_itemData: main_category_types,
          sub_cat_itemData: sub_category_types,
          admin_bear_cost: resJson.data.admin_bear_cost,
          vendor_bear_cost: resJson.data.vendor_bear_cost,
          admin_category: admin_category,
          normalSubcategory: resJson.data.subcategories,
          excludedCategory: excluded_subcategory,
          excludedCategoryMain: excludedSubcategorymainArr,
          actual_corporate_package_price: actual_corporate_package_price,
          UserWalletAmount: UserWalletAmount,
          // showWallet: showWallet,
          purchase_expiry_date: new Date(purchase_expiry_date),
          corporate_package_assign_month: corporate_package_assign_month,
          corporate_package_assign_year: corporate_package_assign_year,
          editData: resJson.data,
          editId: resJson.data._id,
          // add_promotion_type: resJson.data.add_promotion_type,
          promo_free_delivery: resJson.data.free_delivery,
          balance_carry_forward: resJson.data.balance_carry_forward,
          promotion_package_validity: resJson.data.corporate_package_validity,
          package_total_price: resJson.data.corporate_package_total_price,
          customer_minimum_order_limit: resJson.data.customer_minimum_order_limit,
          customer_minimum_order_amount: resJson.data.customer_minimum_order_amount,
          // servicetype: service_types,
          isLoading: true,
          subcategories: subcategoryOpts,
          exclude_categories: excludeOpts,
          excludeOption: excludeOption,
          CatOptions: CatOptions,
          subcategory_pieces_id: subcat_text_box_data,
          excluded_pieces_count: excluded_pieces_count,
          excluded_pieces: excluded_pieces,
          // from_date_range: new Date(package_date),
          carry_forward_validity: resJson.data.carry_forward_validity,
          mainCategoryIds: mainCategoryIds,
          serviceTypesIds: serviceId,
          main_categories: main_categories,
          sub_categories: sub_categories,
          other_subcategories: other_subcategories,
          inclu_subcategory: inclu_subcategory,
          service_type: service_type,
          individual_subCategories: individual_subCategories,

        });

        // await this.getCreatedPackage();
        // var ServiceID = [];
        // ServiceID.push(this.state.editData.servicetype);
        // if (this.state.temp_ajax == 0) {
        //     this.setState({ temp_ajax: this.state.temp_ajax + 1 });
        // this.getSubCategoriesList(mainCategoryIds, serviceId, 'temp_ajax');
        // }
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("ERROR", "Error!", 3000);
    }
  };


  requestForAlterPackage = async () => {
    var package_id = this.state.package_id;
    var vendor_id = this.state.vendor_id;
    var corporate_main_admin_id = this.state.corporate_main_admin_id;
    var corporate_sub_admin_id = this.state.corporate_sub_admin_id;
    var branch_location_id = this.state.branch_location_id;

    var corporate_package_assign_month = this.state.corporate_package_assign_month;
    var corporate_package_assign_year = this.state.corporate_package_assign_year;

    let formData = new FormData();
    formData.append("package_id", package_id);
    formData.append("vendor_id", vendor_id);
    formData.append("corporate_main_admin_id", corporate_main_admin_id);
    formData.append("corporate_sub_admin_id", corporate_sub_admin_id);
    formData.append("branch_location_id", branch_location_id);
    formData.append("corporate_package_assign_month", corporate_package_assign_month);
    formData.append("corporate_package_assign_year", corporate_package_assign_year);

    let path = ApiRoutes.CUSTOMER_REQUEST_FOR_ALTER_CORPORATE_PACKAGE;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        // this.getUserData(true);
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.props.history.push("/corporate-package")
      } else {
        if (resJson.data.errors && resJson.data.errors.length > 0) {
          // resJson.data.errors.forEach((error) => {
          // formOptions.setFieldError(error.errField, error.errText);
          // });
        } else {
          NotificationManager.error(resJson.message, "Error!", 3000);
        }
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  }


  getUserData = async () => {
    let path = ApiRoutes.GET_PROFILE;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.setAuthData(resJson.data);

        this.setState(
          {
            userData: resJson.data,
          },
        );
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  // getWalletAmount = async () => {
  //   let path = ApiRoutes.GET_WALLET_AMOUNT;
  //   const res = await Http("GET", path);
  //   if (res) {
  //       const resJson = await res.json();
  //       if (res.status == 200) {
  //           this.setState({
  //               walletAmount: resJson.data.wallet_amount,
  //           });
  //       }
  //   }
  // };

  deleteCard = async (cards) => {
    let path = ApiRoutes.DELETE_CARD;
    let formData = new FormData();
    formData.append("cardData", JSON.stringify(cards));
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        NotificationManager.success(resJson.message, "Success!", 3000);
        this.showCardList();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  showCardList = async () => {
    let path = ApiRoutes.GET_CARDSLIST;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          cardList: resJson.data,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  addCardById = (event) => {
    var elems = document.getElementsByClassName("shampoo_opts");
    var currentState = event.target.checked;
    var elemsLength = elems.length;

    for (var i = 0; i < elemsLength; i++) {
      if (elems[i].type === "checkbox") {
        elems[i].checked = false;
      }
    }

    event.target.checked = currentState;
    if (event.target.checked) {
      this.setState({
        card_id: event.target.value,
      });
    } else {
      this.setState({
        card_id: "",
      });
    }
  };


  handleWallet = async (walletAmount) => {
    var PackPrice = this.state.actual_corporate_package_price;
    if (walletAmount < PackPrice) {
      var balanceAmount = 0;
      var origin_amount = PackPrice - walletAmount;
      this.setState({ balanceAmount: balanceAmount, reduced_amount: walletAmount, origin_amount: origin_amount, walletUsed: 'yes' });
    } else if (walletAmount > PackPrice) {
      var balanceAmount = walletAmount - PackPrice;
      // var origin_amount = walletAmount - PackPrice;
      this.setState({ balanceAmount: balanceAmount, reduced_amount: PackPrice, origin_amount: 0, walletUsed: 'yes' });
    }
  };

  getWalletData = async (val) => {
    if (val != '') {
      this.setState({
        walletUsed: "no",
        reduced_amount: "",
        wallet_total: "",
      },
        () => this.get_admin_vendor_corporate_package(),
      );
    }
  }


  customerActivateCorporatePackage = async (type) => {
    this.setState({ isloading: true });
    var package_id = this.state.package_id;
    var vendor_id = this.state.vendor_id;
    var corporate_main_admin_id = this.state.corporate_main_admin_id;
    var corporate_sub_admin_id = this.state.corporate_sub_admin_id;
    var branch_location_id = this.state.branch_location_id;
    var corporate_package_assign_month = this.state.corporate_package_assign_month;
    var corporate_package_assign_year = this.state.corporate_package_assign_year;
    var payment_method = 'card';

    if (this.state.walletUsed == 'yes' && this.state.editData.corporate_package_commision_for == 'staff_pay') {
      if (this.state.UserWalletAmount >= this.state.editData.actual_corporate_package_price) {
        payment_method = "wallet";
      } else if (this.state.UserWalletAmount < this.state.editData.actual_corporate_package_price) {
        payment_method = "card";
      } else {
        payment_method = 'card'
      }
    } else if (this.state.editData.corporate_package_commision_for == 'staff_pay') {
      payment_method = 'card'
    } else {
      payment_method = '';
    }

    let formData = new FormData();
    formData.append("package_id", package_id);
    formData.append("vendor_id", vendor_id);
    formData.append("corporate_main_admin_id", corporate_main_admin_id);
    formData.append("corporate_sub_admin_id", corporate_sub_admin_id);
    formData.append("branch_location_id", branch_location_id);
    formData.append("corporate_package_assign_month", corporate_package_assign_month);
    formData.append("corporate_package_assign_year", corporate_package_assign_year);
    formData.append("card_id", this.state.card_id);
    formData.append("payment_method", payment_method);
    formData.append("walletUsed", this.state.walletUsed);
    formData.append("walletAmount", this.state.UserWalletAmount);
    formData.append("corporate_package_order_type", type);
    let path = ApiRoutes.CUSTOMER_ACTIVATE_CORPORATE_PACKAGE;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        if (payment_method == "card") {
          window.location.replace(resJson.data.payment_url);
        } else {
          NotificationManager.success(resJson.message, "Success!", 3000);
          this.props.history.push("/corporate-package")
        }
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  }

  render() {
    const { item, lang, cardList, reduced_amount } = this.state;

    return (
      <Translation>
        {(t) => (
          <section className="p-4 p-md-5 wrapper" style={{ backgroundColor: "#F5FDFE" }}>
            <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
              <div onClick={SidebarAddClass} className="btn text-white py-2 px-3 toggle_btn d-lg-none">
                <i className="fas fa-bars"></i>
              </div>
            </div>
            <div className="d-flex clearCart align-items-center">
              <div className="w-100 d-flex justify-content-between my_cart_content_mb">
                <div style={{ width: "100%" }}>
                  <h1 className="page_title mb-5 custom-page-tit-phem" style={{ width: "100%" }}>{t("menu.corporate-package")}</h1>
                  <div className="customer-transportation-details mb-5" style={{ marginBottom: "2rem !important" }}>
                    {this.state.editData && this.state.editData.with_or_without_trans == 'yes' ?
                      <span className="with-transportation"> {"( " + t('with_transportation') + " )"} </span> :
                      <span className="without-transportation"> {"( " + t('without_transportation') + " )"} </span>}
                  </div>
                </div>
              </div>
            </div>
            {this.state.isloading ? (
              <div className="loading"></div>
            ) : (
              <Row className="border py-3" style={{ backgroundColor: "white", display: 'flex', flexDirection: 'column' }}>
                <>
                  {this.state.editData && this.state.editData != '' && (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <p style={{
                            padding: "10px 10px 10px 28px",
                            fontSize: "21px"
                          }}>
                            <span style={{ fontWeight: 'bolder' }}><b>{t("menu.laundry_name")} : </b></span>
                            <span
                            // className="themeColor"
                            >
                              {" "}
                              <>{this.state.editData.vendor_shop_name[lang]}
                              </>
                              {
                                this.state.editData.is_corporate_package_active == 1 && (
                                  <span style={{
                                    fontSize: '14px',
                                    color: 'red',
                                    paddingLeft: '10px'
                                  }}>{t("menu.expired_package")}</span>
                                )
                              }
                            </span>

                          </p>

                        </div>
                        <div>
                          {
                            this.state.editData && this.state.editData.isCustomerCurrentActivePackage && this.state.editData.is_request_to_alter_package == 0 && this.state.editData.is_customer_used_package_count == 0 ? (<>
                              <div className="coporate-customer-key-details-activate-button">
                                <Button onClick={() => this.requestForAlterPackage()} className="btn" type="button">
                                  {t("menu.Request_for_alter_package")}
                                </Button>
                              </div>
                            </>) : (<></>)
                          }
                          {
                            this.state.editData && this.state.editData.isCustomerCurrentActivePackage && this.state.editData.is_request_to_alter_package == 1 ? (<>
                              <div className="coporate-customer-key-details-activate-button">
                                <Button
                                  disabled="true"
                                  // onClick={() => this.requestForAlterPackage()} 
                                  className="btn btn-warning" type="button">
                                  {t("menu.pending_for_alter_package_request")}
                                </Button>
                              </div>
                            </>) : (<></>)
                          }
                          {
                            this.state.editData && this.state.editData.isCustomerCurrentActivePackage && this.state.editData.is_request_to_alter_package == 2 ? (<>
                              <div className="coporate-customer-key-details-activate-button">
                                <Button
                                  disabled="true"
                                  // onClick={() => this.requestForAlterPackage()} 
                                  className="btn btn-success" type="button">
                                  {t("menu.alter_package_request_is_approved")}
                                </Button>
                              </div>
                            </>) : (<></>)
                          }
                          {
                            this.state.editData && this.state.editData.isCustomerCurrentActivePackage && this.state.editData.is_request_to_alter_package == 3 ? (<>
                              <div className="coporate-customer-key-details-activate-button">
                                <Button
                                  disabled="true"
                                  // onClick={() => this.requestForAlterPackage()} 
                                  className="btn btn-danger" type="button">
                                  {t("menu.alter_package_request_is_rejected")}
                                </Button>
                              </div>
                            </>) : (<></>)
                          }
                        </div>
                      </div>
                      <div>
                        <Col md="12">
                          <div className="leftpaneladdres">
                            <div className="d-flex w-100">
                              <div style={{ display: 'flex', flexWrap: 'wrap', padding: '30px' }} className="venderdetailsright corporatepackagedetail">
                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.package_name")} : </b></span>
                                    <span
                                    className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.editData.title[lang]}</>
                                    </span>
                                  </p>
                                </div>
                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.package_type")} : </b></span>
                                    <span
                                    className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.editData.corporate_package_type == 0 ? (<>
                                        {t("menu.subcategory")}
                                      </>) : (<>
                                        {t("menu.pieces")}
                                      </>)}</>
                                    </span>
                                  </p>
                                </div>
                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.price")} : </b></span>
                                    <span
                                    className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.editData.corporate_package_total_price}{" "}{t("menu.OMR")}</>
                                    </span>
                                  </p>
                                </div>
                                {/* corporate admin pay amount */}
                                {this.state.editData.actual_admin_paid_package_price > 0 && (
                                  <div className="coporate-customer-key-details-main">
                                    <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                      <span className="corporate-tit"><b>{t("menu.admin_bear_cost")} : </b></span>
                                      <span
                                      className="corporate-val"
                                      >
                                        {" "}
                                        <>{this.state.editData.actual_admin_paid_package_price}{" "}{t("menu.OMR")}</>
                                      </span>
                                    </p>
                                  </div>
                                )}
                                {/* corporate admin pay amount */}
                                {
                                  this.state.editData.corporate_package_type == 0 && (
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.total_package_count")} : </b></span>
                                        <span
                                        className="corporate-val"
                                        >
                                          {" "}
                                          <>{this.state.editData.SubcatTotPcsCount}{" "}{t("menu.Pieces")}</>
                                        </span>
                                      </p>
                                    </div>
                                  )}

                                {
                                  this.state.editData.corporate_package_type == 0 ? (<>
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.minimum_order_pieces")} : </b></span>
                                        <span
                                        className="corporate-val"
                                        >
                                          {" "}

                                          <>{this.state.editData.customer_minimum_order_limit}{" "}{t("menu.Pieces")}</>
                                        </span>
                                      </p>
                                    </div>
                                  </>) : (<>
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.total_order_pieces")} : </b></span>
                                        <span
                                        className="corporate-val"
                                        >
                                          {" "}
                                          <>{this.state.editData.SubcatTotPcsCount + this.state.editData.excluded_pieces}{" "}{t("menu.Pieces")}</>
                                        </span>
                                      </p>
                                    </div>
                                  </>)

                                }
                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.main_categories")} : </b></span>
                                    <span
                                    className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.main_categories && this.state.main_categories.length > 0 && this.state.main_categories.toString()}</>
                                    </span>
                                  </p>
                                </div>
                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.sub_categories")} : </b></span>
                                    <span
                                    className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.sub_categories && this.state.sub_categories.length > 0 && this.state.sub_categories.toString()}</>
                                    </span>
                                  </p>
                                </div>
                                {this.state.individual_subCategories && this.state.individual_subCategories.length > 0 && (
                                  this.state.individual_subCategories.map(function (value, key) {
                                    return (
                                      <div className="coporate-customer-key-details-main">
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span className="corporate-tit"><b>{value.name[lang]} : </b></span>
                                          {/* <span 
                                                    // className="themeColor"
                                                    >
                                                      {" "}
                                                      <>{value.value}{" "}{t("menu.Pieces")}</>
                                                      <span></span>
                                                    </span>  */}
                                          {value.used_count ? (
                                            <>
                                              <span className="package-information corporate-val"
                                                style={{ minWidth: "24%" }}
                                              >{value.value} {t("promotion_pieces_count")}</span>
                                              <span className="usedCount" style={{ minWidth: "25%" }}>
                                                {"(" + t("package-used") + " " + value.used_count + " " + t("promotion_pieces_count") + ")"}
                                              </span>
                                            </>
                                          ) : (
                                            <span className="package-information">{value.value} {t("promotion_pieces_count")}</span>
                                          )

                                          }
                                        </p>
                                      </div>
                                      // <div className="promo-pack-flex">
                                      //       <div className="package-name">{value.name[lang]}{(" :")}</div>
                                      //       {value.used_count ? (
                                      //           <>
                                      //               <div className="package-information" style={{ width: "25%" }}>{value.value} {t("promotion_pieces_count")}</div>
                                      //               <div className="usedCount">
                                      //                   {"(" + t("package-used") + " " + value.used_count + " " + t("promotion_pieces_count") + ")"}
                                      //               </div>
                                      //           </>
                                      //       ) : (
                                      //           <div className="package-information">{value.value} {t("promotion_pieces_count")}</div>
                                      //       )

                                      //       }
                                      //   </div>
                                    );
                                  })
                                )}

                                {
                                  this.state.editData.corporate_package_type == 1 && this.state.editData.excluded_pieces > 0 && (<>
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.OtherPieces")} : </b></span>
                                        {/* <span 
                                                    // className="themeColor"
                                                    >
                                                      {" "}
                                                      <>{value.value}{" "}{t("menu.Pieces")}</>
                                                      <span></span>
                                                    </span>  */}
                                        {this.state.editData.used_pieces_count ? (
                                          <>
                                            <span className="package-information"
                                              style={{ minWidth: "24%" }}
                                            >{this.state.editData.excluded_pieces} {t("promotion_pieces_count")}</span>
                                            <span className="usedCount" style={{ minWidth: "25%" }}>
                                              {"(" + t("package-used") + " " + this.state.editData.used_pieces_count + " " + t("promotion_pieces_count") + ")"}
                                            </span>
                                          </>
                                        ) : (
                                          <span className="package-information">{this.state.editData.excluded_pieces} {t("promotion_pieces_count")}</span>
                                        )

                                        }
                                      </p>
                                    </div>
                                  </>)
                                }

                                {
                                  this.state.editData.corporate_package_type == 1 && this.state.other_subcategories.length > 0 && (<>
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.Included Sub-Category")} : </b></span>
                                        <span
                                        className="corporate-val"
                                        >
                                          {" "}
                                          <>{this.state.other_subcategories && this.state.other_subcategories.length > 0 && this.state.other_subcategories.toString()}</>
                                        </span>
                                      </p>
                                    </div>
                                  </>)}
                                {
                                  this.state.editData.corporate_package_type == 1 && this.state.excludedCategoryMain && this.state.excludedCategoryMain.length > 0 && (
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.excluded_sub_categories")} : </b></span>
                                        <span
                                        className="corporate-val"
                                        >
                                          {" "}
                                          <>{this.state.excludedCategoryMain && this.state.excludedCategoryMain.length > 0 && this.state.excludedCategoryMain.toString()}</>
                                        </span>
                                      </p>
                                    </div>
                                  )
                                }

                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.service_type")} : </b></span>
                                    <span
                                    className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.service_type && this.state.service_type.length > 0 && this.state.service_type.toString()}</>
                                    </span>
                                  </p>
                                </div>{this.state.editData.expiry_date && (
                                  <div className="coporate-customer-key-details-main">
                                    <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                      <span className="corporate-tit"><b>{t("label_package_expiry_date")} : </b></span>
                                      <span
                                     className="corporate-val"
                                      >
                                        {" "}
                                        <>{this.state.editData.expiry_date}</>
                                      </span>
                                    </p>
                                  </div>
                                )}
                                {this.state.editData && this.state.editData.with_or_without_trans == 'yes' && (
                                  <div className="coporate-customer-key-details-main">
                                    <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                      <span className="corporate-tit"><b>{t("menu.free_delivery")} : </b></span>
                                      <span
                                      className="corporate-val"
                                      >
                                        {" "}
                                        <>{this.state.editData.free_delivery && this.state.editData.free_delivery == 'yes' ? (<>{t("menu.yes")}</>) : (<>{t("menu.no")}</>)}</>
                                      </span>
                                    </p>
                                  </div>
                                )}
                                {
                                  this.state.editData.free_delivery == 'yes' && (
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.minimum_amount_for_free_delivery")} : </b></span>
                                        <span
                                       className="corporate-val"
                                        >
                                          <>{this.state.editData.customer_minimum_order_amount && this.state.editData.customer_minimum_order_amount}{" "}{t("menu.OMR")}</>
                                        </span>
                                      </p>
                                    </div>
                                  )
                                }
                                <div className="coporate-customer-key-details-main">
                                  <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                    <span className="corporate-tit"><b>{t("menu.carry_forward")} : </b></span>
                                    <span
                                   className="corporate-val"
                                    >
                                      {" "}
                                      <>{this.state.editData.balance_carry_forward && this.state.editData.balance_carry_forward == 'yes' ? (<>{t("menu.yes")}</>) : (<>{t("menu.no")}</>)}</>
                                    </span>
                                  </p>
                                </div>
                                {
                                  this.state.editData.balance_carry_forward == 'yes' && (
                                    <div className="coporate-customer-key-details-main">
                                      <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                        <span className="corporate-tit"><b>{t("menu.carry_forward_validity")} : </b></span>
                                        <span
                                        className="corporate-val"
                                        >
                                          {" "}
                                          <>{this.state.editData.carry_forward_validity && this.state.editData.carry_forward_validity}{" "}{t("menu.month")}</>
                                        </span>
                                      </p>
                                    </div>
                                  )
                                }
                              </div>
                            </div>

                            {
                              this.state.editData && this.state.editData.corporate_package_commision_for == 'staff_pay' && this.state.editData.isCustomerHavingActivePackage == 0 && (
                                <>
                                  {this.state.UserWalletAmount > 0 && (
                                    <div className="payment_section">
                                      <div className="copuncode d-flex justify-content-between align-items-center">
                                        <h5>{t("commons.use_wallet")}</h5>
                                        <Formik
                                          enableReinitialize
                                          initialValues={{
                                          }}
                                        >
                                          {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, values, errors, touched, isSubmitting }) => (
                                            <Form className="applycoupon">
                                              <Row className="coupon_row">
                                                <Col xs={12} md={6}>

                                                  <p>{this.state.UserWalletAmount.toFixed(3) + " " + t("commons.balanceomr")}</p>
                                                </Col>
                                                <Button className="btn text-uppercase" type="button" onClick={() => this.handleWallet(this.state.UserWalletAmount)}>
                                                  {t("commons.apply")}
                                                </Button>

                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </div>

                                    </div>
                                  )}
                                  {cardList &&
                                    cardList.length > 0 && this.state.editData.isCustomerHavingActivePackage == 0 &&
                                    <div className="copuncode" style={{ width: "50%" }}>
                                      {
                                        cardList.map((item, index) => {
                                          return (
                                            <span key={index} className="addonouter d-flex justify-content-between align-items-center credit_card_arabic_amount">
                                              {
                                                this.state.lang == 'en' ? (
                                                  <>
                                                    <span className="custom_radio">
                                                      <p className="m-0">{item.brand + " - " + item.masked_card}</p>
                                                      <input
                                                        type="checkbox"
                                                        className="shampoo_opts "
                                                        name="shampoo"
                                                        value={item.id}
                                                        onClick={(event) => {
                                                          this.addCardById(event);
                                                        }}
                                                      />
                                                      <span className="radio_indicator">&nbsp;</span>
                                                    </span>
                                                    <p className="m-0">{t("commons.Expire_on") + " -- " + item.expiry_month + "/" + item.expiry_year}</p>
                                                    <span className="btn_cancel btn_cancel_close" onClick={(e) => this.deleteCard(item)}>
                                                      <i className="fas fa-trash-alt"></i>
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="btn_cancel btn_cancel_close" onClick={(e) => this.deleteCard(item)}>
                                                      <i className="fas fa-trash-alt"></i>
                                                    </span>
                                                    <p className="m-0">{t("commons.Expire_on") + " -- " + item.expiry_month + "/" + item.expiry_year}</p>

                                                    <span className="custom_radio">
                                                      <p className="m-0">{item.brand + " - " + item.masked_card}</p>
                                                      <input
                                                        type="checkbox"
                                                        className="shampoo_opts "
                                                        name="shampoo"
                                                        value={item.id}
                                                        onClick={(event) => {
                                                          this.addCardById(event);
                                                        }}
                                                      />
                                                      <span className="radio_indicator">&nbsp;</span>
                                                    </span>
                                                  </>
                                                )
                                              }


                                            </span>

                                          );
                                        })}
                                    </div>
                                  }
                                  {reduced_amount > 0 && (
                                    <div className="balance_amount_section">
                                      <div className="balance_amount_flex1">
                                        <div className="balance_amount_title">{t("package_price")}</div>
                                        <div className="balance_wallet_amount">{(this.state.actual_corporate_package_price).toFixed(3) + " " + (t("commons.balanceomr"))}</div>
                                      </div>
                                      <div className="balance_amount_flex1">
                                        <div className="balance_amount_title" style={{ color: "red" }}>{t("Wallet_amount_applied")}</div>
                                        <div className="wallet_amount_deduction">
                                          <div className="balance_wallet_amount" style={{ color: "red" }}>{(this.state.reduced_amount).toFixed(3) + " " + (t("commons.balanceomr"))}</div>
                                          <span className="close" onClick={() => {
                                            this.getWalletData('remove_wallet');
                                          }}>x</span>
                                        </div>
                                      </div>

                                      <div className="balance_amount_flex1">
                                        <div className="balance_amount_title">{t("wallet_balance_amount")}</div>
                                        <div className="balance_wallet_amount">{(this.state.origin_amount).toFixed(3) + " " + (t("commons.balanceomr"))}</div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )

                            }


                            {this.state.editData.is_corporate_package_active != undefined && this.state.editData.is_corporate_package_active == 1 ? (<>
                            </>) : (<>
                              <div className="coporate-customer-key-details-activate-button">
                                <span style={{
                                  textAlign: 'end',
                                  paddingTop: '11px',
                                  color: 'green',
                                  fontWeight: 'bold'
                                }}>
                                  {
                                    this.state.editData.corporate_package_commision_for == 'staff_pay' && this.state.walletUsed == 'no' && (
                                      <div className="coporate-customer-key-details-main1">
                                        <p style={{ fontSize: '16px' }} className="coporate-customer-key-details">
                                          <span><b>{t("menu.actual_corporate_package_price_info_one")} {this.state.editData.actual_corporate_package_price}{" "}{t("menu.OMR")}{" "}</b></span>
                                        </p>
                                      </div>
                                    )
                                  }
                                </span>
                                {
                                  this.state.editData && this.state.editData.corporate_package_commision_for && this.state.editData.corporate_package_commision_for == 'staff_pay' ? (<>
                                    <Button
                                      style={{ pointerEvents: this.state.editData.isCustomerHavingActivePackage > 0 ? 'none' : 'unset' }}
                                      disabled={this.state.editData.isCustomerHavingActivePackage == 0 ? false : true} onClick={() => this.customerActivateCorporatePackage("staff_pay")} className="btn" type="button">
                                      {t("menu.Pay_Now")}
                                    </Button>
                                  </>) : (<>
                                    <Button
                                      style={{ pointerEvents: this.state.editData.isCustomerHavingActivePackage > 0 ? 'none' : 'unset' }}
                                      disabled={this.state.editData.isCustomerHavingActivePackage == 0 ? false : true} onClick={() => this.customerActivateCorporatePackage("company_pay")} className="btn" type="button">
                                      {t("menu.Activate")}
                                    </Button>
                                  </>)
                                }
                              </div>
                            </>)

                            }
                          </div>
                        </Col>
                      </div>

                    </>
                  )}
                </>
              </Row>
            )}
            <div className="sidebar_overlay" onClick={SidebarRemoveClass}>
              {" "}
            </div>
          </section>
        )}
      </Translation>
    );
  }
}