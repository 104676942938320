import styled from 'styled-components';

export const StyledButtondiv = styled.span`
 background-color: ${({ theme }) => theme.colors.primary} !important;
 color: ${({ theme }) => theme.colors.secondary} !important;
 font-family: ${({ theme }) => theme.font.family};
 border: 1px solid ${({ theme }) => theme.colors.primary} !important;
 transition: background-color 0.3s;
 border-radius: .25rem;
 font-size: 1.21rem;
 padding: .85rem 2rem;
 &:hover {
   background-color: ${({ theme }) => theme.colors.secondary} !important;
   color: ${({ theme }) => theme.colors.primary} !important;
 }
`;

export const StyledButtondiv1 = styled.button`
 background-color: ${({ theme }) => theme.colors.primary} !important;
 color: ${({ theme }) => theme.colors.secondary} !important;
 font-family: ${({ theme }) => theme.font.family};
 border: 1px solid ${({ theme }) => theme.colors.primary} !important;
 transition: background-color 0.3s;
 border-radius: .25rem;
 font-size: 1.21rem;
 padding: .85rem 2rem;
 &:hover {
   background-color: ${({ theme }) => theme.colors.secondary} !important;
   color: ${({ theme }) => theme.colors.primary} !important;
 }
`;

export const StyledButtondiv2 = styled.button`
 background-color: ${({ theme }) => theme.colors.secondary} !important;
 color: ${({ theme }) => theme.colors.primary} !important;
 font-family: ${({ theme }) => theme.font.family};
 border: 1px solid ${({ theme }) => theme.colors.primary} !important;
 transition: background-color 0.3s;
 border-radius: .25rem;
 font-size: 1.21rem;
 padding: .85rem 2rem;
 &:hover {
   background-color: ${({ theme }) => theme.colors.primary} !important;
   color: ${({ theme }) => theme.colors.secondary} !important;
 }
`;

export const StyledButtondiv3 = styled.button`
 background-color: ${({ theme }) => theme.colors.secondary} !important;
 color: ${({ theme }) => theme.colors.primary} !important;
 font-family: ${({ theme }) => theme.font.family};
 border: 1px solid ${({ theme }) => theme.colors.primary} !important;
 transition: background-color 0.3s;
 border-radius: .25rem;
 font-size: 1.21rem;
 padding: .85rem 2rem;
 &:hover {
   background-color: ${({ theme }) => theme.colors.primary} !important;
   color: ${({ theme }) => theme.colors.secondary} !important;
   border: 1px solid  ${({ theme }) => theme.colors.secondary} !important;
 }
`;

export const StyledButtondiv4 = styled.button`
 background-color: ${({ theme }) => theme.colors.primary} !important;
 color: ${({ theme }) => theme.colors.secondary} !important;
 font-family: ${({ theme }) => theme.font.family};
 border: 1px solid ${({ theme }) => theme.colors.primary} !important;
 transition: background-color 0.3s;
 border-radius: .25rem;
 font-size: 1rem;
 padding: .6rem .8rem;;
 &:hover {
   background-color: ${({ theme }) => theme.colors.secondary} !important;
   color: ${({ theme }) => theme.colors.primary} !important;
 }
`;

export const StyledStrong = styled.strong`
  font-family: ${({ theme }) => theme.font.family};
  color: ${({ theme }) => theme.colors.primary} !important;
`;

export const StyledSpan = styled.span`
  font-family: ${({ theme }) => theme.font.family};
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 5px; /* Optional: Add some space between text and value */
`;
// export default StyledButtondiv;