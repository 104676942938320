import React, { Component } from "react";
import { Col, Container, Row, Form, Label, Input, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import InfiniteScroll from "react-infinite-scroller";
import { ThemeContext } from "styled-components";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import {ThemeButtonLink} from "../../helpers/Button";
import {StyledButtondiv3} from '../../helpers/StyledButtondiv';
import AuthService from "../../services/auth.service";

import { Translation } from "react-i18next";

const DeliveryTimeOpts = [
  { value: 1, label: "commons.1_Day" },
  { value: 2, label: "commons.2_Days" },
  { value: 3, label: "commons.3_Days" },
  { value: 4, label: "commons.4_Days" },
  { value: 5, label: "commons.5_Days" },
];
const MediumDeliveryTimeOpts = [
  { value: 2, label: "commons.2_Days" },
  { value: 4, label: "commons.4_Days" },
  { value: 6, label: "commons.6_Days" },
  { value: 8, label: "commons.8_Days" },
  { value: 10, label: "commons.10_Days" },
];

const LongerDeliveryTimeOpts = [
  { value: 5, label: "commons.5_Days" },
  { value: 10, label: "commons.10_Days" },
  { value: 15, label: "commons.15_Days" },
  { value: 20, label: "commons.20_Days" },
  { value: 25, label: "commons.25_Days" },
];
const RatingOpts = [
  { value: 1, label: "1+" },
  { value: 2, label: "2+" },
  { value: 3, label: "3+" },
  { value: 4, label: "4+" },
  { value: 5, label: "5+" },
];

const sortOpts = [
  { value: "nearest", label: "selects.Nearest" },
  { value: "newly_added", label: "selects.Newly_Added" },
  { value: "most_popular", label: "selects.Most_Popular" },
];

const packageOpts = [
  { value: 'yes', label: "packages.With_package" },
  { value: 'no', label: "packages.without_package" },

];
const hasMoreItems = true;

var coords = AuthService.getCurrentLocation();

export default class Dashboard extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.oneRef = React.createRef();

    this.state = {
      vendorsList: [],
      modal: false,
      service_providers: [],
      cleaning_typeList: [],
      tagsList: [],
      keyword: "",
      cleaning_type: "",
      service_provider: "",
      tag: "",
      delivery_time: "",
      medium_delivery_time: "",
      longer_delivery_time: "",
      // minimum_order_amount: 0,
      rating: "",
      latitude: coords && coords.latitude,
      longitude: coords && coords.longitude,
      max_distance: 0,
      sort_by: "nearest",
      vendor_availablity_km: 25,
      currentPage: 1,
      limit: 10,
      hasMoreItems: true,
      isLoading: false,
      resetData: false,
      lang: "",
      searchText: "",
      itemData: [],
      measure_type: "",
      freedelivery: '',
      packageData: '',
      vendor_package_data: 0,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.setState({ lang: localStorage.getItem("lang") || "en" });
    // this.getServiceProviders();
    // this.getCleaningType();
    // this.getTags();
    // this.getSettings();
    this.getAllData();
  }

  getSettings = async () => {
    let path = ApiRoutes.GET_SETTINGS;

    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();
      if (res.status == 200) {
        this.setState({
          vendor_availablity_km: resJson.data.settingsData.vendor_availablity_km,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };
  getAllData = async () => {
    let path = ApiRoutes.GET_ALL_DATA;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        this.setState({
          itemData: resJson.data,
          vendor_availablity_km: resJson.data.vendor_availablity_km,
        });
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };



  // getCleaningType = async () => {
  //   let path = ApiRoutes.GET_CLEANINGTYPE;

  //   const res = await Http("GET", path);
  //   if (res) {
  //     const resJson = await res.json();

  //     if (res.status == 200) {
  //       this.setState({
  //         cleaning_typeList: resJson.data.docs,
  //       });
  //     } else {
  //       NotificationManager.error(resJson.message, "Error!", 3000);
  //     }
  //   } else {
  //     NotificationManager.error("Server Error", "Error!", 3000);
  //   }
  // };

  // getTags = async () => {
  //   let path = ApiRoutes.GET_TAGS;

  //   const res = await Http("GET", path);
  //   if (res) {
  //     const resJson = await res.json();

  //     if (res.status == 200) {
  //       this.setState({
  //         tagsList: resJson.data.docs,
  //       });
  //     } else {
  //       NotificationManager.error(resJson.message, "Error!", 3000);
  //     }
  //   } else {
  //     NotificationManager.error("Server Error", "Error!", 3000);
  //   }
  // };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchText !== this.state.searchText) {
      this.handleCheck();
    }
  }

  handleSearch = (e) => {
    this.setState({ searchText: e.target.value });
  };

  handleCheck = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.scroll.pageLoaded = 0;
      this.getVendors(1);
    }, 500);
  };
  getVendors = async (currentPage) => {
    let path =
      ApiRoutes.GET_VENDORS +
      "?page_no=" +
      `${currentPage}` +
      "&limit=" +
      `${this.state.limit}` +
      "&keyword=" +
      `${this.state.searchText}` +
      "&cleaning_type=" +
      `${this.state.cleaning_type}` +
      "&service_provider=" +
      `${this.state.service_provider}` +
      "&tag=" +
      `${this.state.tag}` +
      // "&minimum_order_amount=" +
      // `${this.state.minimum_order_amount}`+
      "&delivery_time=" +
      `${this.state.delivery_time}` +
      "&medium_delivery_time=" +
      `${this.state.medium_delivery_time}` +
      "&longer_delivery_time=" +
      `${this.state.longer_delivery_time}` +
      "&rating=" +
      `${this.state.rating}` +
      "&latitude=" +
      `${this.state.latitude}` +
      "&longitude=" +
      `${this.state.longitude}` +
      "&max_distance=" +
      `${this.state.max_distance}` +
      "&sort_by=" +
      `${this.state.sort_by}` +
      "&measure_type=" +
      `${this.state.measure_type}` +
      "&freeDelivery=" +
      `${this.state.freedelivery}` +
      "&packageData=" +
      `${this.state.packageData}`;
    const res = await Http("GET", path);

    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        resJson.data.docs.map((item, index) => {
          if (item.vendorPackage == 1) {
            this.setState({ vendor_package_data: 1 });
          }
        })
        if (currentPage == 1) {

          this.setState({ vendorsList: [] }, () =>
            this.setState({
              vendorsList: [...this.state.vendorsList, ...resJson.data.docs],
              hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
            })
          );
        } else {
          this.setState({
            vendorsList: [...this.state.vendorsList, ...resJson.data.docs],
            hasMoreItems: currentPage < resJson.data.totalPages ? true : false,
          });
        }
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  onChangeSortBy = async (sort_by) => {
    this.scroll.pageLoaded = 0;

    this.setState(
      {
        resetData: true,
        sort_by: sort_by,
        modal: false,
      },
      () => this.getVendors(1)
    );
  };

  toggleFilterBox = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  hideFilterBox = () => {
    this.setState({
      modal: false,
    });
  };

  onApplyFilter = async () => {
    this.scroll.pageLoaded = 0;
    this.setState(
      {
        resetData: true,
        modal: false,
      },
      () => this.getVendors(1)
    );
  };

  onResetFilter = () => {
    this.scroll.pageLoaded = 0;
    this.setState(
      {
        freedelivery: true,
        resetData: true,
        modal: false,
        keyword: "",
        cleaning_type: "",
        service_provider: "",
        measure_type: "",
        tags: "",
        tag: "",
        delivery_time: "",
        medium_delivery_time: "",
        longer_delivery_time: "",
        // minimum_order_amount: 0,
        rating: "",
        max_distance: 0,
        sort_by: "nearest",
      },
      () => this.getVendors(1)
    );
  };

  setFavourite = async (vendor_id, is_favourite, index) => {
    var action = is_favourite ? "unfavourite" : "favourite";
    var new_favourite = is_favourite ? 0 : 1;

    let formData = new FormData();
    formData.append("vendor_id", vendor_id);
    formData.append("action", action);

    let path = ApiRoutes.SET_FAVOURITES;
    const res = await Http("POST", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        let vendorsList = Object.assign([], this.state.vendorsList);
        vendorsList[index].is_favourite = new_favourite;
        this.setState({ vendorsList: vendorsList });

        NotificationManager.success(resJson.message, "Success!", 3000);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    const theme = this.context;
    var { authUser } = this.props;
    var { service_providers, cleaning_typeList, lang, itemData } = this.state;
    const loader = <div className="loading"></div>;
    return (
      <Translation>
        {(t) => (
          <>
            <section className="find_laundries_sec py-4 py-md-5" style={{ backgroundColor: "#F5FDFE", minHeight: "calc(100vh - 190px)" }}>
              <Container className="py-3 py-md-4">
                <div>
                  <InfiniteScroll
                    ref={(scroll) => {
                      this.scroll = scroll;
                    }}
                    pageStart={0}
                    // loadMore={this.loadPagination}
                    loadMore={this.getVendors.bind(this)}
                    hasMore={this.state.hasMoreItems}
                    loader={loader}
                  >
                    <div className="vendor_list_filter_sec mb-4 mb-md-5">
                      <h2 className="section_title">{t("contents.Find_Laundries")}</h2>

                      <div className="sortby_filter_sec">
                        <Form className="ml-auto d-flex align-items-center">
                          <Input
                            className="form-control mr-3 w-100 ml-3"
                            type="text"
                            placeholder={t("commons.Search_by_keyword")}
                            name="search"
                            onChange={this.handleSearch}
                            onKeyDown={(e) => {
                              if (e.keyCode == 13) {
                                e.preventDefault();
                              }
                            }}
                          ></Input>

                          <Input
                            className="form-control w-100"
                            type="select"
                            name="select"
                            defaultValue={this.state.sort_by}
                            onChange={(e) => {
                              this.onChangeSortBy(e.target.value);
                            }}
                          >
                            <option disabled value="">
                              {t("selects.Sort_By")}
                            </option>
                            {sortOpts.map((item, index) => {
                              return (
                                <option key={index} value={item.value}>
                                  {t(item.label)}
                                </option>
                              );
                            })}
                          </Input>
                        </Form>
                        <span className="filter_view" onClick={this.toggleFilterBox}>
                          <img src="./assets/img/filter.svg" alt="Filter" />
                        </span>
                      </div>
                    </div>
                    <ul className="laundries_list home_laundries_list">
                      {this.state.vendorsList.map((item, index) => {
                        return (
                          <li key={index}>
                            <div className="w-100 laundries_desc">
                              <div className="laundries_desc_img">
                                <img src={item.user_image_thumb_url} alt={item.shop_name && item.shop_name[lang]} />
                              </div>
                              <div className="laundries_desc_data">
                                <h3 className="laundries_desc_data_title mb-2">{item.shop_name && item.shop_name[lang]}</h3>
                                <p className="branch_title mb-2">
                                  <b> {item.branch_name && item.branch_name[lang]}</b>
                                </p>
                                {item.customerCorporatePackage == 1 &&
                                  <div className="overlay-corporate-ribbon"><span>
                                    <Link to={"/corporate-package"} >{t("purchased_corporate_package")}</Link>
                                  </span></div>
                                }
                                {
                                  item.customerPackage == 1 && (<div className="overlay-ribbon"><span>
                                    <Link to={"/promotions"} >{t("purchased_package")}</Link>
                                  </span></div>)
                                }

                                <ul className="laundries_desc_data_list d-flex flex-wrap">
                                  <li>
                                    {t("labels.Service_Provider")} :{" "}
                                    {item.service_providers.length > 0
                                      ? item.service_providers.map((item, index) => {
                                        return <span key={index}>{(index ? ", " : "") + item.name[lang]}</span>;
                                      })
                                      : t("commons.N/A")}
                                  </li>
                                  {/* <li>
                                    {t("labels.Express_Delivery")}: {item.is_normal_express_delivery == 1 ? t("commons.Available") : t("commons.Not Available")}
                                  </li> */}
                                  <li>
                                    {t("labels.Express_Delivery_Time")}:{" "}
                                    {item.normal_express_delivery_time
                                      ? item.normal_express_delivery_time > 1
                                        ? item.normal_express_delivery_time + " " + t("commons.Hours")
                                        : item.normal_express_delivery_time + " " + t("commons.Hour")
                                      : t("commons.N/A")}
                                  </li>
                                  <li>
                                    {t("labels.Normal_Delivery_Time")}:{" "}
                                    {item.normal_delivery_time
                                      ? item.normal_delivery_time > 1
                                        ? item.normal_delivery_time + " " + t("commons.Days")
                                        : item.normal_delivery_time + " " + t("commons.Day")
                                      : t("commons.N/A")}
                                  </li>
                                  {/* <li>
                                    {t("labels.Minimum_Order_Value")}:{" "}
                                    {item.minimum_order_amount ? t("commons.OMR") + " " + item.minimum_order_amount.toFixed(3) : t("commons.N/A")}
                                  </li> */}
                                </ul>
                                <div className="lable_tag_vendor new">
                                  <ul>
                                    {authUser && item && item.is_freedelivery == 'available' && (
                                      <li className="freedelivery">{t("contents.Free-delivery")}</li>
                                    )}
                                    {authUser && item && item.is_discount == 'available' && (
                                      lang == 'en' ?
                                        <li className="discount">{item && item.discount_type == 'percent' ? item.discount_amount + "% " + t("contents.Discount") : t("contents.OMR") + item.discount_amount + " " + t("contents.Discount")}</li> :
                                        <li className="discount">{item && item.discount_type == 'percent' ? t("contents.Discount") + item.discount_amount + " % " : t("contents.Discount") + item.discount_amount + " " + t("contents.OMR")}</li>
                                    )}
                                    {authUser && item && item.is_discount_subcategory == 'available' && (
                                      <li className="discount_subcategory">{t("contents.Sub_Discount")}</li>
                                    )}
                                    {item && item.vendorPackage == 1 && (
                                      <li className="package">{t("package-available-section")}</li>
                                    )}

                                    {/* display corporate package tag */}
                                    {item && item.vendorCorporatePackage == 1 && (
                                      <li className="corporate-package">{t("corporate-package-available-section")}</li>
                                    )}
                                  </ul>
                                </div>
                              </div>

                            </div>
                            <div className="laundries_rates">
                              <div className="like_rate_view d-inline-flex align-items-center justify-content-end w-100 mb-3 mb-md-4">
                                {authUser && (
                                  <div
                                    className={"ml-4 " + (item.is_favourite ? "like_view" : "unlike_view")}
                                    onClick={() => {
                                      this.setFavourite(item._id, item.is_favourite, index);
                                    }}
                                  >
                                    <i className="fas fa-heart"></i>
                                  </div>
                                )}
                              </div>
                              <div>
                                <div className="ratting_view mb-3">
                                  <i className="fas fa-star mr-2"></i>
                                  {item.average_rating ? item.average_rating.toFixed(1) : "0.0"}
                                </div>

                                {/* <Link to={"vendor-details/" + item._id} className="btn">
                                  {t("buttons.View_Detail")}
                                </Link> */}
                                <ThemeButtonLink
                                  link={"vendor-details/" + item._id} // Dynamic link
                                  buttonText={t("buttons.View_Detail")}
                                />
                                {item && item.is_available ? (
                                  <span
                                    className="distance"
                                    style={{
                                      color: "green",
                                      fontWeight: "400",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {" "}
                                    {t("contents.Online")}
                                  </span>
                                ) : (
                                  <span
                                    className="distance"
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {" "}
                                    {t("contents.Offline")}
                                  </span>
                                )}

                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {this.state.vendorsList && this.state.vendorsList.length === 0 && (
                      <center>
                        <h6>{t("commons.No_available_laundries")}</h6>{" "}
                      </center>
                    )}
                  </InfiniteScroll>
                </div>

                {this.state.isLoading && this.state.vendorsList.length == 0 && <div>{t("commons.No_laundries_are_available.")}</div>}
              </Container>
            </section>

            <Modal className="modal_view_sec" isOpen={this.state.modal} backdrop={"static"} keyboard={false} toggle={this.toggleFilterBox}>
              <div className="filter_modal_header" style={{backgroundColor: theme.colors.primary}}>
                {/* <span onClick={this.onResetFilter} style={{ cursor: "pointer" }} className="btn1">
                  {t("filters.Clear")}
                </span> */}
                <StyledButtondiv3 onClick={this.onResetFilter}>
                  {t("filters.Clear")}
                </StyledButtondiv3>
                <div className="filter_modal_heading">{t("filters.Filter_By")}</div>
                <StyledButtondiv3 onClick={this.onApplyFilter}>
                  {t("filters.Apply")}
                </StyledButtondiv3>
                {/* <span onClick={this.onApplyFilter} style={{ cursor: "pointer" }} className="btn1">
                  {t("filters.Apply")}
                </span> */}
              </div>
              <ModalBody>
                {/* <FormGroup className="mb-5" dir="ltr">
                  <Label className="field_view_name pb-4">{t("filters.Minimum_Order_Amount")}</Label>
                  <InputRange
                    maxValue={100}
                    minValue={0}
                    value={parseInt(this.state.minimum_order_amount)}
                    onChange={(minimum_order_amount) => this.setState({ minimum_order_amount })}
                  />
                </FormGroup> */}
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("labels.Normal_Delivery_Time")}</Label>
                  <ul className="daycheck_type_list_sec">
                    {DeliveryTimeOpts.map((item, index) => {
                      return (
                        <li key={index}>
                          <input
                            type="radio"
                            name="estimate"
                            value={item.value}
                            defaultChecked={item.value == this.state.delivery_time}
                            onChange={(event) => this.setState({ delivery_time: event.target.value })}
                          />
                          <div className="daycheck_type_list">{t(item.label)}</div>
                        </li>
                      );
                    })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("labels.Medium_Delivery_Time")}</Label>
                  <ul className="daycheck_type_list_sec">
                    {MediumDeliveryTimeOpts.map((item, index) => {
                      return (
                        <li key={index}>
                          <input
                            type="radio"
                            name="estimate"
                            value={item.value}
                            defaultChecked={item.value == this.state.medium_delivery_time}
                            onChange={(event) => this.setState({ medium_delivery_time: event.target.value })}
                          />
                          <div className="daycheck_type_list">{t(item.label)}</div>
                        </li>
                      );
                    })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("labels.Longer_Delivery_Time")}</Label>
                  <ul className="daycheck_type_list_sec">
                    {LongerDeliveryTimeOpts.map((item, index) => {
                      return (
                        <li key={index}>
                          <input
                            type="radio"
                            name="estimate"
                            value={item.value}
                            defaultChecked={item.value == this.state.longer_delivery_time}
                            onChange={(event) => this.setState({ longer_delivery_time: event.target.value })}
                          />
                          <div className="daycheck_type_list">{t(item.label)}</div>
                        </li>
                      );
                    })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5" dir="ltr">
                  <Label className="field_view_name pb-4">{t("filters.Max_distance_from_your_location")}</Label>
                  <InputRange maxValue={this.state.vendor_availablity_km} minValue={0} value={parseInt(this.state.max_distance)} onChange={(max_distance) => this.setState({ max_distance })} />
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("filters.Rating")}</Label>
                  <ul className="daycheck_type_list_sec rating">
                    {RatingOpts.map((item, index) => {
                      return (
                        <li key={index}>
                          <Input
                            type="radio"
                            name="rating"
                            value={item.value}
                            onChange={(event) => this.setState({ rating: event.target.value })}
                            defaultChecked={item.value == this.state.rating}
                          />
                          <div className="daycheck_type_list">
                            <i className="fas fa-star mr-2"></i>
                            {item.label}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("filters.Tags")}</Label>
                  <ul className="daycheck_type_list_sec rating">
                    {itemData &&
                      itemData.tagsList &&
                      itemData.tagsList.length > 0 &&
                      itemData.tagsList.map((item, index) => {
                        return (
                          <li key={index}>
                            <Input
                              type="radio"
                              name="tags"
                              value={item._id}
                              onChange={(event) => this.setState({ tags: event.target.value })}
                              defaultChecked={this.state.tags && this.state.tags == item._id}
                            />
                            <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                          </li>
                        );
                      })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("filters.Cleaning_Type")}</Label>
                  <ul className="daycheck_type_list_sec rating">
                    {itemData &&
                      itemData.cleaningTypesDataList &&
                      itemData.cleaningTypesDataList.length > 0 &&
                      itemData.cleaningTypesDataList.map((item, index) => {
                        return (
                          <li key={index}>
                            <Input
                              type="radio"
                              name="cleaning_type"
                              value={item._id}
                              defaultChecked={this.state.cleaning_type && this.state.cleaning_type == item._id}
                              onChange={(event) => this.setState({ cleaning_type: event.target.value })}
                            />
                            <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                          </li>
                        );
                      })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("labels.Service_Provider")}</Label>
                  <ul className="daycheck_type_list_sec rating">
                    {itemData &&
                      itemData.serviceProvidersList &&
                      itemData.serviceProvidersList.length > 0 &&
                      itemData.serviceProvidersList.map((item, index) => {
                        return (
                          <li key={index}>
                            <Input
                              type="radio"
                              name="service_provider"
                              value={item._id}
                              defaultChecked={this.state.service_provider && this.state.service_provider == item._id}
                              onChange={(event) =>
                                this.setState({
                                  service_provider: event.target.value,
                                })
                              }
                            />
                            <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                          </li>
                        );
                      })}
                  </ul>
                </FormGroup>

                <FormGroup className="mb-5">
                  <Label className="field_view_name pb-2">{t("commons.Measure_Type")}</Label>
                  <ul className="daycheck_type_list_sec rating">
                    {itemData &&
                      itemData.measureTypesList &&
                      itemData.measureTypesList.length > 0 &&
                      itemData.measureTypesList.map((item, index) => {
                        return (
                          <li key={index}>
                            <Input
                              type="radio"
                              name="measure_type"
                              value={item._id}
                              defaultChecked={this.state.measure_type && this.state.measure_type == item._id}
                              onChange={(event) =>
                                this.setState({
                                  measure_type: event.target.value,
                                })
                              }
                            />
                            <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                          </li>
                        );
                      })}
                  </ul>
                </FormGroup>
                <FormGroup className="mb-5">
                  {itemData &&
                    itemData.freeDelivery &&
                    itemData.freeDelivery.length > 0 && <Label className="field_view_name pb-2">{t("filters.Free-delivery")}</Label>}

                  <ul className="daycheck_type_list_sec">
                    {itemData &&
                      itemData.freeDelivery &&
                      itemData.freeDelivery.length > 0 &&
                      itemData.freeDelivery.map((item, index) => {
                        return (
                          <li key={index}>
                            <Input
                              type="radio"
                              name="freedelivery"
                              value={item._id}
                              defaultChecked={this.state.freedelivery && this.state.freedelivery == item._id}
                              onChange={(event) =>
                                this.setState({
                                  freedelivery: event.target.value,
                                })
                              }
                            />
                            <div className="daycheck_type_list">{lang && item.name[lang]}</div>
                          </li>
                        );
                      })}

                  </ul>
                </FormGroup>
                {
                  this.state.vendor_package_data == 1 ?
                    <FormGroup className="mb-5">
                      <Label className="field_view_name pb-2">{t("label_promotion_package")}</Label>
                      <ul className="daycheck_type_list_sec">
                        {
                          packageOpts.map((item, index) => {
                            return (
                              <li key={index}>
                                <input
                                  type="radio"
                                  name="estimate"
                                  value={item.value}
                                  // defaultChecked={item.value == this.state.packageData}
                                  onChange={(event) => this.setState({ packageData: event.target.value })}
                                />
                                <div className="daycheck_type_list">{t(item.label)}</div>
                              </li>
                            );
                          })
                        }

                      </ul>
                    </FormGroup>
                    : <></>
                }
              </ModalBody>
            </Modal>
          </>
        )}
      </Translation>
    );
  }
}
